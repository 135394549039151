import {
  ACTION_GET_USER_ACCESS_IS_ON_REQUEST,
  ACTION_GET_USER_ACCESS,
  ACTION_GET_USER_ACCESS_OBTAINED,
} from '../actions/access';

const initialState = {
  userAccess: null,
  userAccessIsOnRequest: false,
  userAccessObtained: false,
};

export default function access(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_USER_ACCESS:
      return {
        ...state,
        userAccess: action.payload,
      };
    case ACTION_GET_USER_ACCESS_IS_ON_REQUEST:
      return {
        ...state,
        userAccessIsOnRequest: action.payload,
      };
    case ACTION_GET_USER_ACCESS_OBTAINED:
      return {
        ...state,
        userAccessObtained: action.payload,
      };
    default:
      return state;
  }
}
