import React from 'react';
import './UserForm.less';
import { Drawer, notification } from 'antd';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import AdvancedInput from '../../shared/AdvancedInput';
import AdvancedSelect from '../../shared/AdvancedSelect';
import AdvancedCheckbox from '../../shared/AdvancedCheckbox';
import AdvancedTextArea from '../../shared/AdvancedTextArea';

import Loading from '../../shared/Loading';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedDatePicker from '../../shared/AdvancedDatePicker';
import AdvancedMaskedInput from '../../shared/AdvancedMaskedInput';

import { ViewTypes } from '../../../app/enum/view_types';
import DocumentTypes from '../../../app/enum/document_types';

import * as UserTypes from '../../../app/enum/user_types';
import * as UserStatus from '../../../app/enum/user_status';

import { UserActions } from '../../../app/redux/actions';
import { UserSelectors } from '../../../app/redux/reducers';


const initialValues = {
  user: { documentType: DocumentTypes.CNPJ, status: UserStatus.UserStatus.WAITING_FIRST_LOGIN },
  userValuesToUpdate: {},
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialValues;
  }

  componentDidUpdate(prevProps) {
    const { id, type } = this.props;
    if (id && !prevProps.visible && type === ViewTypes.EDIT) {
      this.getUser(id);
    }
  }

  getUser = async (id) => {
    const { onGetUser } = this.props;
    const { user } = await onGetUser(id);
    let newUser;
    const { lastFupAt, nextFupAt } = user;
    newUser = { ...user };
    if (lastFupAt) {
      newUser = { ...newUser, lastFupAt: new Date(lastFupAt) };
    }
    if (nextFupAt) {
      newUser = { ...newUser, nextFupAt: new Date(nextFupAt) };
    }

    this.setState({ user: newUser });
  };

  onSubmit = async () => {
    const { user, userValuesToUpdate } = this.state;
    const { id, type } = this.props;
    const { onCreateUser, onUpdateUser } = this.props;

    const invalidFields = [];
    if (type === ViewTypes.CREATE) {
      if (!user.type) invalidFields.push(I18n.t('forms.user.invalidFields.type'));
      if (user.documentType === DocumentTypes.CNPJ && !user.document)
        invalidFields.push(I18n.t('forms.user.invalidFields.cnpj'));
      if (!user.name) invalidFields.push(I18n.t('forms.user.invalidFields.name'));
      if (!user.email) invalidFields.push(I18n.t('forms.user.invalidFields.email'));
      if (!user.password) invalidFields.push(I18n.t('forms.user.invalidFields.password'));
      if (!user.confirmPassword) invalidFields.push(I18n.t('forms.user.invalidFields.confirmPassword'));
      if (user.password && user.confirmPassword && user.password !== user.confirmPassword)
        invalidFields.push(I18n.t('forms.user.invalidFields.confirmPasswordWrong'));
    }

    if (type === ViewTypes.EDIT) {
      if (userValuesToUpdate.documentType === DocumentTypes.CNPJ && !userValuesToUpdate.document)
        invalidFields.push(I18n.t('forms.user.invalidFields.cnpj'));
    }

    if (invalidFields.length > 0) {
      return notification.error({
        message: I18n.t('forms.user.invalidFields.validation.required.title'),
        description: I18n.t('forms.user.invalidFields.validation.required.description', {
          fields: invalidFields.join(', '),
        }),
      });
    }

    let success;
    if (type === ViewTypes.CREATE) {
      success = await onCreateUser(user);
    } else if (id && type === ViewTypes.EDIT) {
      success = await onUpdateUser(id, userValuesToUpdate);
    }

    if (success) {
      notification.success({
        message:
          type === ViewTypes.CREATE ? I18n.t('forms.user.createSuccessTitle') : I18n.t('forms.user.updateSuccessTitle'),
        description:
          type === ViewTypes.CREATE
            ? I18n.t('forms.user.createSuccessDescription')
            : I18n.t('forms.user.updateSuccessDescription'),
      });
      this.onCloseForm(true);
    }
  };

  onUpdateUserValue = (key, value) => {
    const { user, userValuesToUpdate } = this.state;
    user[key] = value;
    userValuesToUpdate[key] = value;

    this.setState({
      user,
      userValuesToUpdate,
    });
  };

  onCloseForm = async (refresh) => {
    const { onCloseForm } = this.props;
    await this.setState({ user: initialValues.user, userValuesToUpdate: {} });
    onCloseForm(refresh);
  };

  render() {
    const {
      visible,
      type,
      isGetUserOnRequest,
      isCreateUserOnRequest,
      isUpdateUserOnRequest,
      isGetUserOriginOnRequest,
      isGetUserCompanyTypeOnRequest,
      isGetUserStatusOnRequest,
      isGetUserSupportOnRequest,
      getUserOrigins,
      getUserCompanyTypes,
      getUserStatus,
      getUsersSupports,
      disabled,
    } = this.props;

    const dateFormat = 'DD/MM/YYYY HH:mm';

    const { user } = this.state;

    return (
      <Drawer
        maskClosable={false}
        className="drawer"
        title={type === ViewTypes.CREATE ? I18n.t('forms.user.createTitle') : I18n.t('forms.user.updateTitle')}
        onClose={() => this.onCloseForm()}
        visible={visible}
        headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<MdClose color="black" size="25px" />}
        footer={
          !disabled && (
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <AdvancedButton
                loading={isCreateUserOnRequest || isUpdateUserOnRequest}
                type="link"
                text={I18n.t('forms.cancelButtonText')}
                onClick={() => this.onCloseForm()}
              />
              <AdvancedButton
                loading={isCreateUserOnRequest || isUpdateUserOnRequest}
                text={I18n.t('forms.submitButtonText')}
                onClick={() => this.onSubmit()}
              />
            </div>
          )
        }
      >
        {(type === ViewTypes.CREATE || user) &&
          !isGetUserOnRequest &&
          !isGetUserOriginOnRequest &&
          !isGetUserCompanyTypeOnRequest &&
          !isGetUserStatusOnRequest &&
          !isGetUserSupportOnRequest && (
            <>
              <AdvancedSelect
                value={user?.type}
                options={UserTypes.GetValidUserTypeArray}
                label={I18n.t('forms.user.items.type.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('type', value)}
                required
                disabled={disabled}
              />

              {type === ViewTypes.EDIT && (
                <AdvancedSelect
                  value={user?.status}
                  options={UserStatus.GetUserStatusArray}
                  label={I18n.t('forms.user.items.status.label')}
                  placeholder={I18n.t('shared.selectSomeValue')}
                  onChange={(value) => this.onUpdateUserValue('status', value)}
                  required
                  disabled={disabled}
                />
              )}

              <AdvancedCheckbox
                label={I18n.t('forms.user.items.documentType.label')}
                value={user && user.documentType === DocumentTypes.CPF}
                onChange={(checked) =>
                  this.onUpdateUserValue('documentType', checked ? DocumentTypes.CPF : DocumentTypes.CNPJ)
                }
                disabled={disabled}
              />

              <AdvancedMaskedInput
                placeholder={I18n.t('shared.typeSomething')}
                label={user && user.documentType === DocumentTypes.CPF ? 'CPF' : 'CNPJ'}
                value={user?.document}
                onChange={(value) => {
                  this.onUpdateUserValue('document', value);
                  this.onUpdateUserValue('documentType', user.documentType);
                }}
                kind={user && user.documentType === DocumentTypes.CPF ? DocumentTypes.CPF : DocumentTypes.CNPJ}
                required={user && user.documentType === DocumentTypes.CNPJ}
                rules={(value) =>
                  !value &&
                  user &&
                  user.documentType === DocumentTypes.CNPJ &&
                  I18n.t('forms.user.items.documentType.validation.required.label')
                }
                disabled={disabled}
              />

              <AdvancedInput
                placeholder={I18n.t('shared.typeSomething')}
                label={I18n.t('forms.user.items.name.label')}
                value={user?.name}
                onChange={(value) => this.onUpdateUserValue('name', value)}
                required
                rules={(value) => !value && I18n.t('forms.user.items.name.validation.required.label')}
                disabled={disabled}
              />

              <AdvancedInput
                placeholder={I18n.t('shared.typeSomething')}
                label={I18n.t('forms.user.items.email.label')}
                value={user?.email}
                onChange={(value) => this.onUpdateUserValue('email', value)}
                required
                rules={(value) => !value && I18n.t('forms.user.items.email.validation.required.label')}
                disabled={disabled}
              />

              {type === ViewTypes.CREATE && (
                <>
                  <AdvancedInput
                    label={I18n.t('forms.user.items.password.label')}
                    placeholder={I18n.t('shared.typeSomething')}
                    value={user?.password}
                    onChange={(value) => this.onUpdateUserValue('password', value)}
                    isPassword
                    required
                    disabled={disabled}
                  />

                  <AdvancedInput
                    label={I18n.t('forms.user.items.confirmPassword.label')}
                    placeholder={I18n.t('shared.typeSomething')}
                    value={user?.confirmPassword}
                    onChange={(value) => this.onUpdateUserValue('confirmPassword', value)}
                    isPassword
                    required
                    disabled={disabled}
                  />
                </>
              )}

              <AdvancedMaskedInput
                placeholder={I18n.t('shared.typeSomething')}
                label={I18n.t('forms.user.items.phone.label')}
                value={user?.phone}
                onChange={(value) => this.onUpdateUserValue('phone', value)}
                kind="cel-phone"
                options={{
                  withDDD: true,
                  dddMask: '(99) ',
                }}
                disabled={disabled}
              />

              {!(user && user.documentType === DocumentTypes.CPF) && (
                <>
                  <AdvancedInput
                    placeholder={I18n.t('shared.typeSomething')}
                    label={I18n.t('forms.user.items.companyName.label')}
                    value={user?.companyName}
                    onChange={(value) => this.onUpdateUserValue('companyName', value)}
                    disabled={disabled}
                  />

                  <AdvancedInput
                    placeholder={I18n.t('shared.typeSomething')}
                    label={I18n.t('forms.user.items.ie.label')}
                    value={user?.ie}
                    onChange={(value) => this.onUpdateUserValue('ie', value)}
                    disabled={disabled}
                  />
                </>
              )}

              <AdvancedSelect
                value={user?.supportId}
                options={getUsersSupports}
                label={I18n.t('forms.user.items.supportId.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('supportId', value)}
                disabled={disabled}
              />

              <AdvancedSelect
                value={user?.supportStatusId}
                options={getUserStatus}
                label={I18n.t('forms.user.items.supportStatusId.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('supportStatusId', value)}
                disabled={disabled}
              />

              <AdvancedTextArea
                value={user?.commentsSupport}
                label={I18n.t('forms.user.items.commentsSupport.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('commentsSupport', value)}
                rows={3}
                disabled={disabled}
              />

              <AdvancedCheckbox
                label={I18n.t('forms.user.items.fup.label')}
                value={user?.fup}
                onChange={(checked) => this.onUpdateUserValue('fup', checked)}
                disabled={disabled}
              />

              <AdvancedDatePicker
                pickerStyle={{ width: '100%' }}
                label={I18n.t('forms.user.items.lastFupAt.label')}
                value={user?.lastFupAt}
                format={dateFormat}
                onChange={(value) => this.onUpdateUserValue('lastFupAt', value ? moment(value, dateFormat) : null)}
                showTime
                disabled={disabled}
              />

              <AdvancedDatePicker
                pickerStyle={{ width: '100%' }}
                label={I18n.t('forms.user.items.nextFupAt.label')}
                value={user?.nextFupAt}
                format={dateFormat}
                onChange={(value) => this.onUpdateUserValue('nextFupAt', value ? moment(value, dateFormat) : null)}
                showTime
                disabled={disabled}
              />

              <AdvancedSelect
                value={user?.originId}
                options={getUserOrigins}
                label={I18n.t('forms.user.items.originId.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('originId', value)}
                disabled={disabled}
              />

              <AdvancedSelect
                value={user?.companyTypeId}
                options={getUserCompanyTypes}
                label={I18n.t('forms.user.items.companyTypeId.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => this.onUpdateUserValue('companyTypeId', value)}
                disabled={disabled}
              />
            </>
          )}

        <Loading
          size={40}
          loading={
            isGetUserOnRequest ||
            isGetUserOriginOnRequest ||
            isGetUserCompanyTypeOnRequest ||
            isGetUserStatusOnRequest ||
            isGetUserSupportOnRequest
          }
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  isGetUserOnRequest: UserSelectors.isGetUserOnRequest(state),
  isCreateUserOnRequest: UserSelectors.isCreateUserOnRequest(state),
  isUpdateUserOnRequest: UserSelectors.isUpdateUserOnRequest(state),
  isGetUserOriginOnRequest: UserSelectors.isGetUserOriginOnRequest(state),
  getUserOrigins: UserSelectors.getUserOrigins(state),
  isGetUserCompanyTypeOnRequest: UserSelectors.isGetUserCompanyTypeOnRequest(state),
  getUserCompanyTypes: UserSelectors.getUserCompanyTypes(state),
  isGetUserStatusOnRequest: UserSelectors.isGetUserStatusOnRequest(state),
  getUserStatus: UserSelectors.getUserStatus(state),
  isGetUserSupportOnRequest: UserSelectors.isGetUserSupportOnRequest(state),
  getUsersSupports: UserSelectors.getUsersSupports(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: (id) => dispatch(UserActions.getUser(id)),
  onCreateUser: (data) => dispatch(UserActions.createUser(data)),
  onUpdateUser: (id, data) => dispatch(UserActions.updateUser(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
