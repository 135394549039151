export const ProductsProviderProps = {
  id: 'id',
  code: 'code',
  weight: 'weight',
  name: 'name',
  description: 'description',
  descriptionType: 'descriptionType',
  categories: 'categories',
  minimum: 'minimum',
  multiplier: 'multiplier',
  isVisible: 'isVisible',
  unitType: 'unitType',
  shelfLifeTime: 'shelfLifeTime',
  shelfLifeType: 'shelfLifeType',
  suggestedPrice: 'suggestedPrice',
  height: 'height',
  width: 'width',
  length: 'length',
  images: 'images',
  price: 'price',
  stock: 'stock',
  promotionType: 'promotionType',
  promotionValue: 'promotionValue',
  promotionStartedAtDate: 'promotionStartedAtDate',
  promotionStartedAtTime: 'promotionStartedAtTime',
  promotionFinalizedAtDate: 'promotionFinalizedAtDate',
  promotionFinalizedAtTime: 'promotionFinalizedAtTime',
  image1: 'image1',
  image2: 'image2',
  image3: 'image3',
  image4: 'image4',
  rowNum: 'rowNum',
};

export const ProductProviderPropsTranslated = {
  id: 'id tiffin',
  code: 'codigo',
  weight: 'peso*',
  name: 'nome*',
  description: 'descricao',
  descriptionType: 'tipo de descricao',
  categories: 'categorias',
  minimum: 'minimo*',
  multiplier: 'multiplo*',
  isVisible: 'disponivel*',
  unitType: 'unidade de medida*',
  shelfLifeTime: 'tempo validade',
  shelfLifeType: 'periodo de tempo',
  suggestedPrice: 'preco sugerido',
  height: 'altura',
  width: 'largura',
  length: 'comprimento',
  images: 'imagens URL',
  price: 'preco*',
  stock: 'estoque',
  promotionType: 'tipo valor da promocao',
  promotionValue: 'valor desconto promocao',
  promotionStartedAtDate: 'data inicio da promocao',
  promotionStartedAtTime: 'hora inicio da promocao',
  promotionFinalizedAtDate: 'data termino da promocao',
  promotionFinalizedAtTime: 'hora termino da promocao',
  image1: 'URL imagem 1',
  image2: 'URL imagem 2',
  image3: 'URL imagem 3',
  image4: 'URL imagem 4',
  rowNum: 'rowNum',
};

export const getProductProviderTranslatedProp = (prop) => {
  switch (prop) {
    case ProductProviderPropsTranslated.id:
      return ProductsProviderProps.id;
    case ProductProviderPropsTranslated.code:
      return ProductsProviderProps.code;
    case ProductProviderPropsTranslated.weight:
      return ProductsProviderProps.weight;
    case ProductProviderPropsTranslated.name:
      return ProductsProviderProps.name;
    case ProductProviderPropsTranslated.description:
      return ProductsProviderProps.description;
    case ProductProviderPropsTranslated.descriptionType:
      return ProductsProviderProps.descriptionType;
    case ProductProviderPropsTranslated.categories:
      return ProductsProviderProps.categories;
    case ProductProviderPropsTranslated.minimum:
      return ProductsProviderProps.minimum;
    case ProductProviderPropsTranslated.multiplier:
      return ProductsProviderProps.multiplier;
    case ProductProviderPropsTranslated.isVisible:
      return ProductsProviderProps.isVisible;
    case ProductProviderPropsTranslated.unitType:
      return ProductsProviderProps.unitType;
    case ProductProviderPropsTranslated.shelfLifeTime:
      return ProductsProviderProps.shelfLifeTime;
    case ProductProviderPropsTranslated.shelfLifeType:
      return ProductsProviderProps.shelfLifeType;
    case ProductProviderPropsTranslated.suggestedPrice:
      return ProductsProviderProps.suggestedPrice;
    case ProductProviderPropsTranslated.height:
      return ProductsProviderProps.height;
    case ProductProviderPropsTranslated.width:
      return ProductsProviderProps.width;
    case ProductProviderPropsTranslated.length:
      return ProductsProviderProps.length;
    case ProductProviderPropsTranslated.images:
      return ProductsProviderProps.images;
    case ProductProviderPropsTranslated.price:
      return ProductsProviderProps.price;
    case ProductProviderPropsTranslated.stock:
      return ProductsProviderProps.stock;
    case ProductProviderPropsTranslated.promotionType:
      return ProductsProviderProps.promotionType;
    case ProductProviderPropsTranslated.promotionValue:
      return ProductsProviderProps.promotionValue;
    case ProductProviderPropsTranslated.promotionStartedAtDate:
      return ProductsProviderProps.promotionStartedAtDate;
    case ProductProviderPropsTranslated.promotionStartedAtTime:
      return ProductsProviderProps.promotionStartedAtTime;
    case ProductProviderPropsTranslated.promotionFinalizedAtDate:
      return ProductsProviderProps.promotionFinalizedAtDate;
    case ProductProviderPropsTranslated.promotionFinalizedAtTime:
      return ProductsProviderProps.promotionFinalizedAtTime;
    case ProductProviderPropsTranslated.image1:
      return ProductsProviderProps.image1;
    case ProductProviderPropsTranslated.image2:
      return ProductsProviderProps.image2;
    case ProductProviderPropsTranslated.image3:
      return ProductsProviderProps.image3;
    case ProductProviderPropsTranslated.image4:
      return ProductsProviderProps.image4;
    case ProductProviderPropsTranslated.rowNum:
      return ProductsProviderProps.rowNum;
    default:
      return prop;
  }
};
