import React, { useContext, useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Input, Select, Popover, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';

import { SketchPicker } from 'react-color';
import classNames from 'classnames';
import { useReduxState } from '../../../app/hooks/useReduxState';
import Loading from '../Loading';
import AdvancedInputNumber from '../AdvancedInputNumber';
import AdvancedDatePicker from '../AdvancedDatePicker/AdvancedDatePicker';
import { dataTableCellType } from '../../../app/enum/dataTable_cell_types';

import { OrderActions, ProductActions, ProviderActions, UserActions, TagActions } from '../../../app/redux/actions';
import { UserTypes } from '../../../app/enum/user_types';

export const defaultValues = {
  limit: 10,
  total: 0,
  page: 1,
  data: null,
  sortOrder: { order: 'DESC' },
};

const AdvancedDataTable = forwardRef(
  (
    { columns, sticky, loading, scroll, getMethod, data: dataFromProps, notGetOnStart, refresh, container, onChange },
    ref,
  ) => {
    const [data, setData] = useState(defaultValues.data);
    const [page, setPage] = useState(defaultValues.page);
    const [limit, setLimit] = useState(defaultValues.limit);
    const [total, setTotal] = useState(defaultValues.total);
    const [sortOrder, setSortOrder] = useState(defaultValues.sortOrder);

    const EditableContext = React.createContext(null);

    const { auth: authSelector } = useReduxState();

    useEffect(() => {
      setData(dataFromProps);

      if (!notGetOnStart) {
        getMethod(defaultValues);
      }
    }, []);

    useEffect(() => {
      if (dataFromProps) {
        setTotal(dataFromProps.count);
        setData(dataFromProps);
      }
    }, [dataFromProps]);

    const getRows = (dataRow) => {
      if (dataRow) {
        dataRow.map((object) => ({
          ...object,
          key: object.id ? object.id.toString() : Math.random().toString(36),
        }));
        return dataRow;
      }
      return [];
    };

    const handleTableChange = (pagination, filters, sorter, data) => {
      let sortOrderCopy = null;
      if (onChange) {
        onChange(pagination, filters, sorter);
        if (data.action !== pagination && sorter.order === 'ascend' && sorter.columnKey) {
          setSortOrder({ order: 'ASC', columnKey: sorter.columnKey });
          sortOrderCopy = { order: 'ASC', columnKey: sorter.columnKey };
        }
        if (data.action !== pagination && sorter.order === 'descend' && sorter.columnKey) {
          setSortOrder({ order: 'DESC', columnKey: sorter.columnKey });
          sortOrderCopy = { order: 'DESC', columnKey: sorter.columnKey };
        }
        if (data.action !== pagination && !sorter.order && sorter.columnKey) {
          setSortOrder({ order: undefined, columnKey: undefined });
          sortOrderCopy = { order: undefined, columnKey: undefined };
        }
        if (data.action !== pagination && !sorter.order && !sorter.columnKey) {
          setSortOrder({ order: undefined, columnKey: undefined });
          sortOrderCopy = { order: undefined, columnKey: undefined };
        }
      }

      setPage(pagination.current);
      setLimit(pagination.pageSize);

      let order = null;

      if (data.action !== 'paginate') {
        if (sortOrderCopy?.order === 'ASC') {
          order = 'ASC';
        }
        if (sortOrderCopy?.order === 'DESC' || !sortOrderCopy?.order === undefined) {
          order = 'DESC';
        }
      }

      getMethod({
        page: pagination.current,
        limit: pagination.pageSize,
        column: sorter.columnKey,
        order,
        ...filters,
      });
    };

    function EditableRow({ index, ...props }) {
      const [form] = Form.useForm();
      return (
        <Form form={form} component={false}>
          <EditableContext.Provider value={form}>
            <tr {...props} />
          </EditableContext.Provider>
        </Form>
      );
    }

    function EditableCell({
      title,
      required,
      editable,
      children,
      dataIndex,
      record,
      selector,
      selectorFunction,
      type,
      ...restProps
    }) {
      const [editing, setEditing] = useState(false);
      const inputRef = useRef(null);
      const form = useContext(EditableContext);
      const dispatch = useDispatch();
      const [visible, setVisible] = useState(false);
      const [color, setColor] = useState();

      const toggleEdit = async () => {
        await setEditing(!editing);
        setVisible(!visible);

        let payload = null;

        if (
          typeof record[dataIndex] === 'string' ||
          typeof record[dataIndex] === 'number' ||
          typeof record[dataIndex] === 'boolean'
        ) {
          payload = record[dataIndex];
        } else if (record[dataIndex]?.name) {
          payload = record[dataIndex]?.name;
        }

        form.setFieldsValue({
          [dataIndex]: payload,
        });
      };

      const cancel = () => {
        toggleEdit();
      };

      const save = async () => {
        try {
          const value = await form.validateFields();
          toggleEdit();
          const payload = {
            id: record[dataIndex]?.id || record.id,
            value,
          };

          if (payload.value[dataIndex] === '') {
            payload.value[dataIndex] = null;
          }

          switch (container) {
            case 'users':
              await dispatch(UserActions.updateUser(payload.id, payload.value));
              refresh();
              break;
            case 'providers':
              await dispatch(ProviderActions.updateProvider(payload.id, payload.value));
              refresh();
              break;
            case 'products':
              await dispatch(ProductActions.updateProduct(record.provider.id, payload.id, payload.value));
              refresh();
              break;
            case 'tags':
              await dispatch(TagActions.updateTagById(payload.id, payload.value));
              refresh();
              break;
            default:
              await dispatch(OrderActions.updateOrder(payload.id, payload.value));
              refresh();
          }
        } catch (errInfo) {
          notification.error({
            message: I18n.t('shared.dataTable.notifications.errorOnUpdate.message'),
            description: I18n.t('shared.dataTable.notifications.errorOnUpdate.description'),
          });
        }
      };

      let childNode = children;

      const rightInputType = () => {
        if (selectorFunction && typeof selectorFunction === 'function') {
          const selector = selectorFunction(record);
          if (selector) {
            return (
              <Select
                ref={inputRef}
                className="advanced-data-table__popover__input"
                placeholder={I18n.t('shared.selectSomeValue')}
              >
                {selector.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            );
          }
        }
        if (selector) {
          return (
            <Select
              ref={inputRef}
              className="advanced-data-table__popover__input"
              placeholder={I18n.t('shared.selectSomeValue')}
            >
              {selector.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (type === dataTableCellType.NUMBER) {
          return (
            <AdvancedInputNumber
              precision={2}
              min={0}
              className="advanced-data-table__popover__input"
              placeholder={I18n.t('shared.typeSomething')}
            />
          );
        }
        if (type === dataTableCellType.DATE) {
          return <AdvancedDatePicker format={I18n.t('application.dateFormat')} showTime />;
        }
        if (type === dataTableCellType.COLOR) {
          return (
            <>
              <SketchPicker
                color={color}
                onChange={({ hex }) => {
                  setColor(hex);
                  const hexToSend = hex.includes('#') ? hex.substring(1, hex.length) : hex;
                  form.setFieldsValue({
                    [dataIndex]: hexToSend,
                  });
                }}
                className="advanced-data-table__popover__color-picker"
              />
              <Input
                value={color}
                ref={inputRef}
                className="advanced-data-table__popover__input"
                placeholder={I18n.t('shared.typeSomething')}
              />
            </>
          );
        }
        return (
          <Input
            ref={inputRef}
            className="advanced-data-table__popover__input"
            placeholder={I18n.t('shared.typeSomething')}
          />
        );
      };

      const popoverContent = () => (
        <div className="advanced-data-table__popover">
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required,
                message: `${title} é obrigatório.`,
              },
            ]}
          >
            {rightInputType()}
          </Form.Item>
          <div className="advanced-data-table__popover__buttons-bar">
            <button
              className="advanced-data-table__popover__buttons-bar__button advanced-data-table__popover__buttons-bar__button--cancel"
              onClick={cancel}
              type="button"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              Cancelar
            </button>
            <button
              className="advanced-data-table__popover__buttons-bar__button advanced-data-table__popover__buttons-bar__button--save"
              type="button"
              onClick={save}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      );

      const handleVisibleChange = () => {
        cancel();
        setVisible(!visible);
      };

      if (editable) {
        childNode = editing ? (
          <Popover
            placement="top"
            title={I18n.t('shared.dataTable.popOver.title')}
            content={popoverContent}
            trigger="click"
            open={visible}
            onOpenChange={handleVisibleChange}
          >
            <div
              className={classNames({
                'advanced-data-table__editable-cell': true,
                'advanced-data-table__editable-cell--no-wrap': dataIndex === 'status',
              })}
            >
              {children}
            </div>
          </Popover>
        ) : (
          <Popover
            placement="topLeft"
            title={I18n.t('shared.dataTable.popOver.title')}
            content={popoverContent}
            trigger="click"
            open={visible}
            onOpenChange={handleVisibleChange}
          >
            <div
              className={classNames({
                'advanced-data-table__editable-cell': true,
                'advanced-data-table__editable-cell--no-wrap': dataIndex === 'status',
              })}
              onClick={toggleEdit}
            >
              {children}
            </div>
          </Popover>
        );
      }

      return <td {...restProps}>{childNode}</td>;
    }

    const getColumns = (params) => {
      return params.map((object) => {
        if (object.key === sortOrder.columnKey) {
          return {
            key: object.key,
            title: object.title,
            dataIndex: object.key,
            fixed: object.fixed ? object.fixed : false,
            width: object.width,
            render: object.render ? object.render : undefined,
            sorter: object.sorter,
            onFilter: object.onFilter,
            filters: object.filters,
            align: object.align,
            ellipsis: object.ellipsis,
            editable: object.editable,
            filteredValue: object.filteredValue,
            sortOrder: sortOrder.order === 'ASC' ? 'ascend' : 'descend',
            onCell: (record) => ({
              type: object.type,
              record,
              required: object.required,
              editable: object.editable,
              dataIndex: object.key,
              title: object.title,
              selector: object.selector,
              selectorFunction: (row) => (object.selectorFunction ? object.selectorFunction(row) : undefined),
              render: object.render ? object.render : undefined,
            }),
          };
        }
        return {
          key: object.key,
          title: object.title,
          dataIndex: object.key,
          fixed: object.fixed ? object.fixed : false,
          width: object.width,
          render: object.render ? object.render : undefined,
          sorter: object.sorter,
          onFilter: object.onFilter,
          filters: object.filters,
          align: object.align,
          ellipsis: object.ellipsis,
          editable: object.editable,
          filteredValue: object.filteredValue,
          sortOrder: object.sortOrder,
          onCell: (record) => ({
            type: object.type,
            record,
            required: object.required,
            editable: object.editable,
            dataIndex: object.key,
            title: object.title,
            selector: object.selector,
            selectorFunction: (row) => (object.selectorFunction ? object.selectorFunction(row) : undefined),
            render: object.render ? object.render : undefined,
          }),
        };
      });
    };

    const reset = () => {
      setData(defaultValues.data);
      setTotal(defaultValues.total);
      setLimit(defaultValues.limit);
      setPage(defaultValues.page);
      setSortOrder(defaultValues.sortOrder);
      if (onChange) {
        onChange(null);
      }
    };

    useImperativeHandle(ref, () => ({
      reset,
    }));

    const tableLoading = useCallback(
      () => ({
        spinning: loading,
        indicator: <Loading size={40} loading={loading} />,
      }),
      [loading],
    );

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    return (
      <Table
        rowClassName={(record) =>
          record?.warnings?.length > 0 && [UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type)
            ? 'advanced-data-table__warnings'
            : 'advanced-data-table'
        }
        components={components}
        scroll={scroll || { x: 900, y: 'max-content' }}
        dataSource={data && getRows(data.rows)}
        columns={getColumns(columns)}
        bordered
        sticky={sticky}
        loading={tableLoading()}
        pagination={{
          showTotal: (totalRows, range) => `${range[0]}-${range[1]} de ${totalRows}`,
          total,
          page,
          current: page,
          pageSize: limit,
          locale: {
            items_per_page: '/ página',
            jump_to: 'Vá até',
            jump_to_confirm: 'confirme',
            page: '',
            prev_page: 'Página anterior',
            next_page: 'Próxima página',
            prev_5: '5 páginas anteriores',
            next_5: '5 próximas páginas',
            prev_3: '3 páginas anteriores',
            next_3: '3 próximas páginas',
          },
        }}
        size="small"
        onChange={handleTableChange}
        locale={{
          emptyText: I18n.t('shared.nothingToShow'),
          filterTitle: 'Filtro',
          filterConfirm: 'OK',
          filterReset: 'Reiniciar',
          filterEmptyText: 'Sem filtros',
          selectAll: 'Selecionar tuda página',
          selectInvert: 'Invert current page',
          selectionAll: 'Selecionar tudo',
          sortTitle: 'Ordenar',
          expand: 'Expandir linha',
          collapse: 'Diminuir linha',
          triggerDesc: 'Ordenar maior > menor',
          triggerAsc: 'Ordernar menor > maior',
          cancelSort: 'Cancelar a ordenação',
        }}
      />
    );
  },
);

AdvancedDataTable.displayName = 'AdvancedDataTable';

export default AdvancedDataTable;
