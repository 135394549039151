import React from 'react';

import { Empty } from 'antd';
import { I18n } from 'react-redux-i18n';

import AdvancedInput from '../../../shared/AdvancedInput';

function InnerVolumesOrderForm({ order }) {
  return order?.volumes ? (
    <>
      <AdvancedInput
        label={I18n.t('forms.order.volumes.items.quantity.label')}
        value={order?.volumes?.quantity}
        disabled
      />
      <AdvancedInput
        label={I18n.t('forms.order.volumes.items.weightByVolume.label')}
        value={order?.volumes?.weightByVolume}
        disabled
      />
      <AdvancedInput label={I18n.t('forms.order.volumes.items.height.label')} value={order?.volumes?.height} disabled />
      <AdvancedInput label={I18n.t('forms.order.volumes.items.width.label')} value={order?.volumes?.width} disabled />
      <AdvancedInput label={I18n.t('forms.order.volumes.items.length.label')} value={order?.volumes?.length} disabled />
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={I18n.t('forms.order.volumes.empty.label')} />
  );
}

export default InnerVolumesOrderForm;
