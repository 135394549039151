import React, { useState, useEffect, useCallback } from 'react';

import { Drawer, Collapse, notification } from 'antd';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { accessActionTypes } from '../../../app/enum/access_action_types';
import { accessTypes } from '../../../app/enum/access_types';

import * as OrderStatus from '../../../app/enum/order_status';
import { UserTypes } from '../../../app/enum/user_types';
import { ViewTypes } from '../../../app/enum/view_types';

import { useReduxState } from '../../../app/hooks/useReduxState';

import {
  CardActions,
  OrderActions,
  ProductActions,
  ProviderActions,
  ShipmentActions,
} from '../../../app/redux/actions';
import { hasAccess } from '../../../app/services/access';

import { humanizeDate } from '../../../app/utils/date';
import * as PriceUtils from '../../../app/utils/prices';

import * as OrderPaymentTypes from '../../../app/enum/payment_types';
import { getOrderShipmentStatus } from '../../../app/utils/shipment';

import AdvancedSelect from '../../shared/AdvancedSelect';
import Loading from '../../shared/Loading';
import AdvancedInput from '../../shared/AdvancedInput';
import AdvancedTextArea from '../../shared/AdvancedTextArea';
import AdvancedMaskedInput from '../../shared/AdvancedMaskedInput/AdvancedMaskedInput';
import OrderFormFooter from './innerForms/components/OrderFormFooter';

import InnerDeliveryOrderForm from './innerForms/InnerDeliveryOrderForm';
import InnerPaymentOrderForm from './innerForms/InnerPaymentOrderForm';
import InnerProductsOrderForm from './innerForms/InnerProductsOrderForm';
import InnerVolumesOrderForm from './innerForms/InnerVolumesOrderForm';
import InnerVolumesLabelsOrderForm from './innerForms/InnerVolumesLabelsOrderForm';
import InnerInvoiceOrderForm from './innerForms/InnerInvoiceOrderForm';

function OrderForm({ type, onCloseForm: onCloseFormProp, visible, id: orderId, disabled }) {
  const [freightSelected, setFreightSelected] = useState();
  const [order, setOrder] = useState();
  const [orderOriginal, setOrderOriginal] = useState();
  const [orderForSend, setOrderForSend] = useState();
  const [validPayments, setValidPayments] = useState();
  const [updateProductsQuantity, setUpdateProductsQuantity] = useState([]);
  const [isProductsStockUpdateModalVisible, setIsProductsStockUpdateModalVisible] = useState(false);
  const [showInvoicesFulForm, setShowInvoicesFulForm] = useState(false);

  const { Panel } = Collapse;

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderOriginal && !orderOriginal.provider.orderWithoutStockOn) {
      const getFunction = async () => {
        const productsIdsArray = orderOriginal.items.map((item) => item.productId);
        await dispatch(ProductActions.getOrderProductsStock(productsIdsArray));
      };

      getFunction();
    }
  }, [orderOriginal]);

  const {
    order: orderSelector,
    auth: authSelector,
    provider: providerSelector,
    product: productSelector,
    shipment: shipmentSelector,
    setting: settingSelector,
  } = useReduxState();

  const setCarrierOfferId = (carrierOfferId) => {
    const newOrder = { ...order, carrierOfferId };
    setOrder(newOrder);
  };

  const onUpdateOrderValues = (updateData) => {
    const orderCopy = { ...order };
    const orderForSendCopy = { ...orderForSend };
    updateData.forEach(({ key, value }) => {
      orderCopy[key] = value;
      orderForSendCopy[key] = value;
    });

    setOrderForSend(orderForSendCopy);
    setOrder(orderCopy);
  };

  const invoicesFulFormVisible = useCallback(async (orderCatched) => {
    const stockIds = orderCatched?.items
      ?.map(
        (item) =>
          item.product.units &&
          item.product?.units[0]?.stockId?.toString().length > 0 &&
          item?.product?.units[0]?.stockId,
      )
      ?.filter((item) => item !== (undefined || null || '' || false));

    const stockIdsNoDups = stockIds?.filter((item, index) => stockIds.indexOf(item) === index);
    if (stockIdsNoDups?.length > 0) {
      const defaultStockId = settingSelector?.settings?.find((o) => o.name === 'defaultStockId')?.value;

      const stockIdsFul = stockIdsNoDups.filter((stockId) => stockId.toString() !== defaultStockId.toString());

      if (
        stockIdsFul.length > 0 &&
        hasAccess([accessTypes.CREATE_INVOICES_FUL], [accessActionTypes.CREATE]) &&
        [
          OrderStatus.OrderStatus.ON_CARRIAGE,
          OrderStatus.OrderStatus.WAITING_DELIVERY,
          OrderStatus.OrderStatus.DELIVERED,
        ].includes(orderCatched.status)
      ) {
        setShowInvoicesFulForm(true);
        return;
      }
    }
    setShowInvoicesFulForm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderFormInit = async () => {
    if (orderId && visible && type === ViewTypes.EDIT) {
      const orderCatched = await dispatch(OrderActions.getOrder(orderId));
      setOrder(orderCatched);
      setOrderOriginal(orderCatched);

      dispatch(ProviderActions.getProviderProducts(orderCatched.providerId));
      dispatch(OrderActions.getOrderCancelReason());

      const validPaymentsCatched = await dispatch(
        ProviderActions.getProviderPaymentsByUser(orderCatched.providerId, orderCatched.userId),
      );
      dispatch(OrderActions.getOrderFiles(orderId));
      setOrder(orderCatched);
      setValidPayments(validPaymentsCatched);

      await dispatch(CardActions.getInstallmentTax());
      invoicesFulFormVisible(orderCatched);
    }
  };

  const onCloseForm = (refresh) => {
    setOrderForSend(null);
    setOrder(null);
    onCloseFormProp(refresh);
  };

  const onSubmit = async () => {
    if (!orderForSend) return;

    const freightOptions = orderForSend.freightOptions || [];

    const invalidFreightOptions = freightOptions.filter(
      ({ leadtime, freight, tax }) => !leadtime || freight === null || tax === null,
    );

    if (invalidFreightOptions.length) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidFreightOptionsError.message'),
        description: I18n.t('forms.order.notifications.invalidFreightOptionsError.description'),
      });
    }

    if (!orderForSend?.option && orderForSend.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidCreditCardOptionsError.message'),
        description: I18n.t('forms.order.notifications.invalidCreditCardOptionsError.description'),
      });
    }

    if (orderForSend.items && !order.provider.orderWithoutStockOn) {
      const productsWithoutStock = [];

      productSelector.productsStock?.forEach((product) => {
        const originalItem = orderOriginal?.items?.find((o) => o.productId === Number(product.id));

        let quantityAvailable = 0;
        let isProductWithoutStock = null;

        if (originalItem) {
          quantityAvailable = Number(product.stock) + Number(originalItem?.quantity);
          isProductWithoutStock = orderForSend?.items?.find(
            (item) => Number(item.productId) === Number(product.id) && Number(item.quantity) > quantityAvailable,
          );
        } else {
          quantityAvailable = Number(product.stock);
          isProductWithoutStock = orderForSend?.items?.find(
            (item) => Number(item.productId) === Number(product.id) && Number(item.quantity) > quantityAvailable,
          );
        }

        if (isProductWithoutStock) {
          productsWithoutStock.push({ id: product.id, stock: product.stock });
        }
      });

      if (productsWithoutStock?.length > 0) {
        setUpdateProductsQuantity([...productsWithoutStock]);
        setIsProductsStockUpdateModalVisible(true);
        return notification.error({
          message: I18n.t('forms.order.notifications.invalidProductsStockQuantity.message'),
          description: I18n.t('forms.order.notifications.invalidProductsStockQuantity.description'),
        });
      }

      setIsProductsStockUpdateModalVisible(false);
    }

    await dispatch(OrderActions.updateOrder(order.id, orderForSend));
    return onCloseForm(true);
  };

  useEffect(() => {
    if (order) {
      if (order.tax || order.freight || order.leadtime || order.freightOptions) {
        const freightFound = order.freightOptions?.find((e) => {
          return (
            Number(e.leadtime) === Number(order.leadtime) &&
            Number(e.freight) === Number(order.freight) &&
            Number(e.tax) === Number(order.tax)
          );
        });
        if (freightFound) {
          setFreightSelected(freightFound);
        }
      }
    }
  }, [order]);

  useEffect(() => {
    orderFormInit();
  }, [visible]);

  const isLoading =
    orderSelector.isGetOrderOnRequest ||
    orderSelector.isGetOrderCancelReasonsOnRequest ||
    providerSelector.isGetProviderPaymentsByUserOnRequest ||
    providerSelector.isGetProviderProductsOnRequest;

  const invoicesPanelHeader = (
    <>
      {I18n.t('forms.order.invoices.title')}
      <span className="order-form__invoice-box__header"> ({orderSelector.orderFiles?.length})</span>
    </>
  );

  const showOrderLogsLabelsSection = () => {
    const orderLogStatus = getOrderShipmentStatus(order);
    return orderLogStatus && hasAccess([accessTypes.ORDER_SHIPMENT], [accessActionTypes.UPDATE]);
  };

  const getOrderShipmentLabel = async (id, orderVolumeNumber) => {
    const label = await dispatch(ShipmentActions.getOrderShipmentLabel(id, orderVolumeNumber));
    window.open(label.label_url, '_blank');
  };

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={type === ViewTypes.CREATE ? I18n.t('forms.order.createTitle') : I18n.t('forms.order.updateTitle')}
      onClose={() => onCloseForm()}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ padding: 0, paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={<OrderFormFooter onSubmit={onSubmit} order={order} onCloseForm={onCloseForm} isLoading={isLoading} />}
    >
      <div className="order-form__main-container">
        {order && !isLoading && (
          <div className="order-form__main-container__content">
            <AdvancedInput
              value={order?.id}
              disabled
              label={I18n.t('forms.order.items.id.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            <AdvancedSelect
              value={order?.status}
              options={OrderStatus.GetOrderStatusArrayName(order)}
              label={I18n.t('forms.order.items.status.label')}
              placeholder={I18n.t('shared.selectSomeValue')}
              onChange={(value) => onUpdateOrderValues([{ key: 'status', value }])}
              disabled={disabled}
            />

            {orderSelector.orderCancelReasons && order?.status === OrderStatus.OrderStatus.CANCELED && (
              <AdvancedSelect
                value={order?.cancelReasonId}
                options={orderSelector.orderCancelReasons}
                label="Motivo do cancelamento"
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => onUpdateOrderValues([{ key: 'cancelReasonId', value }])}
                disabled={disabled}
              />
            )}

            <AdvancedInput
              value={humanizeDate(order?.createdAt, 'DD/MM/YYYY HH:mm')}
              disabled
              label={I18n.t('forms.order.items.createdAt.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            <AdvancedInput
              value={order?.origin}
              disabled
              label={I18n.t('forms.order.items.origin.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            <AdvancedTextArea
              value={order?.user?.companyName}
              disabled
              label={I18n.t('forms.order.items.userCompanyName.label')}
              placeholder={I18n.t('shared.typeSomething')}
              rows="2"
            />

            <AdvancedInput
              value={order?.user?.email}
              disabled
              label={I18n.t('forms.order.items.userEmail.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
              <AdvancedInput
                value={order?.user?.phone}
                disabled
                label={I18n.t('forms.order.items.userPhone.label')}
                placeholder={I18n.t('shared.typeSomething')}
              />
            )}

            <AdvancedInput
              value={order?.user?.ie}
              disabled
              label={I18n.t('forms.order.items.userIe.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            <AdvancedInput
              value={order?.user?.document}
              disabled
              label={I18n.t('forms.order.items.userDocument.label')}
              placeholder={I18n.t('shared.typeSomething')}
            />

            <AdvancedTextArea
              value={order?.comments}
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) || disabled}
              label={I18n.t('forms.order.items.comments.label')}
              placeholder={I18n.t('shared.typeSomething')}
              rows="3"
              onChange={(value) => onUpdateOrderValues([{ key: 'comments', value }])}
            />

            {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
              <>
                <AdvancedTextArea
                  value={order?.commentsSupport}
                  label={I18n.t('forms.order.items.commentsSupport.label')}
                  placeholder={I18n.t('shared.typeSomething')}
                  rows={3}
                  onChange={(value) => onUpdateOrderValues([{ key: 'commentsSupport', value }])}
                  disabled={disabled}
                />

                <AdvancedSelect
                  value={order?.problemId}
                  options={orderSelector.orderProblems}
                  label={I18n.t('forms.order.items.problemId.label')}
                  placeholder={I18n.t('shared.selectSomeValue')}
                  onChange={(value) => onUpdateOrderValues([{ key: 'problemId', value }])}
                  disabled={disabled}
                />
              </>
            )}

            <AdvancedMaskedInput
              options={{
                unit: 'R$ ',
              }}
              placeholder={I18n.t('shared.typeSomething')}
              label="Desconto adicional"
              value={Number(order?.discountExtra).toFixed(2)}
              kind="money"
              onChange={(value) => {
                onUpdateOrderValues([
                  { key: 'discountExtra', value: PriceUtils.cleanValue(value) },
                  {
                    key: 'discount',
                    value: Number(PriceUtils.cleanValue(value)) + Number(order?.discountItems || 0),
                  },
                ]);
              }}
              disabled={disabled}
            />

            <Collapse accordion style={{ marginTop: 10 }}>
              <Panel header={invoicesPanelHeader} key="1">
                <InnerInvoiceOrderForm orderId={orderId} showInvoicesFulForm={showInvoicesFulForm} />
              </Panel>

              <Panel header={I18n.t('forms.order.items.providerProducts.header')} key="2">
                <InnerProductsOrderForm
                  order={order}
                  orderOriginal={orderOriginal}
                  onUpdateOrderValues={onUpdateOrderValues}
                  disabled={disabled}
                  updateProductsQuantity={updateProductsQuantity}
                  isProductsStockUpdateModalVisible={isProductsStockUpdateModalVisible}
                  setIsProductsStockUpdateModalVisible={setIsProductsStockUpdateModalVisible}
                  onSubmit={onSubmit}
                />
              </Panel>

              <Panel header={I18n.t('forms.order.payment.header.label')} key="3">
                <InnerPaymentOrderForm
                  validPayments={validPayments}
                  order={order}
                  onUpdateOrderValues={onUpdateOrderValues}
                  orderFormInit={orderFormInit}
                  disabled={disabled}
                  onCloseForm={onCloseForm}
                  coinValue={settingSelector?.settings?.find((o) => o.name === 'defaultCoinValue')?.value || 0}
                />
              </Panel>

              <Panel header={I18n.t('forms.order.delivery.header.label')} key="4">
                <InnerDeliveryOrderForm
                  setCarrierOfferId={setCarrierOfferId}
                  freightSelected={freightSelected}
                  setFreightSelected={setFreightSelected}
                  onUpdateOrderValues={onUpdateOrderValues}
                  order={order}
                  disabled={disabled}
                />
              </Panel>

              <Panel header={I18n.t('forms.order.volumes.header.label')} key="5">
                <InnerVolumesOrderForm order={order} disabled={disabled} />
              </Panel>

              {showOrderLogsLabelsSection() && (
                <Panel header={I18n.t('forms.order.labels.header.label')} key="6">
                  <InnerVolumesLabelsOrderForm
                    order={order}
                    onGetShipmentLabel={getOrderShipmentLabel}
                    isGetOrderShipmentLabelOnRequest={shipmentSelector.isGetOrderShipmentLabelOnRequest}
                  />
                </Panel>
              )}
            </Collapse>
          </div>
        )}
        <Loading size={40} loading={isLoading} />
      </div>
    </Drawer>
  );
}

export default OrderForm;
