import DistributorRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/distributor/distributorRequestsV1';

export const ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST = 'ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST:distributor';
export const ACTION_GET_DISTRIBUTORS = 'ACTION_GET_DISTRIBUTORS:distributor';

export const getDistributors = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST, payload: true });
    const distributors = await DistributorRequestsV1.getAll();
    dispatch({ type: ACTION_GET_DISTRIBUTORS, payload: distributors });
  } finally {
    dispatch({ type: ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST, payload: false });
  }
};
