import React from 'react';
import './ProviderForm.less';

import { Drawer, Form, Input, InputNumber, Modal, notification, Collapse, Button, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedMaskedInput from '../../shared/AdvancedMaskedInput';
import Loading from '../../shared/Loading';

import LocaleSearchTypes from '../../../app/enum/locale_search_types';
import { ViewTypes } from '../../../app/enum/view_types';

import { ProviderActions, LocaleActions } from '../../../app/redux/actions';
import { ProviderSelectors, LocaleSelectors } from '../../../app/redux/reducers';

class ProviderUnitsForm extends React.Component {
  constructor(props) {
    super(props);
    this.formInstance = React.createRef();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { id, type } = this.props;
    if (id && !prevProps.visible && type === ViewTypes.EDIT_PROVIDER_UNITS) {
      this.getProvider(id);
    }
  }

  getProvider = async (id) => {
    const { onGetProvider } = this.props;
    const provider = await onGetProvider(id);
    if (!provider.providerUnits) {
      const newProviderUnits = await this.getProviderUnits(id);
      await this.setState({ provider: { ...provider, providerUnits: newProviderUnits } });
      return;
    }

    await this.setState({ provider });
  };

  getProviderUnits = (providerId) => {
    const { onGetProviderUnits } = this.props;
    return onGetProviderUnits(providerId);
  };

  onSubmitProviderUnits = async () => {
    const { provider } = this.state;
    const { providerUnits } = provider;
    const { onCreateProviderUnit, onUpdateProviderUnit, id: providerId } = this.props;

    const thereIsProblem = providerUnits.some((providerUnit) => {
      const { name, cep, address, province, city, state, country, radius, number } = providerUnit;
      return !name || !cep || !address || !province || !city || !state || !country || !radius || !number;
    });

    if (thereIsProblem) {
      notification.error({
        message: I18n.t('forms.provider.units.errorTitle'),
        description: I18n.t('forms.provider.units.missingParamsErrorDescription'),
      });
      return false;
    }

    return Promise.all(
      providerUnits.map(async (providerUnit) => {
        const { name, cep, id: unitId } = providerUnit;
        if (!unitId && cep) {
          const success = await onCreateProviderUnit(providerId, providerUnit);
          if (success) {
            notification.success({
              message: I18n.t('forms.provider.units.successTitle'),
              description: I18n.t('forms.provider.units.createSuccessDescription').replace('$var1', name),
            });
            return true;
          }
        }
        if (unitId && cep) {
          const success = await onUpdateProviderUnit(providerId, unitId, providerUnit);
          if (success) {
            notification.success({
              message: I18n.t('forms.provider.units.successTitle'),
              description: I18n.t('forms.provider.units.updateSuccessDescription').replace('$var1', name),
            });
            return true;
          }
        }
      }),
    );
  };

  onSubmit = async () => {
    const { onCloseForm } = this.props;
    const success = await this.onSubmitProviderUnits();
    if (success) {
      await this.setState({ provider: null });
      onCloseForm(true);
    }
  };

  onAddProviderUnit = async () => {
    const { provider } = this.state;
    const { providerUnits } = provider;

    providerUnits.push({
      address: null,
      cep: null,
      city: null,
      country: null,
      complement: null,
      name: 'Novo CD',
      number: null,
      province: null,
      radius: 0,
      state: null,
    });

    await this.setState({ provider: { ...provider, providerUnits } });
    this.formInstance.current.setFieldsValue(this.state.provider);
  };

  onOkRemoveProviderUnit = async (providerId, index) => {
    const { provider } = this.state;
    const { providerUnits } = provider;
    const { onDeleteProviderUnit } = this.props;
    const unitId = providerUnits[index].id;

    if (providerUnits.filter((unit) => unit.id).length <= 1) {
      notification.error({
        message: I18n.t('forms.provider.units.errorTitle'),
        description: I18n.t('forms.provider.units.deleteAllErrorDescription'),
      });
      return;
    }
    let success;
    if (unitId) {
      success = await onDeleteProviderUnit(providerId, unitId);
    }
    providerUnits.splice(index, 1);
    await this.setState({ provider: { ...provider, providerUnits } });
    if (success) {
      notification.success({
        message: I18n.t('forms.provider.units.successTitle'),
        description: I18n.t('forms.provider.units.deleteSuccessDescription').replace('$var1', name),
      });
    }
  };

  onRemoveProviderUnit = async (index) => {
    const { provider } = this.state;
    const { providerUnits } = provider;
    const { isDeleteProviderUnitIsOnRequest, id: providerId } = this.props;
    const { confirm } = Modal;

    if (!providerUnits[index].id) {
      providerUnits.splice(index, 1);
      await this.setState({ provider: { ...provider, providerUnits } });
    } else {
      confirm({
        title: 'Atenção, deseja realmente deletar esse CD do fornecedor?',
        icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
        okText: 'Sim',
        okType: 'danger',
        centered: true,
        cancelText: 'Cancelar',
        confirmLoading: isDeleteProviderUnitIsOnRequest,
        onOk: () => this.onOkRemoveProviderUnit(providerId, index),
      });
    }
  };

  onChangePostalCode = async (postalCode, index) => {
    const { onGetLocale } = this.props;
    const { provider } = this.state;
    const { providerUnits } = provider;

    providerUnits[index].cep = postalCode;
    await this.setState({
      provider: {
        ...provider,
        providerUnits,
      },
    });

    if (postalCode.length === 9) {
      const locale = await onGetLocale(postalCode);
      if (locale) {
        const unit = providerUnits[index];
        const { street, district, city, country, state } = locale;
        providerUnits[index] = {
          ...unit,
          address: street || unit.address,
          province: district || unit.province,
          city: city || unit.city,
          country: country.long ? country.long : unit.country,
          state: state.short ? state.short : unit.state,
        };

        await this.setState({
          provider: { ...provider, providerUnits },
        });
        this.formInstance.current.setFieldsValue(this.state.provider);
      }
    }
  };

  editProviderUnitsForm = (disabled) => {
    const { isGetLocaleIsOnRequest } = this.props;
    const { provider } = this.state;
    const { Item, List } = Form;
    const { Panel } = Collapse;

    const { Title } = Typography;

    return (
      <Form
        ref={this.formInstance}
        initialValues={{ ...provider }}
        onValuesChange={(changedValues, allValues) => this.setState({ provider: { ...provider, ...allValues } })}
        layout="vertical"
        hideRequiredMark
      >
        <Item name="name" label="Nome do fornecedor">
          <Input placeholder={I18n.t('shared.typeSomething')} disabled />
        </Item>
        <Item name="id" label="Id do fornecedor">
          <Input placeholder={I18n.t('shared.typeSomething')} disabled />
        </Item>

        <Title style={{ margin: 0, padding: 0, marginBottom: 10 }} level={4}>
          Centros de distribuição:
        </Title>

        <List name="providerUnits">
          {(fields) => {
            return (
              <Collapse>
                {fields.map((field, index) => {
                  const unit = provider.providerUnits[index];
                  const headerTitlePanel = unit && unit.name ? unit.name : 'CD sem nome';
                  const cep = unit && unit.cep;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Panel header={headerTitlePanel} key={index}>
                      <Item name={[index, 'name']} label="Nome *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>

                      <Item name={[index, 'radius']} label="Raio (km) *">
                        <InputNumber
                          placeholder={I18n.t('shared.typeSomething')}
                          style={{ width: '100%' }}
                          step={1}
                          min={0}
                          disabled={isGetLocaleIsOnRequest || disabled}
                        />
                      </Item>

                      <AdvancedMaskedInput
                        label="CEP (somente números) *"
                        value={cep}
                        onChange={(value) => this.onChangePostalCode(value, index)}
                        kind="zip-code"
                        disabled={disabled}
                      />

                      <Item name={[index, 'address']} label="Endereço *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'province']} label="Bairro *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'city']} label="Cidade *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'state']} label="Estado *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'country']} label="País *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'number']} label="Número *">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      <Item name={[index, 'complement']} label="Complemento">
                        <Input
                          disabled={isGetLocaleIsOnRequest || disabled}
                          placeholder={I18n.t('shared.typeSomething')}
                        />
                      </Item>
                      {!disabled && (
                        <Button danger style={{ marginTop: '10px' }} onClick={() => this.onRemoveProviderUnit(index)}>
                          Remover
                        </Button>
                      )}
                    </Panel>
                  );
                })}
              </Collapse>
            );
          }}
        </List>
        {!disabled && (
          <Button style={{ marginTop: 15 }} type="primary" onClick={() => this.onAddProviderUnit()}>
            Adicionar
          </Button>
        )}
      </Form>
    );
  };

  render() {
    const { provider } = this.state;
    const {
      visible,
      type,
      onCloseForm,
      isGetProviderOnRequest,
      isGetProviderUnitsOnRequest,
      isUpdateProviderUnitOnRequest,
      isCreateProviderUnitOnRequest,
      isDeleteProviderUnitIsOnRequest,
      isGetLocaleIsOnRequest,
      disabled,
    } = this.props;

    return (
      <Drawer
        maskClosable={false}
        className="drawer"
        title={I18n.t('forms.provider.units.title')}
        onClose={() => {
          this.setState({ provider: null });
          onCloseForm();
        }}
        visible={visible}
        headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<MdClose color="black" size="25px" />}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <AdvancedButton
              type="link"
              text={I18n.t('forms.cancelButtonText')}
              onClick={() => {
                this.setState({ provider: null });
                onCloseForm();
              }}
            />
            {!disabled ? (
              <AdvancedButton
                text={I18n.t('forms.submitButtonText')}
                loading={
                  isUpdateProviderUnitOnRequest ||
                  isCreateProviderUnitOnRequest ||
                  isDeleteProviderUnitIsOnRequest ||
                  isGetLocaleIsOnRequest
                }
                onClick={() => this.onSubmit()}
              />
            ) : (
              <div />
            )}
          </div>
        }
      >
        {provider &&
          !isGetProviderUnitsOnRequest &&
          type === ViewTypes.EDIT_PROVIDER_UNITS &&
          this.editProviderUnitsForm(disabled)}

        <Loading size={40} loading={isGetProviderOnRequest || isGetProviderUnitsOnRequest} />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  isGetProviderOnRequest: ProviderSelectors.isGetProviderOnRequest(state),
  isGetLocaleIsOnRequest: LocaleSelectors.isGetLocaleIsOnRequest(state),
  isGetProviderUnitsOnRequest: ProviderSelectors.isGetProviderUnitsOnRequest(state),
  isUpdateProviderUnitOnRequest: ProviderSelectors.isUpdateProviderUnitOnRequest(state),
  isCreateProviderUnitOnRequest: ProviderSelectors.isCreateProviderUnitOnRequest(state),
  isDeleteProviderUnitIsOnRequest: ProviderSelectors.isDeleteProviderUnitOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProvider: (id) => dispatch(ProviderActions.getProvider(id)),
  onUpdateProvider: (id, data) => dispatch(ProviderActions.updateProvider(id, data)),
  onCreateProvider: (id, data) => dispatch(ProviderActions.createProvider(data)),
  onGetProviderUnits: (id) => dispatch(ProviderActions.getProviderUnits(id)),
  onGetLocale: (postalCode) => dispatch(LocaleActions.getLocale({ type: LocaleSearchTypes.ADDRESS, postalCode })),
  onCreateProviderUnit: (providerId, data) => dispatch(ProviderActions.createProviderUnit(providerId, data)),
  onUpdateProviderUnit: (providerId, unitId, data) =>
    dispatch(ProviderActions.updateProviderUnit(providerId, unitId, data)),
  onDeleteProviderUnit: (providerId, unitId) => dispatch(ProviderActions.deleteProviderUnit(providerId, unitId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderUnitsForm);
