import { I18n } from 'react-redux-i18n';
import { message } from 'antd';
import { navigate } from '@reach/router';
import AuthRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/auth/authRequestsV1';
import ProviderPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/provider/panel/providerPanelRequestsV1';
import UserRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/user/userRequestsV1';
import { getAuthData, isLogged } from 'tiffin-foods-api-client/lib/cjs/utils/auth';

import PlatformTypes from '../../enum/platform_types';
import { UserTypes } from '../../enum/user_types';
import { UserStatus } from '../../enum/user_status';

import AuthService from '../../services/auth';
import { getToken } from '../../services/firebase';
import { formatSlug } from '../../utils/string';
import { AuthSelectors } from '../reducers';
import { getUserDashboards, hasAccess } from '../../services/access';
import { AccessActions } from './index';
import { accessActionTypes } from '../../enum/access_action_types';
import { accessTypes } from '../../enum/access_types';

export const ACTION_LOG_OUT = 'ACTION_LOG_OUT:auth';
export const ACTION_USER_DETAIL = 'ACTION_USER_DETAIL:auth';
export const ACTION_LOG_IN_IS_ON_REQUEST = 'ACTION_LOG_IN_IS_ON_REQUEST:auth';

export const logout = (cleanServerSideAccess, avoidNavigateToLoginScreen) => async (dispatch) => {
  try {
    if (cleanServerSideAccess) {
      const { refreshToken } = await getAuthData();
      const pushToken = await getToken();

      await AuthRequestsV1.logout({
        refresh_token: refreshToken,
        pushTokenData: {
          deviceId: AuthService.getUniqueIdApp() || '',
          platform: PlatformTypes.WEB,
          pushToken: pushToken || '',
        },
      });
    }

    await AuthService.reset();
    dispatch({ type: ACTION_LOG_OUT });
    if (!avoidNavigateToLoginScreen) {
      navigate(I18n.t('routes.login.url'));
    }
  } catch (err) {
    // eslint-disable-next-line no-empty
  }
};

export const getUserDetails = () => async (dispatch) => {
  try {
    const { user, providers } = await UserRequestsV1.getMe();
    const { type } = user;

    if ([UserTypes.ADMIN, UserTypes.SUPPORT].includes(type)) {
      const allApprovedProviders = await ProviderPanelRequestsV1.getAll({
        simple: true,
        limit: Number.MAX_SAFE_INTEGER,
      });

      const { rows } = allApprovedProviders;
      dispatch({ type: ACTION_USER_DETAIL, payload: { user, providers: rows } });
    } else {
      dispatch({ type: ACTION_USER_DETAIL, payload: { user, providers: providers.map((o) => o.provider) } });
    }
  } finally {
    // eslint-disable-next-line no-empty
  }
};

export const verifyInitialFlow = () => async (dispatch, getState) => {
  try {
    const isAuthenticated = isLogged();
    if (isAuthenticated) {
      await dispatch(getUserDetails());
      await dispatch(
        AccessActions.getUserAccess(async () => {
          const userDashboards = getUserDashboards();
          const userType = AuthSelectors.getUserType(getState());

          if (userType === UserTypes.USER_NATURAL) {
            navigate(I18n.t('routes.forbidden.url'));
            await dispatch(logout(true, true));
          } else {
            const { status } = AuthSelectors.getUser(getState());

            if (status === UserStatus.WAITING_VALIDATION_EMAIL || status === UserStatus.WAITING_VALIDATION_PHONE) {
              navigate(`${I18n.t('routes.signup.url')}/codevalidation`);
            } else {
              message.success(I18n.t('routes.login.messages.welcome'));
              if (userDashboards?.length > 0) {
                navigate(
                  `${I18n.t('routes.panel.dashboards.url')}/${userDashboards[0].accessFeatureId}-${formatSlug(
                    userDashboards[0].accessFeatureDescription,
                  )}`,
                );
              } else {
                if (hasAccess([accessTypes.ORDERS], [accessActionTypes.READ])) {
                  return navigate(I18n.t('routes.panel.orders.url'));
                }
                navigate(I18n.t('routes.panel.providers.url'));
              }
            }
          }
        }),
      );
    } else {
      navigate(I18n.t('routes.login.url'));
    }
  } catch (err) {
    // empty
  }
};

export const authenticate = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_LOG_IN_IS_ON_REQUEST, payload: true });
    await AuthRequestsV1.authenticate({ username, password });
    await dispatch(verifyInitialFlow());
    return true;
  } finally {
    dispatch({ type: ACTION_LOG_IN_IS_ON_REQUEST, payload: false });
  }
};
