import React from 'react';
import { Divider, Layout, Row, Col, message, Breadcrumb, Collapse, Modal, Popover } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DashboardFilled,
  SearchOutlined,
  ExclamationCircleFilled,
  QuestionCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import ProviderForm from '../../../components/provider/form/ProviderForm';
import ProviderUnitsForm from '../../../components/provider/form/ProviderUnitsForm';
import ProviderBankDataForm from '../../../components/provider/form/ProviderPaymentAccountForm';
import ProviderTagsForm from '../../../components/provider/form/ProviderTagsForm';
import ProviderManagersForm from '../../../components/provider/form/ProviderManagersForm';

import { ViewTypes } from '../../../app/enum/view_types';

import { ProviderActions, TagActions } from '../../../app/redux/actions';
import { AuthSelectors, ProviderSelectors } from '../../../app/redux/reducers';

import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import ProviderColumnsProviders from '../../../app/utils/dataTable/columns/providers/ProviderColumnsProviders';
import ProviderColumnsSupporters from '../../../app/utils/dataTable/columns/supporters/ProviderColumnsSupporters';
import { UserTypes } from '../../../app/enum/user_types';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';
import ProviderCertificateForm from '../../../components/provider/form/ProviderCertificateForm';

class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      providerFormVisible: false,
      providerTagsFormVisible: false,
      providerUnitsFormVisible: false,
      providerManagersFormVisible: false,
      providerCertificateFormVisible: false,
      importModalVisible: false,
      certificateWarningVisible: false,
      filteredInfo: null,
      form: {
        type: null,
        id: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { onGetTags } = this.props;
    onGetTags();
  }

  getProviders = (params) => {
    const { getProvidersPaginated } = this.props;
    const { search, filters } = this.state;

    if (params) {
      this.setState({ filters: params });
      getProvidersPaginated({ ...params, search });
    } else {
      getProvidersPaginated({ ...filters, search });
    }
  };

  fieldChange = (name, value) => {
    const { state } = this;
    state[name] = value ? value.toString() : null;
    this.setState(state);
  };

  cleanAdvancedFilters = async () => {
    await this.setState({ filters: null, search: '', filteredInfo: null });
    this.dataTableRef.current.reset();
    this.getProviders();
  };

  removeProvider = async (id) => {
    const { onDeleteProvider } = this.props;
    const success = await onDeleteProvider(id);
    if (success) {
      message.success(I18n.t('routes.panel.providers.deleteSuccess'));
      this.getProviders();
    }
  };

  onProviderFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerFormVisible: visible, form: { id, type } });
  };

  onProviderUnitsFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerUnitsFormVisible: visible, form: { id, type } });
  };

  onProviderBankDataFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerBankDataFormVisible: visible, form: { id, type } });
  };

  onProviderTagsFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerTagsFormVisible: visible, form: { id, type } });
  };

  onProviderManagersFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerManagersFormVisible: visible, form: { id, type } });
  };

  onProviderCertificateFormStateChange = (visible, id = null, type = null) => {
    this.setState({ providerCertificateFormVisible: visible, form: { id, type } });
  };

  onActionsClick = async (id, item, key) => {
    switch (key) {
      case '1':
        return this.onProviderFormStateChange(true, id, ViewTypes.EDIT);
      case '2':
        return this.onProviderUnitsFormStateChange(true, id, ViewTypes.EDIT_PROVIDER_UNITS);
      case '3':
        return this.onProviderBankDataFormStateChange(true, id, ViewTypes.EDIT_PROVIDER_BANK_DATA);
      case '4':
        return this.onProviderTagsFormStateChange(true, id, ViewTypes.EDIT_PROVIDER_TAGS_DATA);
      case '5':
        return this.onProviderManagersFormStateChange(true, id, ViewTypes.EDIT_PROVIDER_MANAGERS_DATA);
      case '6':
        return this.onProviderCertificateFormStateChange(true, id);
      case '7':
        return this.onShowDeleteConfirm(id);
      default:
    }
  };

  handleSearch = () => {
    this.dataTableRef.current.reset();
    this.getProviders();
  };

  onShowDeleteConfirm = (providerId) => {
    const { confirm } = Modal;
    const { deleteProviderIsOnRequest } = this.props;

    confirm({
      title: 'Atenção, deseja realmente deletar esse fornecedor?',
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: 'Sim',
      okType: 'danger',
      centered: true,
      cancelText: 'Cancelar',
      confirmLoading: deleteProviderIsOnRequest,
      onOk: () => this.removeProvider(providerId),
    });
  };

  handleChange = (pagination, filters) => {
    this.setState({ filteredInfo: filters });
  };

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const {
      search,
      form,
      providerUnitsFormVisible,
      providerFormVisible,
      providerBankDataFormVisible,
      providerTagsFormVisible,
      providerManagersFormVisible,
      providerCertificateFormVisible,
    } = this.state;

    const { providersPaginated, isGetProvidersPaginatedOnRequest, isUpdateProviderIsOnRequest, getUser } = this.props;

    const { type, id } = form;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.providers.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel header={<strong>{I18n.t('routes.panel.providers.advancedFilters.title')}</strong>} key="1">
            <Row>
              <AdvancedInput
                style={{ width: 240, marginRight: 10 }}
                value={search}
                onChange={(val) => this.fieldChange('search', val)}
                placeholder={I18n.t('shared.typeToContinue')}
                onPressEnter={this.handleSearch}
              />
              <Popover placement="topLeft" content={I18n.t('routes.panel.providers.advancedFilters.searchFieldTitle')}>
                <QuestionCircleFilled style={{ fontSize: 20, color: '#000000', marginTop: 5 }} />
              </Popover>
            </Row>

            <Row>
              <AdvancedButton
                type="default"
                text={I18n.t('routes.panel.providers.advancedFilters.clearButtonText')}
                onClick={this.cleanAdvancedFilters}
              />
              <AdvancedButton
                style={{ marginLeft: 10 }}
                text={I18n.t('routes.panel.providers.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={this.handleSearch}
              />
            </Row>
          </Panel>
        </Collapse>

        <Content className="panel__layout__content panel__layout__content--content-data">
          {hasAccess([accessTypes.PROVIDERS], [accessActionTypes.CREATE]) && (
            <>
              <Row align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="panel__layout__content__actions">
                    <div className="panel__layout__content__actions__left">
                      <AdvancedButton
                        onClick={() => this.onProviderFormStateChange(true, null, ViewTypes.CREATE)}
                        text={I18n.t('routes.panel.providers.addNewButtonText')}
                        iconLeft={<PlusOutlined />}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <AdvancedDataTable
            refresh={this.getProviders}
            getMethod={(parameters) => this.getProviders(parameters)}
            data={providersPaginated}
            loading={isGetProvidersPaginatedOnRequest || isUpdateProviderIsOnRequest}
            ref={this.dataTableRef}
            onChange={this.handleChange}
            container="providers"
            columns={
              [UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type)
                ? ProviderColumnsSupporters(this.state, this.onActionsClick, getUser)
                : ProviderColumnsProviders(this.state, this.onActionsClick, getUser)
            }
          />
        </Content>

        <ProviderForm
          id={id}
          type={type}
          visible={providerFormVisible}
          onCloseForm={(refresh) => {
            this.onProviderFormStateChange(false);
            if (refresh) {
              this.getProviders();
            }
          }}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />

        <ProviderUnitsForm
          id={id}
          type={type}
          visible={providerUnitsFormVisible}
          onCloseForm={(refresh) => {
            this.onProviderUnitsFormStateChange(false);
            if (refresh) {
              this.getProviders();
            }
          }}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />

        <ProviderBankDataForm
          id={id}
          type={type}
          visible={providerBankDataFormVisible}
          onCloseForm={(refresh) => {
            this.onProviderBankDataFormStateChange(false);
            if (refresh) {
              this.getProviders();
            }
          }}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />

        <ProviderTagsForm
          visible={providerTagsFormVisible}
          onCloseForm={() => this.onProviderTagsFormStateChange(false)}
          id={id}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />

        <ProviderManagersForm
          visible={providerManagersFormVisible}
          onCloseForm={() => this.onProviderManagersFormStateChange(false)}
          id={id}
          type={type}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />

        <ProviderCertificateForm
          visible={providerCertificateFormVisible}
          onCloseForm={(refresh) => {
            this.onProviderCertificateFormStateChange(false);
            if (refresh) {
              this.getProviders();
            }
          }}
          id={id}
          disabled={!hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE])}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  providersPaginated: ProviderSelectors.getProvidersPaginated(state),
  isGetProvidersPaginatedOnRequest: ProviderSelectors.isGetProvidersPaginatedOnRequest(state),
  isUpdateProviderIsOnRequest: ProviderSelectors.isUpdateProviderOnRequest(state),
  isDeleteProviderOnRequest: ProviderSelectors.isDeleteProviderOnRequest(state),
  getUser: AuthSelectors.getUser(state),
  providerCertificate: ProviderSelectors.getProviderCertification(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersPaginated: (parameters) => dispatch(ProviderActions.getProvidersPaginated(parameters)),
  onDeleteProvider: (id) => dispatch(ProviderActions.deleteProvider(id)),
  onGetTags: () => dispatch(TagActions.getTags()),
  onGetProviderCertificate: (id) => dispatch(ProviderActions.getProviderCertificate(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
