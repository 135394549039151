import Immutable from 'seamless-immutable';
import { ACTION_SHOW_MESSAGE } from '../actions/message';

const initialState = Immutable({
  title: null,
  message: null,
  isError: false,
});

export default function message(state = initialState, action) {
  if (action.type === ACTION_SHOW_MESSAGE) {
    return state.merge({
      title: action.payload.title,
      message: action.payload.message,
      isError: action.payload.isError,
    });
  }
  return state;
}

export function getMessage(state) {
  return state.message.message;
}

export function getTitle(state) {
  return state.message.title;
}

export function isError(state) {
  return state.message.isError;
}
