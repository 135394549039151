import classNames from 'classnames';
import React from 'react';
import { Checkbox } from 'antd';

function AdvancedCheckbox({ label, onChange, value, disabled, required, style, noPadding, centered }) {
  return (
    <div
      className={`advanced-checkbox ${disabled && 'advanced-checkbox--disabled'} ${
        noPadding && 'advanced-checkbox--no-padding'
      }`}
      style={style}
    >
      <label
        className={classNames({
          'advanced-checkbox__label': true,
          'advanced-checkbox__label--centered': centered,
          'advanced-checkbox__label--disabled': disabled,
        })}
      >
        <Checkbox onChange={(ev) => onChange(ev.target.checked)} checked={value} disabled={disabled || false} />
        <span className="advanced-checkbox__label__inner">
          {label}
          {required && <strong>*</strong>}
        </span>
      </label>
    </div>
  );
}

export default AdvancedCheckbox;
