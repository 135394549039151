import Immutable from 'seamless-immutable';
import { ACTION_GET_QUESTION_DATA, ACTION_GET_QUESTION_IS_ON_REQUEST } from '../actions/question';

const initialState = Immutable({
  questions: [],
  isGetQuestionsIsOnRequest: false,
});

export default function question(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_QUESTION_DATA:
      return state.merge({
        questions: action.payload,
      });
    case ACTION_GET_QUESTION_IS_ON_REQUEST:
      return state.merge({
        isGetQuestionsIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getQuestions(state) {
  return state.question.questions;
}

export function isGetQuestionsIsOnRequest(state) {
  return state.question.isGetQuestionsIsOnRequest;
}
