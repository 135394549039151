import React from 'react';
import { Modal, Table } from 'antd';
import * as PriceUtils from '../../../app/utils/prices';

function ProviderBillingDetailsModal({ visible, data, onCancel, onOk }) {
  const columns = [
    {
      title: 'Plano',
      dataIndex: 'planName',
      key: 'planName',
      render: (val) => val || '--',
    },
    {
      title: 'Valor',
      dataIndex: 'billingValue',
      key: 'billingValue',
      render: (val) => PriceUtils?.getPrice(val) || '--',
    },
  ];

  return (
    <Modal title="Detalhes da cobrança:" visible={visible} onCancel={onCancel} onOk={onOk}>
      <Table columns={columns} dataSource={data?.jsonBillingItems} pagination={false} />
    </Modal>
  );
}

export default ProviderBillingDetailsModal;
