import React from 'react';
import moment from 'moment-timezone';
import { DatePicker } from 'antd';

import './AdvancedDatePicker.less';

function AdvancedDatePicker({ onOk, onChange, value, format, label, showTime, style, pickerStyle, disabled }) {
  return (
    <div className="advanced-date-picker" style={style}>
      <label className="advanced-date-picker__label">
        <span className="advanced-date-picker__label__inner">{label}</span>
        <DatePicker
          style={pickerStyle}
          showTime={showTime}
          onOk={onOk}
          onChange={onChange}
          format={format || 'DD/MM/YYYY HH:mm'}
          value={moment(value).isValid() ? moment(value) : 0}
          disabled={disabled}
          locale={{
            lang: {
              locale: 'pt_BR',
              placeholder: 'Selecione uma data',
              today: 'Hoje',
              now: 'Agora',
              backToToday: 'Voltar para hoje',
              ok: 'Ok',
              clear: 'Limpar',
              month: 'Mês',
              year: 'Ano',
              yearFormat: 'YYYY',
              monthFormat: 'MMM',
              dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
              monthBeforeYear: true,
            },
            dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
          }}
        />
      </label>
    </div>
  );
}

export default AdvancedDatePicker;
