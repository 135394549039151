import React from 'react';
import { Layout, Card, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import { navigate } from '@reach/router';
import { isLogged } from 'tiffin-foods-api-client/lib/cjs/utils/auth';
import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';

import { AuthActions } from '../../app/redux/actions';
import { AuthSelectors, UserSelectors } from '../../app/redux/reducers';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { onVerifyInitialFlow } = this.props;
    if (isLogged()) {
      onVerifyInitialFlow();
    }
  }

  handleAuthentication = (event) => {
    event.preventDefault();

    const { email, password } = this.state;
    if (!email || !password) {
      notification.error({
        message: 'Preencha todos os campos',
        description: 'Verifique se inseriu o e-mail e a senha.',
      });
    } else {
      const { onLogIn } = this.props;
      onLogIn(email, password);
    }
  };

  render() {
    const { Content } = Layout;
    const { email, password } = this.state;
    const { isLogInOnRequest, userDetailsLoading } = this.props;

    return (
      <Layout>
        <Content className="login">
          <Card bodyStyle={{ padding: '0' }} className="login__container">
            <div className="login__container__signin__container">
              <div className="login__logo">
                <img alt="login logo" className="login__logo__img" src="/assets/img/img_logo.png" />
              </div>
              <form
                className="login__container__signin__container__form"
                onSubmit={(event) => this.handleAuthentication(event)}
              >
                <AdvancedInput
                  label={I18n.t('routes.login.content.email')}
                  placeholder={I18n.t('routes.login.content.emailPlaceholder')}
                  type="email"
                  value={email}
                  onChange={(value) => this.setState({ email: value })}
                  disabled={isLogInOnRequest || userDetailsLoading}
                />
                <AdvancedInput
                  label={I18n.t('routes.login.content.password')}
                  placeholder={I18n.t('routes.login.content.passwordPlaceholder')}
                  type="password"
                  value={password}
                  onChange={(value) => this.setState({ password: value })}
                  disabled={isLogInOnRequest || userDetailsLoading}
                  isPassword
                />
                <AdvancedButton
                  className="login__container__signin__container__form__button"
                  text={I18n.t('routes.login.content.continueButton')}
                  loading={isLogInOnRequest || userDetailsLoading}
                  htmlType="submit"
                />
              </form>
            </div>
            <div className="login__container__signup__container">
              <p className="login__container__signup__container__title">
                {`${I18n.t('routes.login.content.signup.text')}${I18n.t('application.title')}?`}
              </p>
              <AdvancedButton
                className="login__container__signup__container__button"
                text={I18n.t('routes.login.content.signup.button')}
                type="link"
                onClick={() => navigate(I18n.t('routes.signup.url'))}
              />
            </div>
            <div className="login__container__forget__password__container">
              <p className="login__container__forget__password__container__title">
                {`${I18n.t('routes.login.content.forgetPassword.text')}`}
              </p>
              <AdvancedButton
                className="login__container__forget__password__container__button"
                text={I18n.t('routes.login.content.forgetPassword.button')}
                type="link"
                onClick={() => navigate(I18n.t('routes.forgetPassword.url'))}
              />
            </div>
          </Card>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogInOnRequest: AuthSelectors.isLogInOnRequest(state),
  userDetailsLoading: UserSelectors.isGetUserOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLogIn: (email, password) => dispatch(AuthActions.authenticate(email, password)),
  onVerifyInitialFlow: () => dispatch(AuthActions.verifyInitialFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
