import Immutable from 'seamless-immutable';
import { ACTION_GET_CATEGORIES_DATA, ACTION_GET_CATEGORIES_IS_ON_REQUEST } from '../actions/category';

const initialState = Immutable({
  categories: [],
  isGetCategoriesOnRequest: false,
});

export default function category(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_CATEGORIES_DATA:
      return state.merge({
        categories: action.payload,
      });
    case ACTION_GET_CATEGORIES_IS_ON_REQUEST:
      return state.merge({
        isGetCategoriesOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getCategories(state) {
  return [...state.category.categories].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}

export function isGetCategoriesOnRequest(state) {
  return state.category.isGetCategoriesOnRequest;
}
