import Immutable from 'seamless-immutable';
import {
  ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST,
  ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST,
  ACTION_GET_ORDER_SHIPMENT_LABEL_DATA,
} from '../actions/shipment';

const initialState = Immutable({
  isUpdateOrderShipmentStatusOnRequest: false,
  isGetOrderShipmentLabelOnRequest: false,
  orderShipmentLabel: null,
});

export default function provider(state = initialState, action) {
  switch (action.type) {
    case ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST:
      return state.merge({
        isUpdateOrderShipmentStatusOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST:
      return state.merge({
        isGetOrderShipmentLabelOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_SHIPMENT_LABEL_DATA:
      return state.merge({
        orderShipmentLabel: action.payload,
      });
    default:
      return state;
  }
}
