import { i18nReducer } from 'react-redux-i18n';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { seamlessImmutableReconciler } from 'redux-persist-seamless-immutable';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { AuthActions } from './actions';
import authReducer from './reducers/auth';
import dashboardReducer from './reducers/dashboard';
import userReducer from './reducers/user';
import orderReducer from './reducers/order';
import productReducer from './reducers/product';
import paymentReducer from './reducers/payment';
import providerReducer from './reducers/provider';
import cardReducer from './reducers/card';
import categoryReducer from './reducers/category';
import nutritionReducer from './reducers/nutrition';
import distributorReducer from './reducers/distributor';
import localeReducer from './reducers/locale';
import fileReducer from './reducers/file';
import pushReducer from './reducers/push';
import questionReducer from './reducers/question';
import messageReducer from './reducers/message';
import settingReducer from './reducers/setting';
import customerReducer from './reducers/customer';
import tagReducer from './reducers/tag';
import highlightReducer from './reducers/highlight';
import accessReducer from './reducers/access';
import accessGroupReducer from './reducers/accessGroup';
import accessFeatureReducer from './reducers/accessFeature';
import accessGroupUserReducer from './reducers/accessGroupUser';
import accessFeatureUserReducer from './reducers/accessFeatureUser';
import accessGroupFeatureReducer from './reducers/accessGroupFeature';
import shipmentReducer from './reducers/shipment';
import collectionReducer from './reducers/collection';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  blacklist: ['i18n', 'access', 'accessGroup', 'accessFeature', 'accessGroupUser'],
};

const persistedReducer = persistCombineReducers(persistConfig, {
  i18n: i18nReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  order: orderReducer,
  product: productReducer,
  payment: paymentReducer,
  provider: providerReducer,
  card: cardReducer,
  category: categoryReducer,
  nutrition: nutritionReducer,
  distributor: distributorReducer,
  locale: localeReducer,
  file: fileReducer,
  push: pushReducer,
  question: questionReducer,
  message: messageReducer,
  setting: settingReducer,
  customer: customerReducer,
  tag: tagReducer,
  access: accessReducer,
  accessGroup: accessGroupReducer,
  accessFeature: accessFeatureReducer,
  accessGroupUser: accessGroupUserReducer,
  accessFeatureUser: accessFeatureUserReducer,
  accessGroupFeature: accessGroupFeatureReducer,
  highlight: highlightReducer,
  shipment: shipmentReducer,
  collection: collectionReducer,
});

const store = createStore((state, action) => {
  if (action.type === AuthActions.ACTION_LOG_OUT) {
    return persistedReducer({ i18n: state.i18n }, action);
  }
  return persistedReducer(state, action);
  // eslint-disable-next-line max-len
}, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f));
const persistor = persistStore(store);

export default {
  store,
  persistor,
};
