import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Col, Row } from 'antd';

import { TransferReverse } from './styles';
import { useReduxState } from '../../../../app/hooks/useReduxState';

function InnerCategoriesProductForm({ product, updateProductData, disabled }) {
  const { category } = useReduxState();

  const handleInputChange = (categories) => {
    const productCopy = { ...product };
    productCopy.categories = categories;
    updateProductData(productCopy);
  };

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.categories.description')}</p>
        </Col>
      </Row>
      <TransferReverse
        dataSource={category?.categories?.map((categoryMap) => ({
          key: categoryMap.id,
          title: categoryMap.name,
          description: categoryMap.name,
        }))}
        size="small"
        titles={[
          I18n.t('forms.product.items.categories.items.transfer.labelEmpty'),
          I18n.t('forms.product.items.categories.items.transfer.labelFilled'),
        ]}
        targetKeys={product.categories}
        onChange={handleInputChange}
        render={(item) => item.title}
        locale={{
          notFoundContent: I18n.t('forms.product.items.categories.items.transfer.locale.notFoundContent'),
        }}
        disabled={disabled}
      />
    </div>
  );
}

export default InnerCategoriesProductForm;
