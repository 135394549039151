import React from 'react';

import * as Styles from './styles';

export default function AdvancedSelectBox({ source, onRemove, disabled }) {
  return (
    <Styles.Container>
      {source &&
        source.map((element) => (
          <Styles.Line key={element.value} disabled={disabled}>
            <Styles.CustomFaWindowClose
              disabled={disabled}
              onClick={() => {
                if (!disabled) onRemove(element.value);
              }}
            />
            <Styles.CustomSpanInside>{element.label}</Styles.CustomSpanInside>
          </Styles.Line>
        ))}
    </Styles.Container>
  );
}
