import React from 'react';
import { Button } from 'antd';
import { Link } from '@reach/router';

function AdvancedButton({
  text,
  onClick,
  type,
  clickValue,
  disabled,
  shape,
  loading,
  htmlType,
  href,
  target,
  iconRight,
  iconLeft,
  style,
  strongText,
  ...rest
}) {
  return (
    <Button
      {...rest}
      className="advanced-button"
      style={style}
      type={type || 'primary'}
      onClick={() => (onClick ? onClick(clickValue || null) : undefined)}
      shape={shape || undefined}
      disabled={disabled || false}
      loading={loading || false}
      htmlType={htmlType || 'button'}
      target={target || '_self'}
    >
      {href ? (
        <Link className="advanced-button__text" to={href}>
          {iconLeft && iconLeft} {strongText ? <strong>{text}</strong> : text} {iconRight && iconRight}
        </Link>
      ) : (
        <span className="advanced-button__text" style={{ alignSelf: 'start' }}>
          {iconLeft && iconLeft} {strongText ? <strong>{text}</strong> : text} {iconRight && iconRight}
        </span>
      )}
    </Button>
  );
}

export default AdvancedButton;
