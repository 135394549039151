import Immutable from 'seamless-immutable';
import {
  ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ACCESS_FEATURES_PAGINATED_DATA,
  ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST,
  ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST,
  ACTION_UPDATE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST,
  ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST,
  ACTION_GET_ACCESS_FEATURE_BY_ID,
} from '../actions/accessFeature';

const initialState = Immutable({
  accessFeaturesPaginated: null,
  getAccessFeaturesPaginatedIsOnRequest: false,
  accessFeatureById: null,
  accessFeatureByIdIsOnRequest: false,
  createAccessFeatureIsOnRequest: false,
  deleteAccessFeatureIsOnRequest: false,
  updateAccessFeatureIsOnRequest: false,
});

export default function accessFeature(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ACCESS_FEATURES_PAGINATED_DATA:
      return state.merge({
        accessFeaturesPaginated: action.payload,
      });
    case ACTION_GET_ACCESS_FEATURE_BY_ID:
      return state.merge({
        accessFeatureById: action.payload,
      });
    case ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        getAccessFeaturesPaginatedIsOnRequest: action.payload,
      });
    case ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST:
      return state.merge({
        createAccessFeatureIsOnRequest: action.payload,
      });
    case ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST:
      return state.merge({
        accessFeatureByIdIsOnRequest: action.payload,
      });
    case ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST:
      return state.merge({
        deleteAccessFeatureIsOnRequest: action.payload,
      });
    case ACTION_UPDATE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST:
      return state.merge({
        updateAccessFeatureIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}
