import Immutable from 'seamless-immutable';
import { ACTION_SEND_PUSH_IS_ON_REQUEST } from '../actions/push';

const initialState = Immutable({
  isSendPushOnRequest: false,
});

export default function provider(state = initialState, action) {
  switch (action.type) {
    case ACTION_SEND_PUSH_IS_ON_REQUEST:
      return state.merge({
        isSendPushOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function isSendPushOnRequest(state) {
  return state.push.isSendPushOnRequest;
}
