import { notification } from 'antd';
import LocaleRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/locale/localeRequestsV1';

export const ACTION_GET_LOCALE_IS_ON_REQUEST = 'ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST:LOCALE';

function getAddressError(concatText) {
  return `Não conseguimos identificar ${concatText}, verifique se o endereço está correto.`;
}

export const getLocale =
  ({ type, search, postalCode, latitude, longitude }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_LOCALE_IS_ON_REQUEST, payload: true });
      const locale = await LocaleRequestsV1.getLocale({
        type,
        search,
        postalCode,
        latitude,
        longitude,
      });
      const { city, state } = locale;
      if (!city) {
        notification.error({
          message: 'Hmm estranho!',
          description: `${getAddressError('sua cidade')}`,
        });
        return;
      }
      if (!state) {
        notification.error({
          message: 'Hmm estranho!',
          description: `${getAddressError('seu estado')}`,
        });
        return;
      }
      return locale;
    } finally {
      dispatch({ type: ACTION_GET_LOCALE_IS_ON_REQUEST, payload: false });
    }
  };
