import React from 'react';
import './ProviderForm.less';

import { Drawer, Form, Input, Select, Upload, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as PriceUtils from '../../../app/utils/prices';

import * as ProviderTypes from '../../../app/enum/provider_types';
import * as ProviderStatus from '../../../app/enum/provider_status';
import DocumentTypes from '../../../app/enum/document_types';
import { UserTypes } from '../../../app/enum/user_types';
import { ViewTypes } from '../../../app/enum/view_types';

import { ImageCompress } from '../../../app/utils/compressor';
import Loading from '../../shared/Loading';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedInputNumber from '../../shared/AdvancedInputNumber';
import AdvancedMaskedInput from '../../shared/AdvancedMaskedInput';

import { ProviderActions } from '../../../app/redux/actions';
import { AuthSelectors, ProviderSelectors } from '../../../app/redux/reducers';

class ProviderForm extends React.Component {
  constructor(props) {
    super(props);
    this.formInstance = React.createRef();
    this.state = {
      fileList: [],
      providerChangeData: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { id, type } = this.props;
    if (type === ViewTypes.CREATE && !prevProps.visible) {
      this.createProvider();
    }
    if (id && !prevProps.visible && type === ViewTypes.EDIT) {
      this.getProvider(id);
    }
  }

  createProvider = async () => {
    await this.setState({
      provider: {
        minValue: 0,
      },
    });
    this.formInstance.current.resetFields();
  };

  getProvider = async (id) => {
    const { onGetProvider } = this.props;
    const provider = await onGetProvider(id);
    let fileList = [];
    if (provider && provider.images) {
      fileList = provider.images.map((image, index) => ({
        uid: `uid-provider-${index}`,
        name: `provider-picture-${index}`,
        status: 'done',
        url: image.imageUrl,
      }));
    }

    await this.setState({
      provider,
      fileList,
    });

    if (this.formInstance?.current) {
      this.formInstance.current.setFieldsValue(this.state.provider);
    }
  };

  onHandleRemoveImage = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  onHandlePreviewImageCancel = () => this.setState({ previewVisible: false });

  onHandlePreviewImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  onHandleImageBeforeUpload = (file) => {
    const imageCompressParams = { quality: 0.6, maxWidth: 1500, maxHeight: 1500 };
    const reader = new FileReader();
    ImageCompress(file, reader, imageCompressParams);
    reader.onloadend = () => {
      this.setState((state) => {
        const extension = reader.result.split('/')[1].split(';')[0];
        return {
          fileList: [
            ...state.fileList,
            { ...file, name: `${file.uid}.${extension}`, extension, status: 'done', url: reader.result },
          ],
        };
      });
    };

    return false;
  };

  onSubmit = async () => {
    const { providerChangeData, fileList } = this.state;
    const { id, type } = this.props;
    const { onCreateProvider, onUpdateProvider } = this.props;

    const values = {
      ...providerChangeData,
      images: fileList.map((file, index) => ({
        position: index,
        imageBase64: file.url.includes('base64') ? file.url : null,
        imageUrl: !file.url.includes('base64') ? file.url : null,
        extension: file.extension,
      })),
    };

    const dataIsValid = [
      { name: !(type === ViewTypes.CREATE && !values.name) },
      { type: !(type === ViewTypes.CREATE && !values.type) },
      { document: !(type === ViewTypes.CREATE && !values.document) },
    ];

    const invalidFields = dataIsValid.map((invalidData) => {
      if (Object.values(invalidData).toString() === 'false') {
        return Object.keys(invalidData).toString();
      }
      return null;
    });

    const invalidFieldsTranslated = invalidFields.map((entry) => {
      switch (entry) {
        case 'name':
          return I18n.t('forms.provider.invalidFields.name');
        case 'type':
          return I18n.t('forms.provider.invalidFields.type');
        case 'document':
          return I18n.t('forms.provider.invalidFields.document');
        default:
          return null;
      }
    });

    const invalidFiledFilteredTranslated = invalidFieldsTranslated.filter((filtered) => filtered !== null);

    if (invalidFiledFilteredTranslated.length > 0) {
      return notification.error({
        message: I18n.t('forms.provider.invalidFields.validation.required.title'),
        description: I18n.t('forms.provider.invalidFields.validation.required.description', {
          fields: invalidFiledFilteredTranslated.map((string) => string),
        }),
      });
    }

    let success;
    if (type === ViewTypes.CREATE) {
      success = await onCreateProvider(values);
    } else if (onUpdateProvider && id) {
      success = await onUpdateProvider(id, values);
    }

    if (success) {
      notification.success({
        message:
          type === ViewTypes.CREATE
            ? I18n.t('forms.provider.createSuccessTitle')
            : I18n.t('forms.provider.updateSuccessTitle'),
        description:
          type === ViewTypes.CREATE
            ? I18n.t('forms.provider.createSuccessDescription')
            : I18n.t('forms.provider.updateSuccessDescription'),
      });

      this.onCloseForm(true);
    }
  };

  switchTitle = () => {
    const { type } = this.props;
    switch (type) {
      case ViewTypes.CREATE:
        return I18n.t('forms.provider.createTitle');
      case ViewTypes.EDIT:
        return I18n.t('forms.provider.updateTitle');
      default:
        return I18n.t('forms.provider.createTitle');
    }
  };

  onCloseForm = async (refresh) => {
    const { onCloseForm } = this.props;
    await this.setState({ provider: null, providerChangeData: null, fileList: [] });
    onCloseForm(refresh);
  };

  render() {
    const { provider, providerChangeData, previewVisible, previewTitle, previewImage, fileList } = this.state;
    const {
      visible,
      type,
      getUser,
      isGetProviderOnRequest,
      isUpdateProviderOnRequest,
      isCreateProviderOnRequest,
      disabled,
    } = this.props;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Drawer
        maskClosable={false}
        className="drawer"
        title={this.switchTitle()}
        onClose={() => this.onCloseForm()}
        visible={visible}
        headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<MdClose color="black" size="25px" />}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={() => this.onCloseForm()} />
            {!disabled ? (
              <AdvancedButton
                loading={isUpdateProviderOnRequest || isCreateProviderOnRequest}
                text={I18n.t('forms.submitButtonText')}
                onClick={() => this.onSubmit()}
              />
            ) : (
              <div />
            )}
          </div>
        }
      >
        {!isGetProviderOnRequest && (
          <Form
            ref={this.formInstance}
            initialValues={{ ...provider }}
            onValuesChange={(changedValues) =>
              this.setState({
                provider: { ...provider, ...changedValues },
                providerChangeData: { ...providerChangeData, ...changedValues },
              })
            }
            layout="vertical"
            hideRequiredMark
          >
            {type !== ViewTypes.CREATE && (
              <Form.Item name="id" label={I18n.t('forms.provider.providerIdLabel')}>
                <Input disabled placeholder={I18n.t('shared.typeSomething')} />
              </Form.Item>
            )}

            <Form.Item name="name" label={I18n.t('forms.provider.providerNameLabel')} required>
              <Input value={provider?.name} placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
            </Form.Item>

            {type !== ViewTypes.CREATE && (
              <Form.Item name="status" label={I18n.t('forms.provider.providerStatusLabel')} required>
                <Select
                  disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
                  placeholder={I18n.t('shared.selectSomeValue')}
                >
                  {ProviderStatus.GetProviderStatusArray.map((userType) => (
                    <Select.Option key={userType.id} value={userType.id}>
                      {userType.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) && (
              <Form.Item name="type" label={I18n.t('forms.provider.profileTypeLabel')} required>
                <Select placeholder={I18n.t('shared.selectSomeValue')} disabled={disabled}>
                  {ProviderTypes.GetProviderTypeArray.map((userType) => (
                    <Select.Option key={userType.id} value={userType.id}>
                      {userType.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <AdvancedMaskedInput
              label={I18n.t('forms.provider.documentLabel')}
              value={provider?.document}
              required
              onChange={(document) =>
                this.setState({
                  provider: { ...provider, document },
                  providerChangeData: { ...providerChangeData, document },
                })
              }
              kind={DocumentTypes.CNPJ}
              disabled={disabled}
            />

            <Form.Item name="shortDescription" label={I18n.t('forms.provider.shortDescriptionLabel')}>
              <Input placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
            </Form.Item>

            <AdvancedMaskedInput
              label={I18n.t('forms.provider.minValueLabel')}
              value={provider && PriceUtils.getPrice(provider.minValue || 0)}
              onChange={(minValue) =>
                this.setState({
                  provider: { ...provider, minValue: PriceUtils.cleanValue(minValue) },
                  providerChangeData: { ...providerChangeData, minValue: PriceUtils.cleanValue(minValue) },
                })
              }
              options={{ unit: 'R$ ', zeroCents: true }}
              kind="money"
              disabled={disabled}
            />

            <AdvancedInputNumber
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
              label={I18n.t('forms.provider.scoreLabel')}
              value={provider && provider.score}
              max={10}
              step={0.01}
              onChange={(score) =>
                this.setState({
                  provider: { ...provider, score },
                  providerChangeData: { ...providerChangeData, score },
                })
              }
              inputStyle={{ width: '100%' }}
            />

            <Form.Item name="description" label="Descrição">
              <Input.TextArea rows="5" placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
            </Form.Item>

            <Form.Item name="images" label="Imagem">
              <Upload
                fileList={fileList}
                listType="picture-card"
                onPreview={this.onHandlePreviewImage}
                onRemove={this.onHandleRemoveImage}
                beforeUpload={this.onHandleImageBeforeUpload}
                disabled={disabled}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <AdvancedInputNumber
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
              label={I18n.t('forms.provider.comissionPrivateLabel')}
              value={provider && provider.commissionPrivate}
              max={100}
              step={0.01}
              formatter={(value) => `${value || 0}%`}
              onChange={(commissionPrivate) =>
                this.setState({
                  provider: { ...provider, commissionPrivate },
                  providerChangeData: { ...providerChangeData, commissionPrivate },
                })
              }
              inputStyle={{ width: '100%' }}
            />

            <AdvancedInputNumber
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
              label={I18n.t('forms.provider.comissionPublicLabel')}
              value={provider && provider.commissionPublic}
              max={100}
              step={0.01}
              formatter={(value) => `${value || 0}%`}
              onChange={(commissionPublic) =>
                this.setState({
                  provider: { ...provider, commissionPublic },
                  providerChangeData: { ...providerChangeData, commissionPublic },
                })
              }
              inputStyle={{ width: '100%' }}
            />

            <AdvancedInputNumber
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
              label={I18n.t('forms.provider.taxTiffinPrivateLabel')}
              value={provider && provider.taxTiffinPrivate}
              max={100}
              step={0.01}
              formatter={(value) => `${value || 0}%`}
              onChange={(taxTiffinPrivate) =>
                this.setState({
                  provider: { ...provider, taxTiffinPrivate },
                  providerChangeData: { ...providerChangeData, taxTiffinPrivate },
                })
              }
              inputStyle={{ width: '100%' }}
            />

            <AdvancedInputNumber
              disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) || disabled}
              label={I18n.t('forms.provider.taxTiffinPublicLabel')}
              value={provider && provider.taxTiffinPublic}
              max={100}
              step={0.01}
              formatter={(value) => `${value || 0}%`}
              onChange={(taxTiffinPublic) =>
                this.setState({
                  provider: { ...provider, taxTiffinPublic },
                  providerChangeData: { ...providerChangeData, taxTiffinPublic },
                })
              }
              inputStyle={{ width: '100%' }}
            />

            {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) && (
              <AdvancedInputNumber
                label={I18n.t('forms.provider.positionLabel')}
                value={provider && provider.position}
                max={100}
                step={0.01}
                onChange={(position) =>
                  this.setState({
                    provider: { ...provider, position },
                    providerChangeData: { ...providerChangeData, position },
                  })
                }
                inputStyle={{ width: '100%' }}
                disabled={disabled}
              />
            )}
          </Form>
        )}

        <Loading size={40} loading={isGetProviderOnRequest} />

        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.onHandlePreviewImageCancel}>
          <img alt="preview-example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  getUser: AuthSelectors.getUser(state),
  isGetProviderOnRequest: ProviderSelectors.isGetProviderOnRequest(state),
  isUpdateProviderOnRequest: ProviderSelectors.isUpdateProviderOnRequest(state),
  isCreateProviderOnRequest: ProviderSelectors.isCreateProviderOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProvider: (id) => dispatch(ProviderActions.getProvider(id)),
  onUpdateProvider: (id, data) => dispatch(ProviderActions.updateProvider(id, data)),
  onCreateProvider: (data) => dispatch(ProviderActions.createProvider(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm);
