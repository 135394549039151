import React from 'react';
import './AdvancedMaskedInput.less';

import TextInputMask from 'react-masked-text';

function AdvancedMaskedInput({
  disabled,
  label,
  onChange,
  kind,
  value,
  options,
  placeholder,
  required,
  rules = () => false,
}) {
  return (
    <div className="advanced-masked-input">
      <label className="advanced-masked-input__label">
        <span className="advanced-masked-input__label__inner">
          {label} {required && <strong>*</strong>}
        </span>
        {disabled ? (
          <TextInputMask
            disabled
            className="ant-input"
            value={value}
            onChangeText={onChange}
            kind={kind}
            options={{
              ...options,
              mask: value ? options?.mask : '',
            }}
            placeholder={placeholder}
          />
        ) : (
          <TextInputMask
            className="ant-input"
            value={value}
            onChangeText={onChange}
            kind={kind}
            options={{
              ...options,
              mask: value && options?.mask ? options.mask : '',
            }}
            placeholder={placeholder}
          />
        )}
        <span className="advanced-masked-input__label__error">{rules(value)}</span>
      </label>
    </div>
  );
}

export default AdvancedMaskedInput;
