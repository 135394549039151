import styled from 'styled-components';
import { Transfer } from 'antd';

export const TransferReverse = styled(Transfer)`
  flex-direction: column !important;

  .ant-transfer-list {
    width: 100%;
  }

  .ant-btn-icon-only.ant-btn-sm {
    margin: 0;
    transform: rotate(90deg);
  }

  .ant-transfer-operation {
    flex-direction: row !important;
  }

  .ant-btn {
    margin: 10px !important;
  }
`;
