import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Drawer, Dropdown, Form, Input, List, Menu, Typography, notification, Tag, Col } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { MdClose } from 'react-icons/md';
import { ProviderActions } from '../../../app/redux/actions';
import Loading from '../../shared/Loading/Loading';
import UserPreferencesForm from '../../user/form/preference';
import { UserTypes } from '../../../app/enum/user_types';
import { UserStatus } from '../../../app/enum/user_status';
import { useReduxState } from '../../../app/hooks/useReduxState';

function ProviderManagersForm({ id, visible, onCloseForm, disabled }) {
  const { provider: ProviderSelectors } = useReduxState();
  const dispatch = useDispatch();

  const [userPreferencesFormVisible, setUserPreferencesFormVisible] = useState(false);
  const [selectedUserId] = useState();

  const onGetProviderAdmins = async () => {
    if (visible && id) {
      await dispatch(ProviderActions.getProviderAdmins(id));
    }
  };

  const onSubmit = async (admin) => {
    const { name, email } = admin;
    if (!name || !email) {
      notification.error({
        message: I18n.t('forms.managers.notification.error.message'),
        description: I18n.t('forms.managers.notification.error.description'),
      });
    } else {
      await dispatch(ProviderActions.createProviderAdmin(id, admin, onGetProviderAdmins));
    }
  };

  const handleAdminDelete = async (providerId, userId) => {
    await dispatch(ProviderActions.deleteProviderAdmin(providerId, userId, onGetProviderAdmins));
  };

  const handleClickAction = (providerId, userId, key) => {
    switch (key) {
      case '1':
        handleAdminDelete(providerId, userId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onGetProviderAdmins();
  }, [visible]);

  const { Item } = Form;
  const { Title } = Typography;

  return (
    <>
      <Drawer
        maskClosable={false}
        visible={visible}
        className="drawer provider-managers-form"
        title={I18n.t('forms.managers.title')}
        onClose={() => onCloseForm()}
        headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<MdClose color="black" size="25px" />}
      >
        {!disabled && (
          <Form layout="vertical" onFinish={onSubmit} loading={ProviderSelectors.isCreateProviderAdminOnRequest}>
            <Item name="email" label={I18n.t('forms.managers.managerEmailLabel')}>
              <Input placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
            </Item>
            <Item name="name" label={I18n.t('forms.managers.managerNameLabel')}>
              <Input placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
            </Item>

            {!disabled && (
              <Button type="primary" htmlType="submit">
                {I18n.t('forms.managers.addManagerButton')}
              </Button>
            )}
          </Form>
        )}

        <Title level={3} className="provider-managers-form__title" style={{ marginTop: disabled && 0 }}>
          {I18n.t('forms.managers.adminListTitle')}:
        </Title>

        <List
          className="provider-managers-form__list"
          loading={ProviderSelectors.isGetProviderAdminsOnRequest || ProviderSelectors.isDeleteProviderAdminOnRequest}
          itemLayout="horizontal"
          dataSource={ProviderSelectors.providerAdmins || []}
          renderItem={(admin) => (
            <List.Item
              actions={[
                <Dropdown
                  key="options"
                  overlay={
                    <Menu onClick={({ key }) => handleClickAction(id, admin.id, key)}>
                      <Menu.Item key="1" danger>
                        {I18n.t('forms.managers.actions.remove')}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className="ant-dropdown-link">
                    <MoreOutlined style={{ fontSize: 20 }} />
                  </div>
                </Dropdown>,
              ]}
            >
              <List.Item.Meta
                title={admin.name}
                description={
                  <Col key={admin.id}>
                    {admin.email}
                    {admin.status !== UserStatus.APPROVED ? (
                      <Tag style={{ marginTop: 5 }} color="yellow">
                        {I18n.t('forms.managers.listAdmins.adminStatusPending')}
                      </Tag>
                    ) : (
                      <Tag style={{ marginTop: 5 }} color="green">
                        {I18n.t('forms.managers.listAdmins.adminStatusActive')}
                      </Tag>
                    )}
                  </Col>
                }
              />
            </List.Item>
          )}
        />
        <Loading size={40} loading={ProviderSelectors.isGetProviderAdminsOnRequest} />
      </Drawer>

      <UserPreferencesForm
        id={selectedUserId}
        userType={UserTypes.USER_LEGAL}
        visible={userPreferencesFormVisible}
        onCloseForm={() => setUserPreferencesFormVisible(false)}
      />
    </>
  );
}

export default ProviderManagersForm;
