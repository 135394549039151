import React from 'react';

import { Divider, Typography } from 'antd';

import { I18n } from 'react-redux-i18n';
import { ButtonStyled } from '../styles';
import { getOrderShipmentLabels } from '../../../../app/utils/shipment';

const { Title, Paragraph } = Typography;

function InnerVolumesOrderForm({ order, onGetShipmentLabel, isGetOrderShipmentLabelOnRequest }) {
  const { id } = order;
  const labels = getOrderShipmentLabels(order);

  return labels.map((labelNumber, index) => (
    <div style={{ flex: 1, flexDirection: 'column' }} key={labelNumber}>
      <Title style={{ margin: 0 }} level={5}>{`${I18n.t(
        'forms.order.labels.items.title.label',
      )} ${labelNumber}:`}</Title>
      <Paragraph>{`${I18n.t('forms.order.labels.items.title.description')}`}</Paragraph>
      <ButtonStyled onClick={() => onGetShipmentLabel(id, labelNumber)} loading={isGetOrderShipmentLabelOnRequest}>
        {I18n.t('forms.order.labels.items.button.label')}
      </ButtonStyled>

      {index + 1 < labels.length && <Divider style={{ marginBottom: 10, marginTop: 15 }} />}
    </div>
  ));
}

export default InnerVolumesOrderForm;
