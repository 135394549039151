import React from 'react';
import { Col, Row, Upload, Modal } from 'antd';
import { I18n } from 'react-redux-i18n';
import { PlusOutlined } from '@ant-design/icons';
import FileUtils from '../../../../app/utils/file';
import useForm from '../../../../app/hooks/useForm';
import AppConfig from '../../../../app/config/app.config';
import { ImageCompress } from '../../../../app/utils/compressor';

function InnerImagesProductForm({ product, updateProductData, disabled }) {
  const getInitialState = () => ({
    previewImage: null,
    previewVisible: false,
    previewTitle: null,
  });

  const form = useForm(getInitialState());

  const imageCompressParams = { quality: 0.6, maxWidth: 1500, maxHeight: 1500 };

  const onHandlePreviewImage = async (file) => {
    if (file) {
      if (!file.url && !file.preview) {
        file.preview = await FileUtils.getBase64(file.originFileObj);
      }
      form.handleInputChange('previewImage', file.url || file.preview);
      form.handleInputChange('previewVisible', true);
      form.handleInputChange('previewTitle', file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else {
      form.handleInputChange('previewVisible', false);
    }
  };

  const onHandleRemoveImage = async (file) => {
    const index = product.images?.indexOf(file);
    const newFileList = product.images;
    newFileList.splice(index, 1);

    updateProductData({
      ...product,
      images: newFileList,
    });
  };

  const onHandleImageBeforeUpload = (file) => {
    const reader = new FileReader();
    ImageCompress(file, reader, imageCompressParams);
    reader.onloadend = () => {
      const extension = reader.result.split('/')[1].split(';')[0];
      const productCopy = { ...product };
      productCopy.images.push({
        ...file,
        name: `${file.uid}.${extension}`,
        extension,
        status: 'done',
        url: reader.result,
      });
      updateProductData(productCopy);
    };
  };

  const onHandleCloseImagePreview = () => {
    form.handleInputChange('previewVisible', false);
    form.handleInputChange('previewImage', null);
  };

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.pictures.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Upload
            fileList={product.images.map((o, i) => ({ ...o, uid: i.toString() }))}
            listType="picture-card"
            onPreview={onHandlePreviewImage}
            onRemove={onHandleRemoveImage}
            beforeUpload={onHandleImageBeforeUpload}
            locale={{
              removeFile: I18n.t('forms.product.items.pictures.items.upload.removeFile'),
              previewFile: I18n.t('forms.product.items.pictures.items.upload.previewFile'),
            }}
            disabled={disabled}
          >
            {product.images?.length <= AppConfig.maximumProductImages && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{I18n.t('forms.product.items.pictures.items.uploadButton.label')}</div>
              </div>
            )}
          </Upload>
        </Col>
      </Row>
      <Modal
        visible={form.values.previewVisible}
        title={form.values.previewTitle}
        footer={null}
        onCancel={onHandleCloseImagePreview}
      >
        {form.values.previewVisible && <img alt="Preview" style={{ width: '100%' }} src={form.values.previewImage} />}
      </Modal>
    </div>
  );
}

export default InnerImagesProductForm;
