import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumb, Collapse, Row, Layout, Dropdown, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { SearchOutlined, DashboardFilled, MoreOutlined } from '@ant-design/icons';

import { ProviderActions, PaymentActions } from '../../../app/redux/actions';
import { ProviderSelectors, PaymentSelectors, AuthSelectors } from '../../../app/redux/reducers';

import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import ProviderBillingDetailsModal from '../../../components/provider/modal/ProviderBillingDetailsModal';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import * as PriceUtils from '../../../app/utils/prices';
import * as DateUtils from '../../../app/utils/date';

function ProviderBilling({
  providerBillingPaginated,
  isGetProviderBillingPaginatedOnRequest,
  onGetProviderBillingPaginated,
  isGetPaymentStatusOnRequest,
  getPaymentStatus,
  onGetPaymentStatus,
  getMyProviders,
}) {
  const { Content } = Layout;
  const { Panel } = Collapse;

  const [providerId, setProviderId] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [billingSelected, setBillingSelected] = useState();

  const dataTableRef = useRef();

  useEffect(() => {
    onGetPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDetailsModal = async (billingId) => {
    await setBillingSelected(providerBillingPaginated.rows.find((o) => o.id === billingId));
    setVisibleModal(true);
  };

  const onActionsClick = (billingId, item, key) => {
    switch (key) {
      case '1':
        openDetailsModal(billingId);
        break;
      default:
    }
  };

  const cleanFilters = () => {
    dataTableRef.current.reset();
    setProviderId(undefined);
  };

  const onHandleSearch = () => {
    cleanFilters();
    onGetProviderBillingPaginated({}, providerId);
  };
  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.providers.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel header={<strong>{I18n.t('routes.panel.providers.advancedFilters.title')}</strong>} key="1">
          <Row align="middle">
            <AdvancedSelect
              showSearch
              allowClear
              selectStyle={{ width: 240 }}
              value={providerId}
              onChange={(id) => setProviderId(id)}
              placeholder={I18n.t('shared.selectSomeValue')}
              options={getMyProviders.map((provider) => ({
                id: provider.id,
                name: provider.name,
              }))}
            />
          </Row>

          <Row>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.providers.advancedFilters.clearButtonText')}
              onClick={() => cleanFilters()}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.providers.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onHandleSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        <AdvancedDataTable
          ref={dataTableRef}
          refresh={(val) => onGetProviderBillingPaginated(val)}
          getMethod={(val) => onGetProviderBillingPaginated(val)}
          data={providerBillingPaginated}
          loading={isGetProviderBillingPaginatedOnRequest || isGetPaymentStatusOnRequest}
          container="providerBilling"
          columns={[
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.id.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.id.title'),
              fixed: !isMobile ? 'left' : false,
              width: 30,
              render: (value) => value || '--',
              sorter: true,
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.provider.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.provider.title'),
              width: 80,
              render: (value) => value?.name || '--',
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.status.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.status.title'),
              width: 100,
              render: (value) => getPaymentStatus.find((o) => o.id === value).name || '--',
              sorter: true,
              filters: getPaymentStatus?.map((o) => ({ value: o.id, text: o.name })),
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.billingTotalValue.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.billingTotalValue.title'),
              width: 60,
              render: (value) => PriceUtils?.getPrice(value) || '--',
              sorter: true,
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.bankSlipUrl.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.bankSlipUrl.title'),
              width: 100,
              render: (value) =>
                (
                  <a href={value?.bankSlipUrl} target="_blank" rel="noopener noreferrer">
                    {value?.bankSlipUrl}{' '}
                  </a>
                ) || '--',
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.operationDate.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.operationDate.title'),
              width: 60,
              render: (value) => value?.operationDate || '--',
            },
            {
              key: I18n.t('routes.panel.providerBilling.dataTable.columns.dueDate.key'),
              title: I18n.t('routes.panel.providerBilling.dataTable.columns.dueDate.title'),
              width: 60,
              render: (value) => DateUtils.humanizeDateTime(value?.dueDate, 'DD/MM/YYYY HH:mm') || '--',
            },
            {
              key: I18n.t('routes.panel.providers.dataTable.columns.actions.key'),
              title: I18n.t('routes.panel.providers.dataTable.columns.actions.title'),
              fixed: 'right',
              width: 20,
              render: (billingId) => (
                <Dropdown
                  overlay={
                    <Menu onClick={({ item, key }) => onActionsClick(billingId, item, key)}>
                      <Menu.Item key="1">
                        {I18n.t('routes.panel.providerBilling.dataTable.columns.actions.goToDetailsText')}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className="ant-dropdown-link">
                    <MoreOutlined style={{ fontSize: 20 }} />
                  </div>
                </Dropdown>
              ),
            },
          ]}
        />
      </Content>
      <ProviderBillingDetailsModal
        visible={visibleModal}
        data={billingSelected}
        onCancel={() => setVisibleModal(false)}
        onOk={() => setVisibleModal(false)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  getMyProviders: AuthSelectors.getProviders(state),
  providerBillingPaginated: ProviderSelectors.getProviderBillingPaginated(state),
  isGetProviderBillingPaginatedOnRequest: ProviderSelectors.isGetProviderBillingPaginatedOnRequest(state),
  getPaymentStatus: PaymentSelectors.getPaymentStatus(state),
  isGetPaymentStatusOnRequest: PaymentSelectors.isGetPaymentStatusOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProviderBillingPaginated: (params, providerId) =>
    dispatch(ProviderActions.getProviderBillingPaginated(params, providerId)),
  onGetPaymentStatus: () => dispatch(PaymentActions.getPaymentStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderBilling);
