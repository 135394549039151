import styled from 'styled-components';
import { Drawer, InputNumber, Select, Form } from 'antd';

export const Container = styled(Drawer).attrs(() => ({
  headerStyle: { background: '#FFEC00', borderRadius: '0' },
  bodyStyle: { paddingBottom: '80' },
}))``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
`;

export const DeadLineText = styled.span`
  width: 100%;
  padding-bottom: @label-spacing;
`;

export const DeadLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const DeadLineInputNumber = styled(InputNumber)`
  width: 23%;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ContainerPaymentOption = styled.div`
  display: flex;
  max-height: 15%;
  border: 1px solid red;
  flex-direction: column;
  background-color: #f5f5f5;
  align-items: flex-end;
  justify-content: center;
`;

export const SelectProvider = styled(Select)`
  width: 200px;
  min-width: 300px;
  margin: 0;
  padding: 0;
`;

export const FormPayment = styled(Form)`
  display: flex;
  flex: 1px;
  border: 1px solid red;
  height: 90%;
  width: 100%;
`;

export const Footer = styled.div`
  text-align: right;
`;
