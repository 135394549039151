import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { I18n } from 'react-redux-i18n';
import { Layout, Breadcrumb, Collapse, Row, Spin } from 'antd';
import { DashboardFilled } from '@ant-design/icons';
import { useLocation, useParams } from '@reach/router';
import { useDispatch } from 'react-redux';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { getDashboard } from '../../../app/redux/actions/dashboard';
import * as UserActions from '../../../app/redux/actions/user';
import AdvancedSelect from '../../../components/shared/AdvancedSelect';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { getDashboardById } from '../../../app/services/access';
import useForm from '../../../app/hooks/useForm';

const { Content } = Layout;
const { Panel } = Collapse;

const getDefaultValues = () => ({
  supportId: '',
});

function Dashboard() {
  const [dashboardUrl, setDashboardUrl] = useState();
  const [dashboardData, setDashboardData] = useState();
  const { accessFeatureId } = useParams();
  const location = useLocation();

  const getDashboardData = async (newValues) => {
    const response = await getDashboard({ ...newValues, accessFeatureId });
    setDashboardUrl(response);
  };

  const form = useForm(getDefaultValues(), (newValues) => {
    getDashboardData(newValues);
  });

  useEffect(() => {
    form.setForm(getDefaultValues());
  }, [location.pathname]);

  const { access, user } = useReduxState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (access.userAccessObtained) {
      setDashboardData(getDashboardById(accessFeatureId));
      getDashboardData();
    }

    return () => setDashboardUrl(null);
  }, [accessFeatureId, access.userAccessObtained]);

  useEffect(() => {
    if (dashboardData?.accessFeatureData?.metabaseParams?.supportSelector) {
      dispatch(UserActions.getUsersSupport());
    }
  }, [dashboardData]);

  const cleanAdvancedFilters = () => {
    form.setForm(getDefaultValues());
  };

  return (
    <>
      {dashboardData?.accessFeatureDescription ? (
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.dashboards.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{dashboardData?.accessFeatureDescription}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      ) : (
        <></>
      )}

      {user.usersSupports?.length > 0 && dashboardData?.accessFeatureData?.metabaseParams?.supportSelector ? (
        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel header={<strong>{I18n.t('routes.panel.dashboards.advancedFilters.title')}</strong>} key="1">
            {dashboardData?.accessFeatureData?.metabaseParams?.supportSelector ? (
              <Row align="middle">
                {user.usersSupports?.length > 0 ? (
                  <AdvancedSelect
                    label={I18n.t('routes.panel.dashboards.advancedFilters.fields.supportId.label')}
                    allowClear
                    showSearch
                    selectStyle={{ width: 240 }}
                    value={form.values.supportId}
                    onClear={() => form.handleInputChange('supportId', '')}
                    onChange={(val) => form.handleInputChange('supportId', val)}
                    options={user.usersSupports.map((support) => ({
                      id: support.id,
                      name: support.name,
                    }))}
                  />
                ) : (
                  {}
                )}
              </Row>
            ) : (
              <></>
            )}

            <Row>
              <AdvancedButton
                type="default"
                text={I18n.t('routes.panel.dashboards.advancedFilters.clear')}
                onClick={cleanAdvancedFilters}
              />
            </Row>
          </Panel>
        </Collapse>
      ) : (
        <></>
      )}

      <Content className="panel__layout__content panel__layout__content--content-data dashboard-panel p-0">
        {dashboardUrl ? (
          <iframe
            title="dashboard"
            src={dashboardUrl}
            frameBorder="0"
            width="100%"
            height={isMobile ? '100%' : '595px'}
            allowTransparency
          />
        ) : (
          <div className="dashboard-panel__loading">
            <Spin />
          </div>
        )}
      </Content>
    </>
  );
}

export default Dashboard;
