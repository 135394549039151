import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message,
  Breadcrumb,
  Collapse,
  Menu,
  Dropdown,
  Modal,
  Popover,
  Tag,
  Tooltip,
} from 'antd';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DashboardFilled,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
  QuestionCircleFilled,
} from '@ant-design/icons';

import * as DateUtils from '../../../app/utils/date';
import * as UserTypes from '../../../app/enum/user_types';
import * as UserStatus from '../../../app/enum/user_status';
import * as BooleanDefaultStatus from '../../../app/enum/boolean_default_status';
import { ViewTypes } from '../../../app/enum/view_types';

import UserForm from '../../../components/user/form';
import UserPreferencesForm from '../../../components/user/form/preference';
import UserPaymentOptionsForm from '../../../components/user/form/payment';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton';

import { UserActions } from '../../../app/redux/actions';
import { UserSelectors } from '../../../app/redux/reducers';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';
import GenerateNewPasswordModal from '../../../components/user/form/GenerateNewPasswordModal/GenerateNewPasswordModal';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      userFormVisible: false,
      userPreferencesFormVisible: false,
      importModalVisible: false,
      isGenerateNewPasswordModalOpen: false,
      form: {
        user: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { onGetUserOrigins, onGetUserCompanyTypes, onGetUserSupportStatus, onGetUsersSupport } = this.props;
    onGetUserOrigins();
    onGetUserCompanyTypes();
    onGetUserSupportStatus();
    onGetUsersSupport();
  }

  getUsers = (params) => {
    const { getUsersPaginated } = this.props;
    const { search, filters } = this.state;

    if (params) {
      this.setState({ filters: params });
      getUsersPaginated({ ...params, search });
    } else {
      getUsersPaginated({ ...filters, search });
    }
  };

  fieldChange = (name, value) => {
    const { state } = this;
    state[name] = value ? value.toString() : null;
    this.setState(state);
  };

  cleanAdvancedFilters = async () => {
    await this.setState({ filters: null, search: '' });
    this.dataTableRef.current.reset();
    this.getUsers();
  };

  removeUser = async (id) => {
    const { deleteUser } = this.props;
    const success = await deleteUser(id);
    if (success) {
      message.success(I18n.t('routes.panel.users.messages.success.delete'));
      this.getUsers();
    }
  };

  onGenerateNewPassword = async (recoverPassword) => {
    const { onUpdateUser } = this.props;
    const { form } = this.state;
    await onUpdateUser(form.user?.id, {
      recoverPassword,
      status: 'reset_password',
    });
  };

  onActionsClick = async (id, item, key, userType) => {
    switch (key) {
      case '1':
        this.onUserFormStateChange(true, id, ViewTypes.EDIT);
        break;
      case '2':
        return this.onUserPreferencesFormStateChange(true, userType, id);
      case '3':
        return this.onUserPaymentOptionsFormStateChange(true, id);
      case '4':
        return this.onUserGenerateNewPasswordChange(true, id);
      case '5':
        return this.onShowDeleteConfirm(id);
      default:
    }
  };

  onUserPaymentOptionsFormStateChange = (visible, id = null) => {
    this.setState({ userPaymentOptionsFormVisible: visible, form: { user: { id } } });
  };

  onUserFormStateChange = (visible, id = null, type = null) => {
    this.setState({ userFormVisible: visible, form: { user: { id } }, type });
  };

  onUserPreferencesFormStateChange = (visible, userType, id = null) => {
    this.setState({ userPreferencesFormVisible: visible, form: { user: { id, userType } }, userType });
  };

  onUserGenerateNewPasswordChange = (visible, id = null) => {
    this.setState({ isGenerateNewPasswordModalOpen: visible, form: { user: { id } } });
  };

  onCloseGenerateNewPasswordModal = () => {
    this.setState({
      isGenerateNewPasswordModalOpen: false,
    });
  };

  handleSearch = () => {
    this.dataTableRef.current.reset();
    this.getUsers();
  };

  handleChange = (pagination, filters) => {
    this.setState({ filteredInfo: filters });
  };

  onShowDeleteConfirm = (userId) => {
    const { confirm } = Modal;
    const { isDeleteUserOnRequest } = this.props;

    confirm({
      title: 'Atenção, deseja realmente deletar esse usuário?',
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: 'Sim',
      okType: 'danger',
      centered: true,
      cancelText: 'Cancelar',
      confirmLoading: isDeleteUserOnRequest,
      onOk: () => this.removeUser(userId),
    });
  };

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const {
      search,
      userFormVisible,
      form,
      userPreferencesFormVisible,
      userPaymentOptionsFormVisible,
      isGenerateNewPasswordModalOpen,
      type,
    } = this.state;
    const { usersPaginated, isGetUsersPaginatedOnRequest } = this.props;
    const { getUserOrigins, getUserCompanyTypes, getUserStatus, getUsersSupports, isUpdateUserOnRequest } = this.props;

    const { user } = form;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.users.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel header={<strong>{I18n.t('routes.panel.users.advancedFilters.title')}</strong>} key="1">
            <Row>
              <AdvancedInput
                style={{ width: 240, marginRight: 10 }}
                value={search}
                onChange={(val) => this.fieldChange('search', val)}
                placeholder={I18n.t('shared.typeToContinue')}
                onPressEnter={this.handleSearch}
              />

              <Popover placement="topLeft" content={I18n.t('routes.panel.users.advancedFilters.searchFieldTitle')}>
                <QuestionCircleFilled style={{ fontSize: 20, color: '#000000', marginTop: 5 }} />
              </Popover>
            </Row>

            <Row>
              <AdvancedButton
                type="default"
                text={I18n.t('routes.panel.users.advancedFilters.clearButtonText')}
                onClick={this.cleanAdvancedFilters}
              />
              <AdvancedButton
                style={{ marginLeft: 10 }}
                text={I18n.t('routes.panel.users.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={this.handleSearch}
              />
            </Row>
          </Panel>
        </Collapse>

        <Content className="panel__layout__content panel__layout__content--content-data">
          {hasAccess([accessTypes.USERS], [accessActionTypes.CREATE]) && (
            <>
              <Row align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="panel__layout__content__actions">
                    <div className="panel__layout__content__actions__left">
                      <AdvancedButton
                        onClick={() => this.onUserFormStateChange(true, null, ViewTypes.CREATE)}
                        text={I18n.t('routes.panel.users.addNewButtonText')}
                        iconLeft={<PlusOutlined />}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <AdvancedDataTable
            refresh={this.getUsers}
            getMethod={(parameters) => this.getUsers(parameters)}
            data={usersPaginated}
            loading={isGetUsersPaginatedOnRequest || isUpdateUserOnRequest}
            ref={this.dataTableRef}
            container="users"
            onChange={this.handleChange}
            columns={[
              {
                key: I18n.t('routes.panel.users.dataTable.columns.id.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.id.title'),
                fixed: !isMobile ? 'left' : false,
                width: 80,
                render: (value) => value || '--',
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.name.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.name.title'),
                width: 200,
                render: (value) => value || '--',
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.type.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.type.title'),
                width: 200,
                render: (value) => UserTypes.GetUserTypeName(value) || '--',
                filters: UserTypes.GetValidUserTypeFilters,
                filteredValue: this.state.filteredInfo?.type || null,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.status.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.status.title'),
                width: 200,
                render: (value) => UserStatus.GetUserStatusName(value) || '--',
                filters: UserStatus.GetUserStatusFilters,
                filteredValue: this.state.filteredInfo?.status || null,
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                required: true,
                selector: UserStatus.GetUserStatusArray,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.supportStatus.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.supportStatus.title'),
                width: 200,
                render: (value) => {
                  const found = getUserStatus.find((e) => e.id === value);
                  if (found) {
                    return <Tag color={`#${found.color}`}>{found.name}</Tag>;
                  }
                  return '--';
                },
                filteredValue: this.state.filteredInfo?.supportStatusId || null,
                filters: getUserStatus.map((status) => ({
                  value: status.id,
                  text: status.name,
                })),
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                selector: getUserStatus,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.support.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.support.title'),
                width: 150,
                render: (value) => {
                  const found = getUsersSupports.find((e) => e.id === value);
                  if (found) {
                    return found.name;
                  }
                  return '--';
                },
                filteredValue: this.state.filteredInfo?.supportId || null,
                filters: getUsersSupports.map((status) => ({
                  value: status.id,
                  text: status.name,
                })),
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                selector: getUsersSupports,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.commentsSupport.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.commentsSupport.title'),
                width: 200,
                render: (commentsSupport) =>
                  commentsSupport ? (
                    <Tooltip placement="top" title={commentsSupport}>
                      {`${commentsSupport.substr(0, 22)}${commentsSupport.length > 22 ? '...' : ''}`}
                    </Tooltip>
                  ) : (
                    '--'
                  ),
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.email.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.email.title'),
                width: 250,
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.phone.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.phone.title'),
                width: 200,
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.hasOpenCart.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.hasOpenCart.title'),
                width: 100,
                render: (value) => <Tag color={value ? 'green' : 'red'}>{value ? 'SIM' : 'NÃO'}</Tag>,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.lastOrderAt.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.lastOrderAt.title'),
                width: 200,
                render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.fup.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.fup.title'),
                width: 130,
                render: (value) => <Tag color={value ? 'green' : 'red'}>{value ? 'SIM' : 'NÃO'}</Tag>,
                filters: BooleanDefaultStatus.GetBooleanDefaultStatusFilters,
                filteredValue: this.state.filteredInfo?.fup || null,
                sorter: true,
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                selector: BooleanDefaultStatus.GetBooleanDefaultStatusArray,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.lastFupAt.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.lastFupAt.title'),
                width: 200,
                render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                sorter: true,
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                type: 'date',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.nextFupAt.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.nextFupAt.title'),
                width: 200,
                render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                sorter: true,
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                type: 'date',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.origin.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.origin.title'),
                width: 150,
                render: (value) => {
                  const found = getUserOrigins.find((e) => e.id === value);
                  if (found) {
                    return <Tag color="blue">{found.name}</Tag>;
                  }
                  return '--';
                },
                filters: getUserOrigins.map((status) => ({
                  value: status.id,
                  text: status.name,
                })),
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                selector: getUserOrigins,
                filteredValue: this.state.filteredInfo?.originId || null,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.companyType.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.companyType.title'),
                width: 180,
                render: (value) => {
                  const found = getUserCompanyTypes.find((e) => e.id === value);
                  if (found) {
                    return <Tag color="blue">{found.name}</Tag>;
                  }
                  return '--';
                },
                filters: getUserCompanyTypes.map((status) => ({
                  value: status.id,
                  text: status.name,
                })),
                editable: hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]),
                selector: getUserCompanyTypes,
                filteredValue: this.state.filteredInfo?.companyTypeId || null,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.document.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.document.title'),
                width: 200,
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.companyName.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.companyName.title'),
                width: 200,
                render: (companyName) =>
                  companyName ? (
                    <Tooltip placement="top" title={companyName}>
                      {`${companyName.substr(0, 18)}${companyName.length > 18 ? '...' : ''}`}
                    </Tooltip>
                  ) : (
                    '--'
                  ),
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.ie.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.ie.title'),
                width: 200,
                render: (value) => value || '--',
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.createdAt.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.createdAt.title'),
                width: 200,
                render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.users.dataTable.columns.actions.key'),
                title: I18n.t('routes.panel.users.dataTable.columns.actions.title'),
                fixed: 'right',
                width: 65,
                render: (userId, { type: userType }) => (
                  <Dropdown
                    overlay={
                      <Menu onClick={({ item, key }) => this.onActionsClick(userId, item, key, userType)}>
                        <Menu.Item key="1">
                          {I18n.t('routes.panel.users.dataTable.columns.actions.goToDetailsText')}
                        </Menu.Item>
                        <Menu.Item key="2">
                          {I18n.t('routes.panel.users.dataTable.columns.actions.goToUserPreferences')}
                        </Menu.Item>
                        <Menu.Item key="3">
                          {I18n.t('routes.panel.users.dataTable.columns.actions.paymentOptions')}
                        </Menu.Item>
                        {hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE]) && (
                          <Menu.Item key="4">
                            {I18n.t('routes.panel.users.dataTable.columns.actions.generateNewPasswordOption')}
                          </Menu.Item>
                        )}
                        {hasAccess([accessTypes.USERS], [accessActionTypes.REMOVE]) && (
                          <Menu.Item key="5">
                            {I18n.t('routes.panel.users.dataTable.columns.actions.removeText')}
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <MoreOutlined style={{ fontSize: 20 }} />
                  </Dropdown>
                ),
              },
            ]}
          />
        </Content>
        <UserPreferencesForm
          id={user?.id}
          userType={user?.userType}
          visible={userPreferencesFormVisible}
          onCloseForm={(refresh) => {
            this.onUserPreferencesFormStateChange(false);
            if (refresh) {
              this.getUsers();
            }
          }}
        />
        <UserForm
          disabled={!hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE])}
          id={user?.id}
          type={type}
          visible={userFormVisible}
          onCloseForm={(refresh) => {
            this.onUserFormStateChange(false);
            if (refresh) {
              this.getUsers();
            }
          }}
        />
        <UserPaymentOptionsForm
          disabled={!hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE])}
          id={user?.id}
          visible={userPaymentOptionsFormVisible}
          onCloseForm={() => this.onUserPaymentOptionsFormStateChange(false)}
        />
        <GenerateNewPasswordModal
          disabled={!hasAccess([accessTypes.USERS], [accessActionTypes.UPDATE])}
          id={user?.id}
          onClose={this.onCloseGenerateNewPasswordModal}
          visible={isGenerateNewPasswordModalOpen}
          onGeneratePassword={this.onGenerateNewPassword}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getUsersPaginated(state),
  isGetUsersPaginatedOnRequest: UserSelectors.isGetUsersPaginatedOnRequest(state),
  isDeleteUserOnRequest: UserSelectors.isDeleteUserOnRequest(state),
  getUserOrigins: UserSelectors.getUserOrigins(state),
  getUserCompanyTypes: UserSelectors.getUserCompanyTypes(state),
  getUserStatus: UserSelectors.getUserStatus(state),
  getUsersSupports: UserSelectors.getUsersSupports(state),
  isUpdateUserOnRequest: UserSelectors.isUpdateUserOnRequest(state),
  isGetUserOnRequest: UserSelectors.isGetUserOnRequest(state),
  isCreateUserOnRequest: UserSelectors.isCreateUserOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersPaginated: (parameters) => dispatch(UserActions.getUsersPaginated(parameters)),
  deleteUser: (id) => dispatch(UserActions.deleteUser(id)),
  onGetUserOrigins: () => dispatch(UserActions.getUserOrigins()),
  onGetUsersSupport: () => dispatch(UserActions.getUsersSupport()),
  onGetUserCompanyTypes: () => dispatch(UserActions.getUserCompanyTypes()),
  onGetUserSupportStatus: () => dispatch(UserActions.getUserSupportStatus()),
  onGetUser: (id) => dispatch(UserActions.getUser(id)),
  onCreateUser: (data) => dispatch(UserActions.createUser(data)),
  onUpdateUser: (id, data) => dispatch(UserActions.updateUser(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
