import React from 'react';

import { I18n } from 'react-redux-i18n';

import { Menu, Dropdown, Popover, Tooltip, Tag } from 'antd';
import { InfoCircleFilled, MoreOutlined } from '@ant-design/icons';

import * as OrderStatus from '../../../../enum/order_status';
import * as OrderPaymentTypes from '../../../../enum/payment_types';
import { PaymentTypes } from '../../../../enum/payment_types';
import { OrderDeliveryType } from '../../../../enum/order_delivery_types';
import { UserTypes } from '../../../../enum/user_types';

import * as DateUtils from '../../../date';
import * as PriceUtils from '../../../prices';
import { hasAccess } from '../../../../services/access';
import { accessTypes } from '../../../../enum/access_types';
import { accessActionTypes } from '../../../../enum/access_action_types';

function OrderColumnsProviders(
  State,
  onActionsClick,
  getUsersSupports,
  getOrderProblems,
  getPaymentStatus,
  getUser,
  getDefaultCoinValue,
  getDistributors,
) {
  const financialFeesPopoverContent = (
    <div>
      <p>{I18n.t('routes.panel.orders.dataTable.columns.financialFees.popover.content.pix')}</p>
      <p>{I18n.t('routes.panel.orders.dataTable.columns.financialFees.popover.content.bankSlip')}</p>
      <p>{I18n.t('routes.panel.orders.dataTable.columns.financialFees.popover.content.creditCard')}</p>
    </div>
  );
  return [
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.id.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.id.title'),
      fixed: 'left',
      width: 80,
      render: (value) => value || '--',
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.userName.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.userName.title'),
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value.name}>
          {value ? value.name || 'N/A' : 'N/A'}
        </Tooltip>
      ),
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.document.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.document.title'),
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value.document}>
          {value ? value.document : '--'}
        </Tooltip>
      ),
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.status.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.status.title'),
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (_, row) => (
        <Tooltip placement="topLeft" title={OrderStatus.GetOrderStatusName(row)}>
          <span>{OrderStatus.GetOrderStatusName(row) || '--'}</span>
        </Tooltip>
      ),
      filters: OrderStatus.GetOrderStatusFilters,
      filteredValue: State.filteredInfo?.status || null,
      editable: hasAccess([accessTypes.ORDERS], [accessActionTypes.UPDATE]),
      required: true,
      selectorFunction: (row) => OrderStatus.GetOrderStatusArrayName(row),
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.support.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.support.title'),
      width: 150,
      selector: getUsersSupports,
      ellipsis: {
        showTitle: false,
      },
      render: (_, row) => (
        <Tooltip placement="topLeft" title={row.support ? row.support.name : '--'}>
          {row.support ? row.support.name : '--'}
        </Tooltip>
      ),
      filteredValue: State.filteredInfo?.supportId || null,
      filters: getUsersSupports.map((status) => ({
        value: status.id,
        text: status.name,
      })),
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.paymentType.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.paymentType.title'),
      width: 170,
      render: (value) => OrderPaymentTypes.GetPaymentTypesName(value) || '--',
      filters: OrderPaymentTypes.GetPaymentTypeFilters,
      filteredValue: State.filteredInfo?.paymentType || null,
      selector: OrderPaymentTypes.GetPaymentTypesArray,
      required: true,
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.creditType.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.creditType.title'),
      width: 170,
      render: (_, row) =>
        row?.paymentType === PaymentTypes.BANK_SLIP_INSTALLMENT && row?.jsonInstallments ? (
          <Tag color={row?.credit ? 'yellow' : 'blue'}>
            {row?.credit ? I18n.t('forms.order.payment.credit.tiffins') : I18n.t('forms.order.payment.credit.provider')}
          </Tag>
        ) : (
          '--'
        ),
      required: true,
    },
    {
      title: I18n.t('routes.panel.orders.dataTable.columns.jsonInstallments.title'),
      width: 160,
      render: (values) => {
        const { jsonInstallments, paymentType } = values;

        if (paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
          return jsonInstallments || '--';
        }

        return jsonInstallments?.length || '--';
      },
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.createdAt.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.createdAt.title'),
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (createdAt) => (
        <Tooltip placement="topLeft" title={DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm')}>
          {DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm')}
        </Tooltip>
      ),
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.paymentStatus.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.paymentStatus.title'),
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip
          placement="topLeft"
          title={getPaymentStatus.find((paymentStatus) => paymentStatus.id === value)?.name || '--'}
        >
          {getPaymentStatus.find((paymentStatus) => paymentStatus.id === value)?.name || '--'}
        </Tooltip>
      ),
      selector: getPaymentStatus,
      filteredValue: State.filteredInfo?.paymentStatus || null,
      filters: getPaymentStatus.map((status) => ({ value: status.id, text: status.name })),
    },
    {
      title: I18n.t('routes.panel.orders.dataTable.columns.distributor.title'),
      width: 110,
      render: (values) => {
        const { deliveryType, distributorId } = values;

        if (deliveryType === OrderDeliveryType.ON_PROVIDER) {
          return I18n.t('routes.panel.orders.dataTable.columns.distributor.onProvider');
        }

        return getDistributors?.find((distributor) => distributor.id === distributorId)?.name || '--';
      },
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.productsValue.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.productsValue.title'),
      width: 130,
      render: (value) => PriceUtils.getPrice(value) || '--',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <>
          {I18n.t('routes.panel.orders.dataTable.columns.discount.title')}
          <Popover content={I18n.t('routes.panel.orders.dataTable.columns.discount.popover')} trigger="hover">
            <span>
              {' '}
              <InfoCircleFilled style={{ fontSize: '12px', color: 'gray', marginLeft: '2px' }} />
            </span>
          </Popover>
        </>
      ),
      width: 110,
      render: (values) => {
        const totalDiscount = PriceUtils.getDiscount({
          ...values,
          coinValue: getDefaultCoinValue,
        });

        return totalDiscount ? `- ${PriceUtils.getPrice(totalDiscount)}` : '--';
      },
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.tax.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.tax.title'),
      width: 100,
      render: (value) => (value ? PriceUtils.getPrice(value) : '--'),
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.freight.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.freight.title'),
      width: 100,
      render: (value) => (value ? PriceUtils.getPrice(value) : '--'),
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <>
          {I18n.t('routes.panel.orders.dataTable.columns.financialFees.title')}
          <Popover
            title={I18n.t('routes.panel.orders.dataTable.columns.financialFees.popover.title')}
            content={financialFeesPopoverContent}
            trigger="hover"
          >
            <span>
              {' '}
              <InfoCircleFilled style={{ fontSize: '12px', color: 'gray', marginLeft: '2px' }} />
            </span>
          </Popover>
        </>
      ),
      width: 125,
      render: (values) => {
        const { financialFees, paymentType } = values;

        if (paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
          return financialFees ? PriceUtils.getPrice(financialFees) : '--';
        }

        return financialFees ? `- ${PriceUtils.getPrice(financialFees)}` : '--';
      },
    },
    {
      title: I18n.t('routes.panel.orders.dataTable.columns.commission.title'),
      width: 130,
      render: (values) => {
        const commission = PriceUtils.getCommission({
          ...values,
          coinValue: getDefaultCoinValue,
        });

        return commission ? `- ${PriceUtils.getPrice(commission)}` : '--';
      },
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => (
        <>
          {I18n.t('routes.panel.orders.dataTable.columns.receiveValue.title')}
          <Popover content={I18n.t('routes.panel.orders.dataTable.columns.receiveValue.popover')} trigger="hover">
            <span>
              {' '}
              <InfoCircleFilled style={{ fontSize: '12px', color: 'gray', marginLeft: '2px' }} />
            </span>
          </Popover>
        </>
      ),
      width: 120,
      render: (values) => {
        const result = PriceUtils.getReceiveValue({ ...values, coinValue: getDefaultCoinValue });
        return PriceUtils.getPrice(result) || '--';
      },
    },
    {
      key: I18n.t('routes.panel.orders.dataTable.columns.actions.key'),
      title: I18n.t('routes.panel.orders.dataTable.columns.actions.title'),
      fixed: 'right',
      width: 65,
      render: (orderId) => {
        return (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key, keyPath, domEvent }) => {
                  onActionsClick(orderId, item, key, keyPath, domEvent);
                }}
              >
                <Menu.Item key="1">{I18n.t('routes.panel.orders.dataTable.columns.actions.goToDetailsText')}</Menu.Item>

                {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) && (
                  <Menu.Item key="2">{I18n.t('routes.panel.orders.dataTable.columns.actions.removeText')}</Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <div className="ant-dropdown-link">
              <MoreOutlined style={{ fontSize: 20 }} />
            </div>
          </Dropdown>
        );
      },
    },
  ];
}

export default OrderColumnsProviders;
