import AccessFeatureUserPanelRequestsV1
  from 'tiffin-foods-api-client/lib/cjs/modules/accessFeatureUser/panel/accessFeatureUserPanelRequestsV1';
import AccessGroupFeaturePanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/accessGroupFeature/panel/accessGroupFeaturePanelRequestsV1';
import { ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST } from './accessFeatureUsers';

export const ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST:accessGroupsFeatures';
export const ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_DATA =
  'ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_DATA:accessGroupsFeatures';
export const ACTION_GET_ACCESS_GROUPS_FEATURES_OBTAINED =
  'ACTION_GET_ACCESS_GROUPS_FEATURES_OBTAINED:accessGroupsFeatures';

export function getAccessGroupFeaturesPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
    };

    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      const payload = await AccessGroupFeaturePanelRequestsV1.getAll(params);
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_DATA, payload });
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_OBTAINED, payload: true });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createFeatureAndAccessGroupsAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupFeaturePanelRequestsV1.create(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createFeatureAndAccessGroupsAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupFeaturePanelRequestsV1.create(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeFeatureAndAccessGroupsAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupFeaturePanelRequestsV1.remove(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeFeatureAndAccessGroupsAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupFeaturePanelRequestsV1.remove(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateGroupAndAccessFeaturesAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      const response = await AccessGroupFeaturePanelRequestsV1.update(data);
      if (callback) {
        callback(response);
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}
