import React from 'react';
import './NotFound.less';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import AdvancedButton from '../../components/shared/AdvancedButton';

import { AuthActions } from '../../app/redux/actions';

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { Content } = Layout;

    return (
      <Content className="notfound__container">
        <Row className="notfound__container__content" align="middle" justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="notfound__container__content__text">
              <h1>{I18n.t('routes.notfound.title')}</h1>

              <h2>{I18n.t('routes.notfound.description')}</h2>

              <AdvancedButton
                style={{ marginTop: 20 }}
                type="primary"
                size="large"
                shape="round"
                onClick={() => this.props.onVerifyInitialFlow()}
                text={I18n.t('routes.notfound.buttonText')}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="notfound__container__content__logo">
              <img
                alt="notfound"
                className="notfound__container__content__logo__img"
                src="/assets/img/img_notfound.png"
              />
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onVerifyInitialFlow: () => dispatch(AuthActions.verifyInitialFlow()),
});

export default connect(null, mapDispatchToProps)(NotFound);
