import React, { useState } from 'react';
import { Collapse, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { AdvancedDropzone } from 'tiffin-foods-chat-component/lib/cjs/components';

import { OrderActions } from '../../../../app/redux/actions';
import AdvancedModal from '../../../shared/AdvancedModal/AdvancedModal';
import { FileType } from '../../../../app/enum/order_file_type';
import OrderFilesForm from './components/OrderFilesForm';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import OrderInvoicesFulForm from './components/OrderInvoicesFulForm';

function InnerInvoiceOrderForm({ orderId, showInvoicesFulForm }) {
  const [uploadInvoiceModalVisible, setUploadInvoiceModalVisible] = useState();
  const [fileType, setFileType] = useState(null);

  const dispatch = useDispatch();
  const { order: orderSelector } = useReduxState();
  const { Panel } = Collapse;

  function onCloseUploadModal() {
    setUploadInvoiceModalVisible(false);
  }

  async function onHandleCreateOrderFiles(fileUrl, size) {
    const fileToUpload = {
      fileUrl,
      extension: fileUrl?.split('.').slice(-1)[0],
      size,
      type: fileType,
    };

    const response = await dispatch(OrderActions.createOrderFile(orderId, fileToUpload));
    if (response) {
      dispatch(OrderActions.getOrdersPaginated({ filters: null, search: '', providerId: null, filteredInfo: null }));
    }
  }

  function onSetUploadModalVisible(type) {
    setUploadInvoiceModalVisible(true);
    setFileType(type);
  }

  function filterFilesByType(type) {
    return orderSelector.orderFiles?.filter((file) => file.type === type);
  }

  function onAllFilesUploadComplete() {
    notification.success({
      message: I18n.t('forms.order.invoices.inner.createOrderFileNotification.notifications.success.message'),
    });

    setUploadInvoiceModalVisible(false);
  }

  return (
    <>
      <Collapse accordion>
        <Panel
          header={`${I18n.t('forms.order.invoices.inner.invoiceFilesTitle')} (${
            filterFilesByType(FileType.INVOICE)?.length
          })`}
          key="1"
        >
          <OrderFilesForm
            files={filterFilesByType(FileType.INVOICE)}
            type={FileType.INVOICE}
            orderId={orderId}
            // eslint-disable-next-line react/jsx-no-bind
            onSetUploadModalVisible={onSetUploadModalVisible}
          />
        </Panel>
        <Panel
          header={`${I18n.t('forms.order.invoices.inner.genericFilesTitle')} (${
            filterFilesByType(FileType.FILE)?.length
          })`}
          key="2"
        >
          <OrderFilesForm
            files={filterFilesByType(FileType.FILE)}
            type={FileType.FILE}
            orderId={orderId}
            // eslint-disable-next-line react/jsx-no-bind
            onSetUploadModalVisible={onSetUploadModalVisible}
          />
        </Panel>
        {showInvoicesFulForm && (
          <Panel header={I18n.t('forms.order.invoices.inner.createInvoicesFulTitle')} key="3">
            <OrderInvoicesFulForm orderId={orderId} />
          </Panel>
        )}
      </Collapse>
      {uploadInvoiceModalVisible && (
        <AdvancedModal
          visible={uploadInvoiceModalVisible}
          onCloseModal={() => onCloseUploadModal()}
          footer={null}
          label={I18n.t('forms.order.invoices.modalTitle')}
          bodyStyle={{ padding: 0 }}
        >
          <AdvancedDropzone
            onSingleFileUpload={(fileUrl, size) => onHandleCreateOrderFiles(fileUrl, size)}
            onAllFilesUploadComplete={() => onAllFilesUploadComplete()}
            maximumFileAmount={10}
            allowedFileExtensions={['xml', 'pdf']}
            options={{
              filePrefix: `${orderId}`,
              bucketFile: 'tiffin-bucket/files',
              namePrefix: 'file-order',
              metadataCustom: 'metadata',
            }}
          />
        </AdvancedModal>
      )}
    </>
  );
}

export default InnerInvoiceOrderForm;
