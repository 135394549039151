import Immutable from 'seamless-immutable';
import {
  ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_HIGHLIGHTS_PAGINATED_DATA,
  ACTION_GET_HIGHLIGHT_IS_ON_REQUEST,
  ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST,
  ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST,
  ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST,
  ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST,
  ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST,
} from '../actions/highlight';

const initialState = Immutable({
  highlightsPaginated: null,
  isHighlightsPaginatedOnRequest: false,
  isCreateHighlightsOnRequest: false,
  isAssociateHighlightProductsOnRequest: false,
  isDisassociateHighlightProductsOnRequest: false,
  isUpdateHighlightOnRequest: false,
  isDeleteHighlightOnRequest: false,
});

export default function highlight(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_HIGHLIGHTS_PAGINATED_DATA:
      return state.merge({
        highlightsPaginated: action.payload,
      });
    case ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isHighlightsPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_HIGHLIGHT_IS_ON_REQUEST:
      return state.merge({
        isGetHighlightByIdOnRequest: action.payload,
      });
    case ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST:
      return state.merge({
        isCreateHighlightsOnRequest: action.payload,
      });
    case ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST:
      return state.merge({
        isAssociateHighlightProductsOnRequest: action.payload,
      });
    case ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST:
      return state.merge({
        isDisassociateHighlightProductsOnRequest: action.payload,
      });
    case ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST:
      return state.merge({
        isUpdateHighlightOnRequest: action.payload,
      });
    case ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST:
      return state.merge({
        isDeleteHighlightOnRequest: action.paylaod,
      });
    default:
      return state;
  }
}
