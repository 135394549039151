import React, { useState, useRef } from 'react';
import {
  Layout,
  Breadcrumb,
  Collapse,
  Row,
  Popover,
  Col,
  Divider,
  Dropdown,
  Menu,
  Modal,
  message,
  Tooltip,
} from 'antd';
import {
  DashboardFilled,
  QuestionCircleFilled,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { FiExternalLink } from 'react-icons/fi';
import classNames from 'classnames';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { HighlightActions } from '../../../../app/redux/actions';
import * as DateUtils from '../../../../app/utils/date';
import { ViewTypes } from '../../../../app/enum/view_types';
import HighlightsModal from '../../../../components/panel/HighlightsModal/HighlightsModal';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { hasAccess } from '../../../../app/services/access';

import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const { Content } = Layout;
const { Panel } = Collapse;

function Highlights() {
  const { highlight: HighlightSelector } = useReduxState();
  const dispatch = useDispatch();
  const dataTableRef = useRef();
  const [search, setSearch] = useState('');
  const [visibleHighlightsModal, setVisibleHighlightsModal] = useState(false);
  const [type, setType] = useState(ViewTypes.CREATE);
  const [idHighlight, setIdHighlight] = useState('');

  const getHighlightsPaginated = (params) => {
    dispatch(HighlightActions.getHighlightsPaginated(params));
  };

  const cleanAdvancedFilters = () => {
    setSearch(null);
    getHighlightsPaginated();
    dataTableRef.current.reset();
  };

  const onDeleteHighlightById = async (id) => {
    const success = await dispatch(HighlightActions.deleteHighlightById(id));
    if (success) {
      message.success(I18n.t('routes.panel.administration.highlights.messages.success.delete'));
    }
  };

  const handleSearch = () => {
    getHighlightsPaginated({ search });
  };

  const getHighlights = (params, filters) => {
    if (params) {
      getHighlightsPaginated({ ...params, search });
    } else {
      getHighlightsPaginated({ ...filters, search });
    }
  };

  const onHandleOpenHighlightsModal = (id) => {
    setVisibleHighlightsModal(true);
    setIdHighlight(id);
  };

  const onHandleCreateHighlight = () => {
    setType(ViewTypes.CREATE);
    onHandleOpenHighlightsModal();
  };

  const onHandleCloseHighlightsModal = () => {
    getHighlightsPaginated();
    setVisibleHighlightsModal(false);
  };

  const onShowDeleteConfirm = (id) => {
    const { confirm } = Modal;
    confirm({
      title: I18n.t('routes.panel.administration.highlights.popup.deleteConfirm'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('routes.panel.administration.highlights.popup.okText'),
      okType: 'danger',
      centered: true,
      cancelText: I18n.t('routes.panel.administration.highlights.popup.cancelText'),
      confirmLoading: HighlightSelector.isDeleteHighlightOnRequest,
      onOk: async () => {
        await onDeleteHighlightById(id);
        getHighlightsPaginated();
      },
    });
  };

  const onActionsClick = (id, item, key) => {
    switch (key) {
      case '1':
        setType(ViewTypes.EDIT);
        onHandleOpenHighlightsModal(id);
        break;
      case '2':
        navigate(`${I18n.t('routes.panel.administration.highlightProducts.url')}/${id}`);
        break;
      case '3':
        onShowDeleteConfirm(id);
        break;
      default:
    }
  };

  const getColumns = () => {
    const columns = [
      {
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.id.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.id.title'),
        width: 20,
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.title.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.title.title'),
        width: 90,
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.position.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.position.title'),
        width: 40,
        render: (value) => (value ? parseInt(value, 10) : '--'),
      },
      {
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.createdAt.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.createdAt.title'),
        width: 100,
        render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
      },
      {
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.updatedAt.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.updatedAt.title'),
        width: 100,
        render: (updatedAt) => DateUtils.humanizeDateTime(updatedAt, 'DD/MM/YYYY HH:mm'),
      },
    ];

    if (hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.UPDATE])) {
      columns.push({
        key: I18n.t('routes.panel.administration.highlights.dataTable.columns.products.key'),
        title: I18n.t('routes.panel.administration.highlights.dataTable.columns.products.title'),
        width: '15%',
        render: (value, row) => (
          <div>
            <Link to={`${I18n.t('routes.panel.administration.highlightProducts.url')}/${row.id}`}>
              <span
                className={classNames({
                  'highlight-table-content': true,
                  'highlight-table-content--unavailable': parseInt(row?.productsCount, 10) === 0,
                })}
              >
                {parseInt(row?.productsCount, 10) === 0 && (
                  <Tooltip title={I18n.t('routes.panel.administration.highlightProducts.noProductError')} disabled>
                    <span className="highlight-table-content--unavailable__icon">
                      <ExclamationCircleOutlined />
                    </span>
                  </Tooltip>
                )}
                {row?.productsCount || 0}
                &nbsp;
                {I18n.t('routes.panel.administration.highlights.dataTable.columns.products.amountTitle')}
                &nbsp;
                <span>
                  <FiExternalLink />
                </span>
              </span>
            </Link>
          </div>
        ),
      });
    }

    columns.push({
      key: I18n.t('routes.panel.administration.highlights.dataTable.columns.id.key'),
      title: I18n.t('routes.panel.administration.highlights.actions.title'),
      fixed: 'right',
      width: 20,
      render: (highlightId, row) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ item, key, keyPath, domEvent }) => {
                onActionsClick(row.id, item, key, keyPath, domEvent);
              }}
            >
              <Menu.Item key="1">
                {I18n.t('routes.panel.administration.highlights.actions.goToDetailsHighlight')}
              </Menu.Item>
              {hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.UPDATE]) && (
                <Menu.Item key="2">
                  {I18n.t('routes.panel.administration.highlights.actions.goToAssociateHighlight')}
                </Menu.Item>
              )}
              {hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.REMOVE]) && (
                <Menu.Item key="3">{I18n.t('routes.panel.administration.highlights.actions.delete')}</Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="highlight-table-content__ant-dropdown-link">
            <MoreOutlined />
          </div>
        </Dropdown>
      ),
    });

    return columns;
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.highlights.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <div className="highlight-content">
        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel
            header={<strong>{I18n.t('routes.panel.administration.highlights.advancedFilters.title')}</strong>}
            key="1"
          >
            <Row align="middle">
              <div className="highlight-content__search-input">
                <AdvancedInput
                  value={search}
                  onChange={(val) => setSearch(val)}
                  placeholder={I18n.t('shared.typeToContinue')}
                />
              </div>
              <div className="highlight-content__search-popover">
                <Popover
                  placement="topLeft"
                  content={I18n.t('routes.panel.administration.highlights.advancedFilters.searchFieldTitle')}
                >
                  <QuestionCircleFilled />
                </Popover>
              </div>
            </Row>
            <div className="highlight-content__search-filters">
              <Row>
                <div className="highlight-content__search-filters__buttons">
                  <AdvancedButton
                    type="default"
                    text={I18n.t('routes.panel.administration.highlights.advancedFilters.clearButtonText')}
                    onClick={cleanAdvancedFilters}
                  />
                  <AdvancedButton
                    text={I18n.t('routes.panel.administration.highlights.advancedFilters.filterButtonText')}
                    iconLeft={<SearchOutlined />}
                    onClick={handleSearch}
                  />
                </div>
              </Row>
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="highlight-table-content">
        <Content className="panel__layout__content panel__layout__content--content-data">
          {hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.CREATE]) && (
            <>
              <Row align="middle">
                <Col span={24}>
                  <AdvancedButton
                    loading={HighlightSelector.isHighlightsPaginatedOnRequest}
                    onClick={onHandleCreateHighlight}
                    text={I18n.t('routes.panel.administration.highlights.addNewButtonText')}
                    iconLeft={<PlusOutlined />}
                  />
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <AdvancedDataTable
            refresh={getHighlightsPaginated}
            getMethod={getHighlights}
            data={HighlightSelector.highlightsPaginated}
            loading={HighlightSelector.isHighlightsPaginatedOnRequest}
            ref={dataTableRef}
            container="highlights"
            columns={getColumns()}
          />
        </Content>
      </div>

      {visibleHighlightsModal && (
        <HighlightsModal
          visible={visibleHighlightsModal}
          onCloseForm={onHandleCloseHighlightsModal}
          id={idHighlight}
          type={type}
        />
      )}
    </>
  );
}

export default Highlights;
