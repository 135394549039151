import Immutable from 'seamless-immutable';
import { ACTION_GET_INSTALLMENT_TAX, ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST } from '../actions/card';

const initialState = Immutable({
  cardInstallmentTax: [],
  isCardInstallmentTaxOnRequest: false,
});

export default function card(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_INSTALLMENT_TAX:
      return state.merge({
        cardInstallmentTax: action.payload,
      });
    case ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST:
      return state.merge({
        isCardInstallmentTaxOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getCardInstallmentTax(state) {
  return state.card.cardInstallmentTax;
}
