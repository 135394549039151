import Compressor from 'compressorjs';

const ImageCompress = (file, reader, { quality, maxHeight, maxWidth }) => {
  // eslint-disable-next-line no-new
  new Compressor(file, {
    quality,
    maxHeight,
    maxWidth,
    success(result) {
      reader.readAsDataURL(result);
    },
  });
};

export { ImageCompress };
