import React, { useState, useRef } from 'react';
import { Breadcrumb, Layout, Row, Col, Divider, Collapse, Popover, Select, notification, Tag } from 'antd';
import { DashboardFilled, PlusOutlined, QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import * as DateUtils from '../../../app/utils/date';
import CustomerImportModal from '../../../components/panel/CustomerImportModal';
import { CustomerActions } from '../../../app/redux/actions';
import { AuthSelectors, CustomerSelectors } from '../../../app/redux/reducers';
import * as CustomerOriginStatus from '../../../app/enum/customer_origin_status';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';

function Customers({
  getCustomersPaginated,
  isGetCustomersPaginatedOnRequest,
  getMyProviders,
  onGetCustomersPaginated,
  isCreateCustomersOnRequest,
  onUploadCustomers,
  isUploadCustomersOnRequest,
}) {
  const [search, setSearch] = useState('');
  const [providerId, setProviderId] = useState(getMyProviders[0].id);
  const [visibleCustomerImportModal, setVisibleCustomerImportModal] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);

  const { Content } = Layout;
  const { Option } = Select;
  const { Panel } = Collapse;

  const dataTableRef = useRef();

  const onGetCustomersPaginatedRefresh = (params) => {
    params = {
      ...params,
      providerId: params?.providerId || providerId,
      search: params?.search || search,
    };
    onGetCustomersPaginated(params);
  };

  const onCleanAdvancedFilters = async () => {
    await setSearch('');
    await setProviderId(getMyProviders[0].id);
    dataTableRef.current.reset();
    setFilteredInfo(null);
  };

  const onUploadFileSuccess = () => {
    notification.success({
      message: I18n.t('routes.panel.customers.importModal.notification.success.message'),
      description: I18n.t('routes.panel.customers.importModal.notification.success.description'),
    });
    onGetCustomersPaginatedRefresh();
    return setVisibleCustomerImportModal(false);
  };

  const handleSearch = () => {
    dataTableRef.current.reset();
    onGetCustomersPaginatedRefresh();
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.customers.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel header={<strong>{I18n.t('routes.panel.customers.advancedFilters.title')}</strong>} key="1">
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={search}
              onChange={(val) => setSearch(val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover placement="topLeft" content={I18n.t('routes.panel.customers.advancedFilters.searchFieldTitle')}>
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>

          {getMyProviders.length && (
            <Select
              style={{ marginTop: 15, width: 240 }}
              value={providerId}
              onChange={(val) => setProviderId(val)}
              placeholder={I18n.t('header.providerSelectPlaceholder')}
            >
              {getMyProviders.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          )}

          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.customers.advancedFilters.clearButtonText')}
              onClick={onCleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.customers.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={handleSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {hasAccess([accessTypes.CUSTOMERS], [accessActionTypes.CREATE]) && (
          <>
            <Row align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="panel__layout__content__actions">
                  <div className="panel__layout__content__actions__left">
                    <AdvancedButton
                      onClick={() => setVisibleCustomerImportModal(true)}
                      text={I18n.t('routes.panel.customers.addNewButtonText')}
                      iconLeft={<PlusOutlined />}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
          </>
        )}

        <AdvancedDataTable
          refresh={onGetCustomersPaginatedRefresh}
          getMethod={(params) => onGetCustomersPaginatedRefresh(params)}
          data={getCustomersPaginated}
          sticky
          loading={isGetCustomersPaginatedOnRequest}
          ref={dataTableRef}
          container="customers"
          onChange={handleChange}
          columns={[
            {
              key: I18n.t('routes.panel.customers.dataTable.columns.name.key'),
              title: I18n.t('routes.panel.customers.dataTable.columns.name.title'),
              width: 100,
              render: (_, row) => row.customer?.name || '--',
              sorter: true,
            },
            {
              key: I18n.t('routes.panel.customers.dataTable.columns.email.key'),
              title: I18n.t('routes.panel.customers.dataTable.columns.email.title'),
              width: 100,
              render: (_, row) => row.customer?.email || '--',
              sorter: true,
            },
            {
              key: I18n.t('routes.panel.customers.dataTable.columns.origin.key'),
              title: I18n.t('routes.panel.customers.dataTable.columns.origin.title'),
              width: 50,
              filters: CustomerOriginStatus.GetCustomerOriginStatusFilters,
              filteredValue: filteredInfo?.origin || null,
              render: (value) =>
                (
                  <Tag color={CustomerOriginStatus.GetCustomerOriginStatusColor(value)}>
                    {CustomerOriginStatus.GetCustomerOriginStatusName(value)}
                  </Tag>
                ) || '--',
            },
            {
              key: I18n.t('routes.panel.customers.dataTable.columns.createdAt.key'),
              title: I18n.t('routes.panel.customers.dataTable.columns.createdAt.title'),
              width: 50,
              render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
              sorter: true,
            },
          ]}
        />
      </Content>

      <CustomerImportModal
        providerSelected={providerId}
        title={I18n.t('routes.panel.customers.importModal.title')}
        visible={visibleCustomerImportModal}
        onHandleCancel={() => setVisibleCustomerImportModal(false)}
        cancelButtonText={I18n.t('routes.panel.customers.importModal.cancelText')}
        uploadButtonText={I18n.t('routes.panel.customers.importModal.okText')}
        loading={isCreateCustomersOnRequest || isUploadCustomersOnRequest}
        acceptFilesTypes=".csv"
        templatePath="/assets/files/customer-import-template.csv"
        onUploadFileMethod={(providerIdForUpload, filesForUpload) =>
          onUploadCustomers(providerIdForUpload, filesForUpload)
        }
        onUploadFileSuccess={() => onUploadFileSuccess()}
        templateButtonText={I18n.t('routes.panel.customers.importModal.templateButtonText')}
        selectFileTitle={I18n.t('routes.panel.customers.importModal.selectFileTitle')}
        selectFileDescription={I18n.t('routes.panel.customers.importModal.selectFileDescription')}
        templateButtonTextDescription={I18n.t('routes.panel.customers.importModal.templateButtonTextDescription')}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  getCustomersPaginated: CustomerSelectors.getCustomersPaginated(state),
  isGetCustomersPaginatedOnRequest: CustomerSelectors.isGetCustomersPaginatedOnRequest(state),
  getMyProviders: AuthSelectors.getProviders(state),
  isCreateCustomersOnRequest: CustomerSelectors.isCreateCustomersOnRequest(state),
  isUploadCustomersOnRequest: CustomerSelectors.isUploadCustomersOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomersPaginated: (parameters) => dispatch(CustomerActions.getCustomersPaginated(parameters)),
  onUploadCustomers: (providerIdForUpload, filesForUpload) =>
    dispatch(CustomerActions.uploadCustomers(providerIdForUpload, filesForUpload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
