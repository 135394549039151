import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { Layout, Drawer, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { getInstance } from 'tiffin-foods-api-client/lib/cjs/modules/instance/instance';
import { isLogged } from 'tiffin-foods-api-client/lib/cjs/utils/auth';
import { ChatProvider, RibbonWindowedChat } from 'tiffin-foods-chat-component/lib/cjs/components';

import MenuContent from '../../components/shared/Menu';
import HeaderDefault from '../../components/header/HeaderDefault';
import { REACT_APP_API_CHAT_URL } from '../config/env.config';
import { useReduxState } from '../hooks/useReduxState';

import { AuthActions, UserActions } from '../redux/actions';
import { hasAccess } from '../services/access';
import { isSupported, getMessaging } from '../services/firebase';
import NotificationPermissionTypes from '../enum/notification_permission_types';
import { UserTypes } from '../enum/user_types';
import { UserStatus } from '../enum/user_status';

function Panel({ title, container, id, disableChatContainer, routeAccess, routeAccessFeatures }) {
  const [collapsedDrawer, setCollapsedDrawer] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [collapsed, setCollapsed] = useState();

  const { auth, access } = useReduxState();
  const dispatch = useDispatch();

  const notificationPermissionVerification = () => {
    if (Notification.permission === NotificationPermissionTypes.GRANTED) {
      dispatch(UserActions.sendUserPushToken());
    } else if (Notification.permission !== NotificationPermissionTypes.DENIED) {
      Notification.requestPermission().then(async (permission) => {
        if (permission === NotificationPermissionTypes.GRANTED) {
          dispatch(UserActions.sendUserPushToken());
        }
      });
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated && access.userAccessObtained && access.userAccess && routeAccess && routeAccessFeatures) {
      if (!hasAccess(routeAccess, routeAccessFeatures)) {
        dispatch(AuthActions.logout(true, true));
        navigate(I18n.t('routes.forbidden.url'));
      }
    }
  }, [auth?.isAuthenticated, access.userAccessObtained]);

  useEffect(() => {
    if (!isLogged()) {
      window.location.href = I18n.t('routes.login.url');
    }
    if (isSupported()) {
      notificationPermissionVerification();
      getMessaging().onMessage(({ data }) => {
        notification.open({
          message: data?.title,
          description: data?.body,
        });
      });
    }
  }, [access.isAuthenticated]);

  const toggleSideMenu = () => {
    setCollapsedDrawer(!collapsedDrawer);
  };

  const { Sider } = Layout;

  return (
    auth?.isAuthenticated &&
    auth?.user && (
      <ChatProvider
        config={{
          api: {
            chatApiUrl: REACT_APP_API_CHAT_URL,
            forceUserId: false,
          },
          loggedUser: auth,
          getInstance,
          onErrorCallback: ({ message }) =>
            notification.error({
              message: I18n.t('routes.panel.dashboard.errors.message'),
              description: message,
            }),
        }}
      >
        <Layout className="panel__layout">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Layout>
            <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'sticky',
                paddingTop: 44.1,
                top: 0,
                left: 0,
              }}
              className="panel__sider"
              breakpoint="lg"
              collapsedWidth={showDrawer ? '0' : '80'}
              collapsed={collapsed}
              onCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
              collapsible={!showDrawer}
              onBreakpoint={(response) => setShowDrawer(response)}
            >
              <MenuContent collapsed={!showDrawer && collapsed} me={auth?.user} />
            </Sider>
            <Layout className="panel__layout__wrapper">{React.cloneElement(container, { id })}</Layout>
          </Layout>
          {auth?.user &&
            (auth?.user?.emailValidated ||
              auth?.user?.phoneValidated ||
              auth?.user?.status === UserStatus.APPROVED ||
              [UserTypes.ADMIN, UserTypes.SUPPORT].includes(auth?.user?.type)) && (
              <HeaderDefault showDrawer={showDrawer} onToggleSideMenu={toggleSideMenu} />
            )}
          {showDrawer && (
            <Drawer
              maskClosable={false}
              drawerStyle={{ backgroundColor: 'black' }}
              bodyStyle={{ padding: 0, marginTop: -4 }}
              placement="left"
              closable={false}
              onClose={toggleSideMenu}
              visible={collapsedDrawer}
              key="left"
            >
              <MenuContent me={auth?.user} />
            </Drawer>
          )}
          {!disableChatContainer && <RibbonWindowedChat />}
        </Layout>
      </ChatProvider>
    )
  );
}

export default Panel;
