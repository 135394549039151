import {
  ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_DATA,
  ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ACCESS_FEATURES_USERS_OBTAINED,
} from '../actions/accessFeatureUsers';

const initialState = {
  accessFeaturesUsersPaginated: null,
  getaccessFeaturesUsersPaginatedIsOnRequest: false,
  accessFeaturesUsersPaginatedObtained: false,
};

export default function accessFeatureUsers(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_DATA:
      return {
        ...state,
        accessFeaturesUsersPaginated: action.payload,
      };
    case ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST:
      return {
        ...state,
        getaccessFeaturesUsersPaginatedIsOnRequest: action.payload,
      };
    case ACTION_GET_ACCESS_FEATURES_USERS_OBTAINED:
      return {
        ...state,
        accessFeaturesUsersPaginatedObtained: action.payload,
      };
    default:
      return state;
  }
}
