import React from 'react';
import { I18n } from 'react-redux-i18n';
import { getOrderTotalValue, getPrice } from '../../../../../app/utils/prices';

function OrderProductsPriceFooter({ order }) {
  const { value, discount } = order;

  return (
    <div className="order-form__order-info">
      <div className="order-form__order-info__item">
        <span className="order-form__order-info__item__label">
          {`${I18n.t('forms.order.product.advancedModal.stockUpdate.orderValue.value.label')}:`}
        </span>
        <p className="order-form__order-info__item__value">{getPrice(value || 0)}</p>
      </div>

      <div className="order-form__order-info__item">
        <span className="order-form__order-info__item__label">
          {`${I18n.t('forms.order.product.advancedModal.stockUpdate.orderValue.discount.label')}:`}
        </span>
        <p className="order-form__order-info__item__value">{getPrice(discount || 0)}</p>
      </div>

      <div className="order-form__order-info__item">
        <span className="order-form__order-info__item__label">
          {`${I18n.t('forms.order.product.advancedModal.stockUpdate.orderValue.total.label')}:`}
        </span>
        <p className="order-form__order-info__item__value">{getPrice(getOrderTotalValue(order) || 0)}</p>
      </div>
    </div>
  );
}

export default OrderProductsPriceFooter;
