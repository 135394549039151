import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProviderTypes = {
  DUMMY: 'dummy',
  RETAIL: 'retail',
  WHOLESALE: 'wholesale',
  REPRESENTATION_OFFICE: 'representation_office',
};

export const GetProviderTypeArray = [
  { id: ProviderTypes.DUMMY, name: <Translate value="enum.providerType.dummy" /> },
  { id: ProviderTypes.RETAIL, name: <Translate value="enum.providerType.retail" /> },
  { id: ProviderTypes.WHOLESALE, name: <Translate value="enum.providerType.wholesale" /> },
  { id: ProviderTypes.REPRESENTATION_OFFICE, name: <Translate value="enum.providerType.representation_office" /> },
];

export const GetProviderTypeFilters = GetProviderTypeArray.map((type) => ({
  value: type.id,
  text: type.name,
}));

export const GetProvidersTypeName = (value) => GetProviderTypeArray.find((o) => o.id === value).name;
