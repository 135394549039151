import AccessGroupPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/accessGroup/panel/accessGroupPanelRequestsV1';

export const ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST:accessGroup';
export const ACTION_GET_ACCESS_GROUPS_PAGINATED_DATA = 'ACTION_GET_ACCESS_GROUPS_PAGINATED_DATA:accessGroup';
export const ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST = 'ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST:accessGroup';
export const ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST =
  'ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST:accessGroup';
export const ACTION_UPDATE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST =
  'ACTION_UPDATE_ACCESS_GROUPS_BY_ID_IS_ON_REQUEST:accessGroup';
export const ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST = 'ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST:accessGroup';
export const ACTION_GET_ACCESS_GROUP_BY_ID = 'ACTION_GET_ACCESS_GROUP_BY_ID:accessGroup';

export function getAccessGroupsPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
    };
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST, payload: true });
      const accessGroups = await AccessGroupPanelRequestsV1.getAll(params);
      const { count, rows } = accessGroups;
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_PAGINATED_DATA, payload: { count, rows } });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createAccessGroup(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST, payload: true });
      const success = await AccessGroupPanelRequestsV1.create(payload);
      if (success) {
        return true;
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST, payload: false });
    }
  };
}

export function deleteAccessGroupById(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: true });
      await AccessGroupPanelRequestsV1.remove(id);
      if (callback) {
        callback();
      }
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}

export function getAccessGroupById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: true });
      const payload = await AccessGroupPanelRequestsV1.getById(id);
      dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID, payload });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateAccessGroupById(id, payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_UPDATE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: true });
      await AccessGroupPanelRequestsV1.update(id, payload);
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_UPDATE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}
