import React from 'react';
import { Select, Empty } from 'antd';
import { I18n } from 'react-redux-i18n';
import { localeCompare } from '../../../app/utils/string';

const { Option } = Select;

function AdvancedSelect({
  value,
  defaultValue,
  options,
  label,
  onChange,
  onSelect,
  disabled = false,
  placeholder,
  showSearch = false,
  onSearch,
  mode,
  size,
  emptyDescription,
  selectStyle = {},
  allowClear,
  bordered = true,
  dropdownMatchSelectWidth = false,
  required,
  style,
  onClear,
}) {
  return (
    <div className="advanced-select" style={style}>
      {label && (
        <span className="advanced-select__label">
          {label} {required && <strong>*</strong>}
        </span>
      )}
      <Select
        className="advanced-select__select"
        style={selectStyle}
        value={value || undefined}
        defaultValue={defaultValue || undefined}
        onChange={onChange}
        onSelect={onSelect}
        optionFilterProp={showSearch ? 'children' : undefined}
        disabled={disabled}
        placeholder={placeholder || I18n.t('shared.selectSomeValue')}
        showSearch={showSearch}
        allowClear={allowClear}
        bordered={bordered}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        size={size || 'default'}
        mode={mode || undefined}
        onSearch={onSearch ? (val) => onSearch(val) : undefined}
        onClear={onClear || undefined}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyDescription || I18n.t('shared.selectSomeValue')}
          />
        }
        filterOption={showSearch ? (input, option) => localeCompare(option.props.children, input) : undefined}
      >
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <Option key={option.id ? option.id.toString() : 'all'} value={option.id}>
              {option.name}
            </Option>
          ))}
      </Select>
    </div>
  );
}

export default AdvancedSelect;
