import React, { useState } from 'react';

import { I18n } from 'react-redux-i18n';

import AdvancedDatePicker from '../../../../shared/AdvancedDatePicker/AdvancedDatePicker';

function UpdateBankSlipInstallmentForm({ installmentUpdateInfo, updateInstallmentDueDate }) {
  const [newDueDate, setNewDueDate] = useState('');
  const { dueDate, transactionId } = installmentUpdateInfo;

  const handleNewDueDateChange = (value) => {
    setNewDueDate(value);
    updateInstallmentDueDate({ transactionId, dueDate: value });
  };

  return (
    <div className="order-form__update-installment-form__container">
      <AdvancedDatePicker
        label={I18n.t('forms.order.items.previousDueDate.label')}
        format="DD/MM/YYYY"
        value={dueDate}
        disabled
      />

      <AdvancedDatePicker
        label={I18n.t('forms.order.items.newDueDate.label')}
        format="DD/MM/YYYY"
        value={newDueDate}
        onChange={handleNewDueDateChange}
      />
    </div>
  );
}

export default UpdateBankSlipInstallmentForm;
