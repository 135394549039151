import PromotionType from '../enum/promotion_type';
import { PaymentTypes } from '../enum/payment_types';
import FreightTypes from '../enum/freight_type';

function cleanValue(value) {
  if (typeof value === 'string') {
    const removeSpecialChars = value.replace('%', '').replace('R$', '').trim();
    return removeSpecialChars.includes(',')
      ? parseFloat(removeSpecialChars.replace(/\./g, '').replace(',', '.')).toFixed(2)
      : parseFloat(removeSpecialChars).toFixed(2);
  }
  return value.toFixed(2);
}

function getPrice(amount, decimalCount = 2, decimal = ',', thousands = '.', coin = 'R$') {
  if (amount !== null && amount !== undefined) {
    try {
      let newDecimalCount = decimalCount;
      let newAmount = amount;
      newDecimalCount = Math.abs(newDecimalCount);
      newDecimalCount = Number.isNaN(newDecimalCount) ? 2 : newDecimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = parseInt((newAmount = Math.abs(Number(newAmount) || 0).toFixed(newDecimalCount)), 10).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      const price =
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (newDecimalCount
          ? decimal +
            Math.abs(newAmount - i)
              .toFixed(newDecimalCount)
              .slice(2)
          : '');

      return `${coin} ${price}`;
    } catch (err) {
      return '-';
    }
  }
  return '-';
}

function getCoinsDiscount({ wallet, coinValue = 0 }) {
  return wallet?.walletOut ? Number(wallet.walletOut) * Number(coinValue) : 0;
}

function getDiscount({ discount, wallet, coinValue = 0 }) {
  const walletDiscount = getCoinsDiscount({ wallet, coinValue });

  return Number(discount) + walletDiscount;
}

function getOrderTotalValueWithoutFees({ value, freight, tax, discount, wallet, coinValue }) {
  return (
    parseFloat(value || '0') +
    parseFloat(freight || '0') +
    parseFloat(tax || '0') -
    parseFloat(getDiscount({ discount, wallet, coinValue }) || '0')
  );
}

function getFees({ value, fees }) {
  return value * (fees / 100);
}

function getOrderTotalValue({ value, freight, tax, discount, fees, wallet, coinValue }) {
  const totalValueWithoutFees = getOrderTotalValueWithoutFees({
    value,
    freight,
    tax,
    discount,
    wallet,
    coinValue,
  });
  return totalValueWithoutFees + getFees({ value: totalValueWithoutFees, fees });
}

function getOrderFees({ value, freight, tax, discount, fees }) {
  const totalValueWithoutFees = getOrderTotalValueWithoutFees({
    value,
    freight,
    tax,
    discount,
  });
  return getFees({ value: totalValueWithoutFees, fees });
}

function getCurrentPriceForRange(product, quantity = 0) {
  let priceForRange;
  product.prices.forEach((price) => {
    if (!priceForRange) {
      priceForRange = price;
    } else if (quantity >= price.range && price.range > priceForRange.range) {
      priceForRange = price;
    }
  });
  return priceForRange;
}

function getCartSubtotal(items, isIndividual) {
  return items.reduce((total, item) => {
    const { quantity, product } = item;
    const priceForRange = getCurrentPriceForRange(product, quantity);
    return total + priceForRange.value * (isIndividual ? 1 : quantity);
  }, 0);
}

function getItemSubtotal(product, quantity, isIndividual) {
  return getCartSubtotal([{ product, quantity }], isIndividual);
}

function getDiscountByType(type, price, discount) {
  if (type === PromotionType.VALUE) {
    return parseFloat(price) - parseFloat(discount);
  }
  return parseFloat(price) - (parseFloat(price) * parseFloat(discount)) / 100;
}

function getCartDiscount({ items, isIndividual, walletOut, coinValue }) {
  const discount = items.reduce((total, item) => {
    const { quantity, product } = item;
    if (product.promotion) {
      const priceForRange = getCurrentPriceForRange(product, quantity);
      const discountByType = getDiscountByType(product.promotion.type, priceForRange.value, product.promotion.value);
      return total + (priceForRange.value - discountByType) * (isIndividual ? 1 : quantity);
    }
    return total;
  }, 0);

  return getDiscount({ discount, wallet: { walletOut }, coinValue });
}

function getItemDiscount(product, quantity) {
  return getCartDiscount({ items: [{ product, quantity }] });
}

function getCartTotalWithoutFess({ items, freight = 0, isIndividual = false, tax = 0, walletOut, coinValue }) {
  return (
    getCartSubtotal(items, isIndividual) -
    getCartDiscount({ items, isIndividual, walletOut, coinValue }) +
    parseFloat(freight || '0') +
    parseFloat(tax || '0')
  );
}

function getCartTotal({ items, freight = 0, isIndividual = false, tax = 0, fees = 0, walletOut, coinValue }) {
  const totalWithoutFess = getCartTotalWithoutFess({
    items,
    freight,
    isIndividual,
    tax,
    walletOut,
    coinValue,
  });

  return totalWithoutFess + getFees({ value: totalWithoutFess, fees });
}

function getItemTotal(product, quantity, isIndividual) {
  return getCartTotal({ items: [{ product, quantity }], isIndividual });
}

function getCartCommission({ items, isPrivateRepresentative, commission }) {
  return items.reduce((total, item) => {
    const { product, quantity } = item;
    const priceForRange = getCurrentPriceForRange(product, quantity);
    const itemTotalValue = getItemTotal(product, quantity);

    let commissionPercentage;
    if (commission) {
      commissionPercentage = commission;
    } else {
      commissionPercentage = isPrivateRepresentative
        ? priceForRange.commissionPrivate || priceForRange.commissionPublic
        : priceForRange.commissionPublic;
    }

    const commissionFixed = (itemTotalValue * parseFloat(commissionPercentage || 0)) / 100;
    return total + commissionFixed;
  }, 0);
}

function getItemCommission({ product, quantity, isPrivateRepresentative, commission }) {
  return getCartCommission({
    items: [
      {
        product,
        quantity,
      },
    ],
    isPrivateRepresentative,
    commission,
  });
}

function getCartTiffinTax(items, isPrivateRepresentative) {
  return items.reduce((total, item) => {
    const { product, quantity } = item;
    const priceForRange = getCurrentPriceForRange(product, quantity);
    const itemTotalValue = getItemTotal(product, quantity);
    const taxValue = isPrivateRepresentative ? priceForRange.taxTiffinPrivate : priceForRange.taxTiffinPublic;
    const tax = (itemTotalValue * parseFloat(taxValue || 0)) / 100;
    return total + tax;
  }, 0);
}

function getItemTiffinTax(product, quantity, isPrivateRepresentative) {
  return getCartTiffinTax([{ product, quantity }], isPrivateRepresentative);
}

function getCartTax(items) {
  let hasTax;
  const tax = items.reduce((total, item) => {
    if (item.tax) hasTax = true;
    return total + (item.tax || 0);
  }, 0);
  return hasTax ? tax : null;
}

function getItemTax(product) {
  return getCartTax([{ product }]);
}

function getCartFees({ items, freight, tax, fees }) {
  const value = getCartTotal({ items, freight, tax });
  return getFees({ value, fees });
}

function getCommission({ taxTiffin, wallet, coinValue }) {
  return Number(taxTiffin) - getCoinsDiscount({ wallet, coinValue });
}

function getReceiveValue({
  taxTiffin,
  discount,
  wallet,
  paymentType,
  value,
  freight,
  freightType,
  tax,
  financialFees,
  coinValue,
}) {
  const subTotal =
    freightType === FreightTypes.PROVIDER
      ? getOrderTotalValueWithoutFees({
          value,
          freight,
          tax,
          discount,
          wallet,
          coinValue,
        })
      : getOrderTotalValueWithoutFees({
          value,
          tax,
          discount,
          wallet,
          coinValue,
        });

  const commission = getCommission({
    taxTiffin,
    wallet,
    coinValue,
  });

  const result =
    paymentType === PaymentTypes.CREDIT_CARD
      ? subTotal + Number(financialFees) - commission
      : subTotal - Number(financialFees) - commission;

  return result;
}

export {
  cleanValue,
  getPrice,
  getOrderTotalValue,
  getOrderTotalValueWithoutFees,
  getOrderFees,
  getCurrentPriceForRange,
  getCartTotal,
  getCartTotalWithoutFess,
  getCartSubtotal,
  getCartDiscount,
  getCartCommission,
  getCartTiffinTax,
  getCartTax,
  getCartFees,
  getItemTotal,
  getItemSubtotal,
  getItemDiscount,
  getItemCommission,
  getItemTiffinTax,
  getItemTax,
  getFees,
  getDiscount,
  getCoinsDiscount,
  getCommission,
  getReceiveValue,
};
