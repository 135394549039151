import Immutable from 'seamless-immutable';
import { ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST } from '../actions/nutrition';

const initialState = Immutable({
  isGetNutritionsOnRequest: false,
});

export default function nutrition(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST:
      return state.merge({
        isGetNutritionsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function isGetNutritionsOnRequest(state) {
  return state.nutrition.isGetNutritionsOnRequest;
}
