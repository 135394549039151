import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as S from './styles';
import { QuestionSelectors, UserSelectors } from '../../../../app/redux/reducers';

import CustomCheckableTag from '../../../shared/CustomCheckableTag';
import AdvancedInput from '../../../shared/AdvancedInput';

import { UserActions } from '../../../../app/redux/actions';
import { SignupSteps } from '../../../../app/enum/signup_steps';
import { QuestionTypes } from '../../../../app/enum/question_types';

function QuestionsStep({ questions, signupUser, onSetSignupUser }) {
  useEffect(() => {
    if (!questions.length) {
      if (
        signupUser.email &&
        signupUser.password &&
        signupUser.passwordConfirmation &&
        signupUser.checkedPrivacityTerms &&
        signupUser.checkedLegalTerms
      ) {
        onSetSignupUser('currentStep', SignupSteps.PersonalDataStep);
        return () => {};
      }
    }
    onSetSignupUser('currentStep', SignupSteps.QuestionsStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = (preferenceId, index) => {
    if (signupUser.preferences) {
      return !!signupUser?.preferences[index].preferences.find((o) => o.preferenceId === preferenceId);
    }

    return false;
  };

  const handleCheck = (preferenceId, multiple, index) => {
    const preferencesCopy = [...signupUser.preferences].map((o) => ({ ...o, preferences: [...o.preferences] }));

    if (!isChecked(preferenceId, index)) {
      if (!multiple) {
        preferencesCopy[index].preferences = [];
      }
      preferencesCopy[index].preferences.push({ preferenceId });
    } else {
      const preferenceIndex = preferencesCopy[index].preferences.findIndex((o) => o.preferenceId === preferenceId);
      preferencesCopy[index].preferences.splice(preferenceIndex, 1);
    }

    onSetSignupUser('preferences', [...preferencesCopy]);
  };

  const handleChangeInput = (value, index) => {
    const preferencesCopy = [...signupUser.preferences].map((o) => ({ ...o, preferences: [...o.preferences] }));
    preferencesCopy[index].preferences[0] = { ...preferencesCopy[index].preferences[0], value };
    onSetSignupUser('preferences', [...preferencesCopy]);
  };

  return (
    <>
      {questions.map((o, index) => {
        return (
          <div key={index.toString()}>
            <S.TitleQuestion key={o.id}>{`${o.question} ${o.required ? '*' : ''}`}</S.TitleQuestion>
            <div key={o.question}>
              {!!o.multiple && <span key={o.id + 1}>{I18n.t('signup.questionsStep.multipleQuestions')}</span>}
            </div>
            {o.type === QuestionTypes.CHECK ? (
              <S.PreferencesSpan key={o.id + 1}>
                {o.preference.map((item) => (
                  <CustomCheckableTag
                    checked={isChecked(item.id, index)}
                    color="gold"
                    key={item.id}
                    onChange={() => handleCheck(item.id, o.multiple, index)}
                  >
                    {item.preference}
                  </CustomCheckableTag>
                ))}
              </S.PreferencesSpan>
            ) : (
              <AdvancedInput
                value={signupUser.preferences?.[index].preferences[0].value}
                onChange={(value) => handleChangeInput(value, index)}
              />
            )}
          </div>
        );
      })}
    </>
  );
}

const mapStateToProps = (state) => ({
  questions: QuestionSelectors.getQuestions(state),
  signupUser: UserSelectors.getSignupUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsStep);
