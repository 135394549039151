import { FileType } from '../enum/order_file_type';
import { OrderLogStatusTypes, ShipmentTypes } from '../enum/order_log_status';
import { OrderStatus } from '../enum/order_status';

export function getOrderShipmentStatus(order) {
  const { logs, freightQuoteId } = order;

  if (logs && logs.length && freightQuoteId) {
    const log = [...logs].sort(
      ({ createdAt: lastCreatedAt }, { createdAt: nextCreatedAt }) => new Date(nextCreatedAt) - new Date(lastCreatedAt),
    )[0];
    return log.type;
  }

  return null;
}

export function getOrderShipmentLabels(order) {
  const { logs, freightQuoteId } = order;

  if (logs && logs.length && freightQuoteId) {
    const approvedLog = logs.find((log) => log.type === OrderLogStatusTypes.APPROVED);
    if (approvedLog)
      return approvedLog?.data.content.shipment_order_volume_array.map(
        ({ shipment_order_volume_number: labelNumber }) => labelNumber,
      );
  }

  return null;
}

export function getOrderShipmentCurrentStatus(order) {
  const { logs, freightQuoteId, files } = order;

  if (freightQuoteId) {
    if ([OrderStatus.CANCELED, OrderStatus.REJECTED].includes(order.status)) return;

    if (!files || !files.length || !files.some((file) => file.type === FileType.INVOICE))
      return ShipmentTypes.WAITING_INVOICE;

    if (logs?.length) {
      const onCarriageOrderExtraLogArraySorted = [...logs]
        .filter((log) => log.type === OrderLogStatusTypes.ON_CARRIAGE_EXTRA)
        .sort(
          ({ createdAt: lastCreatedAt }, { createdAt: nextCreatedAt }) =>
            new Date(nextCreatedAt) - new Date(lastCreatedAt),
        );
      const onCarriageOrderExtraLog = onCarriageOrderExtraLogArraySorted.length
        ? onCarriageOrderExtraLogArraySorted[0]
        : null;

      if (onCarriageOrderExtraLog) {
        const onCarriageOrderExtraLogState = onCarriageOrderExtraLog?.data?.history?.shipment_order_volume_state;

        switch (onCarriageOrderExtraLogState) {
          case OrderLogStatusTypes.ON_CARRIAGE_EXTRA_SHIPPED:
            return ShipmentTypes.ON_CARRIAGE;
          case OrderLogStatusTypes.ON_CARRIAGE_EXTRA_IN_TRANSIT:
            return ShipmentTypes.IN_TRANSIT;
          case OrderLogStatusTypes.ON_CARRIAGE_EXTRA_DELIVERED:
            return ShipmentTypes.DELIVERED;
          case OrderLogStatusTypes.ON_CARRIAGE_EXTRA_TO_BE_DELIVERED:
            return ShipmentTypes.TO_BE_DELIVERED;
          default:
            return ShipmentTypes.DEFAULT;
        }
      }

      if (logs.some((log) => log.type === OrderLogStatusTypes.ON_CARRIAGE)) return ShipmentTypes.ON_CARRIAGE;

      if (logs.some((log) => log.type === OrderLogStatusTypes.READY_DELIVERY)) return ShipmentTypes.READY_DELIVERY;

      if (logs.some((log) => log.type === OrderLogStatusTypes.APPROVED)) return ShipmentTypes.CREATED;
    }
  }

  return null;
}
