import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from 'react-redux-i18n';
import * as PriceUtils from '../../../../app/utils/prices';

import AdvancedMaskedInput from '../../../shared/AdvancedMaskedInput';
import AdvancedSwitch from '../../../shared/AdvancedSwitch';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';
import AdvancedDatePicker from '../../../shared/AdvancedDatePicker';

function InnerPromotionsProductForm({ updateProductData, product, disabled }) {
  const handlePromotionChange = (name, value) => {
    const productCopy = { ...product };
    productCopy.promotion[name] = value;
    updateProductData(productCopy);
  };

  const handleProductChange = (name, value) => {
    const productCopy = { ...product };
    productCopy[name] = value;
    updateProductData(productCopy);
  };

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.promotional.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSwitch
            label={I18n.t('forms.product.items.promotional.items.hasPromotion.label')}
            onChange={(val) => handleProductChange('hasPromotion', val)}
            value={product?.hasPromotion}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSwitch
            label={I18n.t('forms.product.items.promotional.items.isPercentage.label')}
            onChange={(val) => handlePromotionChange('isPercentage', val)}
            value={product?.promotion?.isPercentage}
            disabled={!product?.hasPromotion || disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {product?.promotion?.isPercentage ? (
            <AdvancedInputNumber
              label={`${I18n.t('forms.product.items.promotional.items.value.label')} (%)`}
              onChange={(val) => handlePromotionChange('value', val)}
              value={product?.promotion?.value || 0}
              precision={2}
              min={0}
              max={100}
              disabled={!product?.hasPromotion || disabled}
            />
          ) : (
            <AdvancedMaskedInput
              options={{
                unit: 'R$ ',
                precision: 2,
              }}
              label={`${I18n.t('forms.product.items.promotional.items.value.label')} (R$)`}
              onChange={(val) => handlePromotionChange('value', PriceUtils.cleanValue(val))}
              value={product?.promotion?.value || 0}
              kind="money"
              disabled={!product?.hasPromotion || disabled}
            />
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <AdvancedDatePicker
            label={I18n.t('forms.product.items.promotional.items.promotionStartDate.label')}
            onChange={(val) => handlePromotionChange('startedAt', val)}
            value={product?.promotion?.startedAt}
            disabled={!product?.hasPromotion || disabled}
            showTime
          />
          <AdvancedDatePicker
            label={I18n.t('forms.product.items.promotional.items.promotionEndDate.label')}
            onChange={(val) => handlePromotionChange('finalizedAt', val)}
            value={product?.promotion?.finalizedAt}
            disabled={!product?.hasPromotion || disabled}
            showTime
          />
        </Col>
      </Row>
    </div>
  );
}

export default InnerPromotionsProductForm;
