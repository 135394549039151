import Imutable from 'seamless-immutable';
import {
  ACTION_GET_CUSTOMERS_PAGINATED,
  ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST,
  ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST,
  ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST,
} from '../actions/customer';

const initialState = Imutable({
  customersPaginated: [],
  isGetCustomerPaginatedOnRequest: false,
  isCreateCustomersOnRequest: false,
  isUploadCustomersIsOnRequest: false,
});

export default function customer(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_CUSTOMERS_PAGINATED:
      return state.merge({
        customersPaginated: action.payload,
      });
    case ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetCustomerPaginatedOnRequest: action.payload,
      });
    case ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST:
      return state.merge({
        isCreateCustomersOnRequest: action.payload,
      });
    case ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST:
      return state.merge({
        isUploadCustomersIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getCustomersPaginated(state) {
  return state.customer.customersPaginated;
}

export function isGetCustomersPaginatedOnRequest(state) {
  return state.customer.isGetCustomerPaginatedOnRequest;
}

export function isCreateCustomersOnRequest(state) {
  return state.customer.isCreateCustomersOnRequest;
}

export function isUploadCustomersOnRequest(state) {
  return state.customer.isUploadCustomersIsOnRequest;
}
