import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { I18n } from 'react-redux-i18n';

function PasswordStrengthChecker({ password, passwordConfirm, setValidated }) {
  const [validatedItems, setValidatedItems] = useState();
  const [alreadyValidatedFirstTime, setAlreadyValidatedFirstTime] = useState(false);

  const verifyPasswordStrength = () => {
    if (validatedItems) {
      const itemsCopy = {
        ...validatedItems,
      };
      Object.keys(itemsCopy).forEach((item) => {
        validatedItems[item].checked = itemsCopy[item].validator(password, passwordConfirm);
      });

      setValidatedItems({
        ...itemsCopy,
      });
    }
  };

  const isAllValidated = () => {
    return validatedItems && Object.values(validatedItems).filter((o) => !o.checked).length === 0;
  };

  const getStrenghtNumber = () => {
    return validatedItems && Object.values(validatedItems).filter((o) => !!o.checked).length;
  };

  useEffect(() => {
    setValidatedItems({
      minimumSize: {
        order: 1,
        checked: false,
        validator: (pw) => (pw || '')?.length >= 8,
      },
      hasNumber: {
        order: 2,
        checked: false,
        validator: (pw) => /\d/.test(pw || ''),
      },
      hasLowercase: {
        order: 3,
        checked: false,
        validator: (pw) => /[a-z]/.test(pw || ''),
      },
      hasUppercase: {
        order: 4,
        checked: false,
        validator: (pw) => /[A-Z]/.test(pw || ''),
      },
      passwordMatched: {
        order: 5,
        checked: false,
        validator: (pw, pwConfirm) => pw && pwConfirm && pw === pwConfirm,
      },
    });
  }, []);

  useEffect(() => {
    if (validatedItems && !alreadyValidatedFirstTime) {
      verifyPasswordStrength();
      setAlreadyValidatedFirstTime(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatedItems, alreadyValidatedFirstTime]);

  const getLevelColor = (level) => {
    // eslint-disable-next-line default-case
    switch (level) {
      case 1:
        return '#7C161C';
      case 2:
        return '#DA3731';
      case 3:
        return '#EF853F';
      case 4:
        return '#FCF050';
      case 5:
        return '#55AD59';
    }
  };

  useEffect(() => {
    verifyPasswordStrength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, passwordConfirm]);

  useEffect(() => {
    setValidated(isAllValidated());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllValidated()]);

  return (
    <div className="password-strenght-checker">
      <div className="password-strenght-checker__levels">
        {validatedItems &&
          Object.values(validatedItems).map((_, keyIndex) => (
            <div
              key={keyIndex.toString()}
              className={classnames({
                'password-strenght-checker__levels__level': true,
                'password-strenght-checker__levels__level--active': keyIndex + 1 <= getStrenghtNumber(),
              })}
              style={{
                backgroundColor:
                  (keyIndex + 1 <= getStrenghtNumber() && getLevelColor(getStrenghtNumber())) || undefined,
              }}
            />
          ))}
      </div>
      <div className="password-strenght-checker__message">
        {I18n.t(`shared.passwordStrengthChecker.securityMessage.level${getStrenghtNumber()}`)}
      </div>
      <div className="password-strenght-checker__instructions">
        <div className="password-strenght-checker__instructions__title">
          {I18n.t('shared.passwordStrengthChecker.instructions.title')}
        </div>
        <div className="password-strenght-checker__instructions__line">
          <ul className="password-strenght-checker__instructions__line__list">
            {validatedItems &&
              Object.values(validatedItems)
                .sort((a, b) => a.order - b.order)
                .map((key, keyIndex) => (
                  <li
                    key={keyIndex.toString()}
                    className={classnames({
                      'password-strenght-checker__instructions__line__list__item': true,
                      'password-strenght-checker__instructions__line__list__item--checked': key.checked,
                    })}
                  >
                    {I18n.t(`shared.passwordStrengthChecker.instructions.line${keyIndex + 1}`)}
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PasswordStrengthChecker;
