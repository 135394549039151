import Immutable from 'seamless-immutable';
import { ACTION_CREATE_FILE_IS_ON_REQUEST } from '../actions/file';

const initialState = Immutable({
  isCreateFileOnRequest: false,
});

export default function file(state = initialState, action) {
  switch (action.type) {
    case ACTION_CREATE_FILE_IS_ON_REQUEST:
      return state.merge({
        isCreateFileOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function isCreateFileOnRequest(state) {
  return state.file.isCreateFileOnRequest;
}
