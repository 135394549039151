import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { I18n } from 'react-redux-i18n';
import { Collapse, notification, Row, Col, Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

import { connect } from 'react-redux';
import AdvancedButton from '../../../shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../../../shared/AdvancedSelect';
import * as S from './styles';

import { PaymentTypes } from '../../../../app/enum/payment_types';

import { AuthSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { UserActions } from '../../../../app/redux/actions';

function PaymentOptionsForm({
  id,
  visible,
  onCloseForm,
  getProviders,
  onGetUserPayments,
  onCreateUserPayments,
  isGetUserPaymentsOnRequest,
  disabled,
}) {
  const [payments, setPayments] = useState([]);

  const onSubmit = async () => {
    if (payments.find((payment) => !payment.providerId))
      return notification.error({
        message: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationMessage'),
        description: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationDescription'),
      });

    if (payments.find((payment) => !payment.installments.length))
      return notification.error({
        message: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationMessage'),
        description: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationDescription'),
      });

    const success = await onCreateUserPayments(id, payments);

    if (success) {
      notification.success({
        message: I18n.t('forms.paymentOptions.paymentOptionsSuccessNotificationMessage'),
        description: I18n.t('forms.paymentOptions.paymentOptionsSuccessNotificationDescription'),
      });
      return onCloseForm();
    }

    notification.error({
      message: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationMessage'),
      description: I18n.t('forms.paymentOptions.paymentOptionsFailedNotificationDescription'),
    });
  };

  const getUserPayments = async () => {
    const userPayments = await onGetUserPayments(id);
    setPayments(userPayments || []);
  };

  const onChangeDeadLines = (value, index, position) => {
    const newPayments = payments.map((paymentElement, paymentIndex) => {
      if (paymentIndex === index) {
        const newInstallments = [...paymentElement.installments];
        newInstallments[position] = value;
        return { ...paymentElement, installments: newInstallments };
      }
      return { ...paymentElement };
    });
    setPayments(newPayments);
  };

  const onHandleSelectProvider = (value, index) => {
    const newPayments = payments.map((paymentElement, paymentIndex) => {
      if (paymentIndex === index) {
        return { ...paymentElement, providerId: value };
      }
      return { ...paymentElement };
    });
    setPayments(newPayments);
  };

  const onAddPaymentDeadLine = () => {
    setPayments([...payments, { providerId: null, paymentType: PaymentTypes.BANK_SLIP_INSTALLMENT, installments: [] }]);
  };

  const onRemovePaymentDeadLine = (index) => {
    const newPaymentsArray = [...payments];
    newPaymentsArray.splice(index, 1);
    setPayments(newPaymentsArray);
  };

  useEffect(() => {
    if (id && visible) {
      getUserPayments();
    } else {
      setPayments(null);
    }
  }, [id, visible]);

  return (
    <S.Container
      className="drawer"
      title={I18n.t('forms.paymentOptions.paymentOptionsTitle')}
      onClose={onCloseForm}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        !disabled && (
          <S.Footer>
            <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={onCloseForm} />
            <AdvancedButton
              loading={isGetUserPaymentsOnRequest}
              text={I18n.t('forms.submitButtonText')}
              onClick={onSubmit}
            />
          </S.Footer>
        )
      }
    >
      {!disabled && (
        <Row>
          <Col span={24}>
            <p>{I18n.t('forms.paymentOptions.description')}</p>
          </Col>
        </Row>
      )}

      {!disabled && (
        <AdvancedButton
          loading={isGetUserPaymentsOnRequest}
          text={I18n.t('forms.paymentOptions.items.addButton.label')}
          onClick={onAddPaymentDeadLine}
        />
      )}

      {payments?.length ? (
        <Collapse style={{ marginTop: 20 }} accordion>
          {payments?.map((item, index) => (
            <Collapse.Panel
              key={String(item.id)}
              header={`${I18n.t('forms.paymentOptions.meansOfPaymentPanelHeader')} ${
                item && item.providerId ? `- ${getProviders.find((o) => o.id === item.providerId).name}` : ''
              }`}
              extra={
                !disabled && (
                  <Popconfirm
                    placement="topLeft"
                    title="Deseja remover essa condição de pagamento?"
                    onConfirm={() => onRemovePaymentDeadLine(index)}
                    okText="Remover"
                    cancelText="Cancelar"
                  >
                    <DeleteTwoTone
                      twoToneColor="red"
                      onClick={(event) => {
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                )
              }
            >
              <AdvancedSelect
                value={item.providerId}
                options={getProviders}
                label={I18n.t('forms.paymentOptions.providerItemLabel')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(value) => onHandleSelectProvider(value, index)}
                disabled={disabled}
              />

              <S.DeadLineText>{I18n.t('forms.paymentOptions.items.numberOfInstallments.label')}</S.DeadLineText>
              <S.DeadLineContainer>
                <S.DeadLineInputNumber
                  value={item.installments[0]}
                  min={1}
                  precision={0}
                  step={1}
                  onChange={(value) => onChangeDeadLines(value, index, 0)}
                  disabled={disabled}
                />
                <S.DeadLineInputNumber
                  value={item.installments[1]}
                  min={1}
                  max={120}
                  precision={0}
                  step={1}
                  onChange={(value) => onChangeDeadLines(value, index, 1)}
                  disabled={disabled}
                />
                <S.DeadLineInputNumber
                  value={item.installments[2]}
                  min={1}
                  precision={0}
                  step={1}
                  onChange={(value) => onChangeDeadLines(value, index, 2)}
                  disabled={disabled}
                />
                <S.DeadLineInputNumber
                  value={item.installments[3]}
                  min={1}
                  precision={0}
                  step={1}
                  onChange={(value) => onChangeDeadLines(value, index, 3)}
                  disabled={disabled}
                />
              </S.DeadLineContainer>
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        <p>{I18n.t('forms.paymentOptions.emptyList')}</p>
      )}
    </S.Container>
  );
}

const mapStateToProps = (state) => ({
  isGetUserPaymentsOnRequest: UserSelectors.isGetUserPaymentsOnRequest(state),
  getProviders: AuthSelectors.getProviders(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserPayments: (userId) => dispatch(UserActions.getUserPayments(userId)),
  onCreateUserPayments: (userId, payments) => dispatch(UserActions.createUserPayments(userId, payments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsForm);
