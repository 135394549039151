import styled from 'styled-components';

export const TitleQuestion = styled.strong`
  max-width: 100%;
  display: inline-block;
`;

export const PreferencesSpan = styled.span`
  display: block;
  padding-bottom: 20px;
`;

export const ContainerFooterButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
`;
