import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { notification } from 'antd';
import AdvancedInput from '../../../shared/AdvancedInput';
import AdvancedButton from '../../../shared/AdvancedButton';
import { UserActions } from '../../../../app/redux/actions';
import { AuthSelectors, UserSelectors } from '../../../../app/redux/reducers';

function ConfirmationCodeStep({
  isCreateUserAndCodeValidation,
  isUpdateUserAndCodeValidation,
  onSetSignupUser,
  signupUser,
  getUser,
  onCreateCodeValidation,
}) {
  const handleClickResendCode = () => {
    const { admin } = signupUser;

    if (admin) {
      onCreateCodeValidation(admin);
      return;
    }

    if (signupUser.name && signupUser.email) {
      onCreateCodeValidation(signupUser);
      return;
    }

    if (getUser.name && getUser.email) {
      onCreateCodeValidation(getUser);
      return;
    }

    notification.error({
      message: I18n.t('forms.user.createCodeValidation.notifications.error.message'),
      description: I18n.t('forms.user.createCodeValidation.notifications.error.description'),
    });
  };

  return (
    <>
      <div>{I18n.t('routes.signup.content.confirmationDataContent.content.headerText')}</div>
      <AdvancedInput
        label={I18n.t('routes.signup.content.confirmationDataContent.content.verificationInput.label')}
        placeholder={I18n.t('routes.signup.content.confirmationDataContent.content.verificationInput.placeholder')}
        value={signupUser?.code}
        onChange={(value) => onSetSignupUser('code', value)}
        disabled={isCreateUserAndCodeValidation || isUpdateUserAndCodeValidation}
        required
      />
      <div className="signup__container__barButtons">
        <AdvancedButton
          text={I18n.t('routes.signup.content.confirmationDataContent.content.resendCode')}
          type="link"
          onClick={handleClickResendCode}
          className="signup__container__barButtons__button"
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isCreateUserAndCodeValidation: UserSelectors.isCreateUserAndCodeIsOnRequest(state),
  isUpdateUserAndCodeValidation: UserSelectors.isUpdateUserAndCodeIsOnRequest(state),
  signupUser: UserSelectors.getSignupUser(state),
  getUser: AuthSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
  onCreateCodeValidation: (user) => dispatch(UserActions.createCodeValidation(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationCodeStep);
