import TagPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/tag/panel/tagPanelRequestsV1';

export const ACTION_GET_TAGS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_TAGS_IS_ON_REQUEST:tag';
export const ACTION_GET_TAGS_PAGINATED_DATA = 'ACTION_GET_TAGS_PAGINATED_DATA:tag';
export const ACTION_CREATE_TAGS_IS_ON_REQUEST = 'ACTION_CREATE_TAGS_IS_ON_REQUEST:tag';
export const ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST = 'ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST:tag';
export const ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST = 'ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST:tag';
export const ACTION_GET_TAGS_IS_ON_REQUEST = 'ACTION_GET_TAGS_IS_ON_REQUEST:tag';
export const ACTION_GET_TAGS_DATA = 'ACTION_GET_TAGS_DATA:tag';

export function getTagsPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: Number(params?.page) - 1 || 0,
    };
    try {
      dispatch({ type: ACTION_GET_TAGS_PAGINATED_IS_ON_REQUEST, payload: true });
      const tags = await TagPanelRequestsV1.getAll(params);
      const { count, rows } = tags;
      dispatch({ type: ACTION_GET_TAGS_PAGINATED_DATA, payload: { count, rows } });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_TAGS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}
export function getTags() {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_TAGS_IS_ON_REQUEST, payload: true });
      const tags = await TagPanelRequestsV1.getAll();
      const { count, rows } = tags;
      dispatch({ type: ACTION_GET_TAGS_DATA, payload: { count, rows } });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_TAGS_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createTags(tagsData) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_CREATE_TAGS_IS_ON_REQUEST, payload: true });
      const success = await TagPanelRequestsV1.create(tagsData);
      if (success) {
        return true;
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_CREATE_TAGS_IS_ON_REQUEST, payload: false });
    }
  };
}

export function deleteTagById(tagId, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST, payload: true });
      await TagPanelRequestsV1.remove(tagId);
      if (callback) {
        callback();
      }
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateTagById(tagId, payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST, payload: true });
      await TagPanelRequestsV1.update(tagId, payload);
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}
