import React from 'react';
import { Modal, Layout, Row, Divider, Input, notification } from 'antd';
import { MdClose } from 'react-icons/md';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../../shared/AdvancedButton/AdvancedButton';
import { genPassword } from '../../../../app/utils/string';

const { Content } = Layout;

function GenerateNewPasswordModal({ visible, onClose, onGeneratePassword }) {
  const [passwordGenerated, setPasswordGenerated] = React.useState('');

  const handleGenerateNewPassword = async () => {
    try {
      const password = genPassword();
      await onGeneratePassword(password);
      setPasswordGenerated(password);
      notification.success({
        message: I18n.t('forms.user.generateNewPassword.confirmationModal.successPassword'),
      });
    } catch (err) {
      notification.error({
        message: I18n.t('forms.user.generateNewPassword.confirmationModal.failedPassword'),
      });
    }
  };

  const handleClose = () => {
    setPasswordGenerated('');
    onClose();
  };

  const renderFooter = () => {
    if (!passwordGenerated) {
      return (
        <>
          <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={handleClose} />
          <AdvancedButton
            loading={false}
            text={I18n.t('forms.confirmButtonText')}
            onClick={handleGenerateNewPassword}
          />
        </>
      );
    }
    return <AdvancedButton type="link" text={I18n.t('forms.closeButtonText')} onClick={handleClose} />;
  };

  const renderContent = () => {
    if (!passwordGenerated) {
      return (
        <div>
          <p>{I18n.t('forms.user.generateNewPassword.confirmationModal.description')}</p>
          <p>{I18n.t('forms.user.generateNewPassword.confirmationModal.underDescription')}</p>
          <strong>{I18n.t('forms.user.generateNewPassword.confirmationModal.warningText')}</strong>
        </div>
      );
    }
    return (
      <Row>
        <p>{I18n.t('forms.user.generateNewPassword.passwordGenerateModal.description')}</p>
        <strong>{I18n.t('forms.user.generateNewPassword.passwordGenerateModal.warningText')}</strong>
        <Divider />
        <div style={{ width: '100%' }}>
          <p style={{ paddingBottom: 0 }}>
            {I18n.t('forms.user.generateNewPassword.passwordGenerateModal.newPasswordText')}
          </p>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Input readOnly value={passwordGenerated} style={{ flexGrow: 1 }} />
          <AdvancedButton
            text={I18n.t('forms.user.generateNewPassword.passwordGenerateModal.copyPasswordText')}
            onClick={() =>
              navigator.clipboard.writeText(passwordGenerated) &&
              notification.success({
                message: I18n.t('forms.user.generateNewPassword.passwordGenerateModal.copiedPassword'),
              })
            }
          />
        </div>
      </Row>
    );
  };

  return (
    <Modal
      width="fit-content"
      className="drawer"
      title={I18n.t('forms.user.generateNewPassword.title')}
      onCancel={handleClose}
      open={visible}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {renderFooter()}
        </div>
      }
    >
      <Content>
        <Row className="Tags-form__preview">
          <Row className="Tags-form__row">{renderContent()}</Row>
        </Row>
      </Content>
    </Modal>
  );
}
export default GenerateNewPasswordModal;
