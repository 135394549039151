export const {
  REACT_APP_VAPID_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_CHAT_URL,
  REACT_APP_API_FILE_URL,
  REACT_APP_DEFAULT_BASIC_TOKEN,
  REACT_APP_PRIVACY_LINK,
  REACT_APP_TERMS_LINK,
  REACT_APP_TERMS_USER_LEGAL_LINK,
} = process.env;
