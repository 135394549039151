import moment from 'moment-timezone';

function humanizeDate(date, format) {
  try {
    if (date) {
      if (moment(date).isValid()) {
        date = moment(date).format(format || 'DD/MM/YYYY HH:mm:ss');
      }
    } else {
      date = '--';
    }
    return date;
  } catch (err) {
    // Empty
  }
}

function humanizeDateTime(date, format) {
  try {
    if (date) {
      if (moment(date).isValid()) {
        date = moment(date).format(format || 'DD/MM/YYYY HH:mm:ss');
      }
    } else {
      date = '--';
    }
    return date;
  } catch (err) {
    // Empty
  }
}

function getDuration(startTime, end) {
  try {
    const duration = moment.duration(moment(end).diff(moment(startTime)));
    return duration.asMinutes();
  } catch (err) {
    // Empty
  }
}

function verifyDateIsToday(startTime) {
  const startTimeDate = moment(startTime);
  const isToday = startTimeDate.isSame(new Date(), 'day');
  if (isToday) {
    return true;
  }
  return false;
}

export { humanizeDate, humanizeDateTime, getDuration, verifyDateIsToday };
