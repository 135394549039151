import React from 'react';
import { Translate } from 'react-redux-i18n';

export const PaymentTypes = {
  PIX: 'pix',
  BANK_SLIP: 'bank_slip',
  BANK_SLIP_INSTALLMENT: 'bank_slip_installment',
  CREDIT_CARD: 'credit_card',
};

export const PaymentTypesOptions = {
  PIX: 'Pix',
  BANK_SLIP: 'Boleto à vista',
  BANK_SLIP_INSTALLMENT: 'Boleto a prazo',
  CREDIT_CARD: 'Cartão de crédito',
};

export const GetPaymentTypesArray = [
  { id: PaymentTypes.PIX, name: <Translate value="enum.paymentType.pix" /> },
  { id: PaymentTypes.BANK_SLIP, name: <Translate value="enum.paymentType.bank_slip" /> },
  { id: PaymentTypes.BANK_SLIP_INSTALLMENT, name: <Translate value="enum.paymentType.bank_slip_installment" /> },
  { id: PaymentTypes.CREDIT_CARD, name: <Translate value="enum.paymentType.credit_card" /> },
];

export const GetPaymentTypeFilters = GetPaymentTypesArray.map((type) => ({
  value: type.id,
  text: type.name,
}));

export const GetPaymentTypesName = (value) => {
  return GetPaymentTypesArray.find((o) => o.id === value)?.name || '';
};
