import CardRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/card/cardRequestsV1';

export const ACTION_GET_INSTALLMENT_TAX = 'ACTION_GET_INSTALLMENT_TAX:card';
export const ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST = 'ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST:card';

export const getInstallmentTax = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST, payload: true });

    const payload = await CardRequestsV1.getInstallmentTax();
    dispatch({
      type: ACTION_GET_INSTALLMENT_TAX,
      payload,
    });
  } finally {
    dispatch({ type: ACTION_GET_INSTALLMENT_TAX_IS_ON_REQUEST, payload: false });
  }
};
