import React, { useCallback, useMemo, useState } from 'react';
import { Collapse, AutoComplete, Button, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { localeCompare } from '../../../../app/utils/string';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { calculateProductStock } from '../../../../app/utils/product-stock';
import AdvancedInput from '../../../shared/AdvancedInput/AdvancedInput';
import AdvancedTextArea from '../../../shared/AdvancedTextArea/AdvancedTextArea';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber/AdvancedInputNumber';
import { UserTypes } from '../../../../app/enum/user_types';
import * as PriceUtils from '../../../../app/utils/prices';

function InnerCollectionProductsForm({ products, form }) {
  const [productSearch, setProductSearch] = useState();

  const { provider: ProviderSelector, auth: authSelector, collection: CollectionSelectors } = useReduxState();

  const { Option } = AutoComplete;
  const { Panel } = Collapse;

  const loadingState = useMemo(
    () =>
      CollectionSelectors.isUpdateCollectionDetailOnRequest ||
      CollectionSelectors.isDeleteCollectionDetailOnRequest ||
      CollectionSelectors.isCreateCollectionOnRequest,
    [
      CollectionSelectors.isUpdateCollectionDetailOnRequest,
      CollectionSelectors.isDeleteCollectionDetailOnRequest,
      CollectionSelectors.isCreateCollectionOnRequest,
    ],
  );

  const getProductStock = useCallback(
    (productId) => {
      const productDetail =
        ProviderSelector.providerProducts.find((product) => Number(product.id) === Number(productId)) || 0;

      return calculateProductStock(productDetail?.units);
    },
    [ProviderSelector.providerProducts],
  );

  const onRemoveCollectionProduct = useCallback(
    async (productId) => {
      const collectionProducts = products.filter((o) => o.id !== productId);

      form.setForm({
        ...form.values,
        products: collectionProducts,
      });
    },
    [products, form],
  );

  const onAddCollectionProduct = useCallback(
    (productId) => {
      const newProduct = form.values?.products?.find((product) => Number(product.id) === Number(productId));

      if (newProduct) {
        return notification.error({
          message: I18n.t('forms.order.notifications.itemAlreadyAddedError.message'),
          description: I18n.t('forms.order.notifications.itemAlreadyAddedError.description'),
        });
      }

      const product = ProviderSelector.providerProducts.find((item) => Number(item.id) === Number(productId));

      form.setForm({
        ...form.values,
        products: [...form.values.products, product],
      });
    },
    [form, ProviderSelector.providerProducts],
  );

  const filterProviderProducts = (searchText) => {
    let productsWithStock = ProviderSelector.providerProducts?.map((item) => ({
      ...item,
      totalProductStock: calculateProductStock(item?.units),
    }));

    if (searchText) {
      productsWithStock = productsWithStock.filter((product) => localeCompare(product?.name, searchText));
    }

    return productsWithStock?.map(({ name, id: productId, totalProductStock }) => {
      const result = {
        value: productId.toString(),
      };

      result.label =
        totalProductStock > 0 ? name : `${I18n.t('forms.collection.items.searchProducts.unavailable')} ${name}`;

      return result;
    });
  };

  return (
    <>
      <div className="collection-form__products__searchInput">
        <div className="collection-form__products__searchInput__wrap">
          <span className="collection-form__products__searchInput__wrap__label">
            {I18n.t('forms.collection.items.searchProducts.label')}
          </span>
          <AutoComplete
            onSearch={(productSearchProp) => setProductSearch(productSearchProp)}
            onSelect={(productId) => onAddCollectionProduct(productId)}
            style={{ fontSize: 14 }}
            placeholder={I18n.t('forms.collection.items.searchProducts.placeholder')}
          >
            {filterProviderProducts(productSearch)?.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </AutoComplete>
          <div className="collection-form__products__searchInput__wrap__tip">
            {I18n.t('forms.collection.addedTipMessage')}
          </div>
        </div>
      </div>

      {products?.length > 0 && (
        <Collapse accordion>
          {products.map((product) => (
            <Panel header={`${product?.minimum} x ${product?.name}`} key={product?.id}>
              <AdvancedInput
                value={product?.id}
                disabled
                label={I18n.t('forms.collection.items.searchProducts.items.productId.label')}
              />

              {product?.code && (
                <AdvancedInput
                  value={product?.code}
                  disabled
                  label={I18n.t('forms.collection.items.searchProducts.items.code.label')}
                />
              )}

              <AdvancedTextArea
                value={product?.name}
                disabled
                label={I18n.t('forms.collection.items.searchProducts.items.productName.label')}
                rows={3}
              />
              <AdvancedInputNumber
                value={getProductStock(product?.id) || 0}
                label={I18n.t('forms.collection.items.searchProducts.items.stock.label')}
                disabled
              />

              <AdvancedInputNumber
                value={product?.minimum}
                label={I18n.t('forms.collection.items.searchProducts.items.quantity.label')}
                placeholder={I18n.t('shared.typeSomething')}
                min={product?.minimum}
                step={product?.multiplier}
                disabled
              />

              <AdvancedInputNumber
                value={PriceUtils.getPrice(PriceUtils.getItemSubtotal(product, product?.minimum))}
                disabled
                label={I18n.t('forms.collection.items.searchProducts.items.value.label')}
                formatter={(value) => PriceUtils.getPrice(value)}
              />

              <AdvancedInputNumber
                value={PriceUtils.getPrice(PriceUtils.getItemDiscount(product, product?.minimum))}
                disabled
                label={I18n.t('forms.collection.items.searchProducts.items.discount.label')}
                formatter={(value) => PriceUtils.getPrice(value)}
              />

              <AdvancedInputNumber
                value={PriceUtils.getPrice(
                  PriceUtils.getItemSubtotal(product, product?.minimum) -
                    PriceUtils.getItemDiscount(product, product?.minimum),
                )}
                disabled
                label={I18n.t('forms.collection.items.searchProducts.items.totalValue.label')}
                formatter={(value) => PriceUtils.getPrice(value)}
              />

              {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
                <>
                  <AdvancedInputNumber
                    value={PriceUtils.getPrice(PriceUtils.getItemTiffinTax(product, product?.minimum))}
                    disabled
                    label={I18n.t('forms.collection.items.searchProducts.items.taxTiffin.label')}
                    formatter={(value) => PriceUtils.getPrice(value)}
                  />

                  <AdvancedInputNumber
                    value={PriceUtils.getPrice(PriceUtils.getItemCommission({ product, quantity: product?.minimum }))}
                    disabled
                    label={I18n.t('forms.collection.items.searchProducts.items.commission.label')}
                    formatter={(value) => PriceUtils.getPrice(value)}
                  />
                </>
              )}

              <Button onClick={() => onRemoveCollectionProduct(product?.id)} danger loading={loadingState}>
                {I18n.t('forms.collection.items.searchProducts.items.removeOrderItems.label')}
              </Button>
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  );
}

export default InnerCollectionProductsForm;
