import jwt from 'jsonwebtoken';
import store from '../index';
import MetabaseParamTypes from '../../enum/metabase_param_types';

export const ACTION_GET_DASHBOARD_IS_ON_REQUEST = 'ACTION_GET_DASHBOARD_IS_ON_REQUEST:dashboard';
export const ACTION_GET_DASHBOARD = 'ACTION_GET_DASHBOARD:dashboard';

export const getDashboard = async (requestParams) => {
  const { access, auth } = store.store.getState();

  if (access.userAccessObtained) {
    const payload = {};
    const dashboards = access.userAccess.filter((o) => o.accessFeatureName.indexOf('dashboard_') >= 0) || [];
    const parsedAccessFeaturedId = parseInt(requestParams.accessFeatureId.split('-')[0], 10);

    if (dashboards?.length > 0 && auth?.user) {
      const currentDashboard = dashboards.find((o) => o.accessFeatureId === parsedAccessFeaturedId);
      const params = currentDashboard?.accessFeatureData.metabaseParams;

      if (params?.supportid === MetabaseParamTypes.USER_ID) {
        payload.supportid = auth.user.id.toString();
      } else if (requestParams?.supportId) {
        payload.supportid = requestParams.supportId.toString();
      }

      try {
        const METABASE_SITE_URL = 'https://tiffin.metabaseapp.com';
        const METABASE_SECRET_KEY = '29ca06fb43ecc9f27fe8169da735905542f2a22c1880a0e40c3bc87d9ffb22e0';
        const metabaseData = {
          resource: { dashboard: currentDashboard.accessFeatureData.metabaseDashboardId },
          params: payload || {},
          exp: Math.round(Date.now() / 1000) + 10 * 60,
        };
        const token = jwt.sign(metabaseData, METABASE_SECRET_KEY);
        return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false`;
      } catch (err) {
        // empty
      }
    }
  }
};
