import Immutable from 'seamless-immutable';
import { ACTION_GET_DASHBOARD, ACTION_GET_DASHBOARD_IS_ON_REQUEST } from '../actions/dashboard';

const initialState = Immutable({
  dashboard: null,
  dashboardIsOnRequest: false,
});

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_DASHBOARD:
      return state.merge({
        dashboard: action.payload,
      });
    case ACTION_GET_DASHBOARD_IS_ON_REQUEST:
      return state.merge({
        dashboardIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getDashboard(state) {
  return state.dashboard.dashboard;
}

export function getDashboardIsOnRequest(state) {
  return state.dashboard.dashboardIsOnRequest;
}
