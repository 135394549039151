import { Link, useParams } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Row, Popover, Collapse } from 'antd';
import { DashboardFilled, QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { useReduxState } from '../../../../app/hooks/useReduxState';
import { AccessFeatureActions, AccessGroupFeaturesActions, AccessGroupActions } from '../../../../app/redux/actions';
import { ACTION_GET_ACCESS_GROUP_BY_ID } from '../../../../app/redux/actions/accessGroup';
import { humanizeDate } from '../../../../app/utils/date';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedCheckbox from '../../../../components/shared/AdvancedCheckbox';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import PaginatedDatatable from '../../../../components/shared/PaginatedDatatable/PaginatedDatatable';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const { Content } = Layout;
const { Panel } = Collapse;

const defaultFields = {
  search: '',
  onlyAssociated: false,
};

function AccessGroupsFeatures() {
  const { accessGroupId } = useParams();
  const dispatch = useDispatch();
  const { accessFeature, accessGroupFeature, accessGroup } = useReduxState();
  const [advancedFilter, setAdvancedFilter] = useState(defaultFields);

  const getAccessGroupFeatures = () => {
    dispatch(
      AccessGroupFeaturesActions.getAccessGroupFeaturesPaginated({
        ...advancedFilter,
        accessGroupId,
        limit: Number.MAX_SAFE_INTEGER,
      }),
    );
  };

  useEffect(() => {
    if (accessGroupId) {
      dispatch(AccessGroupActions.getAccessGroupById(accessGroupId));
      getAccessGroupFeatures();
    }
  }, [accessGroupId]);

  const getAccessFeaturesPaginated = (page) => {
    dispatch(
      AccessFeatureActions.getAccessFeaturesPaginated({
        ...advancedFilter,
        page,
      }),
    );
  };

  const onSearchAdvancedFilters = () => {
    getAccessFeaturesPaginated(0);
    getAccessGroupFeatures();
  };

  useEffect(() => {
    if (accessGroupFeature.accessGroupsFeaturesPaginatedObtained) {
      getAccessFeaturesPaginated(0);
    }
  }, [accessGroupFeature.accessGroupsFeaturesPaginatedObtained]);

  const onSelectAccessFeature = ({ id }) => {
    const foundAccessFeature = accessGroupFeature.accessGroupsFeaturesPaginated?.rows?.find(
      (o) => o.accessFeatureId === id,
    );
    let request = AccessGroupFeaturesActions.createFeatureAndAccessGroupsAssociation;

    if (foundAccessFeature) {
      request = AccessGroupFeaturesActions.removeFeatureAndAccessGroupsAssociation;
    }

    dispatch(request({ accessGroupId, accessFeatureId: id, read: true }, () => getAccessGroupFeatures()));
  };

  const onChangeAdvancedFilters = (name, value) => {
    setAdvancedFilter((otherFields) => ({ ...otherFields, [name]: value }));
  };

  const cleanAdvancedFilters = () => {
    setAdvancedFilter(defaultFields);
  };

  const formatTablePayload = () => {
    if (!advancedFilter.onlyAssociated) {
      return accessFeature.accessFeaturesPaginated;
    }
    return {
      rows: accessGroupFeature.accessGroupsFeaturesPaginated?.rows?.map((o) => o.accessFeature),
    };
  };

  useEffect(() => {
    return () => dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID, payload: null });
  }, []);

  const getGroupFeatureData = (accessFeatureId) => {
    return accessGroupFeature.accessGroupsFeaturesPaginated?.rows?.find((o) => o.accessFeatureId === accessFeatureId);
  };

  const updateFeatureAccess = (accessFeatureId, key, value) => {
    let payload = {
      accessGroupId: parseInt(accessGroupId, 10),
      accessFeatureId: parseInt(accessFeatureId, 10),
      [key]: !!value,
    };

    if (!key) {
      payload = {
        ...payload,
        read: true,
        create: true,
        update: true,
        remove: true,
      };
    }

    dispatch(AccessGroupFeaturesActions.updateGroupAndAccessFeaturesAssociation(payload, getAccessGroupFeatures));
  };

  const onSelectMultiple = (_param, features) => {
    if (features && features.filter((o) => !!o).length) {
      dispatch(
        AccessGroupFeaturesActions.createFeatureAndAccessGroupsAssociationMultiple(
          { accessGroupId, accessFeatureIds: features.filter((o) => !!o).map((o) => o.id), read: true },
          getAccessGroupFeatures,
        ),
      );
    } else {
      dispatch(
        AccessGroupFeaturesActions.removeFeatureAndAccessGroupsAssociationMultiple(
          {
            accessGroupId,
            accessFeatureIds: formatTablePayload()
              ?.rows?.filter((o) => !!o)
              .map((o) => o.id),
          },
          getAccessGroupFeatures,
        ),
      );
    }
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={I18n.t('routes.panel.administration.accessGroups.url')}>
              <span>{I18n.t('routes.panel.administration.accessGroups.pageTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{accessGroup.accessGroupById?.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.accessGroupsFeatures.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.accessGroupsFeatures.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilter.search}
              onChange={(val) => onChangeAdvancedFilters('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.accessGroupsFeatures.advancedFilters.searchFieldTitle')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <AdvancedCheckbox
              label={I18n.t('routes.panel.administration.accessGroupsFeatures.advancedFilters.onlyAssociated.label')}
              onChange={(val) => onChangeAdvancedFilters('onlyAssociated', val)}
              value={advancedFilter.onlyAssociated}
            />
          </Row>
          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.accessGroupsFeatures.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.administration.accessGroupsFeatures.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onSearchAdvancedFilters}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {accessFeature.accessFeatureById?.name && (
          <div className="panel__layout__content__title">
            {I18n.t('routes.panel.administration.accessGroupsFeatures.tableTitle', {
              accessGroup: accessFeature.accessFeatureById?.name,
            })}
          </div>
        )}
        <PaginatedDatatable
          dataSource={formatTablePayload()}
          getMethod={getAccessFeaturesPaginated}
          onChangeSelection={onSelectAccessFeature}
          autoInitalize={false}
          loading={
            accessFeature.getAccessFeaturesPaginatedIsOnRequest ||
            accessGroupFeature.getAccessGroupsFeaturesPaginatedIsOnRequest
          }
          selectedIds={[
            ...(accessGroupFeature.accessGroupsFeaturesPaginated?.rows?.map((o) => o.accessFeature.id) || []),
          ]}
          onSelectAll={onSelectMultiple}
          pageSize={advancedFilter.onlyAssociated ? Number.MAX_SAFE_INTEGER : 10}
          disabled={!hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE])}
          columns={[
            {
              dataIndex: 'name',
              title: 'Nome',
              fixed: !isMobile ? 'left' : false,
              width: '35%',
            },
            {
              dataIndex: 'read',
              title: 'Leitura',
              align: 'center',
              width: '10%',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getGroupFeatureData(row.id)?.read || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'read', value)}
                  disabled={
                    !getGroupFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'create',
              title: 'Criação',
              align: 'center',
              width: '10%',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getGroupFeatureData(row.id)?.create || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'create', value)}
                  disabled={
                    !getGroupFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'update',
              title: 'Atualização',
              align: 'center',
              width: '10%',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getGroupFeatureData(row.id)?.update || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'update', value)}
                  disabled={
                    !getGroupFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'remove',
              title: 'Remoção',
              align: 'center',
              width: '10%',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getGroupFeatureData(row.id)?.remove || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'remove', value)}
                  disabled={
                    !getGroupFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'all',
              title: 'Todos',
              align: 'center',
              width: '10%',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={
                    getGroupFeatureData(row.id)?.read &&
                    getGroupFeatureData(row.id)?.create &&
                    getGroupFeatureData(row.id)?.update &&
                    getGroupFeatureData(row.id)?.remove
                  }
                  onChange={(value) => updateFeatureAccess(row.id, null, value)}
                  disabled={
                    !hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.UPDATE]) ||
                    !getGroupFeatureData(row.id) ||
                    (getGroupFeatureData(row.id)?.read &&
                      getGroupFeatureData(row.id)?.create &&
                      getGroupFeatureData(row.id)?.update &&
                      getGroupFeatureData(row.id)?.remove)
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'createdAt',
              title: 'Criação',
              width: '15%',
              render: (val) => humanizeDate(val, 'DD/MM/YYYY HH:mm'),
            },
          ]}
        />
      </Content>
    </>
  );
}

export default AccessGroupsFeatures;
