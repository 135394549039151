import React, { useEffect, useState } from 'react';
import { StarFilled } from '@ant-design/icons';

function RatingStars({ rating, visible }) {
  const ratingParsed = parseInt(rating, 10);
  const [stars, setStars] = useState();

  const generateStars = () => {
    const starArray = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= ratingParsed) {
        starArray.push(<StarFilled key={i} className="rating-stars__container__star--filled" />);
      } else {
        starArray.push(<StarFilled key={i} />);
      }
    }
    setStars(starArray);
  };

  useEffect(() => {
    generateStars();
  }, [visible]);

  return <div className="rating-stars__container">{stars}</div>;
}

export default RatingStars;
