import UploadRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/upload/uploadRequestsV1';

export const ACTION_CREATE_FILE_IS_ON_REQUEST = 'ACTION_CREATE_FILE_IS_ON_REQUEST:file';

export function createFile({ objectId, fileBase64, extension, options }) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_CREATE_FILE_IS_ON_REQUEST, payload: true });
      const { url } = await UploadRequestsV1.create({
        objectId,
        fileBase64,
        extension,
        options,
      });

      return url;
    } catch (err) {
      // empty
    } finally {
      dispatch({ type: ACTION_CREATE_FILE_IS_ON_REQUEST, payload: false });
    }
  };
}
