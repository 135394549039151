import SettingRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/setting/settingRequestsV1';

export const ACTION_GET_SETTINGS_IS_ON_REQUEST = 'ACTION_GET_SETTINGS_IS_ON_REQUEST:setting';
export const ACTION_GET_SETTINGS_DATA = 'ACTION_GET_SETTINGS_DATA:setting';

export function getSettings() {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_SETTINGS_IS_ON_REQUEST, payload: true });
      const settings = await SettingRequestsV1.getAll();
      dispatch({ type: ACTION_GET_SETTINGS_DATA, payload: settings });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_SETTINGS_IS_ON_REQUEST, payload: false });
    }
  };
}
