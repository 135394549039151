import React from 'react';
import { List, Popover, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import { FileTextOutlined, MoreOutlined } from '@ant-design/icons';

import { OrderActions } from '../../../../../app/redux/actions';
import { useReduxState } from '../../../../../app/hooks/useReduxState';
import AdvancedButton from '../../../../shared/AdvancedButton/AdvancedButton';

function OrderFilesForm({ files, type, orderId, onSetUploadModalVisible }) {
  const { order: orderSelector } = useReduxState();
  const dispatch = useDispatch();

  function fileSizeMetric(size) {
    if (size >= 1000000) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    if (size >= 1000) {
      return `${(size / 1024).toFixed(2)} KB`;
    }
    return `${size} Byte`;
  }

  async function onHandleDeleteOrderFile(fileId) {
    const result = await dispatch(OrderActions.deleteOrderFile(orderId, fileId));
    if (result) {
      notification.success({
        message: I18n.t('forms.order.invoices.inner.actions.deleteOrderFile.notifications.success.message'),
      });
    }
    await dispatch(OrderActions.getOrderFiles(orderId));
  }

  return (
    <>
      <div className="order-form__invoice-box__info">
        <span className="order-form__invoice-box__info__files-number">
          {files?.length} {I18n.t('forms.order.invoices.inner.filesNumber')}
        </span>

        <AdvancedButton
          text={I18n.t('forms.order.invoices.inner.modalButton')}
          onClick={() => onSetUploadModalVisible(type)}
        />
      </div>
      <List
        className="order-form__invoice-box__list-files"
        loading={
          orderSelector.isGetOrderFilesOnRequest
            ? orderSelector.isGetOrderFilesOnRequest
            : orderSelector.isDeleteOrderFileOnRequest
        }
        itemLayout="horizontal"
        dataSource={files}
        locale={{ emptyText: I18n.t('forms.order.invoices.inner.list.empty') }}
        renderItem={(file) => (
          <List.Item
            className="order-form__invoice-box__list-files__items"
            actions={[
              <Popover
                trigger="click"
                key={file.id}
                // eslint-disable-next-line react/no-unstable-nested-components
                content={() => (
                  <div>
                    <a
                      className="order-form__invoice-box__list-files__items__viewOrderFile"
                      href={file.fileUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <p>{I18n.t('forms.order.invoices.inner.actions.viewOrderFile')}</p>
                    </a>
                    <span
                      className="order-form__invoice-box__list-files__items__deleteOrderFile"
                      onClick={() => onHandleDeleteOrderFile(file.id)}
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex="0"
                    >
                      {I18n.t('forms.order.invoices.inner.actions.deleteOrderFile.popoverText')}
                    </span>
                  </div>
                )}
              >
                <span className="order-form__invoice-box__list-files__actions">
                  <MoreOutlined style={{ fontSize: '18px' }} />
                </span>
              </Popover>,
            ]}
          >
            <List.Item.Meta
              className="order-form__invoice-box__list-files__items__meta"
              avatar={<FileTextOutlined style={{ fontSize: '24px' }} />}
              title={
                <div className="order-form__invoice-box__list-files__items__meta__container">
                  <a
                    href={file.fileUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="order-form__invoice-box__list-files__items__meta__container__title"
                    data-filetype={file.fileUrl.slice(-12)}
                  >
                    {file.fileUrl}
                  </a>
                </div>
              }
              description={
                <div>
                  <span className="order-form__invoice-box__list-files__items__meta__description">
                    {moment(file.createdAt).format('DD/MM/YYYY hh:mm')} -{' '}
                    <span className="order-form__invoice-box__list-files__items__meta__description__file-type">
                      {fileSizeMetric(file.size)}
                    </span>
                  </span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default OrderFilesForm;
