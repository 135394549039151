import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)`
  background-color: black;
  color: #ffec00;
  border: none;

  :active {
    background-color: lighten(0.2, '000');
    color: #ffec00;
  }
  :focus {
    background-color: black;
    color: #ffec00;
  }
  :hover {
    background-color: black;
    color: #ffec00;
  }
`;
