import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Drawer, Typography, Upload, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import { MdClose } from 'react-icons/md';
import moment from 'moment-timezone';
import { useReduxState } from '../../../app/hooks/useReduxState';
import useForm from '../../../app/hooks/useForm';
import { ProviderActions } from '../../../app/redux/actions';
import { parseFormData } from '../../../app/utils/object';
import AdvancedInput from '../../shared/AdvancedInput/AdvancedInput';

const initialState = {
  certificatePassword: '',
  file: null,
};

function ProviderCertificateForm({ id, visible, onCloseForm, disabled }) {
  const { provider: ProviderSelectors } = useReduxState();
  const dispatch = useDispatch();

  const { Text, Title } = Typography;
  const { providerCertification, isCreateProviderCertificateIsOnRequest, isGetProviderCertificateIsOnRequest } =
    ProviderSelectors;

  const loadingState = useMemo(
    () => isCreateProviderCertificateIsOnRequest || isGetProviderCertificateIsOnRequest,
    [isCreateProviderCertificateIsOnRequest, isGetProviderCertificateIsOnRequest],
  );

  const form = useForm(initialState);

  const onHandleFileBeforeUpload = (file) => {
    form.handleInputChange('file', file);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const extension = reader.result.split('/')[1].split(';')[0];

      form.handleInputChange('reader', {
        ...file,
        name: file.name,
        extension,
        status: 'done',
        url: reader.result,
      });
    };
  };

  const submitCallback = useCallback(
    (error) => {
      if (error) {
        notification.error({
          message: error.mensagem || I18n.t('forms.providerCertificate.notifications.error.unknownError'),
        });
      } else {
        notification.success({
          message: I18n.t('forms.providerCertificate.notifications.success.createProviderCertification'),
        });
      }
      onCloseForm(true);
    },
    [onCloseForm],
  );

  const onSubmit = useCallback(async () => {
    if (!form.values.sequential) {
      return notification.error({
        message: I18n.t('forms.providerCertificate.notifications.error.sequential'),
      });
    }

    if (!form.values.serie) {
      return notification.error({
        message: I18n.t('forms.providerCertificate.notifications.error.serie'),
      });
    }

    if (!form.values.certificatePassword) {
      return notification.error({
        message: I18n.t('forms.providerCertificate.notifications.error.certificatePassword'),
      });
    }

    if (!form.values.file) {
      return notification.error({
        message: I18n.t('forms.providerCertificate.notifications.error.file'),
      });
    }

    const payload = parseFormData(form.values);

    await dispatch(ProviderActions.createProviderCertificate(id, payload, submitCallback));

    form.setForm(initialState);
  }, [form, id]);

  const handleInputChange = useCallback(
    (key, value) => {
      form.handleInputChange(key, value);
    },
    [form],
  );

  const getProviderCertificate = useCallback(async () => dispatch(ProviderActions.getProviderCertificate(id)), [id]);

  useEffect(() => {
    if (id && visible) {
      getProviderCertificate();
    }
  }, [id]);

  useEffect(() => {
    if (id && providerCertification) {
      form.setForm({ ...form.values, ...providerCertification.jsonData });
    }
  }, [providerCertification]);

  return (
    <Drawer
      maskClosable={false}
      visible={visible}
      className="drawer provider-certificate-form"
      title={I18n.t('forms.providerCertificate.title')}
      onClose={() => onCloseForm()}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div className="provider-certificate-form__footer">
          <Button type="link" onClick={onCloseForm} loading={loadingState}>
            {I18n.t('forms.providerCertificate.footerCancelButtonLabel')}
          </Button>

          <Button type="primary" onClick={onSubmit} loading={loadingState}>
            {I18n.t('forms.providerCertificate.footerButtonLabel')}
          </Button>
        </div>
      }
    >
      {!disabled && (
        <>
          <Title level={4}>{I18n.t('forms.providerCertificate.NFeTitle')}</Title>

          <AdvancedInput
            onChange={(value) => handleInputChange('serie', value)}
            label={I18n.t('forms.providerCertificate.fields.serie.label')}
            placeholder={I18n.t('shared.typeSomething')}
            disabled={disabled}
            value={form.values.serie}
          />

          <AdvancedInput
            onChange={(value) => handleInputChange('sequential', value)}
            label={I18n.t('forms.providerCertificate.fields.sequential.label')}
            placeholder={I18n.t('shared.typeSomething')}
            disabled={disabled}
            value={form.values.sequential}
          />

          <Title level={4}>{I18n.t('forms.providerCertificate.digitalCertificateTitle')}</Title>

          <Text className="provider-certificate-form__digital-certificate__description">
            {I18n.t('forms.providerCertificate.digitalCertificateDescription')}
          </Text>

          {!!providerCertification?.updatedAt && (
            <div className="provider-certificate-form__digital-certificate__sent">
              <Text className="provider-certificate-form__digital-certificate__sent__text">
                {`${I18n.t('forms.providerCertificate.certificateSent')} ${moment(
                  providerCertification?.updatedAt,
                ).format('DD/MM/YYYY HH:mm')}`}
              </Text>
            </div>
          )}

          {!!providerCertification?.jsonData?.certificateExpirationDate && (
            <div className="provider-certificate-form__digital-certificate__sent">
              <Text className="provider-certificate-form__digital-certificate__sent__text">
                {`${I18n.t('forms.providerCertificate.certificateExpiration')} ${
                  providerCertification?.jsonData?.certificateExpirationDate
                }`}
              </Text>
            </div>
          )}

          <AdvancedInput
            onChange={(value) => handleInputChange('certificatePassword', value)}
            label={I18n.t('forms.providerCertificate.fields.certificatePassword.label')}
            placeholder={I18n.t('shared.typeSomething')}
            disabled={disabled}
            value={form.values.certificatePassword}
            style={{ marginTop: '8px' }}
            isPassword
          />

          <div className="provider-certificate-form__digital-certificate__upload">
            <Upload
              accept=".pfx,.p12"
              maxCount={1}
              beforeUpload={onHandleFileBeforeUpload}
              fileList={form.values.reader ? [{ ...form.values.reader, uid: 1 }] : []}
              onRemove={() => {
                form.handleInputChange('file', undefined);
                form.handleInputChange('reader', undefined);
              }}
            >
              <div className="provider-certificate-form__digital-certificate__upload__content">
                <Button type="primary">{I18n.t('forms.providerCertificate.fields.upload.buttonText')}</Button>

                <Text className="provider-certificate-form__digital-certificate__upload__content__text">
                  {I18n.t('forms.providerCertificate.fields.upload.text')}
                </Text>
              </div>
            </Upload>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default React.memo(ProviderCertificateForm);
