import React, { useEffect } from 'react';
import { Collapse, Checkbox, Button, Modal } from 'antd';
import { I18n } from 'react-redux-i18n';
import { ExclamationCircleFilled } from '@ant-design/icons';
import AdvancedInput from '../../../../shared/AdvancedInput';
import AdvancedInputNumber from '../../../../shared/AdvancedInputNumber';
import AdvancedMaskedInput from '../../../../shared/AdvancedMaskedInput/AdvancedMaskedInput';
import * as PriceUtils from '../../../../../app/utils/prices';

const { confirm } = Modal;

function FreightOptionView({ options, order, onUpdateOrderValues, setFreightSelected, setCarrierOfferId, disabled }) {
  const { Panel } = Collapse;

  const onUpdateFreightOptionValues = ({ key, value }, option) => {
    const freightOptionsCopy = order.freightOptions;
    freightOptionsCopy[option.index] = { ...freightOptionsCopy[option.index], [key]: value };
    onUpdateOrderValues([{ key: 'freightOptions', value: freightOptionsCopy }]);
  };

  const onSelectFreight = (option) => {
    if (option.tax === null || option.freight === null || option.leadTime === null) {
      return confirm({
        title: I18n.t('forms.order.delivery.freightSelect.invalidPopup.title'),
        content: I18n.t('forms.order.delivery.freightSelect.invalidPopup.content'),
        icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
        okText: I18n.t('forms.order.delivery.freightSelect.invalidPopup.okText'),
        okType: 'danger',
        centered: true,
        cancelButtonProps: {
          style: {
            display: 'none',
          },
        },
      });
    }
    setFreightSelected(option);
    onUpdateOrderValues([
      { key: 'freightDescription', value: option.freightDescription },
      { key: 'freightQuoteId', value: option.freightQuoteId },
      { key: 'leadtime', value: option.leadtime },
      { key: 'tax', value: option.tax },
      { key: 'freight', value: option.freight },
    ]);
    if (option.carrierOfferId) {
      setCarrierOfferId(option.carrierOfferId);
    }
  };

  const onDeselectFreight = () => {
    setFreightSelected(null);
    setCarrierOfferId(null);
    onUpdateOrderValues([
      { key: 'leadtime', value: null },
      { key: 'tax', value: null },
      { key: 'freight', value: null },
    ]);
  };

  const isFreightSelected = (option) => {
    return (
      order.leadtime !== null &&
      order.tax !== null &&
      order.freight !== null &&
      Number(order.leadtime) === Number(option.leadtime) &&
      Number(order.tax) === Number(option.tax) &&
      Number(order.freight) === Number(option.freight)
    );
  };

  const onRemoveFreightOption = async (option) => {
    if (isFreightSelected(option)) onDeselectFreight();
    const freightOptionsCopy = order.freightOptions;
    freightOptionsCopy.splice(option.index, 1);
    onUpdateOrderValues([{ key: 'freightOptions', value: freightOptionsCopy }]);
  };

  useEffect(() => {
    options?.forEach((o) => {
      if (o.leadtime === null && order?.leadtime !== null) {
        onUpdateFreightOptionValues({ key: 'leadtime', value: order.leadtime }, o);
      }
      if (o.freight === null && order?.freight !== null) {
        onUpdateFreightOptionValues({ key: 'freight', value: order.freight }, o);
      }
      if (o.tax === null && order?.tax !== null) {
        onUpdateFreightOptionValues({ key: 'tax', value: order.tax }, o);
      }
    });
  }, []);

  return (
    <Collapse accordion>
      {options.map((option, index) => (
        <Panel
          header={I18n.t('forms.order.delivery.items.freightOptions.header.label', {
            option: index + 1,
          })}
          key={index.toString()}
          extra={
            <Checkbox
              checked={isFreightSelected(option)}
              onClick={(event) => event.stopPropagation()}
              onChange={() => (isFreightSelected(option) ? onDeselectFreight() : onSelectFreight(option))}
              disabled={disabled}
            />
          }
        >
          {option.freightDescription && (
            <AdvancedInput
              label={I18n.t('forms.order.delivery.items.freightDescription.label')}
              value={option.freightDescription}
              disabled
            />
          )}
          {option.freightQuoteId && (
            <AdvancedInput
              label={I18n.t('forms.order.delivery.items.freightQuoteId.label')}
              value={option.freightQuoteId}
              disabled
            />
          )}
          <AdvancedInputNumber
            label={I18n.t('forms.order.delivery.items.leadtime.label')}
            value={option.leadtime || order.leadtime}
            onChange={(value) => onUpdateFreightOptionValues({ key: 'leadtime', value }, option)}
          />
          <AdvancedMaskedInput
            options={{
              unit: 'R$ ',
            }}
            placeholder={I18n.t('shared.typeSomething')}
            label={I18n.t('forms.order.delivery.items.freightValue.label')}
            value={option.freight !== null ? Number(option.freight).toFixed(2) : order.freight}
            kind="money"
            onChange={(value) =>
              onUpdateFreightOptionValues({ key: 'freight', value: PriceUtils.cleanValue(value) }, option)
            }
          />
          <AdvancedMaskedInput
            options={{
              unit: 'R$ ',
            }}
            placeholder={I18n.t('shared.typeSomething')}
            label={I18n.t('forms.order.delivery.items.taxes.label')}
            value={option.tax !== null ? Number(option.tax).toFixed(2) : order.tax}
            kind="money"
            onChange={(value) =>
              onUpdateFreightOptionValues({ key: 'tax', value: PriceUtils.cleanValue(value) }, option)
            }
          />
          {!disabled && !option.freightQuoteId && (
            <Button style={{ marginTop: 10 }} danger onClick={() => onRemoveFreightOption(option)}>
              {I18n.t('forms.order.delivery.items.removeFreight.label')}
            </Button>
          )}
        </Panel>
      ))}
    </Collapse>
  );
}

export default FreightOptionView;
