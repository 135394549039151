import { Modal } from 'antd';
import React from 'react';

function AdvancedModal({
  style,
  closable = true,
  title,
  visible,
  children,
  onCloseModal,
  footer,
  label,
  bodyStyle,
  width = '700px',
  okText,
  onOk,
  confirmLoading,
}) {
  return (
    <Modal
      className="advanced-modal"
      closable={closable}
      style={style}
      title={title}
      open={visible}
      footer={closable ? footer : null}
      onCancel={onCloseModal}
      onOk={onOk}
      bodyStyle={bodyStyle}
      width={width}
      okText={okText}
      confirmLoading={confirmLoading}
    >
      {label && (
        <div className="advanced-modal__label">
          <span className="advanced-modal__label__inner">{label}</span>
        </div>
      )}
      {children}
    </Modal>
  );
}

export default AdvancedModal;
