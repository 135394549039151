import React from 'react';
import { Collapse, Button } from 'antd';
import { I18n } from 'react-redux-i18n';

import { useDispatch } from 'react-redux';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { getLocale } from '../../../../app/redux/actions/locale';

import AdvancedSelect from '../../../shared/AdvancedSelect';
import AdvancedInput from '../../../shared/AdvancedInput';
import AdvancedTextArea from '../../../shared/AdvancedTextArea';
import FreightOptionView from './components/FreightOptionView';
import AdvancedMaskedInput from '../../../shared/AdvancedMaskedInput';

import * as PriceUtils from '../../../../app/utils/prices';
import * as OrderStatus from '../../../../app/enum/order_status';
import * as OrderDeliveryTypes from '../../../../app/enum/order_delivery_types';
import { UserTypes } from '../../../../app/enum/user_types';
import LocaleSearchTypes from '../../../../app/enum/locale_search_types';

function InnerDeliveryOrderForm({
  order,
  onUpdateOrderValues,
  freightSelected,
  setFreightSelected,
  setCarrierOfferId,
  disabled,
}) {
  const dispatch = useDispatch();
  const { auth: authSelector, locale: localeSelector, distributor: distributorSelector } = useReduxState();

  const { Panel } = Collapse;

  const onParseJsonAddress = (updateData) => {
    const jsonAddressCopy = { ...order.jsonAddress };
    updateData.forEach(({ key, value }) => {
      jsonAddressCopy[key] = value;
    });
    onUpdateOrderValues([{ key: 'jsonAddress', value: jsonAddressCopy }]);
  };

  const onSearchPostalCode = async (postalCode) => {
    if (postalCode.length === 9) {
      onParseJsonAddress([{ key: 'postalCode', value: postalCode }]);
      const locale = await dispatch(getLocale({ type: LocaleSearchTypes.ADDRESS, postalCode }));
      if (locale) {
        return onParseJsonAddress([
          { key: 'street', value: locale.street },
          { key: 'district', value: locale.district },
          { key: 'postalCode', value: postalCode },
          { key: 'city', value: locale.city },
          { key: 'state', value: locale.state.short },
          { key: 'country', value: locale.country.long },
        ]);
      }
    }
    onParseJsonAddress([{ key: 'postalCode', value: postalCode }]);
  };

  const onAddFreightOption = async () => {
    const option = {
      leadtime: order?.leadtime || null,
      freight: order?.freight || null,
      tax: order?.tax || null,
      index: order?.freightOptions ? order.freightOptions.length + 1 : 1,
    };

    if (order?.freightOptions) {
      order?.freightOptions.push(option);
    }

    onUpdateOrderValues([
      { key: 'freightOptions', value: order?.freightOptions || [option] },
      { key: 'status', value: OrderStatus.OrderStatus.WAITING_FREIGHT_CONFIRMATION },
    ]);
  };

  const providerFreightOptions = order?.freightOptions
    ?.map((o, index) => ({ ...o, index }))
    .filter((o) => !o.freightQuoteId);

  const integrationFreightOptions = order?.freightOptions
    ?.map((o, index) => ({ ...o, index }))
    .filter((o) => o.freightQuoteId);

  return (
    <>
      <AdvancedSelect
        value={order?.deliveryType}
        options={OrderDeliveryTypes.GetOrderDeliveryTypeArray}
        disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) || disabled}
        label={I18n.t('forms.order.delivery.items.deliveryType.label')}
        placeholder={I18n.t('shared.selectSomeValue')}
        onChange={(value) => onUpdateOrderValues([{ key: 'deliveryType', value }])}
      />

      {order?.address && !order?.jsonAddress && (
        <AdvancedTextArea
          value={order?.address}
          label={I18n.t('forms.order.delivery.items.address.label')}
          placeholder={I18n.t('shared.typeSomething')}
          onChange={(value) => onUpdateOrderValues([{ key: 'address', value }])}
          disabled
        />
      )}

      <Collapse accordion>
        <Panel header={I18n.t('forms.order.delivery.items.address.label')} key={0}>
          <AdvancedMaskedInput
            value={order?.jsonAddress?.postalCode}
            label={I18n.t('forms.order.delivery.items.postalCode.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onSearchPostalCode(value)}
            kind="zip-code"
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.street}
            label={I18n.t('forms.order.delivery.items.street.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'street', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.district}
            label={I18n.t('forms.order.delivery.items.district.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'district', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.number}
            label={I18n.t('forms.order.delivery.items.number.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'number', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.complement}
            label={I18n.t('forms.order.delivery.items.complement.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'complement', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.city}
            label={I18n.t('forms.order.delivery.items.city.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'city', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.state}
            label={I18n.t('forms.order.delivery.items.state.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'state', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
          <AdvancedInput
            value={order?.jsonAddress?.country}
            label={I18n.t('forms.order.delivery.items.country.label')}
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(value) => onParseJsonAddress([{ key: 'country', value }])}
            disabled={localeSelector.localeIsOnRequest || disabled}
          />
        </Panel>
      </Collapse>

      {order?.deliveryType !== OrderDeliveryTypes.OrderDeliveryType.ON_PROVIDER && (
        <div style={{ marginTop: 15 }}>
          {order?.freight !== null && order?.tax !== null && order?.leadtime !== null && (
            <AdvancedTextArea
              placeholder={I18n.t('shared.typeSomething')}
              label={I18n.t('forms.order.delivery.items.freight.label')}
              disabled
              autoSize
              value={I18n.t('forms.order.delivery.items.freight.values', {
                value: PriceUtils.getPrice(order?.freight),
                taxes: PriceUtils.getPrice(order?.tax),
                leadTime: order?.leadtime,
              })}
            />
          )}
          <AdvancedInput
            placeholder={I18n.t('shared.typeSomething')}
            label={I18n.t('forms.order.delivery.freightType.integration.distributor.deliveryCodeLabel')}
            autoSize
            value={order?.deliveryCode}
            onChange={(value) => onUpdateOrderValues([{ key: 'deliveryCode', value }])}
          />

          <AdvancedSelect
            value={order?.distributorId}
            options={distributorSelector.distributors}
            label={I18n.t('forms.order.delivery.freightType.integration.distributor.name')}
            placeholder={I18n.t('forms.order.delivery.freightType.integration.distributor.nameUndefined')}
            onChange={(value) => onUpdateOrderValues([{ key: 'distributorId', value }])}
          />

          <Collapse accordion>
            <>
              {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) &&
                integrationFreightOptions?.length && (
                  <Panel
                    header={I18n.t('forms.order.delivery.freightType.integration.header.label')}
                    key="0"
                    extra={
                      <span>
                        {I18n.t('forms.order.delivery.freightType.integration.header.extra')}{' '}
                        {order?.freightQuoteId || '--'}
                      </span>
                    }
                  >
                    <FreightOptionView
                      options={integrationFreightOptions}
                      order={order}
                      freightSelected={freightSelected}
                      setFreightSelected={setFreightSelected}
                      onUpdateOrderValues={onUpdateOrderValues}
                      setCarrierOfferId={setCarrierOfferId}
                    />
                  </Panel>
                )}

              <Panel key="1" header={I18n.t('forms.order.delivery.freightType.provider.header.label')}>
                {providerFreightOptions && !!providerFreightOptions.length && (
                  <FreightOptionView
                    options={providerFreightOptions}
                    order={order}
                    freightSelected={freightSelected}
                    setFreightSelected={setFreightSelected}
                    onUpdateOrderValues={onUpdateOrderValues}
                    setCarrierOfferId={setCarrierOfferId}
                  />
                )}

                {(!order?.freightOptions || order?.freightOptions?.filter((o) => !o.freightQuoteId).length < 4) && (
                  <Button
                    style={{ marginTop: providerFreightOptions && !!providerFreightOptions.length ? 15 : 0 }}
                    type="primary"
                    onClick={() => onAddFreightOption()}
                  >
                    {I18n.t('forms.order.delivery.items.addFreight.label')}
                  </Button>
                )}
              </Panel>
            </>
          </Collapse>
        </div>
      )}
    </>
  );
}

export default InnerDeliveryOrderForm;
