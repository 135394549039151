export const OrderLogStatusTypes = {
  APPROVED: 'approved',
  READY_DELIVERY: 'ready_delivery',
  ON_CARRIAGE: 'on_carriage',
  ON_CARRIAGE_EXTRA: 'on_carriage_extra',
  ON_CARRIAGE_EXTRA_DELIVERED: 'DELIVERED',
  ON_CARRIAGE_EXTRA_IN_TRANSIT: 'IN_TRANSIT',
  ON_CARRIAGE_EXTRA_TO_BE_DELIVERED: 'TO_BE_DELIVERED',
  ON_CARRIAGE_EXTRA_SHIPPED: 'SHIPPED',
};

export const ShipmentTypes = {
  WAITING_INVOICE: 'Aguardando faturar',
  CREATED: 'Aguardando pronto para envio',
  READY_DELIVERY: 'Aguardando coleta',
  ON_CARRIAGE: 'Despachado',
  IN_TRANSIT: 'Em trânsito',
  TO_BE_DELIVERED: 'Saiu para entrega',
  DELIVERED: 'Entregue',
  CANCELED: 'Cancelado',
  DEFAULT: 'Não identificado',
};
