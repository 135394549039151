import React, { useState, useRef, useEffect } from 'react';
import { Breadcrumb, Collapse, Row, Layout, Select, Dropdown, Menu, Tag, Tooltip } from 'antd';
import { SearchOutlined, DashboardFilled, MoreOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { ProviderSelectors, AuthSelectors } from '../../../app/redux/reducers';
import { ProviderActions } from '../../../app/redux/actions';
import * as DateUtils from '../../../app/utils/date';
import ProviderRatingDetailsModal from '../../../components/provider/modal/providerRatingDetailsModal/ProviderRatingDetailsModal';
import RatingStars from '../../../components/shared/RatingStars/RatingStars';

function ProviderRatings({
  isGetProviderRatingsIsOnRequest,
  getProviderRatings,
  onGetProviderRatings,
  getMyProviders,
}) {
  const [providerId, setProviderId] = useState(getMyProviders[0].id);
  const [visibleModal, setVisibleModal] = useState();
  const [providerRatingSelected, setProviderRatingSelected] = useState();

  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Option } = Select;

  const dataTableRef = useRef();

  const onCancel = () => {
    setVisibleModal(false);
  };

  useEffect(() => {
    onGetProviderRatings({ providerId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDetailsModal = async (providerRatingId) => {
    await setProviderRatingSelected(getProviderRatings?.rows?.find((o) => o.id === providerRatingId));
    setVisibleModal(true);
  };
  const onActionsClick = (providerRatingId, item, key) => {
    switch (key) {
      case '1':
        openDetailsModal(providerRatingId);
        break;
      default:
    }
  };

  const handleCleanButton = () => {
    setProviderId(getMyProviders[0].id);
    dataTableRef.current.reset();
  };

  const cleanFilters = () => {
    dataTableRef.current.reset();
  };

  const onOk = () => {
    cleanFilters();
    onGetProviderRatings({ providerId });
    setVisibleModal(false);
  };

  const onHandleSearch = () => {
    cleanFilters();
    onGetProviderRatings({ providerId });
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.providerRatings.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel header={<strong>{I18n.t('routes.panel.providers.advancedFilters.title')}</strong>} key="1">
          <Row align="middle">
            <AdvancedSelect
              selectStyle={{ width: 240 }}
              value={providerId}
              onChange={(id) => setProviderId(id)}
              placeholder={I18n.t('shared.selectSomeValue')}
              showSearch
              options={getMyProviders.map((provider) => ({
                id: provider.id,
                name: provider.name,
              }))}
            />
          </Row>

          <Row>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.providers.advancedFilters.clearButtonText')}
              onClick={handleCleanButton}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.providers.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onHandleSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        <AdvancedDataTable
          ref={dataTableRef}
          refresh={(val) => onGetProviderRatings({ providerId, ...val })}
          getMethod={(val) => onGetProviderRatings({ providerId, ...val })}
          data={getProviderRatings}
          loading={isGetProviderRatingsIsOnRequest}
          container="providerRatings"
          columns={[
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.id.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.id.title'),
              fixed: 'left',
              width: 30,
              render: (value) => value || '--',
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.orderId.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.orderId.title'),
              fixed: 'left',
              width: 30,
              render: (value) => value || '--',
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.userName.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.userName.title'),
              width: 80,
              render: (value) => value || '--',
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.rating.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.rating.title'),
              width: 50,
              render: (value) => <RatingStars rating={parseInt(value, 10)} visible /> || '--',
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.comment.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.comment.title'),
              width: 100,
              ellipsis: {
                showTitle: false,
              },
              render: (value) => (
                <Tooltip placement="topLeft" title={value || '--'}>
                  {value || '--'}
                </Tooltip>
              ),
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.createdAt.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.createdAt.title'),
              width: 60,
              render: (value) => DateUtils?.humanizeDate(value) || '--',
            },
            {
              key: I18n.t('routes.panel.providerRatings.dataTable.columns.repliedAt.key'),
              title: I18n.t('routes.panel.providerRatings.dataTable.columns.repliedAt.title'),
              width: 60,
              render: (value) => DateUtils?.humanizeDate(value) || '--',
            },
            {
              key: I18n.t('routes.panel.providers.dataTable.columns.actions.key'),
              title: I18n.t('routes.panel.providers.dataTable.columns.actions.title'),
              fixed: 'right',
              width: 20,
              render: (providerRatingId) => (
                <Dropdown
                  overlay={
                    <Menu onClick={({ item, key }) => onActionsClick(providerRatingId, item, key)}>
                      <Menu.Item key="1">
                        {I18n.t('routes.panel.providerRatings.dataTable.columns.actions.goToDetailsText')}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className="ant-dropdown-link">
                    <MoreOutlined style={{ fontSize: 20 }} />
                  </div>
                </Dropdown>
              ),
            },
          ]}
        />
      </Content>
      <ProviderRatingDetailsModal
        visible={visibleModal}
        data={providerRatingSelected}
        onOk={onOk}
        onCancel={onCancel}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  isGetProviderRatingsIsOnRequest: ProviderSelectors.isGetProviderRatingsIsOnRequest(state),
  getProviderRatings: ProviderSelectors.getProviderRatings(state),
  getMyProviders: AuthSelectors.getProviders(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProviderRatings: (params, callback) => dispatch(ProviderActions.getProviderRatings(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderRatings);
