import Immutable from 'seamless-immutable';

import { AuthActions } from '../actions';

const initialState = Immutable({
  isLogInOnRequest: false,
  isAuthenticated: false,
  user: null,
  providers: null,
});

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.ACTION_LOG_IN_IS_ON_REQUEST:
      return state.merge({
        isLogInOnRequest: action.payload,
      });
    case AuthActions.ACTION_USER_DETAIL:
      return state.merge({
        user: action.payload.user,
        providers: action.payload.providers,
        isAuthenticated: true,
      });
    default:
      return state;
  }
};

export function isLogInOnRequest(state) {
  return state.auth.isLogInOnRequest;
}

export function isAuthenticated(state) {
  return state.auth.isAuthenticated;
}

export function getUser(state) {
  return state.auth.user;
}

export function getUserId(state) {
  return getUser(state) && getUser(state).id;
}

export function getUserType(state) {
  return getUser(state) && getUser(state).type;
}

export function getProviders(state) {
  let sortProviders = [];
  if (state.auth?.providers && state.auth?.providers.length > 0) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    sortProviders = [...state.auth?.providers].sort((a, b) => {
      if (a.name.localeCompare(b.name) < 0) {
        return -1;
      }
      if (a.name.localeCompare(b.name) > 0) {
        return 1;
      }
      return 0;
    });
  }
  return sortProviders;
}
