/* eslint-disable max-len */
import React from 'react';
import { Avatar, Button, InputNumber, List } from 'antd';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import { getPrice } from '../../../../../app/utils/prices';

function ProductsAmountSelectionList({
  order,
  onChange,
  onRemoveOrderItems,
  getCorrectItemStock,
  updateProductsQuantity,
}) {
  return (
    <div className="order-form__products-list">
      <List
        itemLayout="horizontal"
        dataSource={order.items}
        renderItem={(item, index) => (
          <div>
            {!!updateProductsQuantity.find((product) => Number(product.id) === Number(item?.productId)) && (
              <List.Item className="order-form__products-list__item">
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={64}
                      src={
                        item?.product?.images?.length > 0
                          ? item?.product?.images[0]?.imageUrl
                          : '/assets/img/img_unavailable.jpg'
                      }
                    />
                  }
                  title={<span className="order-form__products-list__item__title">{item?.product?.name}</span>}
                  description={
                    <div className="order-form__products-list__item__product-info">
                      <div className="order-form__products-list__item__product-info__container">
                        <div className="order-form__products-list__item__product-info__container__stock">
                          <span className="order-form__products-list__item__product-info__container__stock__label">
                            {I18n.t('forms.order.product.advancedModal.stockUpdate.items.stock.label')}
                          </span>

                          <div className="order-form__products-list__item__product-info__container__stock__stock-value">
                            {`${getCorrectItemStock(item.productId) || 0} ${item?.product?.unit?.name}(S)`}
                          </div>
                        </div>
                        <div className="order-form__products-list__item__product-info__container__multiple">
                          <span className="order-form__products-list__item__product-info__container__multiple__label">
                            {`${I18n.t('forms.order.product.advancedModal.stockUpdate.items.multiplier.label')}: `}
                          </span>
                          <span className="order-form__products-list__item__product-info__container__multiple__value">
                            {`x${item?.product?.multiplier}`}
                          </span>

                          <div>
                            <span className="order-form__products-list__item__product-info__container__minimum__label">
                              {`${I18n.t('forms.order.product.advancedModal.stockUpdate.items.minimum.label')}: `}
                            </span>
                            <span className="order-form__products-list__item__product-info__container__minimum__value">
                              {`${item?.product?.minimum} ${item?.product?.unit?.name}(S)`}
                            </span>
                          </div>
                        </div>
                        <div className="order-form__products-list__item__product-info__container__discount">
                          <div>
                            <span className="order-form__products-list__item__product-info__container__discount__label">
                              {`${I18n.t('forms.order.product.advancedModal.stockUpdate.items.discount.label')}: `}
                            </span>
                            <span className="order-form__products-list__item__product-info__container__discount__value">
                              {`${getPrice(item?.discount)}`}
                            </span>
                          </div>

                          <div>
                            <span className="order-form__products-list__item__product-info__container__order-value__label">
                              {`(${item?.quantity}x ${getPrice(item?.product?.prices[0]?.value)}): `}{' '}
                            </span>
                            <span className="order-form__products-list__item__product-info__container__order-value__value">
                              {`${getPrice(item?.value)}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
                <div className="order-form__products-list__action">
                  <div className="order-form__products-list__action__button">
                    <Button
                      type="text"
                      onClick={() =>
                        onChange({
                          item,
                          quantity: Number(item?.quantity) - Number(item?.product?.multiplier),
                        })
                      }
                      className="order-form__products-list__action__button__remove"
                      disabled={getCorrectItemStock(item.productId) === 0}
                    >
                      -
                    </Button>

                    <InputNumber
                      className={classNames({
                        'order-form__products-list__action__input': true,
                        'order-form__products-list__action__input--warning':
                          Number(item?.quantity) > getCorrectItemStock(item.productId),
                      })}
                      onChange={(quantity) => onChange({ item, quantity: Number(quantity) })}
                      value={Number(item?.quantity)}
                      min={1}
                      disabled={getCorrectItemStock(item.productId) === 0}
                    />

                    <Button
                      type="text"
                      onClick={() =>
                        onChange({
                          item,
                          quantity: Number(item?.quantity) + Number(item?.product?.multiplier),
                        })
                      }
                      className="order-form__products-list__action__button__add"
                      disabled={getCorrectItemStock(item.productId) === 0}
                    >
                      +
                    </Button>
                  </div>
                  <div className="order-form__products-list__action__full-stock">
                    <button
                      type="button"
                      className="order-form__products-list__action__full-stock__button"
                      onClick={() =>
                        getCorrectItemStock(item.productId) > 0
                          ? onChange({
                              item,
                              quantity: Number(getCorrectItemStock(item.productId)),
                            })
                          : onRemoveOrderItems(index)
                      }
                    >
                      {getCorrectItemStock(item.productId) > 0 ? (
                        <span>{`${I18n.t(
                          'forms.order.product.advancedModal.stockUpdate.action.fullButton.text',
                        )} ${getCorrectItemStock(item.productId)} ${item?.product?.unit?.name}(S)`}</span>
                      ) : (
                        <span>{I18n.t('forms.order.product.advancedModal.stockUpdate.action.removeButton.text')}</span>
                      )}
                    </button>
                  </div>
                </div>
              </List.Item>
            )}
          </div>
        )}
      />
    </div>
  );
}

export default ProductsAmountSelectionList;
