import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { Modal, Col, Row, notification, Spin, Tabs } from 'antd';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { AdvancedDropzone } from 'tiffin-foods-chat-component/lib/cjs/components';
import { useReduxState } from '../../../app/hooks/useReduxState';
import AdvancedButton from '../../shared/AdvancedButton/AdvancedButton';
import { HighlightActions } from '../../../app/redux/actions';
import AdvancedPreviewHighlight from '../AdvancedPreviewHighlight/AdvancedPreviewHighlight';
import AdvancedInput from '../../shared/AdvancedInput/AdvancedInput';
import useForm from '../../../app/hooks/useForm';
import { ViewTypes } from '../../../app/enum/view_types';
import AdvancedInputNumber from '../../shared/AdvancedInputNumber';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';

const getInitialState = () => ({
  title: null,
  subtitle: null,
  imageUrl: null,
  imageMobileUrl: null,
  position: null,
});

function HighlightsModal({ id, type, onCloseForm, visible }) {
  const dispatch = useDispatch();
  const { highlight: highlightSelector } = useReduxState();
  const form = useForm(getInitialState());

  const onHandleRefresh = () => {
    form.setForm(getInitialState());
    onCloseForm();
  };

  const onSubmitHighlight = async () => {
    const { title, subtitle, position, imageUrl, imageMobileUrl } = form.values;

    if (!title || (type === ViewTypes.CREATE && !imageUrl && !imageMobileUrl)) {
      return notification.error({
        message: I18n.t('routes.panel.administration.highlights.highlightsModal.messages.requiredFields'),
      });
    }

    const payload = {
      title,
      subtitle: subtitle || null,
      position,
      imageUrl,
      imageMobileUrl,
    };

    let response;

    if (type === ViewTypes.CREATE) {
      response = await dispatch(HighlightActions.createHighlights(payload));
    } else if (type === ViewTypes.EDIT) {
      response = await dispatch(HighlightActions.updateHighlight(id, payload));
    }

    if (response) {
      notification.success({
        message: I18n.t(
          type === ViewTypes.EDIT
            ? 'routes.panel.administration.highlights.highlightsModal.messages.updateSuccess'
            : 'routes.panel.administration.highlights.highlightsModal.messages.createSuccess',
        ),
      });
      form.setForm(getInitialState());
      onCloseForm();
    }
  };

  useEffect(() => {
    const getHighlightData = async () => {
      const highlight = await dispatch(HighlightActions.getHighlightById(id));
      form.setForm({ ...highlight });
    };

    if (visible) {
      getHighlightData();
    }
  }, [visible]);

  const disabled =
    type === ViewTypes.CREATE
      ? !hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.CREATE])
      : !hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.UPDATE]);

  return (
    <Modal
      className="highlights-modal"
      title={
        type === ViewTypes.CREATE
          ? I18n.t('routes.panel.administration.highlights.highlightsModal.createTitle')
          : I18n.t('routes.panel.administration.highlights.highlightsModal.updateTitle')
      }
      onCancel={onHandleRefresh}
      open={visible}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div className="highlights-modal__footer">
          <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={onHandleRefresh} />
          <AdvancedButton
            loading={
              type === ViewTypes.CREATE
                ? highlightSelector.isCreateHighlightsOnRequest
                : highlightSelector.isUpdateHighlightOnRequest
            }
            text={
              type === ViewTypes.CREATE
                ? I18n.t('routes.panel.administration.highlights.highlightsModal.createSubmit')
                : I18n.t('routes.panel.administration.highlights.highlightsModal.updateSubmit')
            }
            onClick={onSubmitHighlight}
            disabled={disabled}
          />
        </div>
      }
    >
      <div className="highlights-modal__form">
        {(highlightSelector.isCreateHighlightsOnRequest ||
          highlightSelector.isUpdateHighlightOnRequest ||
          highlightSelector.isGetHighlightByIdOnRequest) && (
          <div className="highlights-modal__form__loading">
            <Spin />
          </div>
        )}
        <Row>
          <Col span={24}>
            <AdvancedInput
              onChange={(value) => form.handleInputChange('title', value)}
              className="highlights-modal__form__input"
              placeholder={I18n.t('routes.panel.administration.highlights.highlightsModal.defaultValues.title')}
              value={form.values.title}
              maxLength={70}
              label={I18n.t('routes.panel.administration.highlights.highlightsModal.inputLabel.title')}
              required
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AdvancedInput
              onChange={(value) => form.handleInputChange('subtitle', value)}
              className="highlights-modal__form__input"
              placeholder={I18n.t('routes.panel.administration.highlights.highlightsModal.defaultValues.subtitle')}
              value={form.values.subtitle}
              maxLength={98}
              label={I18n.t('routes.panel.administration.highlights.highlightsModal.inputLabel.subtitle')}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AdvancedInputNumber
              onChange={(value) => form.handleInputChange('position', value)}
              className="highlights-modal__form__input"
              placeholder={I18n.t('routes.panel.administration.highlights.highlightsModal.defaultValues.position')}
              value={form.values.position && parseInt(form.values.position, 10)}
              label={I18n.t('routes.panel.administration.highlights.highlightsModal.inputLabel.position')}
              disabled={disabled}
            />
          </Col>
        </Row>
        {!disabled && (
          <Tabs>
            <Tabs.TabPane tab="Web" key="1">
              <Row>
                <Col span={24}>
                  <div className="highlights-modal__form__dropzone">
                    <AdvancedDropzone
                      onSingleFileUpload={(fileUrl) => form.handleInputChange('imageUrl', fileUrl)}
                      maximumFileAmount={1}
                      allowedFileExtensions={['jpg', 'png', 'gif', 'jpeg']}
                      autoUploadOnFileChange
                      customIcon={<div />}
                      customTitle={I18n.t(
                        'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileImageTitle',
                      )}
                      customSubtitle={I18n.t(
                        'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileImageDescription',
                      )}
                      customBottom={
                        <div className="highlights-modal__form__dropzone__bottom">
                          {I18n.t(
                            // eslint-disable-next-line max-len
                            'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileButtonDescription',
                          )}
                        </div>
                      }
                      options={{
                        filePrefix: 'highlight',
                        bucketFile: 'tiffin-bucket/highlights',
                        namePrefix: 'file-highlight',
                        metadataCustom: 'metadata',
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <AdvancedPreviewHighlight
                title={form.values.title}
                subtitle={form.values.subtitle}
                imageUrl={form.values.imageUrl}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Mobile/Responsivo" key="2">
              <Row>
                <Col span={24}>
                  <div className="highlights-modal__form__dropzone">
                    <AdvancedDropzone
                      onSingleFileUpload={(fileUrl) => form.handleInputChange('imageMobileUrl', fileUrl)}
                      maximumFileAmount={1}
                      allowedFileExtensions={['jpg', 'png', 'gif', 'jpeg']}
                      autoUploadOnFileChange
                      customIcon={<div />}
                      customTitle={I18n.t(
                        'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileImageTitle',
                      )}
                      customSubtitle={I18n.t(
                        'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileImageDescription',
                      )}
                      customBottom={
                        <div className="highlights-modal__form__dropzone__bottom">
                          {I18n.t(
                            // eslint-disable-next-line max-len
                            'routes.panel.administration.highlights.highlightsModal.uploadImage.selectFileButtonDescription',
                          )}
                        </div>
                      }
                      options={{
                        filePrefix: 'highlight',
                        bucketFile: 'tiffin-bucket/highlights',
                        namePrefix: 'file-highlight',
                        metadataCustom: 'metadata',
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <AdvancedPreviewHighlight
                title={form.values.title}
                subtitle={form.values.subtitle}
                imageUrl={form.values.imageMobileUrl}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </Modal>
  );
}

export default HighlightsModal;
