import React, { useEffect } from 'react';

import { I18n } from 'react-redux-i18n';

import { Col, Drawer, notification, Row } from 'antd';
import { MdClose } from 'react-icons/md';

import { useDispatch } from 'react-redux';
import AdvancedInput from '../../shared/AdvancedInput';
import Loading from '../../shared/Loading';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedTextArea from '../../shared/AdvancedTextArea';

import useForm from '../../../app/hooks/useForm';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { AccessFeatureActions } from '../../../app/redux/actions';

function AccessFeatureForm({ onCloseForm, id, visible }) {
  const { accessFeature } = useReduxState();
  const dispatch = useDispatch();

  const getInitialState = () => ({
    name: null,
  });

  const form = useForm(getInitialState());

  useEffect(() => {
    if (!visible) {
      form.setForm(getInitialState());
    }
  }, [visible]);

  const onSubmit = async () => {
    const { values } = form;
    const payload = {
      name: values.name,
      description: values.description,
    };

    const dataIsValid = [{ name: !!payload.name }];
    const invalidFields = dataIsValid.map((invalidData) => {
      if (Object.values(invalidData).toString() === 'false') {
        return Object.keys(invalidData).toString();
      }
      return null;
    });

    const invalidFieldsTranslated = invalidFields.map((entry) => {
      switch (entry) {
        case 'name':
          return I18n.t('forms.accessFeature.invalidFields.name');
        default:
          return null;
      }
    });

    const invalidFiledFilteredTranslated = invalidFieldsTranslated.filter((filtered) => filtered !== null);

    if (invalidFiledFilteredTranslated.length > 0) {
      return notification.error({
        message: I18n.t('forms.accessFeature.invalidFields.validation.required.title'),
        description: I18n.t('forms.accessFeature.invalidFields.validation.required.description', {
          fields: invalidFiledFilteredTranslated.map((string) => string),
        }),
      });
    }
    let success;
    if (!id) {
      success = await dispatch(AccessFeatureActions.createAccessFeature(payload));
    } else {
      delete payload.title;
      success = await dispatch(AccessFeatureActions.updateAccessFeatureById(id, payload));
    }

    if (success) {
      form.setForm(getInitialState());
      notification.success({
        message: id
          ? I18n.t('forms.accessFeature.updateSuccessTitle')
          : I18n.t('forms.accessFeature.createSuccessTitle'),
        description: id
          ? I18n.t('forms.accessFeature.updateSuccessDescription')
          : I18n.t('forms.accessFeature.createSuccessDescription'),
      });
      onCloseForm(true);
    }
  };

  useEffect(() => {
    if (id && accessFeature.accessFeatureById) {
      form.setForm(accessFeature.accessFeatureById);
    }
  }, [id, accessFeature.accessFeatureById]);

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={id ? I18n.t('forms.accessFeature.updateTitle') : I18n.t('forms.accessFeature.createTitle')}
      onClose={() => onCloseForm()}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton
            type="link"
            text={I18n.t('forms.cancelButtonText')}
            onClick={() => {
              onCloseForm();
            }}
          />
          <AdvancedButton
            loading={
              accessFeature.createAccessFeatureIsOnRequest ||
              accessFeature.updateAccessFeatureIsOnRequest ||
              accessFeature.accessFeatureByIdIsOnRequest
            }
            text={I18n.t('forms.submitButtonText')}
            onClick={onSubmit}
          />
        </div>
      }
    >
      {visible && (
        <div className="product-form">
          {accessFeature.accessFeatureByIdIsOnRequest && (
            <div className="product-form__loading">
              <Loading />
            </div>
          )}
          <Row>
            <Col span={24}>
              <AdvancedInput
                label={I18n.t('forms.accessFeature.items.name.label')}
                onChange={(val) => form.handleInputChange('name', val)}
                value={form.values.name}
                disabled={!!id}
              />
            </Col>
          </Row>
          <Row className="spacing-bottom-sm">
            <Col span={24}>
              <strong>{I18n.t('forms.accessFeature.items.name.cantChange')}</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AdvancedTextArea
                label={I18n.t('forms.accessFeature.items.description.label')}
                onChange={(val) => form.handleInputChange('description', val)}
                value={form.values.description}
                maxLength={100}
              />
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
}

export default AccessFeatureForm;
