export const CollectionTypeEnum = {
  PRIVATE: 'privado',
  PUBLIC: 'público',
  PLATFORM: 'tiffins',
};

export const CollectionType = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  PLATFORM: 'platform',
};
