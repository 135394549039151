import React from 'react';
import { Translate } from 'react-redux-i18n';

export const CustomerOriginStatus = {
  TIFFIN: 'tiffins',
  PENDING: 'pending',
  PROVIDER: 'provider',
};

const { TIFFIN, PENDING, PROVIDER } = CustomerOriginStatus;

export const GetCustomerOriginStatusArray = [
  { id: TIFFIN, name: <Translate value="enum.customerOriginStatus.tiffin" /> },
  { id: PENDING, name: <Translate value="enum.customerOriginStatus.pending" /> },
  { id: PROVIDER, name: <Translate value="enum.customerOriginStatus.provider" /> },
];

export const GetCustomerOriginStatusFilters = GetCustomerOriginStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

export const GetCustomerOriginStatusName = (value) => GetCustomerOriginStatusArray.find((o) => o.id === value).name;

export const GetCustomerOriginStatusColor = (value) => {
  switch (value) {
    case TIFFIN:
      return 'yellow';
    case PENDING:
      return 'blue';
    case PROVIDER:
      return 'green';
    default:
      return undefined;
  }
};
