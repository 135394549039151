import StorageService from './storage';

export const PERSIST_KEY_USER_DATA = '@TiffinFoodsPersistence:user:user_data';
export const PERSIST_KEY_USER_AUTH_DATA = '@TiffinFoodsPersistence:user:user_auth_data';
export const PERSIST_KEY_USER_PROVIDERS_DATA = '@TiffinFoodsPersistence:user:user_providers_data';
export const PERSIST_KEY_REDUX_PERSIST = 'persist:root';
export const PERSIST_UNIQUEID_APP_DATA = '@TiffinFoodsPersistence:user:user_data_uniqueid';

function isAuthenticated() {
  const auth = StorageService.get(PERSIST_KEY_USER_AUTH_DATA);
  return auth && auth.access_token;
}

function getAuth() {
  if (isAuthenticated()) {
    return StorageService.get(PERSIST_KEY_USER_AUTH_DATA);
  }
}

function getToken() {
  const auth = getAuth();
  return auth && auth.access_token;
}

function getRefreshToken() {
  const auth = getAuth();
  return auth && auth.refresh_token;
}

function getUser() {
  if (isAuthenticated()) {
    return StorageService.get(PERSIST_KEY_USER_DATA);
  }
}

function getProviders() {
  if (isAuthenticated()) {
    return StorageService.get(PERSIST_KEY_USER_PROVIDERS_DATA);
  }
}

function getUniqueIdApp() {
  return StorageService.get(PERSIST_UNIQUEID_APP_DATA);
}

function createUniqueIdApp() {
  const uniqueIdFound = getUniqueIdApp();
  if (uniqueIdFound) return uniqueIdFound;
  const uniqueIdGenerated = Math.floor(Math.random() * 10000000000000000000).toString(16);
  StorageService.set(PERSIST_UNIQUEID_APP_DATA, uniqueIdGenerated);
  return uniqueIdGenerated;
}

function createAuth(data) {
  StorageService.set(PERSIST_KEY_USER_AUTH_DATA, data);
}

function createUser(user, providers) {
  StorageService.set(PERSIST_KEY_USER_DATA, user);
  StorageService.set(PERSIST_KEY_USER_PROVIDERS_DATA, providers);
}

function reset() {
  StorageService.reset(PERSIST_KEY_USER_DATA);
  StorageService.reset(PERSIST_KEY_USER_AUTH_DATA);
  StorageService.reset(PERSIST_KEY_USER_PROVIDERS_DATA);
  StorageService.reset(PERSIST_KEY_REDUX_PERSIST);
}

function getUserType() {
  const user = getUser();
  return user && user.type;
}

function getUserId() {
  const user = getUser();
  return user && user.id;
}

export default {
  reset,
  getUser,
  getUserType,
  getAuth,
  getToken,
  getRefreshToken,
  getProviders,
  createAuth,
  createUser,
  isAuthenticated,
  getUserId,
  createUniqueIdApp,
  getUniqueIdApp,
};
