import ProviderRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/provider/providerRequestsV1';
import ProviderPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/provider/panel/providerPanelRequestsV1';
import ProviderRequestsV3 from 'tiffin-foods-api-client/lib/cjs/modules/provider/providerRequestsV3';
import { ProviderStatus } from '../../enum/provider_status';
import FileUtils from '../../utils/file';

export const ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST =
  'ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST:provider';

export const ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDER_PRODUCTS_DATA = 'ACTION_GET_PROVIDER_PRODUCTS_DATA:provider';

export const ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDERS_PAGINATED = 'ACTION_GET_PROVIDERS_PAGINATED:provider';

export const ACTION_GET_PROVIDER_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_IS_ON_REQUEST:provider';
export const ACTION_DELETE_PROVIDER_IS_ON_REQUEST = 'ACTION_DELETE_PROVIDER_IS_ON_REQUEST:provider';
export const ACTION_UPDATE_PROVIDER_IS_ON_REQUEST = 'ACTION_UPDATE_PROVIDER_IS_ON_REQUEST:provider';
export const ACTION_CREATE_PROVIDER_IS_ON_REQUEST = 'ACTION_CREATE_PROVIDER_IS_ON_REQUEST:provider';

export const ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST = 'ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST:provider';

export const ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST =
  'ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:provider';
export const ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST =
  'ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:provider';
export const ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST =
  'ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:provider';
export const ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST =
  'ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:provider';

export const ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST:provider';
export const ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST = 'ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST:provider';
export const ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST = 'ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST:provider';
export const ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST = 'ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDER_BILLING_PAGINATED_DATA = 'ACTION_GET_PROVIDER_BILLING_PAGINATED_DATA:provider';
export const ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST:provider';
export const ACTION_GET_ALL_PROVIDERS_DATA = 'ACTION_GET_ALL_PROVIDERS_DATAL:provider';

export const ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST:provider';

export const ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST = 'ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST:provider';

export const ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST =
  'ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST:provider';

export const ACTION_GET_EXPORT_PROVIDER_PRODUCTS_DATA = 'ACTION_GET_EXPORT_PROVIDER_PRODUCTS_DATA:provider';

export const ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST:provider';

export const ACTION_GET_PROVIDER_ADMINS_DATA = 'ACTION_GET_PROVIDER_ADMINS_DATA:provider';

export const ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST = 'ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST:provider';

export const ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST = 'ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST:provider';

export const ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDER_RATINGS_DATA = 'ACTION_GET_PROVIDER_RATINGS_DATA:provider';

export const ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST =
  'ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST:provider';
export const ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_ERROR = 'ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_ERROR:provider';

export const ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST:provider';
export const ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_ERROR = 'ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_ERROR:provider';
export const ACTION_GET_PROVIDER_CERTIFICATE_DATA = 'ACTION_GET_PROVIDER_CERTIFICATE_DATA:provider';

export const ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST = 'ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST:provider';

export const getProvidersPaginated = (parameters) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST, payload: true });

    parameters = {
      ...parameters,
      limit: parameters?.limit || 10,
      page: parameters?.page ? parameters.page - 1 : 0,
      order: parameters.order || 'DESC',
      column: parameters.column || 'createdAt',
      simple: true,
    };

    const providers = await ProviderPanelRequestsV1.getAll(parameters);
    dispatch({
      type: ACTION_GET_PROVIDERS_PAGINATED,
      payload: providers,
    });
  } finally {
    dispatch({ type: ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export function getProviderPayments(providerId) {
  return async (dispatch) => {
    if (providerId) {
      try {
        dispatch({
          type: ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST,
          payload: true,
        });
        return await ProviderPanelRequestsV1.getProviderPayments(providerId);
      } catch (err) {
        /* empty */
      } finally {
        dispatch({
          type: ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST,
          payload: false,
        });
      }
    }
  };
}

export function getProviderPaymentsByUser(providerId, userId) {
  return async (dispatch) => {
    if (providerId && userId) {
      try {
        dispatch({
          type: ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST,
          payload: true,
        });
        return await ProviderRequestsV1.getProviderPaymentByUser(providerId, userId);
      } catch (err) {
        /* empty */
      } finally {
        dispatch({
          type: ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST,
          payload: false,
        });
      }
    }
  };
}

export function getProviderProducts(providerId) {
  return async (dispatch) => {
    if (providerId) {
      try {
        dispatch({
          type: ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST,
          payload: true,
        });

        const products = await ProviderPanelRequestsV1.getProviderProducts(providerId, {
          column: 'name',
          order: 'asc',
        });
        const { rows, count } = products;
        dispatch({
          type: ACTION_GET_PROVIDER_PRODUCTS_DATA,
          payload: { rows, count },
        });
      } catch (err) {
        /* empty */
      } finally {
        dispatch({
          type: ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST,
          payload: false,
        });
      }
    }
  };
}

export const getProvider = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_IS_ON_REQUEST, payload: true });
    if (id) {
      return await ProviderPanelRequestsV1.getById(id);
    }
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_IS_ON_REQUEST, payload: false });
  }
};

export const deleteProvider = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PROVIDER_IS_ON_REQUEST, payload: true });
    if (id) {
      return await ProviderPanelRequestsV1.deleteProvider(id);
    }
  } finally {
    dispatch({ type: ACTION_DELETE_PROVIDER_IS_ON_REQUEST, payload: false });
  }
};

export const updateProvider = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_PROVIDER_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.update(id, data);
    return true;
  } finally {
    dispatch({ type: ACTION_UPDATE_PROVIDER_IS_ON_REQUEST, payload: false });
  }
};

export const createProvider = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PROVIDER_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.create(data);
    return true;
  } finally {
    dispatch({ type: ACTION_CREATE_PROVIDER_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderUnits = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST, payload: true });
    return await ProviderPanelRequestsV1.getProviderUnits(id);
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST, payload: false });
  }
};

export const createProviderUnit = (providerId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.createProviderUnits(providerId, data);
    return true;
  } finally {
    dispatch({ type: ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST, payload: false });
  }
};

export const updateProviderUnit = (providerId, unitId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.updateProviderUnits(providerId, unitId, data);
    return true;
  } finally {
    dispatch({ type: ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST, payload: false });
  }
};

export const deleteProviderUnit = (providerId, unitId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.deleteProviderUnits(providerId, unitId);
    return true;
  } finally {
    dispatch({ type: ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderPaymentAccount = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: true });
    return await ProviderPanelRequestsV1.getProviderPaymentAccounts(id);
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: false });
  }
};

export const createProviderPaymentAccount = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.createProviderPaymentAccount(id, data);
    return true;
  } finally {
    dispatch({ type: ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: false });
  }
};

export const updateProviderPaymentAccount = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.updateProviderPaymentAccount(id, data);
    return true;
  } finally {
    dispatch({ type: ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: false });
  }
};

export const deleteProviderPaymentAccount = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.deleteProviderPaymentAccount(id);
    return true;
  } finally {
    dispatch({ type: ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST, payload: false });
  }
};

export const getAllProviders = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST, payload: true });
    const { rows } = await ProviderRequestsV3.getAll({
      simple: true,
      status: ProviderStatus.APPROVED,
      removeDummy: true,
      order: 'ASC',
      column: 'name',
    });
    dispatch({ type: ACTION_GET_ALL_PROVIDERS_DATA, payload: rows });
    return rows;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderBillingPaginated = (params, providerId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST, payload: true });
    params = {
      ...params,
      limit: params.limit || 10,
      page: params.page - 1 || 0,
    };

    if (providerId) {
      params.providerId = providerId;
    }

    const providerBilling = await ProviderPanelRequestsV1.getProviderBillings(params);
    dispatch({ type: ACTION_GET_PROVIDER_BILLING_PAGINATED_DATA, payload: providerBilling });
  } catch (err) {
    // nothing here sir
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderTags = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST, payload: true });
    return await ProviderPanelRequestsV1.getProviderTags(id);
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST, payload: false });
  }
};

export const updateProviderTags = (id, providerTagsData) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.updateProviderTags(id, providerTagsData);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST, payload: false });
  }
};

export const getExportProviderProducts = (providerId, providerName) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST, payload: true });
    const products = await ProviderPanelRequestsV1.getProviderProductsExport(providerId);
    const xlsxProducts = FileUtils.jsonToXlsx(products, providerName);
    FileUtils.createDownloadFile(xlsxProducts);
    dispatch({ type: ACTION_GET_EXPORT_PROVIDER_PRODUCTS_DATA, payload: products });
    return true;
  } catch (err) {
    // empty
  } finally {
    dispatch({ type: ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderAdmins = (providerId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST, payload: true });
    const admins = await ProviderPanelRequestsV1.getProviderAdmins(providerId);
    dispatch({ type: ACTION_GET_PROVIDER_ADMINS_DATA, payload: admins });
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST, payload: false });
  }
};

export const deleteProviderAdmin = (providerId, userId, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.deleteProviderAdmin(providerId, userId);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST, payload: false });
  }
};

export const createProviderAdmin = (providerId, admin, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.createProviderAdmin(providerId, admin);
    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderRatings = (params, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST, payload: true });
    params = {
      ...params,
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
      order: params?.order || 'DESC',
      column: params?.column || 'createdAt',
      simple: true,
    };
    const providerRatings = await ProviderRequestsV1.getProviderRatings(params.providerId, params);
    dispatch({ type: ACTION_GET_PROVIDER_RATINGS_DATA, payload: providerRatings });
    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderCertificate = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST, payload: true });
    const payload = await ProviderPanelRequestsV1.getProviderCertificate(id);
    dispatch({ type: ACTION_GET_PROVIDER_CERTIFICATE_DATA, payload });
    return true;
  } catch (err) {
    dispatch({ type: ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_ERROR, payload: false });
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST, payload: false });
  }
};

export const createProviderCertificate = (id, data, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST, payload: true });
    const response = await ProviderPanelRequestsV1.createProviderCertificate(id, data);

    if (response && !response.codigo) {
      if (typeof callback === 'function') {
        callback();
        getProviderCertificate(id);
      }
      return true;
    }
    if (response.codigo) {
      if (typeof callback === 'function') {
        callback(response);
      }
    }
    return false;
  } catch (err) {
    dispatch({ type: ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_ERROR, payload: false });
  } finally {
    dispatch({ type: ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST, payload: false });
  }
};

export const getProviderIntegrations = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST, payload: true });
    const response = await ProviderPanelRequestsV1.getProviderIntegration(id);
    return response;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST, payload: false });
  }
};
