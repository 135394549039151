import React from 'react';

import { Col, Divider, Row } from 'antd';
import { I18n } from 'react-redux-i18n';

import PriceTypes from '../../../../app/enum/price_types';
import * as PriceUtils from '../../../../app/utils/prices';

import AdvancedSwitch from '../../../shared/AdvancedSwitch';
import AdvancedButton from '../../../shared/AdvancedButton';
import AdvancedMaskedInput from '../../../shared/AdvancedMaskedInput';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';

function InnerValuesProductForm({ updateProductData, product, disabled }) {
  const defaultPrice = { type: PriceTypes.WHOLESALE, range: 0, commissionPrivate: 0, value: 0 };

  const onAddNewPrice = () => {
    const productCopy = { ...product };
    productCopy.prices?.push(defaultPrice);
    updateProductData(productCopy);
  };

  const onRemovePriceVariation = (index) => {
    const productCopy = { ...product };
    productCopy.prices?.splice(index, 1);
    updateProductData(productCopy);
  };

  const handlePriceChange = (index, name, value) => {
    const productCopy = { ...product };
    if (productCopy.prices) productCopy.prices[index][name] = Number(value || 0);
    updateProductData(productCopy);
  };

  const handleProductChange = (name, value) => {
    const productCopy = { ...product };
    productCopy[name] = value;
    updateProductData(productCopy);
  };

  const primaryPriceIndex = product?.prices?.findIndex((price) => price.type === PriceTypes.WHOLESALE_PRIMARY);

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.prices.header.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedMaskedInput
            options={{
              unit: 'R$ ',
              precision: 2,
            }}
            placeholder={I18n.t('shared.typeSomething')}
            label={I18n.t('forms.product.items.prices.items.value.label')}
            onChange={(val) => handlePriceChange(primaryPriceIndex, 'value', PriceUtils.cleanValue(val))}
            value={primaryPriceIndex > -1 && product?.prices[primaryPriceIndex]?.value?.toFixed(2)}
            kind="money"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedMaskedInput
            options={{
              unit: 'R$ ',
              precision: 2,
            }}
            placeholder={I18n.t('shared.typeSomething')}
            label={I18n.t('forms.product.items.prices.items.suggestedPrice.label')}
            value={product?.suggestedPrice?.toFixed(2)}
            onChange={(value) => handleProductChange('suggestedPrice', Number(PriceUtils.cleanValue(value)))}
            kind="money"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.prices.items.commissionPrivate.label')}
            onChange={(val) => handlePriceChange(primaryPriceIndex, 'commissionPrivate', val)}
            value={primaryPriceIndex > -1 && product?.prices[primaryPriceIndex]?.commissionPrivate?.toFixed(2)}
            max={100}
            step={0.01}
            formatter={(value) => `${value || 0}%`}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSwitch
            label={I18n.t('forms.product.items.prices.items.wholesale.label')}
            onChange={(val) => handleProductChange('isVariablePrices', val)}
            value={product?.isVariablePrices}
            disabled={disabled}
          />
        </Col>
      </Row>
      {product?.isVariablePrices && (
        <div>
          {product?.prices.map(
            (price, priceIndex) =>
              price.type === PriceTypes.WHOLESALE && (
                <div key={priceIndex.toString()}>
                  <Row>
                    <Col span={24}>
                      <AdvancedInputNumber
                        label={I18n.t('forms.product.items.prices.items.wholesaleValues.items.range.label')}
                        onChange={(val) => handlePriceChange(priceIndex, 'range', val)}
                        value={price.range || 0}
                        min={0}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <AdvancedMaskedInput
                        options={{
                          unit: 'R$ ',
                          precision: 2,
                        }}
                        placeholder={I18n.t('shared.typeSomething')}
                        label={I18n.t('forms.product.items.prices.items.value.label')}
                        value={price.value || 0}
                        onChange={(value) => handlePriceChange(priceIndex, 'value', PriceUtils.cleanValue(value))}
                        kind="money"
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <AdvancedInputNumber
                        label={I18n.t('forms.product.items.prices.items.commissionPrivate.label')}
                        onChange={(val) => handlePriceChange(priceIndex, 'commissionPrivate', val)}
                        value={price.commissionPrivate || 0}
                        max={100}
                        step={0.01}
                        formatter={(value) => `${value || 0}%`}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <AdvancedButton
                        text={I18n.t('forms.product.items.prices.items.wholesaleValues.items.removeVariable.label')}
                        onClick={() => onRemovePriceVariation(priceIndex)}
                        type="danger"
                        disabled={
                          disabled || product?.prices.filter((o) => o.type === PriceTypes.WHOLESALE).length <= 1
                        }
                        ghost
                      />
                    </Col>
                  </Row>
                  <Divider />
                </div>
              ),
          )}
          {!disabled && (
            <AdvancedButton
              text={I18n.t('forms.product.items.prices.items.wholesaleValues.items.addVariable.label')}
              onClick={() => onAddNewPrice()}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default InnerValuesProductForm;
