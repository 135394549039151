import NutritionRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/nutrition/nutritionRequestsV1';

export const ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST = 'ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST:nutrition';

export const getNutritions = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST, payload: true });
    return await NutritionRequestsV1.getAll();
  } finally {
    dispatch({ type: ACTION_GET_NUTRITIONS_UNITS_IS_ON_REQUEST, payload: false });
  }
};
