import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { I18n } from 'react-redux-i18n';

import { NutritionActions } from '../../../../app/redux/actions';
import AdvancedSwitch from '../../../shared/AdvancedSwitch';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';
import AdvancedCheckbox from '../../../shared/AdvancedCheckbox';
import Loading from '../../../shared/Loading';
import { useReduxState } from '../../../../app/hooks/useReduxState';

function InnerNutritionProductForm({ updateProductData, product, productById, disabled }) {
  const dispatch = useDispatch();
  const { nutrition } = useReduxState();

  useEffect(() => {
    const getNutritions = async () => {
      const allNutritions = await dispatch(NutritionActions.getNutritions());
      const productCopy = { ...product };
      productCopy.nutritions = allNutritions;
      updateProductData(productCopy);
    };

    if (!productById || (productById && productById?.nutritions?.length === 0)) {
      getNutritions();
    }
  }, [productById]);

  const onProductInfo = (name, value) => {
    const productCopy = { ...product };
    productCopy[name] = value;
    updateProductData(productCopy);
  };

  const onChangeNutritionalInfo = (index, name, value) => {
    const productCopy = { ...product };
    productCopy.nutritions[index][name] = value;
    updateProductData(productCopy);
  };

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.nutritional.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSwitch
            label={I18n.t('forms.product.items.nutritional.items.showNutritionalInfos.label')}
            onChange={(val) => onProductInfo('showNutritionalInfos', val)}
            value={product?.showNutritionalInfos}
            disabled={disabled}
          />
        </Col>
      </Row>

      {nutrition.isGetNutritionsOnRequest && (
        <Row>
          <Col span={24}>
            <div className="product-form__inner__loading">
              <Loading />
            </div>
          </Col>
        </Row>
      )}

      {!nutrition.isGetNutritionsOnRequest &&
        product?.showNutritionalInfos &&
        product?.nutritions?.map((nutritionInner, nutritionInnerIndex) => (
          <Row key={nutritionInnerIndex.toString()}>
            <Col span={24}>
              {nutritionInner.type === 'field_input_int' && (
                <AdvancedInputNumber
                  label={nutritionInner.name}
                  onChange={(value) => onChangeNutritionalInfo(nutritionInnerIndex, 'value', value)}
                  value={nutritionInner.value}
                  precision={2}
                  step={0.1}
                  min={0}
                  disabled={!product?.showNutritionalInfos || disabled}
                />
              )}
              {nutritionInner.type === 'field_check' && (
                <AdvancedCheckbox
                  label={nutritionInner.name}
                  onChange={(value) => onChangeNutritionalInfo(nutritionInnerIndex, 'value', value)}
                  value={nutritionInner.value}
                  disabled={!product?.showNutritionalInfos || disabled}
                />
              )}
            </Col>
          </Row>
        ))}
    </div>
  );
}

export default InnerNutritionProductForm;
