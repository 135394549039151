import styled from 'styled-components';

export const ContainerTitle = styled.strong`
  font-size: 17px;
  text-transform: uppercase;
  color: black;
  max-width: 100%;
  padding-bottom: 15px;
  display: block;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 4px;
    width: 25px;
    background-color: #ffec00;
  }
`;

export const ContainerText = styled.span`
  display: block;
`;

export const HiddenBar = styled.div`
  margin-top: 10px;
  max-height: 74px;
`;

export const ContainerFooterButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
