import React, { useState } from 'react';
import './MenuContent.less';

import { Link, useLocation } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { HiCash, HiUserGroup, HiStar } from 'react-icons/hi';
import { AiFillTags, AiOutlineProfile } from 'react-icons/ai';
import { RiAdminLine } from 'react-icons/ri';
import { FiKey, FiUsers, FiAlignLeft } from 'react-icons/fi';
import { BsFillChatFill } from 'react-icons/bs';
import { MdDashboard } from 'react-icons/md';
import { FaUserAlt, FaBoxOpen, FaTags, FaIndustry, FaFunnelDollar, FaBookOpen } from 'react-icons/fa';
import { Menu } from 'antd';
import { connect } from 'react-redux';

import { accessActionTypes } from '../../../app/enum/access_action_types';
import { accessTypes } from '../../../app/enum/access_types';
import { UserTypes } from '../../../app/enum/user_types';
import { getUserDashboards, hasAccess } from '../../../app/services/access';
import { AuthSelectors } from '../../../app/redux/reducers';
import { formatSlug } from '../../../app/utils/string';

function MenuContent({ getUserProviders, me, collapsed }) {
  const location = useLocation();

  const getActiveRoute = () => {
    const allRoutes = Object.values(I18n.t('routes.panel'));
    const routes = [];

    // external routes;
    allRoutes.forEach((o) => {
      if (o.url) {
        routes.push(o.url);
      }
    });

    // dynamic generated routes;
    routes.push(location.pathname);

    // inner routes;
    allRoutes.forEach((o) => {
      if (!o.url && typeof o === 'object') {
        Object.values(o).forEach((oo) => {
          routes.push(oo.url);
        });
      }
    });

    const allFilteredRoutes = routes.filter((o) => !!o);

    return allFilteredRoutes.filter((o) => location.pathname.indexOf(o) >= 0);
  };

  const defaultOpenKeys = [
    I18n.t('routes.panel.dashboards.url'),
    I18n.t('routes.panel.administration.url'),
    I18n.t('routes.panel.marketing.url'),
  ];

  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys?.length ? [keys[keys.length - 1]] : []);
  };

  return (
    <div className="menu">
      <Menu
        inlineCollapsed={collapsed}
        theme="dark"
        mode="vertical"
        className="menu__content"
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        selectedKeys={getActiveRoute()}
        defaultOpenKeys={defaultOpenKeys}
      >
        {getUserDashboards()?.length > 0 && (
          <Menu.SubMenu
            className="menu__content__item__submenu"
            icon={<MdDashboard className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.dashboards.url')}
            title={I18n.t('routes.panel.dashboards.pageTitle')}
          >
            {getUserDashboards().map((accessFeature) => (
              <Menu.Item
                icon={<MdDashboard className="menu__content__item__icon" />}
                key={`${I18n.t('routes.panel.dashboards.url')}/${accessFeature.accessFeatureId}-${formatSlug(
                  accessFeature.accessFeatureDescription,
                )}`}
              >
                <Link
                  to={`${I18n.t('routes.panel.dashboards.url')}/${accessFeature.accessFeatureId}-${formatSlug(
                    accessFeature.accessFeatureDescription,
                  )}`}
                >
                  <span className="menu__content__item__text">{accessFeature.accessFeatureDescription}</span>
                </Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}

        {hasAccess([accessTypes.ORDERS], [accessActionTypes.READ]) && (
          <Menu.Item icon={<FaBoxOpen className="menu__content__item__icon" />} key={I18n.t('routes.panel.orders.url')}>
            <Link className="menu__content__item" to={I18n.t('routes.panel.orders.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.orders.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(me?.type) &&
          hasAccess([accessTypes.USERS], [accessActionTypes.READ]) && (
            <Menu.Item
              icon={<FaUserAlt className="menu__content__item__icon" />}
              key={I18n.t('routes.panel.users.url')}
            >
              <Link to={I18n.t('routes.panel.users.url')}>
                <span className="menu__content__item__text">{I18n.t('routes.panel.users.sidebarTitle')}</span>
              </Link>
            </Menu.Item>
          )}

        {hasAccess([accessTypes.PROVIDER_RATINGS], [accessActionTypes.READ]) && getUserProviders?.length > 0 && (
          <Menu.Item
            icon={<HiStar className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.providerRatings.url')}
          >
            <Link to={I18n.t('routes.panel.providerRatings.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.providerRatings.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {hasAccess([accessTypes.PRODUCTS], [accessActionTypes.READ]) && (
          <Menu.Item icon={<FaTags className="menu__content__item__icon" />} key={I18n.t('routes.panel.products.url')}>
            <Link to={I18n.t('routes.panel.products.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.products.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {hasAccess([accessTypes.PROVIDERS], [accessActionTypes.READ]) && (
          <Menu.Item
            icon={<FaIndustry className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.providers.url')}
          >
            <Link to={I18n.t('routes.panel.providers.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.providers.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {hasAccess([accessTypes.CHAT], [accessActionTypes.READ]) && (
          <Menu.Item
            icon={<BsFillChatFill className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.chats.url')}
          >
            <Link to={I18n.t('routes.panel.chats.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.chats.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {hasAccess([accessTypes.CUSTOMERS], [accessActionTypes.READ]) && [UserTypes.USER_LEGAL].includes(me?.type) && (
          <Menu.Item
            icon={<HiUserGroup className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.customers.url')}
          >
            <Link to={I18n.t('routes.panel.customers.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.customers.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {hasAccess([accessTypes.PROVIDER_BILLING], [accessActionTypes.READ]) && (
          <Menu.Item
            icon={<HiCash className="menu__content__item__icon" />}
            key={I18n.t('routes.panel.providerBilling.url')}
          >
            <Link to={I18n.t('routes.panel.providerBilling.url')}>
              <span className="menu__content__item__text">{I18n.t('routes.panel.providerBilling.sidebarTitle')}</span>
            </Link>
          </Menu.Item>
        )}

        {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(me?.type) && (
          <>
            {hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.CREATE]) && (
              <Menu.SubMenu
                className="menu__content__item__submenu"
                icon={<FaFunnelDollar className="menu__content__item__icon" />}
                key={I18n.t('routes.panel.marketing.url')}
                title={I18n.t('routes.panel.marketing.pageTitle')}
              >
                <Menu.Item
                  icon={<FaBookOpen className="menu__content__item__icon" />}
                  key={I18n.t('routes.panel.marketing.notifications.url')}
                >
                  <Link to={I18n.t('routes.panel.marketing.notifications.url')}>
                    <span className="menu__content__item__text">
                      {I18n.t('routes.panel.marketing.notifications.pageTitle')}
                    </span>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            {hasAccess(
              [
                accessTypes.ADMIN_TAGS,
                accessTypes.ADMIN_HIGHLIGHTS,
                accessTypes.ADMIN_ACCESS_GROUPS,
                accessTypes.ADMIN_ACCESS_FEATURES,
                accessTypes.ADMIN_COLLECTIONS,
              ],
              [accessActionTypes.READ, accessActionTypes.CREATE],
            ) && (
              <Menu.SubMenu
                className="menu__content__item__submenu"
                icon={<RiAdminLine className="menu__content__item__icon" />}
                key={I18n.t('routes.panel.administration.url')}
                title={I18n.t('routes.panel.administration.sidebarTitle')}
              >
                {hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.READ]) && (
                  <Menu.Item
                    icon={<AiFillTags className="menu__content__item__icon" />}
                    key={I18n.t('routes.panel.administration.tags.url')}
                  >
                    <Link to={I18n.t('routes.panel.administration.tags.url')}>
                      <span className="menu__content__item__text">
                        {I18n.t('routes.panel.administration.tags.pageTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.READ]) && (
                  <Menu.Item
                    icon={<AiOutlineProfile className="menu__content__item__icon" />}
                    key={I18n.t('routes.panel.administration.highlights.url')}
                  >
                    <Link to={I18n.t('routes.panel.administration.highlights.url')}>
                      <span className="menu__content__item__text">
                        {I18n.t('routes.panel.administration.highlights.pageTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {hasAccess([accessTypes.ADMIN_ACCESS_GROUPS], [accessActionTypes.READ]) && (
                  <Menu.Item
                    icon={<FiUsers className="menu__content__item__icon" />}
                    key={I18n.t('routes.panel.administration.accessGroups.url')}
                  >
                    <Link to={I18n.t('routes.panel.administration.accessGroups.url')}>
                      <span className="menu__content__item__text">
                        {I18n.t('routes.panel.administration.accessGroups.pageTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {hasAccess([accessTypes.ADMIN_ACCESS_FEATURES], [accessActionTypes.READ]) && (
                  <Menu.Item
                    icon={<FiKey className="menu__content__item__icon" />}
                    key={I18n.t('routes.panel.administration.accessFeature.url')}
                  >
                    <Link to={I18n.t('routes.panel.administration.accessFeature.url')}>
                      <span className="menu__content__item__text">
                        {I18n.t('routes.panel.administration.accessFeature.pageTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {hasAccess([accessTypes.ADMIN_COLLECTIONS], [accessActionTypes.READ]) && (
                  <Menu.Item
                    icon={<FiAlignLeft className="menu__content__item__icon" />}
                    key={I18n.t('routes.panel.administration.collections.url')}
                  >
                    <Link to={I18n.t('routes.panel.administration.collections.url')}>
                      <span className="menu__content__item__text">
                        {I18n.t('routes.panel.administration.collections.pageTitle')}
                      </span>
                    </Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
          </>
        )}
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  getUserProviders: AuthSelectors.getProviders(state),
  me: AuthSelectors.getUser(state),
});

export default connect(mapStateToProps)(MenuContent);
