import './styles/less/index.less';
import 'moment/locale/pt-br';
import moment from 'moment-timezone';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import jstz from 'jstimezonedetect';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { Spin, ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import 'react-image-lightbox/style.css';

import { PersistGate } from 'redux-persist/integration/react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Config from './app/config/app.config';

import i18nDictionary from './i18n';

import Redux from './app/redux';
import Routes from './app/routes';

Bugsnag.start({
  apiKey: '7ab4d64ab1c7a655ef41c77920f37766',
  plugins: [new BugsnagPluginReact()],
});

const { store, persistor } = Redux;

try {
  const tz = jstz.determine();
  const timezone = tz.name() || Config.timezone;
  let { language } = Config.language;
  if (!i18nDictionary[language]) ({ language } = Config);

  // initialize react-redux-i18n
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(i18nDictionary));
  store.dispatch(setLocale(language));
  moment.tz.setDefault(timezone);
  moment.locale(language);
} catch (err) {
  // err
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <ConfigProvider locale={ptBR}>
      <Provider store={store} style={{ overflow: 'hidden' }}>
        <PersistGate loading={<Spin size="large" />} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </ErrorBoundary>,
);
