import React from 'react';

import { Divider, Empty } from 'antd';
import { I18n } from 'react-redux-i18n';

import { useReduxState } from '../../../../app/hooks/useReduxState';
import { QuestionTypes } from '../../../../app/enum/question_types';
import CustomCheckableTag from '../../../shared/CustomCheckableTag';

function UserPreferencesSection({ preferences }) {
  const { question: QuestionSelectors } = useReduxState();

  const getInputAnswer = (questionPreferenceId) => {
    const preferenceIndex = preferences.findIndex((o) => o.preferenceId === questionPreferenceId);
    const answer = preferences[preferenceIndex]?.value;

    return answer || I18n.t('forms.user.preferences.notAnswered.title');
  };

  if (!preferences || !QuestionSelectors.questions) {
    return <Empty description={I18n.t('forms.user.preferences.empty.title')} />;
  }

  return QuestionSelectors.questions?.map((o, questionIndex) => (
    <>
      <div>{o.question}</div>
      {o.type === QuestionTypes.CHECK &&
        o.preference.map((questionPreference) => {
          const selected = preferences.find((preference) => preference.preferenceId === questionPreference.id);
          return (
            <CustomCheckableTag color="gold" key={questionPreference.id} checked={!!selected}>
              {questionPreference.preference}
            </CustomCheckableTag>
          );
        })}
      {o.type === QuestionTypes.INPUT && <div>{getInputAnswer(o.preference[0].id)}</div>}
      {questionIndex !== Object.keys(QuestionSelectors.questions).length - 1 && <Divider />}
    </>
  ));
}
export default UserPreferencesSection;
