export const PaymentStatus = {
  NOT_FINISHED: 'not_finished',
  AUTHORIZED: 'authorized',
  CONFIRMED: 'confirmed',
  DENIED: 'denied',
  CANCELED: 'canceled',
  REFUNDED: 'refunded',
  PENDING: 'pending',
  ABORTED: 'aborted',
  SCHEDULED: 'scheduled',
  LATE: 'late',
};
