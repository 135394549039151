import React, { useEffect, useState } from 'react';
import { Modal, notification, Row, Col, Form, Select, Upload, Tag, Radio } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { InboxOutlined } from '@ant-design/icons';
import { CustomerActions } from '../../../app/redux/actions';
import { AuthSelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../shared/AdvancedButton';

function CustomerImportModal({
  title,
  visible,
  onHandleCancel,
  cancelButtonText,
  uploadButtonText,
  loading,
  onUploadFileMethod,
  onUploadFileSuccess,
  templatePath,
  templateButtonText,
  getMyProviders,
  acceptFilesTypes,
  selectFileTitle,
  selectFileDescription,
  templateButtonTextDescription,
  onCreateCustomer,
  providerSelected,
}) {
  const [providerId, setProviderId] = useState(providerSelected);
  const [fileList, setFileList] = useState();
  const [customerEmailList, setCustomerEmailList] = useState([]);
  const [customerEmailListMode, setCustomerEmailListMode] = useState(1);
  const { Item } = Form;
  const { Option } = Select;
  const { Dragger } = Upload;
  const beforeUpload = () => false;

  useEffect(() => {
    setProviderId(providerSelected);
  }, [visible]);

  const finishModal = () => {
    setFileList();
    setCustomerEmailList([]);
    setCustomerEmailListMode(1);
    onUploadFileSuccess();
  };

  const uploadFile = async () => {
    const customerEmailListMap = customerEmailList.find((o) => !o.includes('@' && '.com'));
    if (customerEmailListMode === 1 && !customerEmailList) {
      return notification.error({
        message: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.message'),
        description: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.description'),
      });
    }
    if (customerEmailListMap) {
      return notification.error({
        message: I18n.t('routes.panel.customers.importModal.notification.error.wrongEmails.message'),
        description: I18n.t('routes.panel.customers.importModal.notification.error.wrongEmails.description'),
      });
    }
    if (!providerId) {
      return notification.error({
        message: I18n.t('routes.panel.customers.importModal.notification.noProviderId.message'),
        description: I18n.t('routes.panel.customers.importModal.notification.error.noProviderId.description'),
      });
    }
    if (customerEmailListMode === 2 && fileList.length <= 0) {
      return notification.error({
        message: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.message'),
        description: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.description'),
      });
    }
    let success;

    if (customerEmailListMode === 1) {
      if (customerEmailList.length <= 0) {
        return notification.error({
          message: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.message'),
          description: I18n.t('routes.panel.customers.importModal.notification.error.noEmail.description'),
        });
      }

      success = await onCreateCustomer(providerId, { emails: customerEmailList });
    }
    if (customerEmailListMode === 2) {
      success = await onUploadFileMethod(providerId, fileList);
    }
    if (success) {
      finishModal();
    }
  };

  const emailIsValid = (val) => {
    return !!(val.includes('@' && '.com') || !customerEmailList.find((o) => o === val));
  };

  const tagRender = ({ label, value, closable, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={emailIsValid(value) ? 'green' : 'red'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={onHandleCancel}
      footer={[
        <AdvancedButton type="link" onClick={onHandleCancel} key="cancel" text={cancelButtonText} />,
        <AdvancedButton type="primary" loading={loading} onClick={uploadFile} key="upload" text={uploadButtonText} />,
      ]}
    >
      <Row>
        <Col span={24}>
          <Item label={I18n.t('routes.panel.customers.importModal.items.provider.label')}>
            <Select
              placeholder={I18n.t('shared.selectSomeValue')}
              onChange={(id) => setProviderId(id)}
              defaultValue={providerId}
              value={providerId}
            >
              {getMyProviders?.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Radio.Group onChange={(e) => setCustomerEmailListMode(e.target.value)} value={customerEmailListMode}>
            <Radio value={1}>
              <strong>{I18n.t('routes.panel.customers.importModal.items.emailList.manual')}</strong>
            </Radio>
            <Radio value={2}>
              <strong>{I18n.t('routes.panel.customers.importModal.items.emailList.csv')}</strong>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {customerEmailListMode === 1 && (
        <>
          <Row className="customer-import-modal__template">
            <Col>
              <strong>Dica:</strong> aperte <strong>enter</strong> quando terminar de digitar cada email.
            </Col>
          </Row>
          <Row className="customer-import-modal__select">
            <Col span={24}>
              <Select
                tagRender={tagRender}
                mode="tags"
                placeholder={I18n.t('shared.typeSomething')}
                className="customer-import-modal__select"
                onChange={setCustomerEmailList}
                value={customerEmailList}
              >
                {customerEmailList}
              </Select>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col span={24} />
      </Row>
      {customerEmailListMode === 2 && (
        <>
          <Row className="customer-import-modal__template">
            <Col>
              <a href={templatePath} target="_blank" rel="noopener noreferrer">
                <strong>{templateButtonText}</strong>
              </a>
              , {templateButtonTextDescription}
            </Col>
          </Row>
          <Row className="customer-import-modal__select">
            <Col span={24}>
              <Dragger
                name="file"
                accept={acceptFilesTypes}
                multiple={false}
                beforeUpload={(file) => beforeUpload(file)}
                onChange={(info) => setFileList([info.file])}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ color: 'black' }} />
                </p>
                <p className="ant-upload-text">{selectFileTitle}</p>
                <p className="ant-upload-hint">{selectFileDescription}</p>
              </Dragger>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  getMyProviders: AuthSelectors.getProviders(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateCustomer: (providerId, data) => dispatch(CustomerActions.createCustomer(providerId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerImportModal);
