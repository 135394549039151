import React, { useEffect, useRef, useState } from 'react';
import { Card, Steps, notification, Modal } from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { isLogged } from 'tiffin-foods-api-client/lib/cjs/utils/auth';
import classNames from 'classnames';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  TypeUserStep,
  AccessDataStep,
  ConfirmationCodeStep,
  PersonalDataStep,
  QuestionsStep,
} from '../../components/signup/steps';
import {
  SignupSteps,
  GetSignupStepsArray,
  GetNextStep,
  GetPrevStep,
  GetRightCurrentStep,
} from '../../app/enum/signup_steps';
import { UserSelectors, QuestionSelectors, AuthSelectors } from '../../app/redux/reducers';
import { AuthActions, QuestionActions, UserActions } from '../../app/redux/actions';
import { ContainerFooterButton } from './styles';
import AdvancedButton from '../../components/shared/AdvancedButton/AdvancedButton';
import Validators from '../../app/utils/validators';
import { UserTypes } from '../../app/enum/user_types';
import { UserStatus } from '../../app/enum/user_status';
import { QuestionTypes } from '../../app/enum/question_types';
import { accessTypes } from '../../app/enum/access_types';
import { accessActionTypes } from '../../app/enum/access_action_types';
import { hasAccess } from '../../app/services/access';

function Signup({
  signupUser,
  onGetQuestions,
  questions,
  onSignupUserAndCreateCodeValidation,
  onSetSignupUser,
  createUserAndCodeIsOnRequest,
  authenticatedUser,
  onValidateCode,
  isValidateCodeIsOnRequest,
  isCreateCodeIsOnRequest,
  onClearSignupUser,
  onLogout,
}) {
  const cardRef = useRef(null);

  const [stepContent, setStepContent] = useState();
  const steps = () =>
    GetSignupStepsArray.map((o) => {
      if (!questions.length && o.step === SignupSteps.QuestionsStep) {
        return;
      }
      return <Steps.Step title={o.title} key={o.key} />;
    });

  const requiredQuestionsAreAnswered = () => {
    if (questions.length) {
      const requiredQuestionsQuantity = questions?.filter((o) => o.required).length;
      const requiredQuestionsAnswered = signupUser?.preferences?.filter((o) => {
        if (!o.required || (o.type === QuestionTypes.CHECK && !o.preferences.length)) {
          return false;
        }

        if (o.type === QuestionTypes.INPUT) {
          const { value } = o.preferences[0];

          if (!value || (o.mask && !new RegExp(o.mask).test(value))) {
            return false;
          }
        }

        return true;
      });

      return requiredQuestionsQuantity <= requiredQuestionsAnswered?.length;
    }
  };

  const nextButtonText = () => {
    switch (signupUser?.currentStep) {
      case SignupSteps.PersonalDataStep:
        return I18n.t('routes.signup.content.registerButton');
      case SignupSteps.ConfirmationCodeStep:
        return I18n.t('routes.signup.content.validateButton');
      default:
        return I18n.t('routes.signup.content.nextButton');
    }
  };

  const handleNextButton = async () => {
    switch (signupUser?.currentStep) {
      case SignupSteps.TypeUserStep:
        if (!signupUser?.document) {
          return notification.error({
            message: I18n.t('routes.signup.content.notificationserNoCNPJ.message'),
            description: I18n.t('routes.signup.content.notificationserNoCNPJ.description'),
          });
        }
        if (!Validators.validateCNPJ(signupUser?.document)) {
          return notification.error({
            message: I18n.t('routes.signup.content.notificationNoCNPJValid.message'),
            description: I18n.t('routes.signup.content.notificationNoCNPJValid.description'),
          });
        }
        break;
      case SignupSteps.AccessDataStep:
        if (!signupUser?.email || !Validators.validateEmail(signupUser?.email)) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.description'),
          });
        }
        if (!signupUser?.password) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noPassword.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noPassword.description'),
          });
        }
        if (!signupUser?.passwordConfirmation) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noPasswordConfirmation.message'),
            description: I18n.t(
              'routes.signup.content.dataAccessContent.notifications.noPasswordConfirmation.description',
            ),
          });
        }
        if (signupUser?.password !== signupUser?.passwordConfirmation) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noMatchPassword.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noMatchPassword.description'),
          });
        }
        if (!signupUser?.checkedPrivacityTerms) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noPrivicityTerms.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noPrivicityTerms.description'),
          });
        }
        if (!signupUser?.checkedLegalTerms) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noLegalTerms.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noLegalTerms.description'),
          });
        }
        break;
      case SignupSteps.QuestionsStep:
        if (!requiredQuestionsAreAnswered()) {
          return notification.error({
            message: I18n.t('signup.questionsStep.notifications.noAnswer.message'),
            description: I18n.t('signup.questionsStep.notifications.noAnswer.description'),
          });
        }
        break;
      case SignupSteps.PersonalDataStep:
        if (!signupUser?.email) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.description'),
          });
        }
        if (!signupUser?.name) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noName.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noName.description'),
          });
        }
        if (!signupUser?.phone) {
          return notification.error({
            message: I18n.t('routes.signup.content.dataAccessContent.notifications.noPhone.message'),
            description: I18n.t('routes.signup.content.dataAccessContent.notifications.noPhone.description'),
          });
        }
        break;
      case SignupSteps.ConfirmationCodeStep:
        if (!signupUser?.code) {
          return notification.error({
            message: I18n.t('routes.signup.content.confirmationDataContent.notifications.noCode.message'),
            description: I18n.t('routes.signup.content.confirmationDataContent.notifications.noCode.description'),
          });
        }
        break;
      default:
    }

    if (signupUser?.currentStep === SignupSteps.ConfirmationCodeStep) {
      const success = await onValidateCode(signupUser?.code);
      if (!success) {
        return;
      }

      if (hasAccess([accessTypes.ORDERS], [accessActionTypes.READ])) {
        return navigate(I18n.t('routes.panel.orders.url'));
      }

      navigate(I18n.t('routes.panel.providers.url'));
      return;
    }

    if (signupUser?.currentStep === SignupSteps.PersonalDataStep) {
      const success = await onSignupUserAndCreateCodeValidation(signupUser);
      if (!success) {
        return;
      }

      if (window.dataLayer) {
        window.dataLayer.push({ event: 'Provider_Registration_Event', pageCategory: 'signup' });
      }
    }

    if (signupUser?.currentStep === SignupSteps.AccessDataStep && questions.length) {
      onSetSignupUser('currentStep', SignupSteps.QuestionsStep);
      return;
    }

    if (signupUser?.currentStep === SignupSteps.AccessDataStep && !questions.length) {
      onSetSignupUser('currentStep', SignupSteps.PersonalDataStep);
      return;
    }

    onSetSignupUser('currentStep', GetNextStep(signupUser?.currentStep, 1));
  };

  const handlePrevButton = () => {
    if (signupUser?.currentStep === SignupSteps.PersonalDataStep && !questions.length) {
      onSetSignupUser('currentStep', SignupSteps.AccessDataStep);
      return;
    }

    const prevStep = GetPrevStep(signupUser?.currentStep, 1);

    if (prevStep === signupUser?.currentStep || signupUser?.currentStep === SignupSteps.ConfirmationCodeStep) {
      const { confirm } = Modal;

      confirm({
        title: I18n.t('signup.cancelRegister.title'),
        content: I18n.t('signup.cancelRegister.content'),
        icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
        okText: I18n.t('signup.cancelRegister.okText'),
        okType: 'danger',
        centered: true,
        cancelText: I18n.t('signup.cancelRegister.cancelText'),
        onOk: async () => {
          await onClearSignupUser(null);
          await onLogout();
        },
      });

      return;
    }

    onSetSignupUser('currentStep', prevStep);
  };

  useEffect(() => {
    onGetQuestions(UserTypes.USER_LEGAL);

    if (isLogged()) {
      if ([UserStatus.APPROVED].includes(authenticatedUser?.status)) {
        navigate('/');
        return () => {};
      }
      onSetSignupUser('currentStep', SignupSteps.ConfirmationCodeStep);
      return () => {};
    }

    if (
      questions.length &&
      !requiredQuestionsAreAnswered() &&
      signupUser?.email &&
      signupUser?.password &&
      signupUser?.passwordConfirmation &&
      signupUser?.checkedPrivacityTerms &&
      signupUser?.checkedLegalTerms
    ) {
      onSetSignupUser('currentStep', SignupSteps.QuestionsStep);
      return () => {};
    }

    if (signupUser?.document && signupUser?.documentType && signupUser?.type) {
      onSetSignupUser('currentStep', SignupSteps.AccessDataStep);
      return () => {};
    }
    onSetSignupUser('currentStep', SignupSteps.TypeUserStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signupUser?.currentStep) {
      switch (signupUser?.currentStep) {
        case SignupSteps.TypeUserStep:
          setStepContent(<TypeUserStep />);
          break;
        case SignupSteps.AccessDataStep:
          setStepContent(<AccessDataStep />);
          break;
        case SignupSteps.QuestionsStep:
          setStepContent(<QuestionsStep />);
          break;
        case SignupSteps.PersonalDataStep:
          setStepContent(<PersonalDataStep />);
          break;
        case SignupSteps.ConfirmationCodeStep:
          setStepContent(<ConfirmationCodeStep />);
          break;
        default:
          setStepContent(<TypeUserStep />);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupUser?.currentStep]);

  return (
    <div className="signup">
      <div className="box-container">
        <div className="signup__inner">
          <Card
            className={classNames({
              signup__container: true,
              'signup__container--responsive': cardRef.current?.clientHeight >= 550,
            })}
          >
            <div className="signup__logo">
              <img alt="signup logo" className="signup__logo__img" src="/assets/img/img_logo.png" />
            </div>
            <div className="signup__steps">
              <Steps
                current={GetRightCurrentStep(signupUser?.currentStep, !!questions.length)}
                direction="horizontal"
                size="small"
                labelPlacement="vertical"
              >
                {steps()}
              </Steps>
            </div>
            <div className="signup__container__register__container">
              <div className="signup__container__register__container__form__container">{stepContent}</div>
            </div>

            <ContainerFooterButton>
              <>
                <AdvancedButton
                  text={I18n.t('routes.signup.content.prevButton')}
                  onClick={handlePrevButton}
                  type="text"
                  disabled={createUserAndCodeIsOnRequest || isValidateCodeIsOnRequest || isCreateCodeIsOnRequest}
                />
                <AdvancedButton
                  text={nextButtonText()}
                  onClick={handleNextButton}
                  loading={createUserAndCodeIsOnRequest || isValidateCodeIsOnRequest || isCreateCodeIsOnRequest}
                />
              </>
            </ContainerFooterButton>
          </Card>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  authenticatedUser: AuthSelectors.getUser(state),
  questions: QuestionSelectors.getQuestions(state),
  signupUser: UserSelectors.getSignupUser(state),
  createUserAndCodeIsOnRequest: UserSelectors.isCreateUserAndCodeIsOnRequest(state),
  isValidateCodeIsOnRequest: UserSelectors.isValidateCodeIsOnRequest(state),
  isCreateCodeIsOnRequest: UserSelectors.isCreateCodeIsOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
  onClearSignupUser: () => dispatch(UserActions.clearSignupUser()),
  onGetQuestions: (userType, callback) => dispatch(QuestionActions.getQuestions(userType, callback)),
  onSignupUserAndCreateCodeValidation: (user) => dispatch(UserActions.createUserAndCodeValidation(user)),
  onValidateCode: (code) => dispatch(UserActions.validateCode(code)),
  onLogout: () => dispatch(AuthActions.logout(isLogged())),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
