import React from 'react';
import { Layout, Card, Form, Image, Typography, Input, notification } from 'antd';

import { navigate } from '@reach/router';

import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import AdvancedButton from '../../components/shared/AdvancedButton';

import { useReduxState } from '../../app/hooks/useReduxState';
import { UserActions } from '../../app/redux/actions';

function ForgetPassword() {
  const { Item } = Form;
  const { Title, Paragraph } = Typography;
  const { Content } = Layout;

  const dispatch = useDispatch();
  const { user: UserSelectors } = useReduxState();

  const onSubmit = async (data) => {
    const { email } = data;

    if (!email) {
      notification.error({
        message: I18n.t('routes.forgetPassword.content.notification.error.message'),
        description: I18n.t('routes.forgetPassword.content.notification.error.description'),
      });
    } else {
      const response = await dispatch(UserActions.passwordRecovery(email));

      if (response) {
        notification.success({
          message: I18n.t('routes.forgetPassword.content.notification.success.message'),
          description: I18n.t('routes.forgetPassword.content.notification.success.description'),
        });
        navigate(I18n.t('routes.login.url'));
      }
    }
  };

  return (
    <Layout>
      <Content className="password__recovery">
        <Card className="password__recovery__container" loading={UserSelectors.isPostPasswordRecoverOnRequest}>
          <div className="password__recovery__container__signin__container">
            <div className="password__recovery__logo">
              <Image
                className="password__recovery__logo__img"
                width={130}
                src="/assets/img/img_logo.png"
                alt="register password logo"
              />
            </div>

            <div className="password__recovery__container__signin__container__text">
              <Title level={3}>{I18n.t('routes.forgetPassword.content.header')}</Title>
              <Paragraph>{I18n.t('routes.forgetPassword.content.text')}</Paragraph>
            </div>

            <div className="password__recovery__container__signin__container__form">
              <Form layout="vertical" onFinish={onSubmit}>
                <Item name="email" label={I18n.t('routes.forgetPassword.content.email')}>
                  <Input type="email" placeholder={I18n.t('shared.typeSomething')} />
                </Item>

                <div className="password__recovery__container__signin__container__form__footer">
                  <AdvancedButton
                    className="password__recovery__container__signin__container__form__login__button"
                    text={I18n.t('routes.forgetPassword.content.backToLoginButton')}
                    type="default"
                    onClick={() => navigate(I18n.t('routes.login.url'))}
                  />

                  <AdvancedButton
                    className="password__recovery__container__signin__container__form__button__submit"
                    text={I18n.t('routes.forgetPassword.content.sendEmailButton')}
                    htmlType="submit"
                  />
                </div>
              </Form>
            </div>
          </div>
        </Card>
      </Content>
    </Layout>
  );
}

export default ForgetPassword;
