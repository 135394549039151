import QuestionRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/question/questionRequestsV1';

export const ACTION_GET_QUESTION_IS_ON_REQUEST = 'ACTION_GET_QUESTION_IS_ON_REQUEST:question';
export const ACTION_GET_QUESTION_DATA = 'ACTION_GET_QUESTION_DATA:question';

export const getQuestions = (userType, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_QUESTION_IS_ON_REQUEST, payload: true });
    const questions = await QuestionRequestsV1.getCurrent({ userType });
    dispatch({ type: ACTION_GET_QUESTION_DATA, payload: questions });
    if (callback) {
      callback();
    }
    return true;
  } catch {
    // empty
  } finally {
    dispatch({ type: ACTION_GET_QUESTION_IS_ON_REQUEST, payload: false });
  }
};
