import React from 'react';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import { I18n } from 'react-redux-i18n';

import * as ProviderTypes from '../../../../enum/provider_types';
import * as ProviderStatus from '../../../../enum/provider_status';
import { UserTypes } from '../../../../enum/user_types';

import * as DateUtils from '../../../date';
import * as PriceUtils from '../../../prices';
import { hasAccess } from '../../../../services/access';
import { accessTypes } from '../../../../enum/access_types';
import { accessActionTypes } from '../../../../enum/access_action_types';

function ProviderColumnsProviders(State, onActionsClick, getUser) {
  return [
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.id.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.id.title'),
      fixed: !isMobile ? 'left' : false,
      width: 100,
      render: (value) => value || '--',
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.status.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.status.title'),
      width: 250,
      render: (value) => ProviderStatus.GetProvidersStatusName(value) || '--',
      filters: ProviderStatus.GetProviderStatusFilters,
      filteredValue: State.filteredInfo?.status || null,
      selector: ProviderStatus.GetProviderStatusArray,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.name.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.name.title'),
      width: 200,
      render: (value) => value || '--',
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.document.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.document.title'),
      width: 200,
      render: (value) => value || '--',
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.type.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.type.title'),
      width: 150,
      render: (value) => ProviderTypes.GetProvidersTypeName(value) || '--',
      filters: ProviderTypes.GetProviderTypeFilters,
      filteredValue: State.filteredInfo?.type || null,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.minValue.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.minValue.title'),
      width: 200,
      render: (value) => PriceUtils.getPrice(value) || '--',
      sorter: true,
      editable: hasAccess([accessTypes.PROVIDERS], [accessActionTypes.UPDATE]),
      type: 'number',
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.createdAt.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.createdAt.title'),
      width: 200,
      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
      sorter: true,
    },
    {
      key: I18n.t('routes.panel.providers.dataTable.columns.actions.key'),
      title: I18n.t('routes.panel.providers.dataTable.columns.actions.title'),
      fixed: 'right',
      width: 65,
      render: (providerId) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ item, key, keyPath, domEvent }) => onActionsClick(providerId, item, key, keyPath, domEvent)}
            >
              <Menu.Item key="1">
                {I18n.t('routes.panel.providers.dataTable.columns.actions.goToDetailsText')}
              </Menu.Item>
              <Menu.Item key="2">
                {I18n.t('routes.panel.providers.dataTable.columns.actions.goToProviderUnitsText')}
              </Menu.Item>
              <Menu.Item key="3">
                {I18n.t('routes.panel.providers.dataTable.columns.actions.goToProviderBankDataText')}
              </Menu.Item>
              {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) &&
                hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.READ]) && (
                  <Menu.Item key="4">{I18n.t('routes.panel.providers.dataTable.columns.actions.goToTags')}</Menu.Item>
                )}
              {hasAccess([accessTypes.PROVIDER_MANAGERS], [accessActionTypes.READ]) && (
                <Menu.Item key="5">
                  {I18n.t('routes.panel.providers.dataTable.columns.actions.goToProviderManagersText')}
                </Menu.Item>
              )}
              {hasAccess([accessTypes.CONFIG_ENOTAS], [accessActionTypes.CREATE]) && (
                <Menu.Item key="6">
                  {I18n.t('routes.panel.providers.dataTable.columns.actions.goToProviderDigitalCertificate')}
                </Menu.Item>
              )}
              {hasAccess([accessTypes.PROVIDERS], [accessActionTypes.REMOVE]) && (
                <Menu.Item key="7">{I18n.t('routes.panel.providers.dataTable.columns.actions.removeText')}</Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="ant-dropdown-link">
            <MoreOutlined style={{ fontSize: 20 }} />
          </div>
        </Dropdown>
      ),
    },
  ];
}

export default ProviderColumnsProviders;
