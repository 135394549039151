import Immutable from 'seamless-immutable';
import {
  ACTION_GET_ORDERS_PAGINATED,
  ACTION_GET_ORDERS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ORDER_IS_ON_REQUEST,
  ACTION_CREATE_ORDER_IS_ON_REQUEST,
  ACTION_DELETE_ORDER_IS_ON_REQUEST,
  ACTION_UPDATE_ORDER_IS_ON_REQUEST,
  ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST,
  ACTION_GET_ORDER_PROBLEMS,
  ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST,
  ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST,
  ACTION_GET_ORDER_CANCEL_REASONS_DATA,
  ACTION_CREATE_ORDER_FILE_IS_ON_REQUEST,
  ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST,
  ACTION_GET_ORDER_FILES_IS_ON_REQUEST,
  ACTION_GET_ORDER_FILES_DATA,
  ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST,
  ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST,
  ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST,
  ACTION_POST_INVOICES_FUL_IS_ON_REQUEST,
} from '../actions/order';

const initialState = Immutable({
  ordersPaginated: null,
  orderCancelReasons: [],
  isGetOrdersPaginatedOnRequest: false,
  isGetOrderOnRequest: false,
  isDeleteOrderOnRequest: false,
  isCreateOrderOnRequest: false,
  isUpdateOrderOnRequest: false,
  isUpdateBankSlipIsOnRequest: false,
  isGetOrderProblemsOnRequest: false,
  isGetOrderCancelReasonsOnRequest: false,
  isUpdateOrderEvaluationIsOnRequest: false,
  isUpdateBankSlipInstallmentDueDateIsOnRequest: false,
  orderProblems: [],
  orderPaymentStatus: [],
  isGetOrderFilesOnRequest: false,
  isDeleteOrderFileOnRequest: false,
  isCreateOrderFileOnRequest: false,
  orderFiles: [],
  isPostInvoicesFulOnRequest: false,
});

export default function order(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ORDER_CANCEL_REASONS_DATA:
      return state.merge({
        orderCancelReasons: action.payload,
      });
    case ACTION_GET_ORDERS_PAGINATED:
      return state.merge({
        ordersPaginated: action.payload,
      });
    case ACTION_GET_ORDERS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetOrdersPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_IS_ON_REQUEST:
      return state.merge({
        isGetOrderOnRequest: action.payload,
      });
    case ACTION_DELETE_ORDER_IS_ON_REQUEST:
      return state.merge({
        isDeleteOrderOnRequest: action.payload,
      });
    case ACTION_CREATE_ORDER_IS_ON_REQUEST:
      return state.merge({
        isCreateOrderOnRequest: action.payload,
      });
    case ACTION_UPDATE_ORDER_IS_ON_REQUEST:
      return state.merge({
        isUpdateOrderOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST:
      return state.merge({
        isGetOrderProblemsOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_PROBLEMS:
      return state.merge({
        orderProblems: action.payload,
      });
    case ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST:
      return state.merge({
        isGetOrderCancelReasonsOnRequest: action.payload,
      });
    case ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST:
      return state.merge({
        isRenewOrderPaymentOnRequest: action.payload,
      });
    case ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST:
      return state.merge({
        isUpdateOrderEvaluationIsOnRequest: action.payload,
      });
    case ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST:
      return state.merge({
        isUpdateBankSlipInstallmentDueDateIsOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_FILES_IS_ON_REQUEST:
      return state.merge({
        isGetOrderFilesOnRequest: action.payload,
      });
    case ACTION_GET_ORDER_FILES_DATA:
      return state.merge({
        orderFiles: action.payload,
      });
    case ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST:
      return state.merge({
        isDeleteOrderFileOnRequest: action.payload,
      });
    case ACTION_CREATE_ORDER_FILE_IS_ON_REQUEST:
      return state.merge({
        isCreateOrderFileOnRequest: action.payload,
      });
    case ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST:
      return state.merge({
        isUpdateBankSlipIsOnRequest: action.payload,
      });
    case ACTION_POST_INVOICES_FUL_IS_ON_REQUEST:
      return state.merge({
        isPostInvoicesFulOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getOrdersPaginated(state) {
  return state.order.ordersPaginated;
}

export function isGetOrdersPaginatedOnRequest(state) {
  return state.order.isGetOrdersPaginatedOnRequest;
}

export function isDeleteOrderOnRequest(state) {
  return state.order.isDeleteOrderOnRequest;
}

export function isCreateOrderOnRequest(state) {
  return state.order.isCreateOrderOnRequest;
}

export function isUpdateOrderOnRequest(state) {
  return state.order.isUpdateOrderOnRequest;
}

export function getOrderProblems(state) {
  return state.order.orderProblems;
}

export function isUpdateOrderEvaluationIsOnRequest(state) {
  return state.order.isUpdateOrderEvaluationIsOnRequest;
}

export function isPostInvoicesFulOnRequest(state) {
  return state.order.isPostInvoicesFulOnRequest;
}
