import { notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import OrderRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/order/orderRequestsV1';
import OrderPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/order/panel/orderPanelRequestsV1';
import { OrderStatus } from '../../enum/order_status';
import { OrderDeliveryType } from '../../enum/order_delivery_types';

export const ACTION_GET_ORDERS_PAGINATED = 'ACTION_GET_USERS_PAGINATED:order';
export const ACTION_GET_ORDERS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST:order';
export const ACTION_GET_ORDER_IS_ON_REQUEST = 'ACTION_GET_ORDER_IS_ON_REQUEST:order';
export const ACTION_CREATE_ORDER_IS_ON_REQUEST = 'ACTION_CREATE_ORDER_IS_ON_REQUEST:order';
export const ACTION_UPDATE_ORDER_IS_ON_REQUEST = 'ACTION_UPDATE_ORDER_IS_ON_REQUEST:order';
export const ACTION_DELETE_ORDER_IS_ON_REQUEST = 'ACTION_DELETE_ORDER_IS_ON_REQUEST:order';
export const ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST = 'ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST:order';
export const ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST = 'ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST:order';
export const ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST = 'ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST:order';
export const ACTION_GET_ORDER_PROBLEMS = 'ACTION_GET_ORDER_PROBLEMS:order';
export const ACTION_GET_ORDER_CANCEL_REASONS_DATA = 'ACTION_GET_ORDER_CANCEL_REASONS_DATA:order';
export const ACTION_GET_ORDER_FILES_IS_ON_REQUEST = 'ACTION_GET_ORDER_FILES_IS_ON_REQUEST:order';
export const ACTION_CREATE_ORDER_FILE_IS_ON_REQUEST = 'ACTION_CREATE_ORDER_FILE_IS_ON_REQUEST:order';
export const ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST = 'ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST:order';
export const ACTION_GET_ORDER_FILES_DATA = 'ACTION_GET_ORDER_FILES_DATA:order';
export const ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST = 'ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST:order';
export const ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST =
  'ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST:order';
export const ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST = 'ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST:order';
export const ACTION_POST_INVOICES_FUL_IS_ON_REQUEST = 'ACTION_POST_INVOICES_FUL_IS_ON_REQUEST:order';
export const getOrdersPaginated = (parameters) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORDERS_PAGINATED_IS_ON_REQUEST, payload: true });

    const { status } = parameters;

    if (status && status.length && status.includes(OrderStatus.WAITING_DELIVERY)) {
      parameters.status.push(OrderStatus.WAITING_DELIVERY);
      if (parameters?.deliveryType?.length) {
        parameters.deliveryType.push(OrderDeliveryType.DELIVERY);
      } else {
        parameters.deliveryType = [OrderDeliveryType.DELIVERY];
      }
    }
    if (status && status.length && status.includes(OrderStatus.WAITING_DELIVERY_ON_PROVIDER)) {
      parameters.status.push(OrderStatus.WAITING_DELIVERY);
      if (parameters?.deliveryType?.length) {
        parameters.deliveryType.push(OrderDeliveryType.ON_PROVIDER);
      } else {
        parameters.deliveryType = [OrderDeliveryType.ON_PROVIDER];
      }
    }

    parameters = {
      ...parameters,
      limit: parameters?.limit || 10,
      page: parameters?.page ? parameters.page - 1 : 0,
    };

    const payload = await OrderPanelRequestsV1.getAll(parameters);
    dispatch({
      type: ACTION_GET_ORDERS_PAGINATED,
      payload,
    });
  } finally {
    dispatch({ type: ACTION_GET_ORDERS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const getOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORDER_IS_ON_REQUEST, payload: true });
    if (id) return await OrderPanelRequestsV1.getById(id);
  } finally {
    dispatch({ type: ACTION_GET_ORDER_IS_ON_REQUEST, payload: false });
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_ORDER_IS_ON_REQUEST, payload: true });
    await OrderPanelRequestsV1.remove(id);
    return true;
  } finally {
    dispatch({ type: ACTION_DELETE_ORDER_IS_ON_REQUEST, payload: false });
  }
};

export const updateOrder = (orderId, data) => async (dispatch) => {
  let response = false;
  if (data.discountExtra) {
    data = {
      ...data,
      discountExtra: Number(data?.discountExtra),
    };
  }
  try {
    dispatch({ type: ACTION_UPDATE_ORDER_IS_ON_REQUEST, payload: true });
    response = await OrderPanelRequestsV1.update(orderId, data);
  } catch (e) {
    response = false;
  } finally {
    dispatch({ type: ACTION_UPDATE_ORDER_IS_ON_REQUEST, payload: false });
  }
  return response;
};

export const createOrder = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_ORDER_IS_ON_REQUEST, payload: true });
    await OrderPanelRequestsV1.create(data);
    return true;
  } finally {
    dispatch({ type: ACTION_CREATE_ORDER_IS_ON_REQUEST, payload: false });
  }
};

export const getOrderProblems = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST, payload: true });
    const problems = await OrderRequestsV1.getWithProblem();
    dispatch({ type: ACTION_GET_ORDER_PROBLEMS, payload: problems });
  } finally {
    dispatch({ type: ACTION_GET_ORDER_PROBLEMS_IS_ON_REQUEST, payload: false });
  }
};

export const getOrderCancelReason = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST, payload: true });
    const reasons = await OrderRequestsV1.getCancelReasons();
    dispatch({ type: ACTION_GET_ORDER_CANCEL_REASONS_DATA, payload: reasons });
  } finally {
    dispatch({ type: ACTION_GET_ORDER_CANCEL_REASONS_IS_ON_REQUEST, payload: false });
  }
};

export const renewOrderPayment = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST, payload: true });
    await OrderRequestsV1.renewPayment(orderId);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({
      type: ACTION_RENEW_ORDER_PAYMENT_IS_ON_REQUEST,
      payload: false,
    });
  }
};

export const getOrderFiles = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORDER_FILES_IS_ON_REQUEST, payload: true });
    const files = await OrderPanelRequestsV1.getOrderFiles(id);
    dispatch({ type: ACTION_GET_ORDER_FILES_DATA, payload: files });
  } finally {
    dispatch({ type: ACTION_GET_ORDER_FILES_IS_ON_REQUEST, payload: false });
  }
};

export const createOrderFile = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_ORDER_FILE_IS_ON_REQUEST, payload: true });
    const response = await OrderPanelRequestsV1.createOrderFile(id, data);
    dispatch(getOrderFiles(id));
    return response;
  } finally {
    dispatch({ type: ACTION_CREATE_ORDER_IS_ON_REQUEST, payload: false });
  }
};

export const deleteOrderFile = (id, fileId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST, payload: true });
    await OrderPanelRequestsV1.removeOrderFile(id, fileId);
    return true;
  } finally {
    dispatch({ type: ACTION_DELETE_ORDER_FILE_IS_ON_REQUEST, payload: false });
  }
};

export const updateOrderEvaluation = (orderId, payload) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST, payload: true });
    await OrderRequestsV1.updateEvaluationByOrderId(orderId, payload);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_ORDER_EVALUATION_IS_ON_REQUEST, payload: false });
  }
};

export const updateBankSlipInstallmentDueDate = (orderId, transactionId, payload) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST, payload: true });
    await OrderRequestsV1.updateBankSlipInstallmentDueDate(orderId, transactionId, payload);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_BANKSLIP_INSTALLMENT_DUEDATE_IS_ON_REQUEST, payload: false });
  }
};

export const updateBankSlip = (orderId, payload) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST, payload: true });
    const response = await OrderRequestsV1.updateBankSlip(orderId, payload);
    return !!response;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_BANKSLIP_IS_ON_REQUEST, payload: false });
  }
};

export const postInvoicesFul = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_POST_INVOICES_FUL_IS_ON_REQUEST, payload: true });
    const success = await OrderPanelRequestsV1.postInvoicesFul(orderId);
    if (success) {
      notification.success({
        message: I18n.t('forms.order.invoices.inner.alert.createInvoicesFiles.success.message'),
        description: I18n.t('forms.order.invoices.inner.alert.createInvoicesFiles.success.description'),
      });

      return !!success;
    }
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_POST_INVOICES_FUL_IS_ON_REQUEST, payload: false });
  }
};
