import styled from 'styled-components';
import { Modal } from 'antd';
import { FaTrash } from 'react-icons/fa';

export const ContainerUsersSelected = styled.div`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  flex: 1;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 180px;
`;

export const TitleAdvancedSelectBox = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 5px;
`;

export const HeaderAdvancedSelectBox = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 10px;
`;

export const CustomFaTrash = styled(FaTrash).attrs({
  size: 16,
})`
  margin: 5px;

  :hover {
    color: red;
  }
`;

export const CustomModalAlert = styled(Modal)``;
