import firebase from 'firebase/app';
import 'firebase/messaging';
import { REACT_APP_VAPID_KEY } from '../config/env.config';

import config from '../config/firebase.config';

firebase.initializeApp(config);

const isSupported = () => firebase.messaging.isSupported();

const getMessaging = () => isSupported && firebase.messaging();

const getToken = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const token = await messaging.getToken({ vapidKey: REACT_APP_VAPID_KEY });
      if (token) return token;
    }
  } catch (err) {
    // empty
  }
};

export { isSupported, getMessaging, getToken };
