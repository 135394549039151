import { Link, useParams } from '@reach/router';
import React, { useState, useEffect, useRef } from 'react';
import { Layout, Breadcrumb, Row, Popover, Collapse, Tooltip } from 'antd';
import { DashboardFilled, QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { useReduxState } from '../../../../app/hooks/useReduxState';
import { ProductActions, HighlightActions } from '../../../../app/redux/actions';
import { humanizeDate } from '../../../../app/utils/date';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedCheckbox from '../../../../components/shared/AdvancedCheckbox';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import PaginatedDatatable from '../../../../components/shared/PaginatedDatatable/PaginatedDatatable';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';
import { getHighlightById } from '../../../../app/redux/actions/highlight';
import { AuthSelectors } from '../../../../app/redux/reducers';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect';

const { Content } = Layout;
const { Panel } = Collapse;

const defaultFields = {
  search: '',
  highlightId: null,
  page: 1,
};

function HighlightProducts({ getMyProviders }) {
  const { highlightId } = useParams();
  const dispatch = useDispatch();
  const { product, highlight } = useReduxState();
  const [advancedFilter, setAdvancedFilter] = useState(defaultFields);
  const [highlightDetails, setHighlightDetails] = useState(defaultFields);
  const datatableRef = useRef();

  const getHighlightProducts = (page) => {
    const payload = {
      ...advancedFilter,
      page: page || 1,
    };

    if (advancedFilter.onlyAssociated) {
      payload.highlightId = highlightId;
    }

    if (!payload.search || payload.search === '') {
      delete payload.search;
    }

    delete payload.onlyAssociated;

    dispatch(ProductActions.getProductsByHighlight(payload));
  };

  useEffect(() => {
    if (highlightId) {
      const getHighlightDetails = async () => {
        const response = await dispatch(getHighlightById(highlightId));
        setHighlightDetails(response);
      };

      getHighlightDetails();
    }
  }, [highlightId]);

  const onSelectProduct = ({ id }, isNotSelected, currentPage) => {
    let request = HighlightActions.associateHighlightProducts;

    if (!isNotSelected) {
      request = HighlightActions.disassociateHighlightProducts;
    }

    dispatch(request(highlightId, [id], () => getHighlightProducts(currentPage)));
  };

  const onChangeAdvancedFilters = (name, value) => {
    setAdvancedFilter((otherFields) => ({ ...otherFields, [name]: value }));
  };

  const cleanAdvancedFilters = () => {
    setAdvancedFilter(defaultFields);
    setTimeout(() => datatableRef.current?.reset(), 100);
  };

  const parseSelectedProducts = () => {
    const response = [];

    if (highlightId) {
      product.productsByHighlight?.rows?.forEach((o) => {
        if (
          o.highlights.map((oo) => oo.highlightId).includes(parseInt(highlightId, 10)) &&
          !response.includes(o.highlightId)
        ) {
          response.push(o.id);
        }
      });
    }

    return response;
  };

  const onSelectMultiple = (_params, checkedItems, products) => {
    if (checkedItems?.filter((o) => !!o).length > 0 && products?.filter((o) => !!o).length > 0) {
      const mappedProducts = products.map((o) => o?.id);
      dispatch(
        HighlightActions.associateHighlightProducts(highlightId, mappedProducts, () =>
          getHighlightProducts(advancedFilter.page),
        ),
      );
    } else {
      dispatch(
        HighlightActions.disassociateHighlightProducts(
          highlightId,
          product.productsByHighlight?.rows.map((o) => o.id),
          () => getHighlightProducts(advancedFilter.page),
        ),
      );
    }
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={I18n.t('routes.panel.administration.highlights.url')}>
              <span>{I18n.t('routes.panel.administration.highlights.pageTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{highlightDetails?.title || '--'}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.highlightProducts.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.highlightProducts.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row className="panel__layout__content--advanced-filter__item" align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilter.search}
              onChange={(val) => onChangeAdvancedFilters('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.highlightProducts.advancedFilters.searchFieldTitle')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>
          <Row>
            <AdvancedSelect
              style={{ width: 240 }}
              value={advancedFilter.providerId}
              onChange={(val) => onChangeAdvancedFilters('providerId', val)}
              placeholder={I18n.t('shared.selectSomeValue')}
              options={getMyProviders}
              allowClear
            />
          </Row>
          <Row className="panel__layout__content--advanced-filter__item">
            <AdvancedCheckbox
              label={I18n.t('routes.panel.administration.highlightProducts.advancedFilters.onlyAssociated.label')}
              onChange={(val) => onChangeAdvancedFilters('onlyAssociated', val)}
              value={advancedFilter.onlyAssociated}
            />
          </Row>
          <Row>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.highlightProducts.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <div className="panel__layout__content--advanced-filter__button">
              <AdvancedButton
                text={I18n.t('routes.panel.administration.highlightProducts.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={() => datatableRef.current.reset()}
              />
            </div>
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {highlightDetails?.title && (
          <div className="panel__layout__content__title">
            {I18n.t('routes.panel.administration.highlightProducts.tableTitle', {
              highlightName: highlightDetails?.title,
            })}
          </div>
        )}
        <PaginatedDatatable
          dataSource={product.productsByHighlight}
          ref={datatableRef}
          getMethod={getHighlightProducts}
          onChangeSelection={hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.UPDATE]) && onSelectProduct}
          loading={
            product.isGetProductsByHighlightOnRequest ||
            highlight.isAssociateHighlightProductsOnRequest ||
            highlight.isDisassociateHighlightProductsOnRequest
          }
          selectedIds={parseSelectedProducts()}
          onSelectAll={onSelectMultiple}
          disabled={!hasAccess([accessTypes.ADMIN_HIGHLIGHTS], [accessActionTypes.UPDATE])}
          onChange={(page) => onChangeAdvancedFilters('page', page)}
          columns={[
            {
              dataIndex: 'id',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.id.title'),
              fixed: !isMobile ? 'left' : false,
            },
            {
              dataIndex: 'code',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.code.title'),
              render: (val) => val || '--',
            },
            {
              dataIndex: 'name',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.name.title'),
              width: 300,
              render: (val) => (
                <Tooltip title={val} placement="topLeft">
                  <span className="panel__layout__content__column-item">{val}</span>
                </Tooltip>
              ),
            },
            {
              dataIndex: 'providerId',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.providerId.title'),
              render: (_val, row) => row.provider.id,
            },
            {
              dataIndex: 'providerName',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.providerName.title'),
              width: 300,
              render: (_val, row) => (
                <Tooltip title={row.provider.name} placement="topLeft">
                  <span className="panel__layout__content__column-item">{row.provider.name}</span>
                </Tooltip>
              ),
            },
            {
              dataIndex: 'isVisible',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.isVisible.title'),
              render: (val) =>
                val
                  ? I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.isVisible.yes')
                  : I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.isVisible.no'),
            },
            {
              dataIndex: 'createdAt',
              title: I18n.t('routes.panel.administration.highlightProducts.dataTable.columns.createdAt.title'),
              render: (val) => humanizeDate(val, 'DD/MM/YYYY HH:mm'),
              width: '20%',
            },
          ]}
        />
      </Content>
    </>
  );
}

const mapStateToProps = (state) => ({
  getMyProviders: AuthSelectors.getProviders(state),
});

export default connect(mapStateToProps)(HighlightProducts);
