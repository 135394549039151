import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UserTypes = {
  USER_NATURAL: 'user_natural',
  USER_LEGAL: 'user_legal',
  USER_REPRESENTATIVE: 'user_representative',
  ADMIN: 'admin',
  SUPPORT: 'support',
  ANYUSER: 'any_user',
};

export const GetUserTypeArray = [
  { id: UserTypes.ADMIN, name: <Translate value="enum.userType.admin" />, valid: true },
  { id: UserTypes.USER_NATURAL, name: <Translate value="enum.userType.userNatural" />, valid: true },
  { id: UserTypes.USER_LEGAL, name: <Translate value="enum.userType.userLegal" />, valid: true },
  { id: UserTypes.USER_REPRESENTATIVE, name: <Translate value="enum.userType.representative" />, valid: true },
  { id: UserTypes.SUPPORT, name: <Translate value="enum.userType.support" />, valid: true },
  { id: UserTypes.ANYUSER, name: <Translate value="enum.userType.anyUser" />, valid: false },
];

export const GetValidUserTypeArray = GetUserTypeArray.filter((type) => type.valid).map((type) => ({
  id: type.id,
  name: type.name,
}));

export const GetValidUserTypeFilters = GetValidUserTypeArray.map((type) => ({
  value: type.id,
  text: type.name,
}));

export const GetUserTypeName = (value) => GetUserTypeArray.find((o) => o.id === value)?.name;
