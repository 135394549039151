import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UserOrderStatus = {
  TOP_USER: 'top_user',
  FIRST_ORDER: 'first_order',
};

export const GetUserOrderStatusArray = [
  { id: UserOrderStatus.TOP_USER, name: <Translate value="enum.userOrderStatus.top_user" /> },
  { id: UserOrderStatus.FIRST_ORDER, name: <Translate value="enum.userOrderStatus.first_order" /> },
];

export const GetUserOrderStatusFilters = GetUserOrderStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

export const GetUserOrderStatusName = (value) => GetUserOrderStatusArray.find((o) => o.id === value).name;
