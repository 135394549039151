import React from 'react';
import { AutoComplete, Input } from 'antd';

const { Search } = Input;

function AdvancedAutoComplete({
  label,
  options,
  disabled,
  style,
  required,
  onSearch,
  onSelect,
  onKeyUp,
  onKeyDown,
  loading,
  placeholder,
}) {
  return (
    <div className="advanced-auto-complete" style={style}>
      <div style={{ display: 'flex', flexDirection: 'column' }} className="advanced-auto-complete__label">
        {label && (
          <span className="advanced-auto-complete__label__inner">
            {label} {required && <strong>*</strong>}
          </span>
        )}
        <AutoComplete
          options={options}
          disabled={disabled}
          onSearch={onSearch}
          onSelect={onSelect}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        >
          <Search size="middle" placeholder={placeholder} loading={loading} />
        </AutoComplete>
      </div>
    </div>
  );
}

export default AdvancedAutoComplete;
