import slugify from 'slugify';

export function getInitials(name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }
  return initials;
}

export function validateEmail(str) {
  const exp =
    '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]' +
    '{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';
  return exp.test(str);
}

export function removeSpecialChars(str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : null;
}

const currency = 'R$';

export const cleanCurrencyMask = (val) => {
  return parseFloat(val.replace(currency, '').replace(',', '-').replace(/\./g, '').replace(/-/g, '.'));
};

export function toTitleCase(str) {
  const splitStr = str ? str.toLowerCase().split('') : '';

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = `${splitStr[i]?.charAt(0)?.toUpperCase() || ''} ${splitStr[i]?.substring(1) || ''}`;
  }

  return splitStr.join(' ');
}

const replaceSpecialChars = (str) => {
  return str
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/([^\w]+|\s+)/g, '-')
        .replace(/-\-+/g, '-')
        .replace(/(^-+|-+$)/g, '')
        .replace(/ /g, '')
        .toLowerCase()
    : '';
};

export function localeCompare(stringOne, stringTwo) {
  return replaceSpecialChars(stringOne).indexOf(replaceSpecialChars(stringTwo)) >= 0;
}

export function formatSlug(text) {
  return slugify(text, {
    lower: true,
    strict: true,
  });
}

export function genPassword() {
  let password = '';
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const passwordLength = 8;

  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
}
