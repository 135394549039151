import { Row, Col, Drawer, Form, InputNumber, notification, Select, Input } from 'antd';
import React from 'react';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import DocumentTypes from '../../../app/enum/document_types';
import { ViewTypes } from '../../../app/enum/view_types';

import { PaymentActions, ProviderActions } from '../../../app/redux/actions';
import { PaymentSelectors, ProviderSelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedMaskedInput from '../../shared/AdvancedMaskedInput';
import Loading from '../../shared/Loading';
import './ProviderForm.less';

class ProviderPaymentAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.formInstance = React.createRef();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { id, type } = this.props;
    if (id && !prevProps.visible && type === ViewTypes.EDIT_PROVIDER_BANK_DATA) {
      this.getProviderPaymentAccount(id);
    }
  }

  getProviderPaymentAccount = async (id) => {
    const { onGetProviderPaymentAccount } = this.props;
    const paymentAccount = await onGetProviderPaymentAccount(id);
    this.setState({ paymentAccount });
    this.getPaymentsBanks();
    this.getPaymentsAccountTypes();
  };

  getPaymentsBanks = async () => {
    const { onGetPaymentsBanks } = this.props;
    const paymentsBanks = await onGetPaymentsBanks();
    this.setState({ paymentsBanks });
  };

  getPaymentsAccountTypes = async () => {
    const { onGetPaymentsAccountTypes } = this.props;
    const paymentsAccountTypes = await onGetPaymentsAccountTypes();
    this.setState({ paymentsAccountTypes });
  };

  onSubmit = async () => {
    const { onCloseForm } = this.props;
    const success = await this.onSubmitProviderPaymentAccount();

    if (success) {
      notification.success({
        message: I18n.t('forms.provider.updateSuccessTitle'),
        description: I18n.t('forms.provider.updateProviderBankDataSuccessDescription'),
      });
      await this.setState({ paymentAccount: null });
      onCloseForm(true);
    }
  };

  onSubmitProviderPaymentAccount = async () => {
    const { paymentAccount } = this.state;
    const { id: providerId, onUpdateProviderPaymentAccount, onCreateProviderPaymentAccount } = this.props;

    const { responsibleName, responsibleIdentity, email, bankCode, agency, account, accountDigit, accountType, id } =
      paymentAccount;

    const thereIsProblem =
      !responsibleName ||
      !responsibleIdentity ||
      !email ||
      !bankCode ||
      !accountType ||
      !agency ||
      !account ||
      accountDigit === null;

    if (thereIsProblem) {
      notification.error({
        message: `Não foi possivel salvar`,
        description: `Verifique se todos os campos foram preenchidos corretamente`,
      });
      return false;
    }

    if (id) {
      return onUpdateProviderPaymentAccount(providerId, paymentAccount);
    }
    return onCreateProviderPaymentAccount(providerId, paymentAccount);
  };

  providerPaymentAccountForm = (disabled) => {
    const { paymentAccount, paymentsBanks = [], paymentsAccountTypes = [] } = this.state;
    const { Item } = Form;

    return (
      <Form
        ref={this.formInstance}
        initialValues={{ ...paymentAccount }}
        onValuesChange={(changedValues, allValues) =>
          this.setState({ paymentAccount: { ...paymentAccount, ...allValues } })
        }
        layout="vertical"
        hideRequiredMark
      >
        <Form.Item
          name="responsibleName"
          label="Nome do responsável"
          rules={[
            {
              required: true,
              message: 'Por favor insira um nome',
            },
          ]}
        >
          <Input placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail do responsável"
          rules={[
            {
              type: 'email',
              message: 'Por favor insira um e-mail válido',
            },
            {
              required: true,
              message: 'Por favor insira um e-mail',
            },
          ]}
        >
          <Input placeholder={I18n.t('shared.typeSomething')} disabled={disabled} />
        </Form.Item>

        <AdvancedMaskedInput
          label="CPF do responsável"
          value={paymentAccount.responsibleIdentity}
          onChange={(responsibleIdentity) =>
            this.setState({ paymentAccount: { ...paymentAccount, responsibleIdentity } })
          }
          kind={DocumentTypes.CPF}
          disabled={disabled}
        />

        <Item name="accountType" label="Tipo de conta">
          <Select placeholder={I18n.t('shared.selectSomeValue')} disabled={disabled}>
            {paymentsAccountTypes.map((accountType) => (
              <Select.Option key={accountType.code} value={accountType.code}>
                {accountType.name}
              </Select.Option>
            ))}
          </Select>
        </Item>

        <Item name="bankCode" label="Banco">
          <Select placeholder={I18n.t('shared.selectSomeValue')} disabled={disabled}>
            {paymentsBanks.map((bank) => (
              <Select.Option key={bank.code} value={bank.code}>
                {`${bank.code} - ${bank.name}`}
              </Select.Option>
            ))}
          </Select>
        </Item>

        <Row justify="space-between">
          <Col span={11}>
            <Item name="agency" label="Agência">
              <InputNumber placeholder={I18n.t('shared.typeSomething')} style={{ width: '100%' }} disabled={disabled} />
            </Item>
          </Col>
          <Col span={11}>
            <Item name="agencyDigit" label="Dígito da agência">
              <InputNumber placeholder={I18n.t('shared.typeSomething')} style={{ width: '100%' }} disabled={disabled} />
            </Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <Item name="account" label="Número da conta">
              <InputNumber placeholder={I18n.t('shared.typeSomething')} style={{ width: '100%' }} disabled={disabled} />
            </Item>
          </Col>
          <Col span={11}>
            <Item name="accountDigit" label="Dígito da conta">
              <InputNumber placeholder={I18n.t('shared.typeSomething')} style={{ width: '100%' }} disabled={disabled} />
            </Item>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { paymentAccount } = this.state;
    const {
      visible,
      type,
      onCloseForm,
      isGetProviderPaymentAccountOnRequest,
      isCreateProviderPaymentAccountOnRequest,
      isUpdateProviderPaymentAccountOnRequest,
      isDeleteProviderPaymentAccountOnRequest,
      isGetPaymentsBanksOnRequest,
      isGetPaymentsAccountTypesOnRequest,
      disabled,
    } = this.props;

    return (
      <Drawer
        maskClosable={false}
        className="drawer"
        title={I18n.t('forms.provider.updateProviderBankDataTitle')}
        onClose={() => {
          this.setState({ paymentAccount: null });
          onCloseForm();
        }}
        visible={visible}
        headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<MdClose color="black" size="25px" />}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <AdvancedButton
              type="link"
              text={I18n.t('forms.cancelButtonText')}
              onClick={() => {
                this.setState({ paymentAccount: null });
                onCloseForm();
              }}
            />
            {!disabled ? (
              <AdvancedButton
                text={I18n.t('forms.submitButtonText')}
                loading={
                  isCreateProviderPaymentAccountOnRequest ||
                  isUpdateProviderPaymentAccountOnRequest ||
                  isDeleteProviderPaymentAccountOnRequest
                }
                onClick={() => this.onSubmit()}
              />
            ) : (
              <div />
            )}
          </div>
        }
      >
        {paymentAccount &&
          !isGetProviderPaymentAccountOnRequest &&
          !isGetPaymentsBanksOnRequest &&
          !isGetPaymentsAccountTypesOnRequest &&
          type === ViewTypes.EDIT_PROVIDER_BANK_DATA &&
          this.providerPaymentAccountForm(disabled)}

        <Loading
          size={40}
          loading={
            isGetProviderPaymentAccountOnRequest || isGetPaymentsBanksOnRequest || isGetPaymentsAccountTypesOnRequest
          }
        />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  isGetProviderPaymentAccountOnRequest: ProviderSelectors.isGetProviderPaymentAccountOnRequest(state),
  isCreateProviderPaymentAccountOnRequest: ProviderSelectors.isCreateProviderPaymentAccountOnRequest(state),
  isUpdateProviderPaymentAccountOnRequest: ProviderSelectors.isUpdateProviderPaymentAccountOnRequest(state),
  isDeleteProviderPaymentAccountOnRequest: ProviderSelectors.isDeleteProviderPaymentAccountOnRequest(state),
  isGetPaymentsBanksOnRequest: PaymentSelectors.isGetPaymentsBanksOnRequest(state),
  isGetPaymentsAccountTypesOnRequest: PaymentSelectors.isGetPaymentsAccountTypesOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProviderPaymentAccount: (id) => dispatch(ProviderActions.getProviderPaymentAccount(id)),
  onUpdateProviderPaymentAccount: (id, data) => dispatch(ProviderActions.updateProviderPaymentAccount(id, data)),
  onCreateProviderPaymentAccount: (id, data) => dispatch(ProviderActions.createProviderPaymentAccount(id, data)),
  onDeleteProviderPaymentAccount: (id) => dispatch(ProviderActions.deleteProviderPaymentAccount(id)),
  onGetPaymentsBanks: () => dispatch(PaymentActions.getPaymentsBanks()),
  onGetPaymentsAccountTypes: () => dispatch(PaymentActions.getPaymentsAccountTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderPaymentAccountForm);
