import Immutable from 'seamless-immutable';
import DocumentTypes from '../../enum/document_types';
import { UserTypes } from '../../enum/user_types';
import {
  ACTION_GET_USERS_PAGINATED,
  ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_USER_IS_ON_REQUEST,
  ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST,
  ACTION_DELETE_USER_IS_ON_REQUEST,
  ACTION_CREATE_USER_IS_ON_REQUEST,
  ACTION_UPDATE_USER_IS_ON_REQUEST,
  ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST,
  ACTION_CREATE_CODE_IS_ON_REQUEST,
  ACTION_VALIDATE_CODE_IS_ON_REQUEST,
  ACTION_GET_USER_ORIGIN_IS_ON_REQUEST,
  ACTION_GET_USER_ORIGIN,
  ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST,
  ACTION_GET_USER_COMPANY_TYPE,
  ACTION_GET_USER_STATUS_IS_ON_REQUEST,
  ACTION_GET_USER_STATUS,
  ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST,
  ACTION_GET_USERS_SUPPORT,
  ACTION_GET_USER_SEARCH_IS_ON_REQUEST,
  ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST,
  ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST,
  ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST,
  ACTION_SET_SIGNUP_DATA,
} from '../actions/user';

const initialState = Immutable({
  usersPaginated: null,
  isCreateCodeIsOnRequest: false,
  isCreateUserAndCodeIsOnRequest: false,
  isValidateCodeIsOnRequest: false,
  isGetUsersPaginatedOnRequest: false,
  isGetUserOnRequest: false,
  isCreateUserOnRequest: false,
  isUpdateUserOnRequest: false,
  isDeleteUserOnRequest: false,
  isUploadUserOnRequest: false,
  isGetUserOriginOnRequest: false,
  userOrigins: [],
  isGetUserCompanyTypeOnRequest: false,
  userCompanyTypes: [],
  isGetUserStatusOnRequest: false,
  userStatus: [],
  isGetUserSupportOnRequest: false,
  isUpdateUserAndCodeIsOnRequest: false,
  usersSupports: [],
  isGetUserSearchIsOnRequest: false,
  isGetUserPreferencesIsOnRequest: false,
  isGetUserPaymentsOnRequest: false,
  isPostPasswordRecoverOnRequest: false,
  signupUserData: { documentType: DocumentTypes.CNPJ, type: UserTypes.USER_LEGAL },
});

export default function user(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_USERS_PAGINATED:
      return state.merge({
        usersPaginated: action.payload,
      });
    case ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetUsersPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_USER_IS_ON_REQUEST:
      return state.merge({
        isGetUserOnRequest: action.payload,
      });
    case ACTION_DELETE_USER_IS_ON_REQUEST:
      return state.merge({
        isDeleteUserOnRequest: action.payload,
      });
    case ACTION_CREATE_USER_IS_ON_REQUEST:
      return state.merge({
        isCreateUserOnRequest: action.payload,
      });
    case ACTION_UPDATE_USER_IS_ON_REQUEST:
      return state.merge({
        isUpdateUserOnRequest: action.payload,
      });
    case ACTION_GET_USER_ORIGIN_IS_ON_REQUEST:
      return state.merge({
        isGetUserOriginOnRequest: action.payload,
      });
    case ACTION_GET_USER_ORIGIN:
      return state.merge({
        userOrigins: action.payload,
      });
    case ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST:
      return state.merge({
        isGetUserCompanyTypeOnRequest: action.payload,
      });
    case ACTION_GET_USER_COMPANY_TYPE:
      return state.merge({
        userCompanyTypes: action.payload,
      });
    case ACTION_GET_USER_STATUS_IS_ON_REQUEST:
      return state.merge({
        isGetUserStatusOnRequest: action.payload,
      });
    case ACTION_GET_USER_STATUS:
      return state.merge({
        userStatus: action.payload,
      });
    case ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST:
      return state.merge({
        isGetUserSupportOnRequest: action.payload,
      });
    case ACTION_GET_USERS_SUPPORT:
      return state.merge({
        usersSupports: action.payload,
      });
    case ACTION_GET_USER_SEARCH_IS_ON_REQUEST:
      return state.merge({
        isGetUserSearchIsOnRequest: action.payload,
      });
    case ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST:
      return state.merge({
        isCreateUserAndCodeIsOnRequest: action.payload,
      });
    case ACTION_CREATE_CODE_IS_ON_REQUEST:
      return state.merge({
        isCreateCodeIsOnRequest: action.payload,
      });
    case ACTION_VALIDATE_CODE_IS_ON_REQUEST:
      return state.merge({
        isValidateCodeIsOnRequest: action.payload,
      });
    case ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST:
      return state.merge({
        isGetUserPreferencesIsOnRequest: action.payload,
      });
    case ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST:
      return state.merge({
        isUpdateUserAndCodeIsOnRequest: action.payload,
      });
    case ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST:
      return state.merge({
        isGetUserPaymentsOnRequest: action.payload,
      });
    case ACTION_SET_SIGNUP_DATA:
      return state.merge({
        signupUserData: action.payload,
      });
    case ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST:
      return state.merge({
        isPostPasswordRecoverOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getUsersPaginated(state) {
  return state.user.usersPaginated;
}

export function isGetUsersPaginatedOnRequest(state) {
  return state.user.isGetUsersPaginatedOnRequest;
}

export function isGetUserOnRequest(state) {
  return state.user.isGetUserOnRequest;
}

export function isGetUserPreferencesIsOnRequest(state) {
  return state.user.isGetUserPreferencesIsOnRequest;
}

export function isDeleteUserOnRequest(state) {
  return state.user.isDeleteUserOnRequest;
}

export function isCreateUserOnRequest(state) {
  return state.user.isCreateUserOnRequest;
}

export function isUpdateUserOnRequest(state) {
  return state.user.isUpdateUserOnRequest;
}

export function isUploadUserOnRequest(state) {
  return state.user.isUploadUserOnRequest;
}

export function isGetUserOriginOnRequest(state) {
  return state.user.isGetUserOriginOnRequest;
}

export function getUserOrigins(state) {
  return state.user.userOrigins;
}

export function isGetUserCompanyTypeOnRequest(state) {
  return state.user.isGetUserCompanyTypeOnRequest;
}

export function getUserCompanyTypes(state) {
  return state.user.userCompanyTypes;
}

export function isGetUserSupportOnRequest(state) {
  return state.user.isGetUserSupportOnRequest;
}

export function getUserStatus(state) {
  return state.user.userStatus;
}

export function isGetUserStatusOnRequest(state) {
  return state.user.isGetUserStatusOnRequest;
}

export function getUsersSupports(state) {
  return state.user.usersSupports;
}

export function isGetUserSearchIsOnRequest(state) {
  return state.user.isGetUserSearchIsOnRequest;
}

export function isCreateCodeIsOnRequest(state) {
  return state.user.isCreateCodeIsOnRequest;
}

export function isCreateUserAndCodeIsOnRequest(state) {
  return state.user.isCreateUserAndCodeIsOnRequest;
}

export function isValidateCodeIsOnRequest(state) {
  return state.user.isValidateCodeIsOnRequest;
}

export function isUpdateUserAndCodeIsOnRequest(state) {
  return state.user.isUpdateUserAndCodeIsOnRequest;
}

export function isGetUserPaymentsOnRequest(state) {
  return state.user.isGetUserPaymentsOnRequest;
}

export function isPostPasswordRecoverOnRequest(state) {
  return state.user.isPostPasswordRecoverOnRequest;
}

export function getSignupUser(state) {
  return state.user.signupUserData;
}
