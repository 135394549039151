import React from 'react';
import { Translate } from 'react-redux-i18n';

export const BooleanDefaultStatus = {
  TRUE: true,
  FALSE: false,
};

export const GetBooleanDefaultStatusArray = [
  { id: BooleanDefaultStatus.TRUE, name: <Translate value="enum.booleanDefaultStatus.true" /> },
  { id: BooleanDefaultStatus.FALSE, name: <Translate value="enum.booleanDefaultStatus.false" /> },
];

export const GetBooleanDefaultStatusFilters = GetBooleanDefaultStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

export const GetBooleanDefaultStatusName = (value) => GetBooleanDefaultStatusArray.find((o) => o.id === value).name;
