import Immutable from 'seamless-immutable';
import { ACTION_GET_LOCALE_IS_ON_REQUEST } from '../actions/locale';

const initialState = Immutable({
  localeIsOnRequest: false,
});

export default function locale(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_LOCALE_IS_ON_REQUEST:
      return state.merge({
        localeIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function isGetLocaleIsOnRequest(state) {
  return state.locale.localeIsOnRequest;
}
