import AccessGroupUserPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/accessGroupUser/panel/accessGroupUserPanelRequestsV1';

export const ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST:accessGroupsUsers';
export const ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_DATA =
  'ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_DATA:accessGroupsUsers';
export const ACTION_GET_ACCESS_GROUPS_USERS_OBTAINED = 'ACTION_GET_ACCESS_GROUPS_USERS_OBTAINED:accessGroupsUsers';

export function getAccessGroupUsersPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
    };

    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      const payload = await AccessGroupUserPanelRequestsV1.getAll(params);
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_DATA, payload });
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_OBTAINED, payload: true });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createUserAndAccessGroupsAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupUserPanelRequestsV1.create(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createUserAndAccessGroupsAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupUserPanelRequestsV1.create(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeUserAndAccessGroupsAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupUserPanelRequestsV1.remove(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeUserAndAccessGroupsAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessGroupUserPanelRequestsV1.remove(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}
