import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Upload, Modal, Col, Row, Form, Select, notification, Divider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../shared/AdvancedButton';

import { AuthSelectors, ProductSelectors } from '../../../app/redux/reducers';
import { ProductActions } from '../../../app/redux/actions';

function ImportModal({
  title,
  visible,
  loading,
  acceptFilesTypes = '',
  multiple,
  templatePath,
  cancelButtonText,
  uploadButtonText,
  onCancelButtonClick,
  templateButtonText,
  templateButtonTextDescription,
  selectFileTitle,
  selectFileDescription,
  getProviders,
  onUploadFileSuccess,
  onUploadFileMethod,
  getProductsErrors,
  onSetUploadProductsErrors,
}) {
  const [fileList, setFileList] = useState();
  const [providerId, setProviderId] = useState();

  useEffect(() => {
    if (getProductsErrors) {
      setFileList();
    }
  }, [getProductsErrors]);

  useEffect(() => {
    onSetUploadProductsErrors();
    setFileList();
    setProviderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const beforeUpload = () => false;

  const uploadFile = async () => {
    if (!providerId) {
      notification.error({
        message: I18n.t('shared.importModal.notifications.noProviderId.message'),
        description: I18n.t('shared.importModal.notifications.noProviderId.description'),
      });
      return;
    }
    if (!fileList || fileList.length <= 0) {
      notification.error({
        message: I18n.t('shared.importModal.notifications.noFile.message'),
        description: I18n.t('shared.importModal.notifications.noFile.description'),
      });
      return;
    }
    await onSetUploadProductsErrors();
    const success = await onUploadFileMethod(providerId, fileList);
    if (success) {
      onUploadFileSuccess();
    }
  };

  const { Item } = Form;
  const { Option } = Select;
  return (
    <Modal
      className="import-modal"
      title={title}
      open={visible}
      onCancel={onCancelButtonClick}
      footer={[
        <AdvancedButton type="link" onClick={onCancelButtonClick} key="cancel" text={cancelButtonText} />,
        <AdvancedButton type="primary" loading={loading} onClick={uploadFile} key="upload" text={uploadButtonText} />,
      ]}
    >
      <Row className="import-modal__template">
        <Col>
          <a href={templatePath} target="_blank" rel="noopener noreferrer">
            <strong>{templateButtonText}</strong>
          </a>
          , {templateButtonTextDescription}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Item label={I18n.t('shared.importModal.items.provider.label')}>
            <Select
              placeholder={I18n.t('shared.selectSomeValue')}
              onChange={(id) => setProviderId(id)}
              value={providerId}
            >
              {getProviders?.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row className="import-modal__input">
        <Col span={24}>
          <Upload.Dragger
            name="file"
            fileList={fileList}
            multiple={multiple}
            beforeUpload={(file) => beforeUpload(file)}
            onChange={(info) => setFileList(multiple ? info.fileList : [info.file])}
            accept={acceptFilesTypes}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: 'black' }} />
            </p>
            <p className="ant-upload-text">{selectFileTitle}</p>
            <p className="ant-upload-hint">{selectFileDescription}</p>
          </Upload.Dragger>
        </Col>
      </Row>
      {getProductsErrors && (
        <>
          <Divider />
          <strong className="import-modal__error-box__title">
            {I18n.t('shared.importModal.notifications.sheetError.titleBox')}
          </strong>
          <Row>
            <Col span={24}>
              <Row>
                <p className="import-modal__import_error">
                  {I18n.t('shared.importModal.notifications.sheetError.message')}
                </p>
              </Row>
              <Row>{getProductsErrors}</Row>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  getProviders: AuthSelectors.getProviders(state),
  getProductsErrors: ProductSelectors.getUploadProductsErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUploadProductsErrors: (errors) => dispatch(ProductActions.setUploadProductsErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
