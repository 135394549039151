import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../../../shared/AdvancedButton/AdvancedButton';
import { OrderActions } from '../../../../../app/redux/actions';
import { OrderSelectors } from '../../../../../app/redux/reducers';

function OrderInvoicesFulForm({ orderId, isPostInvoicesFulOnRequest }) {
  const dispatch = useDispatch();

  return (
    <div className="order-form__invoice-ful__content">
      <AdvancedButton
        text={I18n.t('forms.order.invoices.inner.createInvoicesFulButton')}
        style={{ flex: 1, fontWeight: 'bold' }}
        onClick={() => dispatch(OrderActions.postInvoicesFul(orderId))}
        loading={isPostInvoicesFulOnRequest}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isPostInvoicesFulOnRequest: OrderSelectors.isPostInvoicesFulOnRequest(state),
  };
}

export default connect(mapStateToProps)(OrderInvoicesFulForm);
