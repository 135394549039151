import Immutable from 'seamless-immutable';
import {
  ACTION_GET_TAGS_PAGINATED_DATA,
  ACTION_GET_TAGS_PAGINATED_IS_ON_REQUEST,
  ACTION_CREATE_TAGS_IS_ON_REQUEST,
  ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST,
  ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST,
  ACTION_GET_TAGS_DATA,
  ACTION_GET_TAGS_IS_ON_REQUEST,
} from '../actions/tag';

const initialState = Immutable({
  tags: [],
  tagsPaginated: [],
  getTagsPaginatedIsOnRequest: false,
  getTagsIsOnRequest: false,
  createTagsIsOnRequest: false,
  deleteTagsIsOnRequest: false,
  updateTagsIsOnRequest: false,
});

export default function tag(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_TAGS_PAGINATED_DATA:
      return state.merge({
        tagsPaginated: action.payload,
      });
    case ACTION_GET_TAGS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        getTagsPaginatedIsOnRequest: action.payload,
      });
    case ACTION_GET_TAGS_DATA:
      return state.merge({
        tags: action.payload,
      });
    case ACTION_GET_TAGS_IS_ON_REQUEST:
      return state.merge({
        getTagsIsOnRequest: action.payload,
      });
    case ACTION_CREATE_TAGS_IS_ON_REQUEST:
      return state.merge({
        createTagsIsOnRequest: action.payload,
      });
    case ACTION_DELETE_TAG_BY_ID_IS_ON_REQUEST:
      return state.merge({
        deleteTagsIsOnRequest: action.payload,
      });
    case ACTION_UPDATE_TAG_BY_ID_IS_ON_REQUEST:
      return state.merge({
        updateTagsIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getTagsPaginated(state) {
  return state?.tag?.tagsPaginated;
}

export function getTagsPaginatedIsOnRequest(state) {
  return state?.tag?.getTagsPaginatedIsOnRequest;
}

export function getTags(state) {
  return state?.tag?.tags;
}

export function getTagsIsOnRequest(state) {
  return state?.tag?.getTagsIsOnRequest;
}

export function createTagsIsOnRequest(state) {
  return state?.tag?.createTagsIsOnRequest;
}

export function deleteTagsIsOnRequest(state) {
  return state?.tag?.deleteTagsIsOnRequest;
}

export function updateTagByIdIsOnRequest(state) {
  return state?.tag?.updateTagsIsOnRequest;
}
