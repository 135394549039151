import React from 'react';
import { I18n } from 'react-redux-i18n';

function AdvancedPreviewHighlight({ imageUrl, title, subtitle }) {
  return (
    <div className="highlights-modal__advanced-preview" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="highlights-modal__advanced-preview__back">
        <div className="highlights-modal__advanced-preview__infos">
          <div className="highlights-modal__advanced-preview__infos__title">
            {title || I18n.t('routes.panel.administration.highlights.highlightsModal.infos.title')}
          </div>
          <div className="highlights-modal__advanced-preview__infos__subtitle">{subtitle}</div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedPreviewHighlight;
