import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UserStatus = {
  DISABLE: 'disable',
  APPROVED: 'approved',
  WAITING_FIRST_LOGIN: 'waiting_first_login',
  WAITING_VALIDATION_EMAIL: 'waiting_validation_email',
  WAITING_VALIDATION_PHONE: 'waiting_validation_phone',
  WAITING_VALIDATION_DATA: 'waiting_validation_data',
  RESET_PASSWORD: 'reset_password',
};

export const GetUserStatusArray = [
  { id: UserStatus.DISABLE, name: <Translate value="enum.userStatus.disable" /> },
  { id: UserStatus.APPROVED, name: <Translate value="enum.userStatus.approved" /> },
  { id: UserStatus.WAITING_FIRST_LOGIN, name: <Translate value="enum.userStatus.waitingFirstLogin" /> },
  { id: UserStatus.WAITING_VALIDATION_EMAIL, name: <Translate value="enum.userStatus.waitingValidationEmail" /> },
  { id: UserStatus.WAITING_VALIDATION_PHONE, name: <Translate value="enum.userStatus.waitingValidationPhone" /> },
  { id: UserStatus.WAITING_VALIDATION_DATA, name: <Translate value="enum.userStatus.waitingValidationData" /> },
  { id: UserStatus.RESET_PASSWORD, name: <Translate value="enum.userStatus.resetPassword" /> },
];

export const GetUserStatusFilters = GetUserStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

export const GetUserStatusName = (value) => GetUserStatusArray.find((o) => o.id === value).name;
