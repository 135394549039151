export default {
  application: {
    name: 'tiffins',
    shortDescription: 'tiffins - Painel',
    title: 'tiffins',
    cnpjMask: '11.111.111/1111-11',
    dateFormat: 'DD/MM/YYYY HH:mm',
    version: {
      title: 'Versão',
      number: '3.0.0',
    },
    footer: `© Copyright 2022 - tiffins ltda - Todos os direitos reservados`,
    errors: {
      onErrorCallback: {
        formatDetailsError: {
          deleted: 'que foi removido',
          wrong: 'errado',
          notFound: 'em branco',
          invalidDate: 'anterior a data de início',
        },
      },
    },
  },
  shared: {
    advancedPreviewTag: {
      itemDescription: 'Caixinha deguste',
      defaultValues: {
        text: 'DIGITE AQUI',
      },
    },
    advancedPreviewHighlight: {
      defaultValues: {
        text: 'Digite aqui',
      },
    },
    advancedColorPicker: {
      title: 'Escolha uma cor',
    },
    dataTable: {
      popOver: {
        title: 'Editar',
      },
      notifications: {
        errorOnUpdate: {
          message: 'Não foi possivel atualizar o registro!',
          description: 'Favor verifique se o valor ou seleção estam corretos e tente novamente',
        },
      },
    },
    and: ' e ',
    of: 'de',
    selectSomeValue: 'Selecione...',
    typeSomething: 'Digite aqui...',
    typeToContinue: 'Digite algo para pesquisar...',
    nothingToShow: 'Nada para exibir, verifique os parâmetros e tente novamente.',
    requiredFields: 'Campos marcados com * são requeridos.',
    importModal: {
      notifications: {
        sheetError: {
          titleBox: 'Erros encontrados:',
          message:
            'Nenhum produto foi atualizado ou cadastrado, resolva os seguintes problemas e tente subir toda planilha novamente',
        },
        noProviderId: {
          message: 'Você deve selecionar um fornecedor',
          description: 'Selecione um fornecedor para enviar o arquivo csv',
        },
        noFile: {
          message: 'Você deve selecionar um arquivo',
          description: 'Selecione um arquivo xlsx para continuar',
        },
      },
      items: {
        provider: {
          label: 'Fornecedor *',
        },
        customer: {
          label: 'Adicionar clientes: (e-mail)',
        },
      },
    },
    phonePrefix: '55',
    passwordStrengthChecker: {
      securityMessage: {
        level0: 'Digite uma senha',
        level1: 'Senha muito curta',
        level2: 'Senha insegura',
        level3: 'Senha moderada',
        level4: 'Senha relativamente segura',
        level5: 'Senha perfeita 🎉',
      },
      instructions: {
        title: 'A nova senha deve conter',
        line1: 'Pelo menos 8 caracteres',
        line2: 'Possui números',
        line3: 'Letras minusculas',
        line4: 'Pelo menos 1 letra maiuscula',
        line5: 'Senha e confirmação iguais',
      },
    },
    userPasswordModal: {
      header: {
        title: 'Redefinir senha',
      },
      info: {
        description:
          'Você solicitou a recuperação de sua senha, preencha os campos solicitados abaixo para definir uma nova.',
        confirmText: 'Sair da conta',
      },
      form: {
        email: 'E-mail',
        password: 'Nova senha',
        passwordConfirm: 'Confirmação da senha',
        submit: 'Alterar senha',
      },
    },
  },
  header: {
    providerSelectPlaceholder: 'Selecione o fornecedor',
    startSupportChat: 'Fale conosco',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza',
    },
  },
  routes: {
    signup: {
      url: '/signup',
      pageTitle: 'Registro',
      content: {
        name: 'Nome',
        namePlaceholder: 'Seu nome *',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail válido *',
        password: 'Senha',
        passwordPlaceholder: 'Senha segura *',
        passwordConfirmation: 'Confirmação de senha',
        passwordConfirmationPlaceholder: 'Confirmar senha *',
        phone: 'Celular',
        phonePlaceholder: '(XX)XXXXX-XXXX',
        nextButton: 'Próximo',
        registerButton: 'Cadastrar',
        validateButton: 'Confirmar',
        prevButton: 'Anterior',
        caution: 'Atenção: ',
        CNPJInputPlaceholder: 'CNPJ *',
        CPFInputPlaceholder: 'CPF *',
        notificationNoUserType: {
          message: 'Escolha uma das opções',
          description: 'Você gostaria apenas de comprar ou de vender também?',
        },
        notificationserNoCNPJ: {
          message: 'Estão faltando informações',
          description: 'Por favor insira seu CNPJ para continuar.',
        },
        notificationNoCPF: {
          message: 'Estão faltando informações',
          description: 'Por favor insira seu CPF para continuar.',
        },
        notificationNoCNPJValid: {
          message: 'Dados incorretos',
          description: 'Por favor insira um CNPJ válido.',
        },
        notificationNoCPFValid: {
          message: 'Dados incorretos',
          description: 'Por favor insira um CPF válido.',
        },
        confirmationDataContent: {
          notifications: {
            noCode: {
              message: 'Preencha o codigo no campo',
              description: 'Você deve informar o código de validação antes de prosseguir',
            },
          },
          content: {
            headerText: 'Insira abaixo o código que enviamos por e-mail.',
            resendCode: 'reenviar código',
            verificationInput: {
              label: 'Código',
              placeholder: 'Código de verificação',
            },
            help: {
              buttons: {
                changeDataButton: 'ALTERE OS DADOS AQUI',
                needHelpButton: 'PRECISO DE AJUDA',
              },
              headerTitle: 'Precisa de ajuda?',
              textChangeDataContent:
                'Os dados de contato inseridos estão incorretos? Clique abaixo e altere o e-mail ou o telefone.',
              textHelpContent:
                'Algo não saiu bem com o código informado, clique no botão abaixo e fale com a tiffins. Estaremos sempre aqui para te ajudar.',
            },
          },
        },
        representativeProviderContent: {
          content: {
            headerText:
              'Você trabalha exclusivamente para algum de nossos fornecedores? Se sim, escolha abaixo e seja um associado, se não, deixe em branco: ',
          },
        },
        personalDataContent: {
          content: {
            headerText:
              'Insira abaixo seus dados pessoais e de contato, assim poderemos falar com você quando for necessário:',
            name: {
              label: 'Nome',
              placeholder: 'Nome do estabelecimento ou o seu *',
            },
            phone: {
              label: 'Celular',
              placeholder: 'Celular Válido',
            },
          },
        },
        dataAccessContent: {
          contentText: {
            line1: 'Insira abaixo seus dados de acesso, são os dados que serão usados para você entrar ',
            line2: 'na plataforma:',
          },
          notifications: {
            noEmail: {
              message: 'Estão faltando informações',
              description:
                'Por favor insira um e-mail válido para continuar, o mesmo será usado para acessar a plataforma.',
            },
            noPassword: {
              message: 'Estão faltando informações',
              description: 'Por favor insire uma senha segura.',
            },
            noPasswordConfirmation: {
              message: 'Estão faltando informações',
              description: 'Por favor confirme sua senha.',
            },
            noMatchPassword: {
              message: 'Dados incorretos',
              description: 'Sua confirmação de senha está incorreta.',
            },
            noPrivicityTerms: {
              message: 'Importante!',
              description:
                'Aceite os termos de uso e políticas de privacidade, e lembre-se, não deixe de ler é bastante importante.',
            },
            noRepresentativeTerms: {
              message: 'Importante!',
              description:
                'Aceite os termos de uso do consultor de vendas, e lembre-se, não deixe de ler é bastante importante.',
            },
            noLegalTerms: {
              message: 'Importante!',
              description: 'Aceite os termos de adesão, e lembre-se, não deixe de ler é bastante importante.',
            },
            noName: {
              message: 'Estão faltando informações',
              description: 'Por favor insira o nome do seu estabelecimento ou o seu nome.',
            },
            noPhone: {
              message: 'Estão faltando informações',
              description: 'Por favor insira um telefone válido para contato.',
            },
          },
        },
        steps: {
          step1: 'Empresa',
          step2: 'Acesso',
          step3: 'Sobre sua empresa',
          step4: 'Dados de contato',
          step5: 'Confirmação',
        },
        termsOfUse: {
          policyOfPrivacity: 'política de privacidade',
          termsOfUse: 'termos de uso',
          textCheckbox: 'ao criar sua conta, você concorda com a nossa ',
        },
        termsOfRepresentative: {
          termsOfRepresentative: 'termos e condições de uso comercial',
          textCheckbox: 'consultores precisam concordar com o nosso ',
        },
        termsOfLegal: {
          terms: 'termo de adesão',
          textCheckbox: 'fornecedores precisam concordar com o nosso ',
        },
        typeOfAccount: {
          userNatural: {
            radioText: 'Eu quero comprar',
            text: {
              line1: 'Continue seu registro e aproveite todos os produtos de nossos fornecedores.',
            },
            checkboxOption: 'Quero conhecer, não possuo CNPJ',
            checkboxDescription: 'se selecionado não será possível ver preços e fazer pedidos',
          },
          userLegal: {
            radioText: 'Eu quero vender!',
            text: {
              line1:
                'Na tiffins você fornecedor vai precisar de um CNPJ, assim podemos garantir a confiabilidade e qualidade. Continue com seu registro e mostre para o mercado os seus excelentes produtos.',
            },
          },
          userRepresentative: {
            radioText: 'Eu sou consultor de vendas',
            text: {
              line1: 'Na tiffins você consultor de vendas pode ser associado a um fornecedor específico ou buscar ',
              line2: 'entre todos os nossos produtos o que mais se encaixa na sua carteira, visualizar suas ',
              line3: 'comissões e iniciar suas vendas.',
            },
            checkboxOption: 'Não possuo CNPJ',
            checkboxDescription: 'se selecionado você estará sugeito a aprovação.',
          },
        },
      },
    },
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        header: 'Entre com o e-mail e senha cadastrados',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'Entrar',
        signup: {
          text: 'Não tem uma conta na ',
          button: 'Inscreva-se agora!',
        },
        forgetPassword: {
          text: 'Esqueceu a senha?',
          button: 'Clique aqui',
        },
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
      },
      messages: {
        welcome: 'Bem vindo(a) novamente.',
      },
    },
    downloadScreen: {
      url: '/download',
      pageTitle: 'Faça download do app',
      buttonText: 'Voltar para a tela principal',
      title: 'Baixe agora!',
      description:
        'Aproveite as ofertas e variedades em nosso app, ' +
        'escolha a loja de apps do seu celular, baixe agora e não fique de fora dessa!',
    },
    forbidden: {
      url: '/forbidden',
      pageTitle: 'Sem permissão',
      buttonText: 'Voltar para a tela principal',
      title: 'Acesso negado!',
      description:
        'Você tentou acessar uma página para a qual você não têm autorização, ' +
        'entre em contato com o responsável se achar necessário.',
    },
    notfound: {
      pageTitle: 'Página não encontrada',
      buttonText: 'Voltar para a tela principal',
      title: 'Página não encontrada!',
      description:
        'Você tentou acessar uma página que não está disponível, ' +
        'entre em contato com o responsável se achar necessário.',
    },
    registerPassword: {
      url: '/register-password',
      pageTitle: 'Registro de senha',
      content: {
        logoText: 'tiffin foods',
        header: 'Entre com a senha nova',
        email: 'E-mail',
        name: 'Nome',
        password: 'Senha',
        confirmPassword: 'Confirme a senha',
        passwordPlaceholder: 'Crie senha segura',
        confirmPasswordPlaceholder: 'Confirme a senha criada',
        registerButton: 'Cadastrar',
        onSubmit: {
          notification: {
            isEmptyError: {
              message: 'Preencha todos os campos',
              description: 'Verifique se inseriu a senha.',
            },
            isPasswordEqualError: {
              message: 'Preencha a senha corretamente',
              description: 'Verifique se a senha e a confirmação da senha estão corretos.',
            },
          },
        },
      },
    },
    forgetPassword: {
      url: '/forget-password',
      pageTitle: 'Esqueci a senha',
      content: {
        logoText: 'tiffin foods',
        header: 'Recuperar a senha',
        text: 'digite seu e-mail no campo abaixo e caso o mesmo esteja presente em nossa base de dados, iremos enviar para você uma nova senha temporária.',
        email: 'E-mail de cadastro',
        backToLoginButton: 'Voltar ao login',
        sendEmailButton: 'Enviar',
        notification: {
          error: {
            message: 'Preencha o campo do email',
            description: 'Verifique se inseriu o email.',
          },
          success: {
            message: 'E-mail enviado com sucesso!',
            description: 'Verifique se chegou um email com nova senha.',
          },
        },
      },
    },
    panel: {
      pageTitle: 'Painel',
      welcome: 'Bem-vindo(a)',
      dashboard: {
        url: '/panel/dashboard',
        sidebarTitle: 'Dashboard',
        pageTitle: 'Dashboard',
        errors: {
          generic: 'Algo deu errado, tente novamente mais tarde.',
          message: 'algo saiu errado',
        },
      },
      administration: {
        url: '/panel/administration',
        pageTitle: 'Administração',
        sidebarTitle: 'Administração',
        tags: {
          popup: {
            deleteConfirm: 'Atenção, deseja realmente deletar essa tag?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
          tagsModal: {
            items: {
              textColor: 'Cor do texto',
              tagColor: 'Cor da tag',
            },
            title: 'Nova Tag',
            defaultValues: {
              text: 'DIGITE AQUI',
            },
          },
          dataTable: {
            columns: {
              id: {
                key: 'id',
                title: 'ID',
              },
              text: {
                key: 'text',
                title: 'Texto',
              },
              textColor: {
                key: 'textColor',
                title: 'Cor do Texto',
              },
              color: {
                key: 'color',
                title: 'Cor',
              },
              actions: {
                title: 'Ações',
                delete: 'Remover',
              },
            },
          },
          url: '/panel/administration/tags',
          sidebarTitle: 'Tags',
          pageTitle: 'Tags',
        },
        accessGroups: {
          popup: {
            deleteConfirm: 'Atenção, deseja realmente remove esse grupo?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
          advancedFilters: {
            title: 'Filtro avançado',
            ribbon: 'Busque pelo título do grupo de acesso',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
          },
          addNewButtonText: 'Adicionar',
          dataTable: {
            columns: {
              id: {
                key: 'id',
                title: 'ID',
              },
              name: {
                key: 'name',
                title: 'Título',
              },
              defaultUserType: {
                key: 'defaultUserType',
                title: 'Perfil padrão',
              },
              accessGroupsUsers: {
                key: 'accessGroupsUsers',
                title: 'Usuários no grupo',
                amountTitle: 'usuário(s)',
              },
              accessGroupsFeatures: {
                key: 'accessGroupsFeatures',
                title: 'Funcionalidades associadas',
                amountTitle: 'funcionalidade(s)',
              },
              actions: {
                title: 'Ações',
                edit: 'Editar',
                editFeatures: 'Gerenciar funcionalidades',
                editUsers: 'Gerenciar usuários',
                delete: 'Remover',
              },
            },
          },
          url: '/panel/administration/access-groups',
          sidebarTitle: 'Grupos de acesso',
          pageTitle: 'Grupos de acesso',
          confirmation: {
            title: 'Sucesso',
            delete: 'Grupo de acesso removido.',
          },
        },
        accessFeature: {
          popup: {
            deleteConfirm: 'Atenção, deseja realmente remove essa funcionalidade?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
          advancedFilters: {
            title: 'Filtro avançado',
            ribbon: 'Busque pelo título da funcionalidade',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
          },
          addNewButtonText: 'Adicionar',
          dataTable: {
            columns: {
              id: {
                key: 'id',
                title: 'ID',
              },
              name: {
                key: 'name',
                title: 'Título',
              },
              description: {
                key: 'description',
                title: 'Descrição',
              },
              accessFeaturesUsers: {
                key: 'accessFeaturesUsers',
                title: 'Usuários associados',
                amountTitle: 'usuário(s)',
              },
              accessGroupsFeatures: {
                key: 'accessGroupsFeatures',
                title: 'Grupos associados',
                amountTitle: 'em %{amount} grupo(s)',
              },
              actions: {
                title: 'Ações',
                edit: 'Editar',
                editUsers: 'Gerenciar usuários',
                delete: 'Remover',
              },
            },
          },
          url: '/panel/administration/features',
          sidebarTitle: 'Funcionalidades',
          pageTitle: 'Funcionalidades',
          confirmation: {
            title: 'Sucesso',
            delete: 'Funcionalidade removida.',
          },
        },
        accessGroupsUsers: {
          url: '/panel/administration/access-groups/manage-users',
          pageTitle: 'Gerenciar usuários do grupo',
          tableTitle: 'Gerenciando usuários para o grupo "%{accessGroup}"',
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo nome ou e-mail do usuário.',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
            onlyAssociated: {
              label: 'Exibir apenas usuários já associados',
            },
            type: {
              label: 'Tipos de perfil',
            },
          },
        },
        accessGroupsFeatures: {
          url: '/panel/administration/access-groups/manage-features',
          pageTitle: 'Gerenciar funcionalidades do grupo',
          tableTitle: 'Gerenciando funcionalidades para o grupo "%{accessGroup}"',
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo nome da funcionalidade.',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
            onlyAssociated: {
              label: 'Exibir apenas funcionalidades já associadas',
            },
          },
          access: {
            view: 'visualização',
            create: 'Criação',
            update: 'Atualização',
            remove: 'Remoção',
          },
        },
        accessFeaturesUsers: {
          url: '/panel/administration/features/manage-users',
          pageTitle: 'Gerenciar usuários da funcionalidade',
          tableTitle: 'Gerenciando usuários para a funcionalidade "%{accessFeature}"',
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo nome ou e-mail do usuário.',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
            onlyAssociated: {
              label: 'Exibir apenas usuários já associados',
            },
          },
          access: {
            view: 'visualização',
            create: 'Criação',
            update: 'Atualização',
            remove: 'Remoção',
          },
        },
        highlights: {
          url: '/panel/administration/highlights',
          pageTitle: 'Destaques',
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo o título',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
          },
          addNewButtonText: 'Adicionar',
          popup: {
            deleteConfirm: 'Atenção, deseja realmente deletar esse destaque?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
          messages: {
            success: {
              delete: 'Destaque removido com sucesso.',
            },
            errors: {
              generic: 'Algo deu errado, verifique os campos e tente novamente.',
            },
          },
          highlightsModal: {
            createTitle: 'Novo destaque',
            updateTitle: 'Atualizar destaque',
            associateTitle: 'Associar produtos',
            createSubmit: 'Criar',
            updateSubmit: 'Atualizar',
            infos: {
              title: 'Digite um título no campo de texto',
            },
            defaultValues: {
              title: 'Digite o título aqui',
              subtitle: 'Digite a descrição aqui',
              position: 'Digite a posição aqui',
            },
            inputLabel: {
              title: 'Título',
              subtitle: 'Descrição',
              position: 'Posição',
            },
            uploadImage: {
              selectFileImageTitle: 'Arraste e solte a imagem *',
              selectFileImageDescription: 'Tipo de arquivo permitido: jpg, jpeg, png, webp e gif',
              selectFileButtonDescription: 'para selecionar manualmente',
            },
            importCsvModal: {
              selectFileTitle: 'Clique aqui ou arraste os arquivos',
              selectFileDescription: 'Tipo de arquivo permitido: .csv',
            },
            csvSample: {
              fileText: 'Clique aqui',
              fileTextDescription: 'para realizar o download do template para realizar a importação.',
              importWarning: 'Atenção: Caso um dos ids informados não seja válido, toda a operação será cancelada.',
            },
            uploadError: {
              extensionImageMessage: 'Envie apenas arquivos com a extensão .jpg, .jpeg, .png, .webp e .gif',
            },
            productsTitle: 'Produtos',
            associationPopup: {
              deleteConfirm: 'Atenção: Deseja realmente desassociar este produto do destaque?',
              okText: 'Sim',
              cancelText: 'Cancelar',
            },
            associationAllPopup: {
              deleteConfirm: 'Atenção: Deseja realmente desassociar todos os produtos do destaque?',
              okText: 'Sim',
              cancelText: 'Cancelar',
            },
            messages: {
              createSuccess: 'Destaque criado com sucesso.',
              updateSuccess: 'Destaque atualizado com sucesso.',
              requiredFields: 'Todos os campos são requeridos.',
            },
          },
          dataTable: {
            columns: {
              id: {
                key: 'id',
                title: 'ID',
              },
              code: {
                key: 'code',
                title: 'Código de referência',
              },
              position: {
                key: 'position',
                title: 'Posição',
              },
              providerId: {
                key: 'provider',
                title: 'ID fornecedor',
              },
              providerName: {
                key: 'provider',
                title: 'Nome fornecedor',
              },
              isVisible: {
                key: 'isVisible',
                title: 'Disponível',
              },
              title: {
                key: 'title',
                title: 'Título',
              },
              products: {
                key: 'products',
                title: 'Produtos',
                amountTitle: 'produto(s)',
              },
              createdAt: {
                key: 'createdAt',
                title: 'Data de cadastro',
              },
              updatedAt: {
                key: 'updatedAt',
                title: 'Última atualização',
              },
            },
          },
          actions: {
            title: 'Ações',
            goToAssociateHighlight: 'Associar produtos',
            goToDetailsHighlight: 'Detalhes',
            delete: 'Remover',
          },
        },
        highlightProducts: {
          url: '/panel/administration/highlights',
          pageTitle: 'Gerenciar produtos do destaque',
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo ID ou nome do produto.',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
            onlyAssociated: {
              label: 'Exibir apenas produtos já associados',
            },
          },
          tableTitle: 'Gerenciando produtos para o destaque "%{highlightName}"',
          noProductError: 'Esse destaque não estará visível até que seja adicionado ao menos um produto.',
          dataTable: {
            columns: {
              id: {
                title: 'ID',
              },
              code: {
                title: 'Código',
              },
              name: {
                title: 'Nome',
              },
              providerId: {
                title: 'ID fornecedor',
              },
              providerName: {
                title: 'Nome fornecedor',
              },
              isVisible: {
                title: 'Disponível',
                yes: 'Sim',
                no: 'Não',
              },
              createdAt: {
                title: 'Data de criação',
              },
              updatedAt: {
                title: 'Data de atualização',
              },
            },
          },
        },
        collections: {
          advancedFilters: {
            title: 'Filtro avançado',
            searchFieldTitle: 'Busque pelo nome da lista',
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
          },
          addNewButtonText: 'Adicionar',
          popup: {
            deleteConfirm: 'Atenção, deseja realmente deletar essa lista?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
          dataTable: {
            columns: {
              id: {
                key: 'id',
                title: 'ID',
              },
              userId: {
                key: 'userId',
                title: 'ID do usuário',
              },
              type: {
                key: 'type',
                title: 'Tipo',
              },
              title: {
                key: 'title',
                title: 'Nome da lista',
              },
              description: {
                key: 'description',
                title: 'Descrição',
              },
              actions: {
                title: 'Ações',
                edit: 'Editar',
                editUsers: 'Gerenciar usuários',
                delete: 'Remover',
              },
            },
          },
          actions: {
            title: 'Ações',
            goToCollectionDetails: 'Detalhes',
            delete: 'Remover',
          },
          url: '/panel/administration/collections',
          sidebarTitle: 'Listas',
          pageTitle: 'Listas',
          confirmation: {
            title: 'Sucesso',
            delete: 'Lista removida.',
          },
        },
      },
      providerRatings: {
        url: '/panel/evaluations',
        sidebarTitle: 'Avaliações',
        pageTitle: 'Avaliações de pedidos',
        titles: {
          modalTitle: 'Avaliação do pedido %{orderId}',
          evaluationTitle: 'avaliação do usuário',
          yourReply: 'sua resposta',
        },
        notifications: {
          success: {
            message: 'Sucesso!',
            description: 'A avaliação foi respondida.',
          },
          errors: {
            noReply: {
              message: 'Resposta em branco',
              description: 'Você deve preencher o campo de resposta para continuar',
            },
          },
        },
        textArea: {
          placeholder: 'Escreva sua resposta aqui',
        },
        content: {
          warning: 'Atenção!',
          topText: 'Não será possivel alterar a sua',
          bottomText: 'resposta após enviada.',
        },
        popover: {
          buttons: {
            cancelButton: 'não, cancelar',
            sendButton: 'enviar resposta',
            confirmButton: 'certeza',
          },
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            orderId: {
              key: 'orderId',
              title: 'Pedido',
            },
            userName: {
              key: 'userName',
              title: 'Usuário',
            },
            rating: {
              key: 'rating',
              title: 'Avaliação',
            },
            comment: {
              key: 'comment',
              title: 'Comentário',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de avaliação',
            },
            repliedAt: {
              key: 'repliedAt',
              title: 'Data de réplica',
            },
            actions: {
              goToDetailsText: 'Detalhes',
            },
          },
        },
      },
      providerBilling: {
        url: '/panel/provider-billing',
        sidebarTitle: 'Cobranças',
        pageTitle: 'Cobranças',
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            provider: {
              key: 'provider',
              title: 'Nome fornecedor',
            },
            status: {
              key: 'paymentStatus',
              title: 'Status',
            },
            billingTotalValue: {
              key: 'billingTotalValue',
              title: 'Valor Total',
            },
            bankSlipUrl: {
              key: 'jsonPayment',
              title: 'Boleto',
            },
            dueDate: {
              key: 'jsonPayment',
              title: 'Vencimento',
            },
            operationDate: {
              key: 'jsonPayment',
              title: 'Data operação',
            },
            actions: {
              goToDetailsText: 'Detalhes',
            },
          },
        },
      },
      marketing: {
        url: '/panel/marketing',
        sidebarTitle: 'Marketing',
        pageTitle: 'Marketing',
        notifications: {
          pageTitle: 'Notificações',
          url: '/panel/marketing/notifications',
          advancedFilters: {
            title: 'Configuracões',
            searchFieldTitle: `Busque pelo nome ou cnpj do fornecedor`,
            clearButtonText: 'Limpar',
            filterButtonText: 'Buscar',
          },
        },
      },
      customers: {
        url: '/panel/customers',
        sidebarTitle: 'Meus clientes',
        pageTitle: 'Meus clientes',
        addNewButtonText: 'Adicionar',
        importModal: {
          templateButtonTextDescription: 'para realizar o download do template de importação.',
          selectFileTitle: 'Clique aqui ou arraste arquivos',
          selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
          items: {
            emailList: {
              manual: 'Adicionar clientes (e-mail)',
              csv: 'Importar lista de clientes (csv)',
            },
            provider: {
              label: 'Fornecedor *',
            },
          },
          templateButtonText: 'Clique aqui',
          title: 'Indicar seus clientes',
          cancelText: 'Cancelar',
          okText: 'Cadastrar',
          notification: {
            success: {
              message: 'Cadastrado com sucesso',
              description: 'Parabens! Os usuários foram cadastrados com sucesso.',
            },
            error: {
              noEmail: {
                message: 'Ocorreu um erro',
                description: 'Você deve indicar ao menos um e-mail.',
              },
              noProviderId: {
                message: 'Ocorreu um erro',
                description: 'Você deve selecionar o fornecedor para continuar.',
              },
              wrongEmails: {
                message: 'Ocorreu um erro',
                description: 'Por favor, verifique os e-mails em vermelho e tente novamente.',
              },
            },
          },
        },
        advancedFilters: {
          title: 'Filtro avançado',
          searchFieldTitle: 'Busque pelo e-mail do usuário.',
          clearButtonText: 'Limpar',
          filterButtonText: 'Buscar',
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            name: {
              key: 'name',
              title: 'nome',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            origin: {
              key: 'origin',
              title: 'Origem',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
          },
        },
      },
      users: {
        url: '/panel/users',
        sidebarTitle: 'Usuários',
        pageTitle: 'Usuários',
        addNewButtonText: 'Adicionar',
        deleteSuccess: 'Usuário removido com sucesso.',
        advancedFilters: {
          title: 'Filtro avançado',
          searchFieldTitle: `Busque pelo nome, e-mail, telefone, cnpj, cpf,
            razão social ou inscrição estadual do usuário`,
          clearButtonText: 'Limpar',
          filterButtonText: 'Buscar',
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            type: {
              key: 'type',
              title: 'Tipo de perfil',
            },
            status: {
              key: 'status',
              title: 'Situação cadastral',
            },
            supportStatus: {
              key: 'supportStatusId',
              title: 'Etapa do lead',
            },
            support: {
              key: 'supportId',
              title: 'Suporte',
            },
            email: {
              key: 'email',
              title: 'E-mail',
            },
            phone: {
              key: 'phone',
              title: 'Telefone',
            },
            document: {
              key: 'document',
              title: 'Documento',
            },
            companyName: {
              key: 'companyName',
              title: 'Razão Social',
            },
            ie: {
              key: 'ie',
              title: 'Inscrição Estadual',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            hasOpenCart: {
              key: 'hasOpenCart',
              title: 'Carrinho aberto',
            },
            lastOrderAt: {
              key: 'lastOrderAt',
              title: 'Data último pedido',
            },
            fup: {
              key: 'fup',
              title: 'Lembrete de FUP',
            },
            lastFupAt: {
              key: 'lastFupAt',
              title: 'Data último FUP',
            },
            nextFupAt: {
              key: 'nextFupAt',
              title: 'Data próximo FUP',
            },
            origin: {
              key: 'originId',
              title: 'Origem',
            },
            companyType: {
              key: 'companyTypeId',
              title: 'Tipo da empresa',
            },
            commentsSupport: {
              key: 'commentsSupport',
              title: 'Histórico do suporte',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              generateNewPasswordOption: 'Gerar senha temporária',
              removeText: 'Remover',
              goToUserPreferences: 'Preferências',
              paymentOptions: 'Condições de pagamento',
            },
          },
        },
        details: {
          pageTitle: 'Detalhes do usuário',
          pageTitleAdd: 'Adicionar usuário',
          pageDescription: 'Preencha os campos abaixo para editar um usuário.',
          pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo usuário',
          changePasswordPhrase: 'Desejo alterar a senha do usuário',
          messages: {
            success: {
              update: 'Usuário atualizado com sucesso.',
              create: 'Usuário adicionado com sucesso.',
            },
            errors: {
              generic: 'Algo deu errado, verifique os campos tente novamente.',
              email: 'E-mail inválido, verifique e tente novamente.',
            },
          },
        },
      },
      orders: {
        url: '/panel/orders',
        sidebarTitle: 'Pedidos',
        pageTitle: 'Pedidos',
        addNewButtonText: 'Adicionar',
        advancedFilters: {
          title: 'Filtro avançado',
          searchFieldTitle: `Busque pelo nome, e-mail, cnpj, cpf, razão social ou inscrição estadual do usuário`,
          clearButtonText: 'Limpar',
          filterButtonText: 'Buscar',
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            status: {
              key: 'status',
              title: 'Status do pedido',
            },
            shipmentStatus: {
              title: 'Status do envio',
            },
            document: {
              key: 'user',
              title: 'CNPJ',
            },
            paymentType: {
              key: 'paymentType',
              title: 'Meio de pagamento',
            },
            creditType: {
              key: 'credit',
              title: 'Crédito liberado por',
            },
            jsonInstallments: {
              title: 'Parcelas do pagamento',
            },
            userId: {
              key: 'userId',
              title: 'ID usuár.',
            },
            userName: {
              key: 'user',
              title: 'Nome cliente',
            },
            providerId: {
              key: 'providerId',
              title: 'ID forn.',
            },
            providerName: {
              key: 'provider',
              title: 'Nome fornecedor',
            },
            deliveryType: {
              key: 'deliveryType',
              title: 'Tipo de entrega',
            },
            paymentStatus: {
              key: 'paymentStatus',
              title: 'Status pagamento',
            },
            lastPaymentDueAt: {
              key: 'lastPaymentDueAt',
              title: 'Vencido em',
            },
            distributor: {
              title: 'Transportadora',
              onProvider: 'Retirar no local',
            },
            productsValue: {
              key: 'value',
              title: 'Valor dos produtos',
            },
            discount: {
              title: 'Desconto',
              popover:
                'Desconto sobre os produtos fornecidos pelo parceiro e pelas moedas tiffins ou da comissão tiffins',
            },
            freight: {
              key: 'freight',
              title: 'Frete',
            },
            tax: {
              key: 'tax',
              title: 'Impostos',
            },
            financialFees: {
              title: 'Taxa financeira',
              popover: {
                title: 'Cálculos da taxa financeira',
                content: {
                  pix: 'PIX - 1%',
                  bankSlip: 'Boleto - R$2,50 por boleto',
                  creditCard: 'Cartão de crédito - Sem taxas',
                },
              },
            },
            commission: {
              title: 'Comissão',
            },
            receiveValue: {
              title: 'Valor a receber',
              popover: 'O valor do frete estará incluso se a contratação for feita pelo parceiro',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data do pedido',
            },
            userOrderStatus: {
              key: 'userOrderStatus',
              title: 'Class. usuár.',
            },
            problem: {
              key: 'problemId',
              title: 'Problema',
            },
            support: {
              key: 'supportId',
              title: 'Suporte',
            },
            commentsSupport: {
              key: 'commentsSupport',
              title: 'Histórico do suporte',
            },
            origin: {
              key: 'origin',
              title: 'Origem',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
              readyShipmentStatusText: 'Pronto para envio',
              onCarriageShipmentStatusText: 'Pedido despachado',
              getLabelText: 'Imprimir etiqueta',
            },
          },
          cells: {
            warnings: {
              title: 'atenção',
              content: {
                defaultingCustomer: '- O cliente está inadimplente. Conferir pagamentos de pedidos antigos.',
                divergentAddress:
                  '- O endereço informado pelo cliente não confere com o do CNPJ. Realize uma pesquisa de segurança.',
                latePayment: '- O pagamento deste pedido ainda não foi feito. Entre em contato com o cliente.',
              },
            },
          },
        },
        details: {
          pageTitle: 'Detalhes do pedido',
          pageTitleAdd: 'Adicionar pedido',
          pageDescription: 'Preencha os campos abaixo para editar um pedido.',
          pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo pedido',
          messages: {
            success: {
              update: 'Pedido atualizado com sucesso.',
              create: 'Pedido adicionado com sucesso.',
            },
            errors: {
              generic: 'Algo deu errado, verifique os campos tente novamente.',
            },
          },
        },
        messages: {
          shipment: {
            success: 'Status atualizados com sucesso!',
            error: 'Algo deu errado ao tentar atualizar o status, verifique e tente novamente.',
          },
        },
      },
      products: {
        url: '/panel/products',
        sidebarTitle: 'Produtos',
        pageTitle: 'Produtos',
        addNewButtonText: 'Adicionar',
        messages: {
          success: {
            delete: 'Produto removido com sucesso.',
          },
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
        importCsvButtonText: 'Importar',
        exportModal: {
          errors: {
            noProvider: {
              message: 'Erro ao exportar',
              description: 'Você deve selecionar o fornecedor para realizar a exportação dos produtos.',
            },
          },
          container: {
            title: 'Selecione o fornecedor:',
          },
          title: 'Exportar produtos',
          okText: 'Exportar',
          cancelText: 'Cancelar',
        },
        importCsvModal: {
          okText: 'Realizar upload',
          cancelText: 'Cancelar',
          title: 'Importar produtos por xlsx',
          templateButtonText: 'Clique aqui',
          templateButtonTextDescription: 'para realizar o download do template de importação.',
          selectFileTitle: 'Clique aqui ou arraste o arquivo',
          selectFileDescription: 'Tipo de arquivo permitido: .xlsx de até 2mb',
          messages: {
            errors: {
              invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
              maxSelectedFiles: 'Apenas um arquivo por vez.',
              generic: 'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
              genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
            },
            success: {
              import: 'Produto(s) importado(s) com sucesso.',
            },
          },
        },
        advancedFilters: {
          title: 'Filtro avançado',
          searchFieldTitle: 'Busque pelo nome ou código de referência do produto',
          clearButtonText: 'Limpar',
          filterButtonText: 'Buscar',
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            code: {
              key: 'code',
              title: 'Código de referência',
            },
            providerId: {
              key: 'provider',
              title: 'ID fornecedor',
            },
            providerName: {
              key: 'provider',
              title: 'Nome fornecedor',
            },
            isVisible: {
              key: 'isVisible',
              title: 'Disponível',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            updatedAt: {
              key: 'updatedAt',
              title: 'Última atualização',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              goToDetailsText: 'Detalhes',
              removeText: 'Remover',
            },
          },
        },
        details: {
          pageTitle: 'Detalhes do produto',
          pageTitleAdd: 'Adicionar produto',
          pageDescription: 'Preencha os campos abaixo para editar um produto.',
          pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo produto',
          messages: {
            success: {
              update: 'Produto atualizado com sucesso.',
              create: 'Produto adicionado com sucesso.',
            },
            errors: {
              generic: 'Algo deu errado, verifique os campos tente novamente.',
            },
          },
        },
      },
      providers: {
        url: '/panel/providers',
        sidebarTitle: 'Fornecedores',
        pageTitle: 'Fornecedores',
        addNewButtonText: 'Adicionar',
        messages: {
          success: {
            delete: 'Fornecedor removido com sucesso.',
          },
          errors: {
            generic: 'Algo deu errado, verifique os campos tente novamente.',
          },
        },
        advancedFilters: {
          title: 'Filtro avançado',
          searchFieldTitle: `Busque pelo nome ou cnpj do fornecedor`,
          clearButtonText: 'Limpar',
          filterButtonText: 'Buscar',
        },
        dataTable: {
          columns: {
            id: {
              key: 'id',
              title: 'ID',
            },
            status: {
              key: 'status',
              title: 'Etapa do fornecedor',
            },
            document: {
              key: 'document',
              title: 'Documento',
            },
            name: {
              key: 'name',
              title: 'Nome',
            },
            minValue: {
              key: 'minValue',
              title: 'Valor minimo',
            },
            type: {
              key: 'type',
              title: 'Tipo',
            },
            score: {
              key: 'score',
              title: 'Score',
            },
            position: {
              key: 'position',
              title: 'Position',
            },
            createdAt: {
              key: 'createdAt',
              title: 'Data de cadastro',
            },
            actions: {
              key: 'id',
              title: 'Ações',
              removeText: 'Remover',
              goToDetailsText: 'Detalhes',
              goToProviderUnitsText: 'Centros de distribuição',
              goToProviderBankDataText: 'Dados bancários',
              goToTags: 'Tags',
              goToProviderManagersText: 'Administradores',
              goToProviderDigitalCertificate: 'Gerenciar certificado digital'
            },
          },
        },
        details: {
          pageTitle: 'Detalhes do fornecedor',
          pageTitleAdd: 'Adicionar fornecedor',
          pageDescription: 'Preencha os campos abaixo para editar um fornecedor.',
          pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um novo fornecedor',
          messages: {
            success: {
              update: 'Fornecedor atualizado com sucesso.',
              create: 'Fornecedor adicionado com sucesso.',
            },
            errors: {
              generic: 'Algo deu errado, verifique os campos tente novamente.',
            },
          },
        },
      },
      chats: {
        url: '/panel/chats',
        sidebarTitle: 'Conversas',
        pageTitle: 'Conversas',
      },
      dashboards: {
        url: '/panel/dashboard',
        sidebarTitle: 'Dashboards',
        pageTitle: 'Dashboards',
        advancedFilters: {
          title: 'Filtros avançados',
          clear: 'Limpar filtros',
          search: 'Buscar',
          fields: {
            supportId: {
              label: 'Suporte (carteira)',
            },
          },
        },
      },
    },
  },
  signup: {
    cancelRegister: {
      title: 'Você tem certeza?',
      content: 'Todo o progresso será perdido.',
      okText: 'Sim',
      cancelText: 'Cancelar',
    },
    main: {
      changeDataStep: 'Dados de contato (Alteração)',
    },
    changeDataStep: {
      headerDescription:
        'Mantenha os dados atualizados, assim poderemos entrar em contato com você quando for necessário.',
    },
    questionsStep: {
      notifications: {
        noAnswer: {
          message: 'Erro ao avançar',
          description: 'As perguntas marcadas com * são obrigatórias.',
        },
      },
      multipleQuestions: 'Você pode selecionar mais de um.',
    },
  },
  forms: {
    cancelButtonText: 'Cancelar',
    submitButtonText: 'Salvar',
    confirmButtonText: 'Confirmar',
    closeButtonText: 'Fechar',
    provider: {
      createTitle: 'Criar um novo fornecedor',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Fornecedor criado com sucesso.',
      updateTitle: 'Atualizar fornecedor',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Fornecedor atualizado com sucesso.',
      updateProviderBankDataTitle: 'Atualizar dados bancários do fornecedor',
      updateProviderBankDataSuccessDescription: 'Dados bancários do fornecedor atualizados com sucesso',
      minValueLabel: 'Valor mínimo de compra',
      scoreLabel: 'Score',
      comissionPrivateLabel: 'Comissão vendedor particular',
      comissionPublicLabel: 'Comissão vendedor tiffin',
      taxTiffinPrivateLabel: 'Comissão tiffin para pedidos com vendedor',
      taxTiffinPublicLabel: 'Comissão tiffin para pedidos sem vendedor',
      positionLabel: 'Posição na busca',
      providerIdLabel: 'Id do fornecedor',
      providerNameLabel: 'Nome do fornecedor',
      providerStatusLabel: 'Situação cadastral',
      profileTypeLabel: 'Tipo do perfil',
      documentLabel: 'CNPJ',
      shortDescriptionLabel: 'Descrição rápida',
      units: {
        title: 'Gerencie os centros de distribuição',
        successTitle: 'Sucesso',
        errorTitle: 'Erro',
        createSuccessDescription: 'Central de distribuição: $var1 criada com sucesso.',
        updateSuccessDescription: 'Central de distribuição: $var1 atualizada com sucesso.',
        deleteSuccessDescription: 'Central de distribuição: $var1 removido com sucesso.',
        deleteAllErrorDescription: 'Não é possivel remover todos os centros de distribuição de um fornecedor.',
        missingParamsErrorDescription:
          'Verifique se em todos os CDs os campos obrigatórios (*) foram preenchidos corretamente.',
      },
      invalidFields: {
        name: ' nome do fornecedor',
        type: ' tipo do perfil',
        document: ' CNPJ',
        validation: {
          required: {
            title: 'Você deve preencher todos os campos obrigatórios!',
            description: 'Você deve preencher os seguintes campos: %{fields}.',
          },
        },
      },
    },
    providerTags: {
      items: {
        title: 'Selecione as tags do fornecedor: ',
      },
    },
    paymentOptions: {
      emptyList: 'Nenhuma condição de pagamento encontrada.',
      description: 'Clique em adicionar e escolha um fornecedor para cadastrar uma nova condição de pagamento',
      paymentOptionsTitle: 'Condições de pagamento',
      paymentOptionsSuccessTitle: 'Sucesso',
      paymentOptionsSuccessDescription: 'Meio de pagamento criado com sucesso',
      paymentOptionsSuccessNotificationMessage: 'Atualizado com sucesso',
      paymentOptionsSuccessNotificationDescription:
        'As condições de pagamento deste usuário foram atualizados com sucesso!',
      paymentOptionsFailedNotificationMessage: 'Erro ao atualizar',
      paymentOptionsFailedNotificationDescription:
        'Não foi possivel atualizar as condições de pagamento deste usuário, verifique e tente novamente',
      meansOfPaymentPanelHeader: 'Meio de pagamento',
      providerItemLabel: 'Fornecedor',
      items: {
        addButton: {
          label: 'Adicionar',
        },
        numberOfInstallments: {
          label: 'Parcelas em dias',
        },
      },
    },
    order: {
      createTitle: 'Criar um novo pedido',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Pedido criado com sucesso.',
      updateTitle: 'Atualizar pedido',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Pedido atualizado com sucesso.',
      addedTipMessage:
        'Ao selecionar um produto na lista, o mesmo será adicionado no começo da lista de produtos do pedido.',
      notifications: {
        itemAlreadyAddedError: {
          message: 'Erro ao adicionar novo item',
          description: 'Este item já foi adicionado ao pedido.',
        },
        minItemsError: {
          message: 'Erro ao remover todos itens do pedido',
          description: 'Não é possivel remover todos os itens de um pedido existente!',
        },
        invalidFreightOptionsError: {
          message: 'Opções de frete inválidas',
          description: 'Por favor, insira valores válidos nas opções de frete.',
        },
        invalidCreditCardOptionsError: {
          message: 'Opções de pagamento inválida',
          description: 'Por favor, selecione um cartão de crédito e a parcela para realizar o pagamento do pedido.',
        },
        invalidBankSlipInstallmentsError: {
          message: 'Data inválida',
          description: 'A data da parcela nova não pode ser inferior ou igual ao anterior.',
        },
        invalidInstallmentFieldError: {
          message: 'Campo não preenchido',
          description: 'Por favor, selecione uma data para a parcela',
        },
        generateBankSlipInstallmentsSuccess: {
          message: 'Pedido atualizado com sucesso!',
          description: 'Geração dos boletos realizado com sucesso.',
        },
        updateBankSlipInstallmentSuccess: {
          message: 'Atualizado com sucesso!',
          description: 'Atualização do vencimento do boleto realizado com sucesso.',
        },
        updateBankSlipInstallmentError: {
          message: 'Erro na atualização do vencimento!',
          description: 'Não foi possível atualizar o vencimento do boleto.',
        },
        bankSlipInstallmentPaidError: {
          message: 'Erro na atualização do pedido!',
          description: 'Não foi possível atualizar, pois já tem boleto pago.',
        },
        invalidProductStockError: {
          message: 'Item indisponível',
          description: 'Este item não possui mais estoque disponível ou o fornecedor pode tê-lo desabilitado.',
        },
        productStockUpdate: {
          message: 'Quantidade de estoque atualizada!',
          description: 'A quantidade de estoque do(s) produto(s) atualizado(s).',
        },
        invalidProductsStockQuantity: {
          message: 'Estoque(s) inválido(s)',
          description:
            'A quantidade informada para o(s) produto(s) não é válida. Ajuste as quantias em vermelho para continuar.',
        },
      },
      items: {
        id: {
          label: 'ID do pedido',
        },
        status: {
          label: 'Etapa do pedido',
        },
        createdAt: {
          label: 'Data do pedido',
        },
        userCompanyName: {
          label: 'Razão social do cliente',
        },
        userEmail: {
          label: 'E-mail do cliente',
        },
        userPhone: {
          label: 'Telefone do cliente',
        },
        userIe: {
          label: 'Inscrição estadual do cliente',
        },
        userDocument: {
          label: 'Documento do cliente (CNPJ/CPF)',
        },
        commentsSupport: {
          label: 'Histórico do suporte',
        },
        problemId: {
          label: 'Problema',
        },
        supportId: {
          label: 'Suporte',
        },
        comments: {
          label: 'Comentários do usuário',
        },
        providerProducts: {
          header: 'Itens do pedido',
        },
        origin: {
          label: 'Origem',
        },
        searchProducts: {
          label: 'Buscar produto',
          placeholder: 'Procure novos produtos do fornecedor',
          productStockWarning: {
            prefix: 'Os produtos marcados com ',
            suffix:
              ' podem não estar disponíveis por conta do estoque zerado ou o fornecedor pode tê-los desabilitado.',
          },
          unavailable: '(Indisponível)',
          items: {
            productId: {
              label: 'ID do produto',
            },
            code: {
              label: 'Código de referência',
            },
            productName: {
              label: 'Nome do produto',
            },
            quantity: {
              label: 'Quantidade',
              validator: {
                error: 'O valor deve ser múltiplo de %{multiplier}',
              },
            },
            stock: {
              label: 'Estoque',
            },
            value: {
              label: 'Subtotal',
            },
            discount: {
              label: 'Desconto',
            },
            totalValue: {
              label: 'Valor total',
            },
            taxTiffin: {
              label: 'Comissão tiffin',
            },
            commission: {
              label: 'Comissão representante',
            },
            removeOrderItems: {
              label: 'Remover item do pedido',
            },
          },
        },
        installmetValue: {
          label: 'Valor',
        },
        installmetNumber: {
          label: '° parcela',
        },
        generateInstallmentText: {
          label: 'Gerar boletos',
        },
        updateInstallmentText: {
          label: 'Alterar boleto',
        },
        previousDueDate: {
          label: 'Vencimento atual',
        },
        newDueDate: {
          label: 'Vencimento novo',
        },
      },
      renewOrderPayment: {
        pix: {
          title: 'Novo código PIX gerado',
          description: 'Um novo código PIX foi gerado, você já pode copiar o novo código.',
        },
        bankSlip: {
          title: 'Novo código de barras gerado',
          description: 'Um novo código de barras foi gerado, você já pode copiar o novo código.',
        },
      },
      copyPixToClipboard: {
        title: 'Pix copiado com sucesso.',
        description: 'Número de pix copiado para a área de transferência.',
      },
      copyInstallmentCodeToClipboard: {
        title: 'Código de barras copiado com sucesso.',
        description: 'Número do código de barras copiado para a área de transferência.',
      },
      openNotificationItemAlreadyAdded: {
        title: 'Erro ao adicionar novo item.',
        description: 'Este item já foi adicionado ao pedido.',
      },
      openNotificationMinItemsPermitted: {
        title: 'Erro ao remover todos itens do pedido.',
        description: 'Não é possivel remover todos os itens de um pedido existente.',
      },
      invoices: {
        title: 'Arquivos e notas fiscais',
        modalTitle: 'Upload de arquivos',
        inner: {
          alert: {
            createInvoicesFiles: {
              success: {
                message: 'Notas enviadas para emissão',
                description: 'As notas dos fornecedores fulfillment foram enviadas para o órgão emissor',
              },
            },
          },
          createInvoicesFulTitle: 'gerar notas fiscais fulfillment',
          createInvoicesFulButton: 'gerar',
          filesNumber: 'arquivo(s)',
          modalButton: 'subir arquivos',
          createOrderFileNotification: {
            notifications: {
              success: {
                message: 'Arquivo(s) enviado(s) com sucesso.',
              },
            },
          },
          invoiceFilesTitle: 'notas fiscais',
          genericFilesTitle: 'arquivos',
          list: {
            empty: 'Nenhum arquivo',
          },
          actions: {
            viewOrderFile: 'Visualizar arquivo',
            deleteOrderFile: {
              popoverText: 'Remover arquivo',
              notifications: {
                success: {
                  message: 'Arquivo deletado com sucesso.',
                },
              },
            },
          },
        },
      },
      payment: {
        header: {
          label: 'Pagamento',
        },
        warnings: {
          bankSlipStatus:
            'Podem haver mudanças no valor do pedido durante o processo, pois o pedido ainda não está confirmado.',
        },
        items: {
          paymentType: {
            label: 'Tipo de pagamento',
          },
          creditCardOption: {
            label: 'Opção de cartão de crédito',
            noCard: 'Solicite ao cliente que cadastre o cartão de crédito',
          },
          bankslipInstallmetOption: {
            prefix: 'Parcelado em',
            interfix: 'vezes:',
            suffix: 'dias',
          },
          creditTiffinsOption: '(Crédito tiffins)',
          paymentInstallmentOption: {
            label: 'Parcelas do pagamento',
          },
          paymentStatus: {
            label: 'Status do pagamento',
          },
          paymentStatusDetail: {
            label: 'Motivo',
          },
          payments: {
            singular: 'Boleto',
            plural: 'Boletos',
          },
          paymentItemStatus: {
            label: 'Situação do pagamento',
          },
          paymentAt: {
            label: 'Pago em',
            paid: 'Pago',
            notPaid: 'Pendente',
            outDated: 'Vencido',
          },
          dueDate: {
            label: 'Vencimento',
          },
          bankSlipDigitableLine: {
            label: 'Código de barras',
          },
          generateNewBankSlip: {
            label: 'Gerar novo boleto',
          },
          copyBarcode: {
            label: 'Copiar código de barras',
          },
          bankSlipItem: {
            label: 'Boleto',
          },
          barcodeItem: {
            label: 'Código de barras',
          },
          copyBarcodeItem: {
            label: 'Copiar código de barras',
          },
          viewBankSlipItem: {
            label: 'Visualizar o boleto',
          },
          editBankSlipInstallment: {
            label: 'Editar o vencimento do boleto',
          },
          createNewInstallments: {
            description: 'Gerar novas parcelas',
          },
        },
        credit: {
          title: 'Crédito liberado por',
          tiffins: 'tiffins',
          provider: 'fornecedor',
        },
      },
      pix: {
        header: {
          label: 'PIX',
        },
        code: {
          label: 'Código para realizar o pagamento',
        },
        generateNewCode: {
          label: 'Gerar novo código PIX',
        },
        copyCode: {
          label: 'Copiar código PIX',
        },
      },
      delivery: {
        freightType: {
          integration: {
            header: {
              label: 'Frete da integração',
              extra: 'Cotação: ',
            },
            distributor: {
              name: 'Nome da transportadora',
              nameUndefined: 'Ainda não definido',
              deliveryCodeLabel: 'Código de rastreamento',
            },
          },
          provider: {
            header: {
              label: 'Frete do fornecedor',
            },
          },
        },
        header: {
          label: 'Entrega',
        },
        freightSelect: {
          invalidPopup: {
            title: 'Valores inválidos',
            content:
              'Para prosseguir, valide se os campos "Imposto", "Valor do frete" e "Tempo de entrega" estão preenchidos para a opção de frete selecionada que deseja selecionar.',
            okText: 'Entendi',
          },
        },
        items: {
          freightDescription: {
            label: 'Descrição do frete',
          },
          freightQuoteId: {
            label: 'ID cotação do frete',
          },
          deliveryType: {
            label: 'Tipo de entrega',
          },
          address: {
            label: 'Endereço de entrega',
          },
          postalCode: {
            label: 'CEP (somente números) *',
          },
          street: {
            label: 'Rua',
          },
          district: {
            label: 'Bairro',
          },
          number: {
            label: 'Número',
          },
          complement: {
            label: 'Complemento',
          },
          city: {
            label: 'Cidade',
          },
          state: {
            label: 'Estado',
          },
          country: {
            label: 'País',
          },
          freight: {
            label: 'Opção de frete selecionado',
            values: 'Frete: %{value} / Impostos: %{taxes} / Entrega: %{leadTime}',
          },
          freightOptions: {
            header: {
              label: 'Opção de frete %{option}',
            },
          },
          leadtime: {
            label: 'Tempo de entrega (dias)',
          },
          freightValue: {
            label: 'Valor do frete (R$)',
          },
          taxes: {
            label: 'Imposto (R$)',
          },
          removeFreight: {
            label: 'Remover opção de frete',
          },
          addFreight: {
            label: 'Adicionar opção de frete',
          },
          distributorId: {
            label: 'Nome da transportadora',
          },
          deliveryCode: {
            label: 'Código de rastreio',
          },
        },
      },
      volumes: {
        header: {
          label: 'Volumes',
        },
        items: {
          quantity: {
            label: 'Quantidade',
          },
          weightByVolume: {
            label: 'Peso por volume (kg)',
          },
          height: {
            label: 'Altura por volume (cm)',
          },
          width: {
            label: 'Largura por volume (cm)',
          },
          length: {
            label: 'Comprimento por volume (cm)',
          },
        },
        empty: {
          label: 'Não foi possível calcular os volumes do pedido',
        },
      },
      labels: {
        header: {
          label: 'Etiquetas',
        },
        items: {
          title: {
            label: 'Gerar etiqueta do pacote',
            description: 'Ao clicar no botão abaixo uma nova aba irá se abrir com a imagem da etiqueta',
          },
          button: {
            label: 'Gerar etiqueta',
          },
        },
      },
      orderValue: {
        header: {
          label: 'Rastreio',
        },
        value: {
          label: 'Subtotal',
        },
        discount: {
          label: 'Desconto produtos',
        },
        discountExtra: {
          label: 'Desconto extra',
        },
        discountCoupon: {
          label: 'Desconto cupom',
        },
        freight: {
          label: 'Frete',
        },
        tax: {
          label: 'Impostos',
        },
        coin: {
          label: 'Moedas',
        },
        fees: {
          label: 'Juros',
        },
        total: {
          label: 'Total',
        },
        chat: {
          label: 'Falar com cliente',
        },
      },
      subtotalContainer: {
        subtotal: 'Subtotal:',
        discount: 'Desconto:',
        freight: 'Frete:',
        tax: 'Imposto:',
        total: 'Total:',
      },
      product: {
        advancedModal: {
          okText: 'Salvar alterações',
          stockUpdate: {
            title: 'Confirmação de alteração de estoque',
            description: 'Ajuste a quantidade dos produtos de acordo com o estoque e confira os valores:',
            adjustStockButton: 'Ajustar todos estoques',
            items: {
              stock: {
                label: 'Estoque disponível:',
              },
              minimum: {
                label: 'Mínimo',
              },
              multiplier: {
                label: 'Múltiplo',
              },
              discount: {
                label: 'Desconto',
              },
            },
            action: {
              fullButton: {
                text: 'Todos',
              },
              removeButton: {
                text: 'Remover',
              },
            },
            orderValue: {
              value: {
                label: 'Subtotal (itens)',
              },
              discount: {
                label: 'Desconto (itens)',
              },
              total: {
                label: 'Total (itens)',
              },
            },
          },
        },
      },
    },
    product: {
      createTitle: 'Criar um novo produto',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Produto criado com sucesso.',
      updateTitle: 'Atualizar produto',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Produto atualizado com sucesso.',
      description: 'Informe aqui os principais dados do produto.',
      items: {
        id: {
          label: 'Id do produto',
        },
        provider: {
          label: 'Fornecedor',
        },
        isVisible: {
          label: 'Produto disponivel',
        },
        name: {
          label: 'Nome do produto',
        },
        code: {
          label: 'Código de referência',
        },
        description: {
          label: 'Descrição do produto',
        },
        sizes: {
          label: 'Medidas *',
          description: 'Nos informe as medidas do seu produto.',
        },
        minimum: {
          label: 'Quantidade mínima p/ compra',
        },
        multiplier: {
          label: 'Múltiplo p/ compra',
        },
        unitId: {
          label: 'Unidade de medida',
        },
        width: {
          label: 'Largura em cm (ex.: 99,9)',
          placeholder: 'ex.: 99,9',
        },
        height: {
          label: 'Altura em cm (ex.: 99,9)',
          placeholder: 'ex.: 99,9',
        },
        length: {
          label: 'Comprimento em cm (ex.: 99,9)',
          placeholder: 'ex.: 99,9',
        },
        weight: {
          label: 'Peso bruto em kg (ex.: 99,999)',
          placeholder: 'ex.: 99,999',
        },
        shelfLifeTime: {
          label: 'Shelf life (tempo de validade)',
        },
        shelfLifeType: {
          label: 'Shelf life (tipo de validade)',
        },
        prices: {
          header: {
            title: 'Valores *',
            description:
              'Informe os valores do seu produto. Você também pode definir variações de valor, utilizando a opção abaixo de "Preço variável".',
          },
          items: {
            suggestedPrice: {
              label: 'Preço sugerido de venda',
            },
            value: {
              label: 'Preço do produto *',
            },
            commissionPrivate: {
              label: 'Comissão representante (%)',
            },
            wholesale: {
              label: 'Preço variável',
            },
            wholesaleValues: {
              label: 'Valores variáveis',
              items: {
                range: {
                  label: 'Quantidade mínima',
                },
                value: {
                  label: 'Valor',
                },
                commissionPrivate: {
                  label: 'Comissão representante',
                },
                removeVariable: {
                  label: '- Remover variável',
                },
                addVariable: {
                  label: '+ Adicionar variavel de preço',
                },
              },
            },
          },
        },
        categories: {
          title: 'Categorias *',
          description:
            'Selecione as categorias que correspondem ao seu produto. Isso ajudará os clientes a encontrarem seus produtos.',
          items: {
            transfer: {
              locale: {
                notFoundContent: 'Nada para exibir',
              },
              labelEmpty: 'Todas',
              labelFilled: 'Selecionadas',
            },
          },
        },
        nutritional: {
          title: 'Nutricional',
          description:
            'Defina as informações nutricionais a serem exibidas no app e site. Isso ajudará clientes que possuem necessidades especiais ou estão se alimentando de maneira mais controlada.',
          items: {
            showNutritionalInfos: {
              label: 'Exibir informações nutricionais',
            },
          },
        },
        promotional: {
          title: 'Promoção',
          description: 'Você também pode criar promoções para o seu produto utilizando as definições abaixo.',
          items: {
            hasPromotion: {
              label: 'Contém promoção',
            },
            value: {
              label: 'Valor',
            },
            isPercentage: {
              label: 'Promoção com porcentagem',
            },
            promotionStartDate: {
              label: 'Data inicial',
            },
            promotionEndDate: {
              label: 'Data final',
            },
          },
        },
        pictures: {
          title: 'Fotos',
          description: 'Adicione até 4 imagens que serão exibidas no aplicativo e site.',
          items: {
            upload: {
              removeFile: 'Remover imagem',
              previewFile: 'Visualizar imagem',
            },
            uploadButton: {
              label: 'Upload',
            },
          },
        },
        providerUnits: {
          title: 'Centro de distribuição *',
          items: {
            name: {
              label: 'Nome',
            },
            stock: {
              label: 'Estoque (deixar em branco se não for gerenciar o estoque pela tiffin)',
            },
            empty: {
              label:
                'O fornecedor desse produto não possui centros de distribuição cadastrados. ATENÇÃO: É necessário cadastrar um CD para que seus produtos apareçam na plataforma.',
            },
          },
          messages: {
            selectProviderFirst: 'Selecione um Fornecedor.',
          },
        },
      },
      invalidFields: {
        providerId: ' fornecedor',
        name: ' nome',
        weight: ' peso bruto',
        width: ' largura',
        height: ' altura',
        length: ' comprimento',
        unitId: ' unidade de medida',
        minimum: ' quantidade mínima p/ compra',
        multiplier: ' multiplo p/ compra',
        prices: ' preço do produto',
        categories: ' uma categoria ao menos',
        validation: {
          type: 'perfil do usuário',
          cnpj: 'CNPJ',
          name: 'nome',
          email: 'email',
          password: 'senha',
          confirmPassword: 'confirmação de senha',
          confirmPasswordWrong: 'confirmação de senha está incorreta',
          required: {
            title: 'Você deve preencher todos os campos obrigatórios',
            description: 'Você deve preencher os seguintes campos: %{fields}',
          },
        },
      },
      errors: {
        required: {
          notifications: {
            noUnits: {
              message: 'Não existem centros de distribuição cadastrados',
              description:
                'Nenhum centro de distribuição foi cadastrado, é necessário cadastrar ao menos um centro de distribuição para associar seus produtos.',
            },
            noUnitsAssociate: {
              message: 'Centro de distribuição não informado',
              description:
                'Para salvar o produto é necessário informar ao menos um centro de distribuição que esteja disponível.',
            },
          },
          providerId: 'O campo "Fornecedor" é requerido.',
        },
      },
    },
    user: {
      createTitle: 'Criar um novo usuário',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Usuário criado com sucesso.',
      updateTitle: 'Atualizar usuário',
      generateNewPasswordText: 'Gerar senha temporária',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Usuário atualizado com sucesso.',
      generateNewPassword: {
        title: 'Confirmar alteração de senha',
        confirmationModal: {
          description: 'Você tem certeza que deseja gerar uma nova senha? O usuário terá que entrar novamente com a nova senha gerada.',
          underDescription: 'Ao fazer login pela primeira vez, o usuário deverá alterar a senha para uma pessoal e segura.',
          warningText: 'Atenção: ao clicar em confirmar, mostraremos a nova senha e caso você feche a tela, não será mais possível visualizar a senha. Tome nota antes de fechar a tela.',
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          successPassword: 'Senha gerada com sucesso',
          failedPassword: 'Erro ao gerar nova senha'
        },
        passwordGenerateModal: {
          description: 'Senha alterada com sucesso!',
          warningText: 'Atenção: Caso você feche a tela, não será mais possível visualizar a senha. Tome nota antes de fechar a tela.',
          copyPasswordText: 'Copiar senha',
          copiedPassword: 'Senha copiada com sucesso',
          newPasswordText: 'Nova senha:'
        },
      },
      createCodeValidation: {
        notifications: {
          success: {
            message: 'Código enviado',
            description: 'Código enviado com sucesso para %{inbox}',
          },
          error: {
            message: 'Erro no envio do código',
            description:
              'Não conseguimos enviar o seu código, verifique se seu e-mail e celular estão corretos, em seguida tente novamente ou entre em contato com nossa equipe',
          },
        },
      },
      createUserAndCodeValidation: {
        notifications: {
          error: {
            message: 'Erro no envio do código',
            description:
              'Náo conseguimos enviar o seu código, verifique se seu e-mail e celular estão corretos, em seguida tente novamente ou entre em contato com nossa equipe',
          },
        },
      },
      updateUserAndCodeValidation: {
        notifications: {
          error: {
            message: 'Erro no envio do código',
            description:
              'Não conseguimos enviar seu código, verifique se seu e-mail e celular estão corretos, em seguida tente novamente ou entre em contato com nossa equipe',
          },
        },
      },
      items: {
        type: {
          label: 'Tipo de perfil',
        },
        status: {
          label: 'Situação cadastral',
        },
        documentType: {
          label: 'Pessoa física',
          validation: {
            required: {
              label: 'Por favor insira um documento',
            },
          },
        },
        name: {
          label: 'Nome',
          validation: {
            required: {
              label: 'Por favor insira um nome',
            },
          },
        },
        email: {
          label: 'E-mail',
          validation: {
            required: {
              label: 'Por favor insira um e-mail',
            },
            invalid: {
              label: 'Por favor insira um e-mail válido',
            },
          },
        },
        password: {
          label: 'Senha',
          validation: {
            required: {
              label: 'Por favor insira uma senha',
            },
          },
        },
        confirmPassword: {
          label: 'Confirmar senha',
          validation: {
            required: {
              label: 'Por favor confirme a senha',
            },
            mismatch: {
              label: 'A confirmação de senha não está correta',
            },
          },
        },
        phone: {
          label: 'Telefone / celular',
        },
        companyName: {
          label: 'Razão Social',
        },
        ie: {
          label: 'Inscrição Estadual',
        },
        supportId: {
          label: 'Suporte',
        },
        supportStatusId: {
          label: 'Etapa do lead',
        },
        commentsSupport: {
          label: 'Comentário do suporte',
        },
        fup: {
          label: 'Lembrete de FUP',
        },
        lastFupAt: {
          label: 'Data do último FUP',
        },
        nextFupAt: {
          label: 'Data do próximo FUP',
        },
        originId: {
          label: 'Origem',
        },
        companyTypeId: {
          label: 'Tipo da empresa',
        },
      },
      invalidFields: {
        validation: {
          required: {
            title: 'Você deve preencher todos os campos obrigatórios!',
            description: 'Você deve preencher os seguintes campos: %{fields}.',
          },
        },
      },
      preferences: {
        form: {
          title: 'Preferências do usuário',
        },
        empty: {
          title: 'Este usuário não tem respostas registradas',
        },
        notAnswered: {
          title: 'Não respondido',
        },
      },
    },
    marketing: {
      validation: {
        notifications: {
          noUserSelected: {
            message: 'Preenchimento incompleto',
            description: 'Escola ao menos um usuário para enviar a notificação',
          },
          noTypeSelected: {
            message: 'Preenchimento incompleto',
            description:
              'Escolha o tipo de usuário para mandar a notificação ou selecione para escolher os usuários manualmente',
          },
          noTitleSelected: {
            message: 'Preenchimento incompleto',
            description: 'Preencha o titulo para enviar a mensagem',
          },
          noBodySelected: {
            message: 'Preenchimento incompleto',
            description: 'Preencha o corpo da mensagem para enviar',
          },
          failedNotification: {
            message: 'Ops, algo deu errado',
            description: 'Verifique se todos os campos foram preenchidos corretamente e tente novamente.',
          },
          successNotification: {
            message: 'Notificação enviada com sucesso!',
            description: 'A notificação foi enviada para os destinatários escolhidos com sucesso!',
          },
          userAlreadySelected: {
            message: 'Atenção...',
            description: 'Esse usuário já foi selecionado e está na lista para envio.',
          },
          onShowConfirmationToSend: {
            title: 'Atenção, deseja realmente enviar a notificação para os usuários selecionados?',
            okText: 'Sim',
            cancelText: 'Cancelar',
          },
        },
      },
      items: {
        header: {
          label: 'Notificações',
        },
        title: {
          label: 'Título',
        },
        userType: {
          label: 'Tipo de usuário',
        },
        message: {
          label: 'Mensagem',
        },
        searchByUser: {
          label: 'Procure pelo usuário',
        },
        searchBox: {
          placeholder: 'Nome, email, telefone, documento, empresa ou IE',
        },
        send: {
          label: 'Enviar',
        },
        selectedUsers: {
          label: 'Usuários selecionados',
        },
        removeAllUsers: {
          label: 'Remover todos os usuários selecionados',
        },
        removeAllUsersConfirmation: {
          label: 'Você tem certeza que deseja remover todos os usuários selecionados?',
        },
      },
    },
    managers: {
      title: 'Gerenciamento de administradores',
      managerEmailLabel: 'Email do administrador',
      managerNameLabel: 'Nome do administrador',
      addManagerButton: 'Adicionar',
      adminListTitle: 'Administradores',
      actions: {
        preferences: 'Preferências',
        remove: 'Remover',
      },
      listAdmins: {
        adminStatusPending: 'aguardando cadastro',
        adminStatusActive: 'ativo',
      },
      notification: {
        error: {
          message: 'Preencha todos os campos',
          description: 'Verifique se inseriu o e-mail e o nome.',
        },
      },
    },
    providerCertificate: {
      title: 'Gerenciar certificado digital',
      NFeTitle: 'Dados de NFe',
      footerButtonLabel: 'Salvar',
      footerCancelButtonLabel: 'Cancelar',
      digitalCertificateTitle: 'Certificado digital',
      digitalCertificateDescription: 'Nós não armazenamos esse arquivo e a senha em lugar algum',
      certificateSent: 'Você já enviou esse arquivo em ',
      certificateExpiration: 'Seu certificado vence em',
      fields: {
        sequential: {
          label: 'Sequencial de NFe',
        },
        serie: {
          label: 'Série de NFe'
        },
        certificatePassword: {
          label: 'Senha do certificado'
        },
        upload: {
          buttonText: 'Selecione',
          text: 'Selecione um arquivo...',
        }
      },
      notifications: {
        error: {
          file: 'Você deve selecionar um certificado',
          certificatePassword: 'Preencha a senha do certificado',
          unknownError: 'Algo deu errado, verifique os campos e tente novamente.',
          serie: 'Preencha a série de NFe',
          sequential: 'Preencha a sequencial de NFe',
        },
        success: {
          createProviderCertification: 'Cetificado enviado com sucesso!',
        }
      },
      incompleteRegister: {
        title: 'Cadastro incompleto',
        description: 'Precisamos que você finalize seu cadastro para conseguirmos emitir as notas fiscais nos pedidos.',
        message: 'Clique no botão abaixo para ir até o formulário e preencher os dados necessários.',
        footer: {
          closeButtonText: 'Fechar',
          finishRegisterButtonText: 'Finalizar cadastro'
        }
      }
    },
    accessGroup: {
      createTitle: 'Criar um novo grupo de acesso',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Grupo de acesso criado com sucesso.',
      updateTitle: 'Atualizar grupo de acesso',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Grupo de acesso atualizado com sucesso.',
      invalidFields: {
        name: 'nome do grupo de acesso',
        validation: {
          required: {
            title: 'Você deve preencher todos os campos obrigatórios.',
            description: 'Você deve preencher os seguintes campos: %{fields}.',
          },
        },
      },
      items: {
        name: {
          label: 'Título',
        },
        defaultUserType: {
          label: 'Tipo de acesso padrão',
        },
      },
    },
    accessFeature: {
      createTitle: 'Criar uma nova funcionalidade',
      createSuccessTitle: 'Sucesso',
      createSuccessDescription: 'Funcionalidade criada com sucesso.',
      updateTitle: 'Atualizar funcionalidade',
      updateSuccessTitle: 'Sucesso',
      updateSuccessDescription: 'Funcionalidade atualizado com sucesso.',
      invalidFields: {
        name: ' nome da funcionalidade',
        validation: {
          required: {
            title: 'Você deve preencher todos os campos obrigatórios.',
            description: 'Você deve preencher os seguintes campos: %{fields}.',
          },
        },
      },
      items: {
        name: {
          label: 'Título',
          cantChange:
            'Não é possível alterar o título após a sua definição. Entre em contato com o suporte caso necessite.',
        },
        description: {
          label: 'Descrição',
        },
      },
    },
    collection: {
      title: 'Gerenciamento de lista',
      addedTipMessage:
        'Ao selecionar um produto na lista, o mesmo será adicionado no começo da lista de produtos abaixo.',
      items: {
        title: {
          label: 'Nome da lista',
        },
        description: {
          label: 'Descrição da lista',
        },
        searchProducts: {
          label: 'Buscar produto',
          placeholder: 'Procure novos produtos do fornecedor',
          productStockWarning: {
            prefix: 'Os produtos marcados com ',
            suffix:
              ' podem não estar disponíveis por conta do estoque zerado ou o fornecedor pode tê-los desabilitado.',
          },
          unavailable: '(Sem estoque)',
          items: {
            productId: {
              label: 'ID do produto',
            },
            code: {
              label: 'Código de referência',
            },
            productName: {
              label: 'Nome do produto',
            },
            quantity: {
              label: 'Quantidade',
              validator: {
                error: 'O valor deve ser múltiplo de %{multiplier}',
              },
            },
            stock: {
              label: 'Estoque',
            },
            value: {
              label: 'Subtotal',
            },
            discount: {
              label: 'Desconto',
            },
            totalValue: {
              label: 'Valor total',
            },
            taxTiffin: {
              label: 'Comissão tiffin',
            },
            commission: {
              label: 'Comissão representante',
            },
            removeOrderItems: {
              label: 'Remover item da lista',
            },
          },
        },
        providerProducts: {
          header: 'Itens da lista',
        }
      },
      notification: {
        error: {
          emptyProducts: {
            title: 'Erro ao remover o item',
            description: 'Este item não está mais na lista.'
          },
          noTitle: {
            title: 'Preencha o nome da lista',
            description: 'Verifique se inseriu o nome.'
          },
          emptyItens: {
            title: 'Selecione os produtos da lista',
            description: 'A lista está vazia, Verifique se inseriu os produtos.'
          },
        },
        success: {
          updateCollection: {
            title: 'Lista atualizada com sucesso!',
          },
          deleteCollection: {
            title: 'Lista deletada com sucesso!',
          },
          createCollection: {
            title: 'Lista criada com sucesso!',
          }
        }
      }
    }
  },
  enum: {
    productsProviderProps: {
      id: 'id tiffin',
      code: 'codigo',
      weight: 'peso*',
      name: 'nome*',
      description: 'descricao',
      descriptionType: 'tipo de descricao',
      categories: 'categorias',
      minimum: 'minimo*',
      multiplier: 'multiplo*',
      isVisible: 'disponivel*',
      unitType: 'unidade de medida*',
      shelfLifeTime: 'tempo validade',
      shelfLifeType: 'periodo de tempo',
      suggestedPrice: 'preco sugerido',
      height: 'altura',
      width: 'largura',
      length: 'comprimento',
      images: 'imagens URL',
      price: 'preco*',
      commission: 'comissao vendedor',
      taxTiffin: 'comissao tiffin',
      stock: 'estoque',
      tax: 'imposto',
      rowNum: 'rowNum',
    },
    productsProviderValues: {
      days: 'days',
      weeks: 'weeks',
      months: 'months',
      years: 'years',
    },
    providerBillingStatus: {
      pending: 'Pendente',
      confirmed: 'Confirmado',
    },
    customerOriginStatus: {
      tiffin: 'Cliente tiffin',
      pending: 'Pendente de positivação',
      provider: 'Meu cliente',
    },
    paymentType: {
      pix: 'PIX',
      bank_slip: 'Boleto à vista',
      bank_slip_installment: 'Boleto à prazo',
      credit_card: 'Cartão de crédito',
    },
    userType: {
      admin: 'Administrador',
      userNatural: 'Comprador',
      userLegal: 'Vendedor',
      representative: 'Representante',
      support: 'Suporte',
      anyUser: 'Usuário(s) específico(s)',
    },
    userStatus: {
      disable: 'Desabilitado',
      approved: 'Aprovado',
      waitingFirstLogin: 'Aguardando primeiro acesso',
      waitingValidationEmail: 'Aguardando validação do e-mail',
      waitingValidationPhone: 'Aguardando validação do telefone',
      waitingValidationData: 'Aguard. valid. dos dados',
      resetPassword: 'Aguardando mudança de senha',
    },
    userOrderStatus: {
      top_user: 'TOP',
      first_order: '1º pedido',
    },
    providerType: {
      dummy: 'Fornecedor Teste',
      retail: 'Varejo',
      wholesale: 'Atacado',
      representation_office: 'Escritório de representação',
    },
    providerStatus: {
      disable: 'Desabilitado',
      approved: 'Aprovado',
      waitingValidationData: 'Aguard. valid. dos dados',
    },
    orderStatus: {
      pending: 'Pendente de análise',
      waitingDelivery: 'Aguardando envio',
      onCarriage: 'Em trânsito',
      delivered: 'Entregue',
      canceled: 'Cancelado',
      rejected: 'Rejeitado',
      waitingFreightValue: 'Aguardando valores de frete ou impostos',
      waitingFreightConfirmation: 'Aguardando escolha do frete ou impostos',
      waitingTax: 'Aguandando valores de impostos',
      waitingWithdrawal: 'Aguardando retirada',
    },
    paymentStatus: {
      notFinished: 'Falha ao processar o pagamento',
      authorized: 'Meio de pagamento apto a ser capturado ou pago (Boleto)',
      confirmed: 'Pagamento confirmado',
      denied: 'Pagamento negado (Cartão crédito ou débito)',
      canceled: 'Pagamento cancelado',
      refunded: 'Pagamento estornado',
      pending: 'Esperando retorno da instituição financeira',
      aborted: 'Pagamento cancelado por falha no processamento',
      scheduled: 'Recorrência agendada',
    },
    deliveryType: {
      delivery: 'Receber no estabelecimento',
      onProvider: 'Retirar no local',
    },
    booleanDefaultStatus: {
      true: 'Sim',
      false: 'Não',
    },
  },
};
