import { Link, useParams } from '@reach/router';
import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Row, Popover, Collapse, Table } from 'antd';
import { DashboardFilled, QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { GetUserTypeArray, GetUserTypeName, UserTypes } from '../../../../app/enum/user_types';

import { useReduxState } from '../../../../app/hooks/useReduxState';
import { AccessGroupUsersActions, UserActions, AccessGroupActions } from '../../../../app/redux/actions';
import { ACTION_GET_ACCESS_GROUP_BY_ID } from '../../../../app/redux/actions/accessGroup';
import { humanizeDate } from '../../../../app/utils/date';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedCheckbox from '../../../../components/shared/AdvancedCheckbox';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import PaginatedDatatable from '../../../../components/shared/PaginatedDatatable/PaginatedDatatable';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const { Content } = Layout;
const { Panel } = Collapse;

const defaultFields = {
  search: '',
  onlyAssociated: false,
};

function AccessGroupsUsers() {
  const { accessGroupId } = useParams();

  const dispatch = useDispatch();
  const { accessGroupUser, user, accessGroup } = useReduxState();
  const [advancedFilter, setAdvancedFilter] = useState(defaultFields);
  const [advancedFilterTypes, setAdvancedFilterTypes] = useState(GetUserTypeArray.map((o) => o.id));

  const onChangeAdvancedFilterTypes = (type, value) => {
    const payload = [...advancedFilterTypes];

    if (!value) {
      const typeIndex = payload.findIndex((o) => o === type);

      if (typeIndex >= 0) {
        payload.splice(typeIndex, 1);
      }
    } else {
      payload.push(type);
    }

    setAdvancedFilterTypes(payload);
  };

  const getAccessGroupUsers = () => {
    const payload = {
      ...advancedFilter,
      accessGroupId,
      limit: Number.MAX_SAFE_INTEGER,
    };

    dispatch(AccessGroupUsersActions.getAccessGroupUsersPaginated(payload));
  };

  useEffect(() => {
    if (accessGroupId) {
      dispatch(AccessGroupActions.getAccessGroupById(accessGroupId));
      getAccessGroupUsers();
    }
  }, [accessGroupId]);

  const getUsersPaginated = (page) => {
    const payload = {
      ...advancedFilter,
      page,
    };

    if (advancedFilterTypes?.length) {
      payload.type = advancedFilterTypes;
    }

    dispatch(UserActions.getUsersPaginated(payload));
  };

  const onSearchAdvancedFilters = () => {
    getUsersPaginated(0);
    getAccessGroupUsers();
  };

  useEffect(() => {
    if (accessGroupUser.accessGroupsUsersPaginatedObtained) {
      getUsersPaginated(0);
    }
  }, [accessGroupUser.accessGroupsUsersPaginatedObtained]);

  const onSelectUser = ({ id }) => {
    const foundUser = accessGroupUser.accessGroupsUsersPaginated?.rows?.find((o) => o.userId === id);
    let request = AccessGroupUsersActions.createUserAndAccessGroupsAssociation;

    if (foundUser) {
      request = AccessGroupUsersActions.removeUserAndAccessGroupsAssociation;
    }

    dispatch(request({ accessGroupId, userId: id }, getAccessGroupUsers));
  };

  const onChangeAdvancedFilters = (name, value) => {
    setAdvancedFilter((otherFields) => ({ ...otherFields, [name]: value }));
  };

  const cleanAdvancedFilters = () => {
    setAdvancedFilter(defaultFields);
  };

  const formatTablePayload = () => {
    if (!advancedFilter.onlyAssociated) {
      return user.usersPaginated;
    }
    return {
      rows: accessGroupUser.accessGroupsUsersPaginated?.rows?.map((o) => o.user),
    };
  };

  useEffect(() => {
    return () => dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID, payload: null });
  }, []);

  const isActiveUserTypeFilter = (type) => {
    return advancedFilterTypes.find((o) => o === type);
  };

  const onSelectMultiple = (_param, users) => {
    if (users && users.filter((o) => !!o).length) {
      dispatch(
        AccessGroupUsersActions.createUserAndAccessGroupsAssociationMultiple(
          { accessGroupId, userIds: users.filter((o) => !!o).map((o) => o.id) },
          getAccessGroupUsers,
        ),
      );
    } else {
      dispatch(
        AccessGroupUsersActions.removeUserAndAccessGroupsAssociationMultiple(
          {
            accessGroupId,
            userIds: formatTablePayload()
              ?.rows?.filter((o) => !!o)
              .map((o) => o.id),
          },
          getAccessGroupUsers,
        ),
      );
    }
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={I18n.t('routes.panel.administration.accessGroups.url')}>
              <span>{I18n.t('routes.panel.administration.accessGroups.pageTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{accessGroup.accessGroupById?.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.accessGroupsUsers.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row className="panel__layout__content--advanced-filter__item" align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilter.search}
              onChange={(val) => onChangeAdvancedFilters('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.searchFieldTitle')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>

          <Row className="panel__layout__content--advanced-filter__item">
            <AdvancedCheckbox
              label={I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.onlyAssociated.label')}
              onChange={(val) => onChangeAdvancedFilters('onlyAssociated', val)}
              value={advancedFilter.onlyAssociated}
            />
          </Row>

          <div className="panel__layout__content--advanced-filter__label">
            <strong>{I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.type.label')}</strong>
          </div>

          <Row className="panel__layout__content--advanced-filter__item">
            <div className="panel__layout__content--advanced-filter__checks">
              {GetUserTypeArray.filter((o) => o.id !== UserTypes.ANYUSER).map((userType, userTypeIndex) => (
                <div className="panel__layout__content--advanced-filter__checks__item" key={userTypeIndex.toString()}>
                  <AdvancedCheckbox
                    label={userType.name}
                    onChange={(val) => onChangeAdvancedFilterTypes(userType.id, val)}
                    value={isActiveUserTypeFilter(userType.id)}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row className="panel__layout__content--advanced-filter__item">
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <div className="panel__layout__content--advanced-filter__button">
              <AdvancedButton
                text={I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={onSearchAdvancedFilters}
              />
            </div>
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {accessGroup.accessGroupById?.name && (
          <div className="panel__layout__content__title">
            {I18n.t('routes.panel.administration.accessGroupsUsers.tableTitle', {
              accessGroup: accessGroup.accessGroupById?.name,
            })}
          </div>
        )}
        <PaginatedDatatable
          dataSource={formatTablePayload()}
          getMethod={getUsersPaginated}
          onChangeSelection={onSelectUser}
          autoInitalize={false}
          loading={user.isGetUsersPaginatedOnRequest || accessGroupUser.getaccessGroupsUsersPaginatedIsOnRequest}
          selectedIds={[...(accessGroupUser.accessGroupsUsersPaginated?.rows?.map((o) => o.user.id) || [])]}
          pageSize={advancedFilter.onlyAssociated ? Number.MAX_SAFE_INTEGER : 10}
          onSelectAll={onSelectMultiple}
          disabled={!hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_USERS], [accessActionTypes.UPDATE])}
          columns={[
            {
              dataIndex: 'name',
              title: 'Nome',
              width: '30%',
              fixed: !isMobile ? 'left' : false,
            },
            {
              dataIndex: 'email',
              title: 'E-mail',
              width: '25%',
            },
            {
              dataIndex: 'type',
              title: 'Perfil',
              render: (val) => GetUserTypeName(val),
              width: '25%',
            },
            {
              dataIndex: 'createdAt',
              title: 'Criação',
              render: (val) => humanizeDate(val, 'DD/MM/YYYY HH:mm'),
              width: '20%',
            },
          ]}
        />
      </Content>
    </>
  );
}

export default AccessGroupsUsers;
