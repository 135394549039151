import React, { useEffect } from 'react';
import { Layout, Card, Image, Typography, notification } from 'antd';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import URI from 'urijs';
import { I18n } from 'react-redux-i18n';

import AdvancedButton from '../../components/shared/AdvancedButton';

import { AuthActions, UserActions } from '../../app/redux/actions';
import { UserSelectors } from '../../app/redux/reducers';
import { UserTypes } from '../../app/enum/user_types';
import AdvancedInput from '../../components/shared/AdvancedInput/AdvancedInput';
import useForm from '../../app/hooks/useForm';

function RegisterPassword({
  onCreateAdmin,
  onSignIn,
  onSetSignupUser,
  isCreateAdminOnRequest,
  onCreateCodeValidation,
}) {
  const { Title } = Typography;
  const { Content } = Layout;

  const url = new URI(useLocation().href);
  const { email, name, userId } = url.escapeQuerySpace(false).search(true);

  const getInitialState = () => ({
    inputName: name,
    password: null,
    confirmPassword: null,
  });
  const form = useForm(getInitialState());

  useEffect(() => {
    form.setForm(getInitialState());
  }, []);

  const onSubmit = async () => {
    const { inputName, password, confirmPassword } = form.values;
    if (!password || !confirmPassword) {
      notification.error({
        message: I18n.t('routes.registerPassword.content.onSubmit.notification.isEmptyError.message'),
        description: I18n.t('routes.registerPassword.content.onSubmit.notification.isEmptyError.description'),
      });
    } else if (password !== confirmPassword) {
      notification.error({
        message: I18n.t('routes.registerPassword.content.onSubmit.notification.isPasswordEqualError.message'),
        description: I18n.t('routes.registerPassword.content.onSubmit.notification.isPasswordEqualError.description'),
      });
    } else {
      const admin = {
        email,
        name: inputName || name,
      };
      onSetSignupUser('admin', admin);

      await onCreateAdmin({
        id: userId,
        email,
        password,
        name: inputName || name,
        type: UserTypes.USER_LEGAL,
        isCreatingProviderManager: true,
      });

      await onSignIn(email, password);

      onCreateCodeValidation({
        email,
        name: inputName || name,
      });
    }
  };

  return (
    <Layout>
      <Content className="register-password">
        <Card className="register-password__container">
          <div className="register-password__container__signin__container">
            <div className="register-password__logo">
              <Image
                className="register-password__logo_img"
                width={70}
                src="/assets/img/img_logo.png"
                alt="register password logo"
              />
              <Title className="register-password__logo__title" level={3}>
                {I18n.t('application.title')}
              </Title>
            </div>
            <div className="register-password__container__signin__container__form">
              <AdvancedInput
                label={I18n.t('routes.registerPassword.content.email')}
                onChange={(val) => form.handleInputChange('email', val)}
                value={email}
                disabled
              />
              <AdvancedInput
                label={I18n.t('routes.registerPassword.content.name')}
                onChange={(val) => form.handleInputChange('inputName', val)}
                value={form.values.inputName}
                placeholder={I18n.t('shared.typeSomething')}
              />
              <AdvancedInput
                label={I18n.t('routes.registerPassword.content.password')}
                onChange={(val) => form.handleInputChange('password', val)}
                placeholder={I18n.t('routes.registerPassword.content.passwordPlaceholder')}
                type="password"
              />
              <AdvancedInput
                label={I18n.t('routes.registerPassword.content.confirmPassword')}
                onChange={(val) => form.handleInputChange('confirmPassword', val)}
                placeholder={I18n.t('routes.registerPassword.content.confirmPasswordPlaceholder')}
                type="password"
              />
              <AdvancedButton
                loading={isCreateAdminOnRequest}
                className="register-password__container__signin__container__form__button"
                text={I18n.t('routes.registerPassword.content.registerButton')}
                htmlType="submit"
                onClick={onSubmit}
              />
            </div>
          </div>
        </Card>
      </Content>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isCreateAdminOnRequest: UserSelectors.isCreateUserOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateAdmin: (data) => dispatch(UserActions.signUpCreateUser(data)),
  onSignIn: (email, password) => dispatch(AuthActions.authenticate(email, password)),
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
  onCreateCodeValidation: (user) => dispatch(UserActions.createCodeValidation(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPassword);
