import React, { useState } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { DashboardFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Row, Col, Modal, Layout, Collapse, Breadcrumb, notification } from 'antd';

import AdvancedInput from '../../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedTextArea from '../../../../components/shared/AdvancedTextArea';
import AdvancedSelectBox from '../../../../components/shared/AdvancedSelectBox';
import AdvancedAutoComplete from '../../../../components/shared/AdvancedAutoComplete';

import * as Styles from './styles';

import { GetUserTypeArray, UserTypes } from '../../../../app/enum/user_types';

import { UserActions, PushActions } from '../../../../app/redux/actions';
import { PushSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

function Notifications({ isGetUserSearchIsOnRequest, onGetUserSearch, onSendPush, isSendPushIsOnRequest }) {
  const defaultState = { title: null, body: null, type: null, users: [] };

  const [notificationToSend, setNotificationToSend] = useState(defaultState);
  const [usersFound, setUsersFound] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [textSearch, setTextSearch] = useState();
  const [showAlertRemoveAllUsersSelected, setShowAlertRemoveAllUsersSelected] = useState(false);

  const { confirm } = Modal;
  const { Content } = Layout;
  const { Panel } = Collapse;

  const onSubmit = async () => {
    const success = await onSendPush(notificationToSend);
    if (!success) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.failedNotification.message'),
        description: I18n.t('forms.marketing.validation.notifications.failedNotification.description'),
      });
    }
    setNotificationToSend(defaultState);
    setUsersFound(null);
    setUsersSelected(null);
    setTextSearch(null);

    return notification.success({
      message: I18n.t('forms.marketing.validation.notifications.successNotification.message'),
      description: I18n.t('forms.marketing.validation.notifications.successNotification.description'),
    });
  };

  const onShowConfirmation = () => {
    return confirm({
      title: I18n.t('forms.marketing.validation.notifications.onShowConfirmationToSend.title'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('forms.marketing.validation.notifications.onShowConfirmationToSend.okText'),
      okType: 'danger',
      centered: true,
      confirmLoading: isSendPushIsOnRequest,
      cancelText: I18n.t('forms.marketing.validation.notifications.onShowConfirmationToSend.cancelText'),
      onOk: onSubmit,
    });
  };

  const onHandleValidation = () => {
    if (notificationToSend.type === UserTypes.ANYUSER && notificationToSend.users.length < 1) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.noUserSelected.message'),
        description: I18n.t('forms.marketing.validation.notifications.noUserSelected.description'),
      });
    }
    if (!notificationToSend.type) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.noTypeSelected.message'),
        description: I18n.t('forms.marketing.validation.notifications.noTypeSelected.description'),
      });
    }
    if (!notificationToSend.title) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.noTitleSelected.message'),
        description: I18n.t('forms.marketing.validation.notifications.noTitleSelected.description'),
      });
    }
    if (!notificationToSend.body) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.noBodySelected.message'),
        description: I18n.t('forms.marketing.validation.notifications.noBodySelected.description'),
      });
    }
    onShowConfirmation();
  };

  const removeAllSelectedUsers = () => {
    setUsersSelected(null);
    setShowAlertRemoveAllUsersSelected(false);
    setNotificationToSend({ ...notificationToSend, users: [] });
  };

  const onRemove = (userId) => {
    const newNotificationToSend = {
      ...notificationToSend,
      users: notificationToSend.users.filter((id) => id !== userId),
    };
    const newUsersSelected = usersSelected.filter((selected) => selected.value !== userId);
    setNotificationToSend(newNotificationToSend);
    setUsersSelected(newUsersSelected);
  };

  const onAddUser = (userSelectedId) => {
    if (notificationToSend.users.find((userId) => userId === userSelectedId)) {
      return notification.error({
        message: I18n.t('forms.marketing.validation.notifications.userAlreadySelected.message'),
        description: I18n.t('forms.marketing.validation.notifications.userAlreadySelected.description'),
      });
    }

    const userFoundSelected = usersFound.find((userFound) => userFound.value === userSelectedId);
    setUsersSelected([...usersSelected, userFoundSelected]);

    setNotificationToSend({ ...notificationToSend, users: [...notificationToSend.users, userSelectedId] });
  };

  const onUserSearch = async () => {
    if (textSearch) {
      const usersFind = await onGetUserSearch({ search: textSearch });
      setUsersFound(
        usersFind.rows.map(({ name, id, email }) => ({
          label: `${id.toString()} - ${name} - ${email}`,
          value: id.toString(),
          email,
        })),
      );
    }
  };

  let typingTimer;

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.marketing.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.marketing.notifications.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel header={<strong>{I18n.t('routes.panel.marketing.notifications.advancedFilters.title')}</strong>} key="1">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AdvancedInput
                    style={{ marginRight: 20 }}
                    value={notificationToSend.title}
                    onChange={(title) => setNotificationToSend({ ...notificationToSend, title })}
                    label={I18n.t('forms.marketing.items.title.label')}
                    placeholder={I18n.t('shared.typeToContinue')}
                    required
                    disabled={
                      !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AdvancedSelect
                    label={I18n.t('forms.marketing.items.userType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(type) => setNotificationToSend({ ...notificationToSend, type })}
                    value={notificationToSend.type}
                    options={GetUserTypeArray.map((o) => ({
                      id: o.id,
                      name: I18n.t(o.name.props.value),
                    }))}
                    showSearch
                    required
                    disabled={
                      !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                    }
                  />
                </Col>
              </Row>

              <AdvancedTextArea
                style={{ padding: 0 }}
                autoSize
                label={I18n.t('forms.marketing.items.message.label')}
                placeholder={I18n.t('shared.typeToContinue')}
                onChange={(body) => setNotificationToSend({ ...notificationToSend, body })}
                value={notificationToSend.body}
                required
                disabled={
                  !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                }
              />

              {notificationToSend.type === UserTypes.ANYUSER && (
                <AdvancedAutoComplete
                  style={{ marginTop: 10, padding: 0 }}
                  options={usersFound}
                  disabled={
                    notificationToSend.type !== UserTypes.ANYUSER ||
                    !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                  }
                  onSearch={(textToSearch) => setTextSearch(textToSearch)}
                  onSelect={(userSelectedId) => onAddUser(userSelectedId)}
                  onKeyUp={({ target }) => {
                    clearTimeout(typingTimer);
                    if (target.value.length > 0) typingTimer = setTimeout(onUserSearch, 800);
                  }}
                  onKeyDown={() => clearTimeout(typingTimer)}
                  label={I18n.t('forms.marketing.items.searchByUser.label')}
                  placeholder={I18n.t('forms.marketing.items.searchBox.placeholder')}
                  loading={isGetUserSearchIsOnRequest}
                />
              )}

              {hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE]) && (
                <Row style={{ marginTop: 20 }}>
                  <AdvancedButton
                    text={I18n.t('forms.marketing.items.send.label')}
                    onClick={onHandleValidation}
                    loading={isSendPushIsOnRequest}
                  />
                </Row>
              )}
            </Col>

            <Col style={{ marginTop: 25, paddingLeft: 20 }} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Styles.ContainerUsersSelected
                disabled={
                  notificationToSend.type !== UserTypes.ANYUSER ||
                  !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                }
              >
                <Styles.HeaderAdvancedSelectBox>
                  <Styles.TitleAdvancedSelectBox>
                    {I18n.t('forms.marketing.items.selectedUsers.label')}
                  </Styles.TitleAdvancedSelectBox>
                  {hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE]) && (
                    <Styles.CustomFaTrash onClick={() => setShowAlertRemoveAllUsersSelected(true)} />
                  )}
                </Styles.HeaderAdvancedSelectBox>
                <AdvancedSelectBox
                  onRemove={onRemove}
                  source={usersSelected}
                  disabled={
                    notificationToSend.type !== UserTypes.ANYUSER ||
                    !hasAccess([accessTypes.PUSH_NOTIFICATION], [accessActionTypes.UPDATE, accessActionTypes.CREATE])
                  }
                />
              </Styles.ContainerUsersSelected>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      <Styles.CustomModalAlert
        title={I18n.t('forms.marketing.items.removeAllUsers.label')}
        visible={showAlertRemoveAllUsersSelected}
        onOk={() => removeAllSelectedUsers()}
        onCancel={() => setShowAlertRemoveAllUsersSelected(false)}
      >
        <span>{I18n.t('forms.marketing.items.removeAllUsersConfirmation.label')}</span>
      </Styles.CustomModalAlert>
    </>
  );
}

const mapStateToProps = (state) => ({
  isGetUserSearchIsOnRequest: UserSelectors.isGetUserSearchIsOnRequest(state),
  isSendPushIsOnRequest: PushSelectors.isSendPushOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserSearch: (parameters) => dispatch(UserActions.getUserSearch(parameters)),
  onSendPush: (data) => dispatch(PushActions.sendPush(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
