import Immutable from 'seamless-immutable';
import { ACTION_GET_SETTINGS_DATA, ACTION_GET_SETTINGS_IS_ON_REQUEST } from '../actions/setting';

const initialState = Immutable({
  settings: [],
});

export default function setting(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_SETTINGS_DATA:
      return state.merge({
        settings: action.payload,
      });
    case ACTION_GET_SETTINGS_IS_ON_REQUEST:
      return state.merge({
        isGetSettingsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getDefaultCoinValue(state) {
  const found = state?.setting?.settings?.find((o) => o.name === 'defaultCoinValue');
  return found && found.value;
}

export function getDefaultStockId(state) {
  const found = state?.setting?.settings?.find((o) => o.name === 'defaultStockId');
  return found && found.value;
}
