import React from 'react';
import { InputNumber } from 'antd';

import './AdvancedInputNumber.less';
import { I18n } from 'react-redux-i18n';

function AdvancedInputNumber({
  label,
  placeholder,
  min,
  max,
  defaultValue,
  onChange,
  type,
  value,
  disabled,
  isFormatted,
  style,
  inputStyle,
  precision,
  step,
  required,
  suffix,
  className,
  isParsered,
}) {
  return (
    <div className="advanced-input-number" style={style}>
      <label className="advanced-input-number__label">
        <span className="advanced-input-number__label__inner">
          {label} {required && <strong>*</strong>}
        </span>
        <InputNumber
          className={className || 'advanced-input-number__label__inner__input'}
          style={inputStyle}
          size="middle"
          placeholder={placeholder || I18n.t('shared.typeSomething')}
          onChange={(ev) => onChange(ev)}
          type={type || 'tel'}
          value={value}
          disabled={disabled || false}
          min={min}
          max={max}
          precision={precision}
          step={step}
          formatter={(val) => (isFormatted && val ? val.replace(/\./g, ',') : val)} // transformar '.' em ','
          defaultValue={defaultValue}
          suffix={suffix}
          parser={(val) => (isParsered && val ? val.replace(/,/g, '.') : val)} // transformar ',' em '.'
        />
      </label>
    </div>
  );
}
export default AdvancedInputNumber;
