export const ProductsProviderValues = {
  MONTHS: 'meses',
  DAYS: 'dias',
  WEEKS: 'semanas',
  YEARS: 'anos',
  PERCENTAGE: 'percentual',
  VALUE: 'valor',
};

export const getProductProviderCorrectValues = (value) => {
  switch (value) {
    case ProductsProviderValues.MONTHS:
      return 'months';
    case ProductsProviderValues.DAYS:
      return 'days';
    case ProductsProviderValues.WEEKS:
      return 'weeks';
    case ProductsProviderValues.YEARS:
      return 'years';
    case ProductsProviderValues.PERCENTAGE:
      return 'percentage';
    case ProductsProviderValues.VALUE:
      return 'value';
    default:
      return value;
  }
};
