import AccessFeatureUserPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/accessFeatureUser/panel/accessFeatureUserPanelRequestsV1';

export const ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST:accessFeaturesUsers';
export const ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_DATA =
  'ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_DATA:accessFeaturesUsers';
export const ACTION_GET_ACCESS_FEATURES_USERS_OBTAINED =
  'ACTION_GET_ACCESS_FEATURES_USERS_OBTAINED:accessFeaturesUsers';

export function getAccessFeatureUsersPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
    };

    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      const payload = await AccessFeatureUserPanelRequestsV1.getAll(params);
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_DATA, payload });
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_OBTAINED, payload: true });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createUserAndAccessFeaturesAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessFeatureUserPanelRequestsV1.create(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeUserAndAccessFeaturesAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessFeatureUserPanelRequestsV1.remove(data);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateUserAndAccessFeaturesAssociation(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      const response = await AccessFeatureUserPanelRequestsV1.update(data);
      if (callback) {
        callback(response);
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createUserAndAccessFeaturesAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessFeatureUserPanelRequestsV1.create(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function removeUserAndAccessFeaturesAssociationMultiple(data, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
      await AccessFeatureUserPanelRequestsV1.remove(data, true);
      if (callback) {
        callback();
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}
