import React, { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedSelect from '../../shared/AdvancedSelect';

function ExportModal({ visible, title, loading, onSubmit, onCancel, providerList }) {
  const [providerId, setProviderId] = useState();
  const [providerName, setProviderName] = useState();

  const onHandleSubmit = () => {
    if (providerId && providerName) {
      onSubmit(providerId, providerName);
    } else {
      notification.error({
        message: I18n.t('routes.panel.products.exportModal.errors.noProvider.message'),
        description: I18n.t('routes.panel.products.exportModal.errors.noProvider.description'),
      });
    }
  };

  const onCancelModal = () => {
    setProviderId();
    setProviderName();
    onCancel();
  };

  useEffect(() => {
    setProviderId();
    setProviderName();
  }, [visible]);

  return (
    <Modal
      className="export-modal"
      title={title}
      open={visible}
      onCancel={onCancel}
      footer={[
        <AdvancedButton
          type="link"
          onClick={onCancelModal}
          key="cancel"
          text={I18n.t('routes.panel.products.exportModal.cancelText')}
        />,
        <AdvancedButton
          type="primary"
          loading={loading}
          onClick={onHandleSubmit}
          key="submit"
          text={I18n.t('routes.panel.products.exportModal.okText')}
        />,
      ]}
    >
      <div className="export-modal__container">
        <p className="export-modal__container__title">{I18n.t('routes.panel.products.exportModal.container.title')}</p>
        <div className="export-modal__container__select">
          <AdvancedSelect
            placeholder={I18n.t('shared.typeSomething')}
            onChange={(val) => {
              const providerObject = providerList.find((prov) => val === prov.id);
              setProviderName(providerObject.name);
              setProviderId(val);
            }}
            value={providerId}
            showSearch
            options={providerList.map((provider) => ({
              id: provider.id,
              name: provider.name,
            }))}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ExportModal;
