import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { I18n } from 'react-redux-i18n';

import { Collapse, Drawer, notification } from 'antd';
import { MdClose } from 'react-icons/md';

import { useDispatch } from 'react-redux';
import Loading from '../../shared/Loading';
import AdvancedButton from '../../shared/AdvancedButton';
import InnerProductForm from './innerForms/InnerProductForm';
import InnerSizesProductForm from './innerForms/InnerSizesProductForm';
import InnerValuesProductForm from './innerForms/InnerValuesProductForm';
import InnerImagesProductForm from './innerForms/InnerImagesProductForm';
import InnerNutritionProductForm from './innerForms/InnerNutritionProductForm';
import InnerCategoriesProductForm from './innerForms/InnerCategoriesProductForm';
import InnerPromotionsProductForm from './innerForms/InnerPromotionsProductForm';
import InnerProviderUnitsProductForm from './innerForms/InnerProviderUnitsProductForm';

import { ViewTypes } from '../../../app/enum/view_types';
import PriceTypes from '../../../app/enum/price_types';
import PromotionType from '../../../app/enum/promotion_type';
import DescriptionTypes from '../../../app/enum/description_types';

import useForm from '../../../app/hooks/useForm';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { ProductActions } from '../../../app/redux/actions';

const { Panel } = Collapse;

function ProductForm({ type, onCloseForm, visible, providerId, productId, disabled }) {
  const [productById, setProductById] = useState(null);

  const { product: productSelector } = useReduxState();
  const dispatch = useDispatch();

  const getDefaultPromotion = () => ({
    value: 0,
    startedAt: moment(),
    finalizedAt: moment().endOf('month'),
    isPercentage: false,
    type: PromotionType.VALUE,
  });

  const getInitialState = () => ({
    id: productId || null,
    name: null,
    score: null,
    code: null,
    hasPromotion: false,
    showNutritionalInfos: false,
    isVariablePrices: false,
    description: null,
    isVisible: true,
    createdAt: null,
    updatedAt: null,
    multiplier: 1, // req
    shelfLifeTime: null,
    shelfLifeType: null,
    suggestedPrice: null,
    minimum: 1, // req
    weight: null, // req
    descriptionType: null,
    width: null,
    height: null,
    length: null,
    images: [],
    providerUnits: [],
    provider: {
      id: null,
      name: null,
    },
    unit: {
      // req
      id: null,
      code: null,
      name: null,
    },
    promotion: getDefaultPromotion(),
    units: [],
    categories: [],
    nutritions: [],
    prices: [
      {
        type: PriceTypes.WHOLESALE_PRIMARY,
        value: 0,
        range: 0,
        commissionPrivate: 0,
        commissionPublic: 0,
        taxTiffinPrivate: 0,
        taxTiffinPublic: 0,
      },
    ],
  });

  const form = useForm(getInitialState());

  useEffect(() => {
    const getProductById = async () => {
      if (productId) {
        const response = await dispatch(ProductActions.getProduct(providerId, productId));

        const payload = {
          ...response,
          unitId: response?.unit?.id,
          images: response?.images?.map((o) => ({
            ...o,
            url: o.imageUrl,
            uri: o.imageUrl,
          })),
          units: response?.units.map((o) => ({
            ...o.unit,
            stock: o.stock,
          })),
          isVariablePrices: !!response?.prices?.find((o) => o.type === PriceTypes.WHOLESALE),
          categories: response?.categories.map((o) => o.categoryId),
          showNutritionalInfos: false,
          nutritions: [],
          hasPromotion: false,
          width: ((response.width || 0) * 100).toFixed(3),
          height: ((response.height || 0) * 100).toFixed(3),
          length: ((response.length || 0) * 100).toFixed(3),
        };

        if (response.prices && !response.prices.length) {
          payload.prices = [
            {
              type: PriceTypes.WHOLESALE_PRIMARY,
              value: 0,
              range: 0,
              commissionPrivate: 0,
              commissionPublic: 0,
              taxTiffinPrivate: 0,
              taxTiffinPublic: 0,
            },
          ];
        }

        if (response?.nutritions?.length > 0 && response?.nutritions?.filter((o) => !!o.value).length > 0) {
          payload.showNutritionalInfos = true;
          payload.nutritions = response?.nutritions.map((o) => ({
            value: o.value,
            name: o.nutrition.name,
            type: o.nutrition.type,
            id: o.nutrition.id,
          }));
        }

        if (response.promotion) {
          payload.promotion = {
            ...response.promotion,
            startedAt: moment(response.promotion.startedAt),
            finalizedAt: moment(response.promotion.finalizedAt),
          };
          payload.hasPromotion = true;
          payload.promotion.isPercentage = response.promotion.type === PromotionType.PERCENTAGE;
        } else {
          payload.promotion = getDefaultPromotion();
        }

        setProductById(payload);
        form.setForm(payload);
        form.handleInputChange('id', productId);
      } else {
        form.setForm(getInitialState());
      }
    };

    getProductById();

    return () => form.setForm(getInitialState());
  }, [productId]);

  const onSubmit = async () => {
    const { values } = form;

    const payload = {
      id: productId,
      providerId: values.provider.id,
      code: values.code,
      unitId: values.unitId,
      weight: values.weight,
      name: values.name,
      description: values.description,
      descriptionType: DescriptionTypes.TEXT,
      shelfLifeTime: values.shelfLifeTime,
      shelfLifeType: values.shelfLifeType,
      suggestedPrice: values.suggestedPrice,
      width: (values.width / 100).toFixed(5),
      height: (values.height / 100).toFixed(5),
      length: (values.length / 100).toFixed(5),
      minimum: values.minimum,
      multiplier: values.multiplier,
      isVisible: values.isVisible,
      providerUnits: values.units.map((o) => ({ ...o, providerUnitId: o.id })),
    };

    if (productId) {
      payload.categories = values.categories.map((o) => o.categoryId || o);
    } else {
      payload.categories = values.categories;
    }

    if (values.showNutritionalInfos) {
      payload.nutritions = values.nutritions.map((o) => ({
        id: o.id || o.nutrition?.id,
        value: o.value || o.nutrition?.value || '',
      }));
    } else {
      payload.nutritions = [];
    }

    if (!values.isVariablePrices && values.prices) {
      payload.prices = values.prices.filter((o) => o.type === PriceTypes.WHOLESALE_PRIMARY);
    } else {
      payload.prices = values.prices;
    }

    if (values.hasPromotion) {
      payload.promotion = {
        ...values.promotion,
        startedAt: values.promotion.startedAt?.format(),
        finalizedAt: values.promotion.finalizedAt?.format(),
        type: values.promotion.isPercentage ? PromotionType.PERCENTAGE : PromotionType.VALUE,
      };
    }

    if (values?.images?.length > 0) {
      payload.images = values?.images.map((o, i) => {
        if (!o.extension) {
          return o;
        }
        return {
          position: i,
          imageBase64: o.url,
          extension: o.extension,
        };
      });
    }

    const dataIsValid = [
      { providerId: !!payload.providerId },
      { name: !!payload.name },
      { weight: !!payload.weight },
      { width: !!payload.width },
      { height: !!payload.height },
      { length: !!payload.length },
      { unitId: !!payload.unitId },
      { minimum: !!payload.minimum },
      { multiplier: !!payload.multiplier },
      {
        prices:
          !!payload.prices &&
          payload.prices?.length > 0 &&
          payload.prices.find((o) => o.type === PriceTypes.WHOLESALE_PRIMARY).value > 0,
      },
      { categories: !!payload.categories && payload.categories?.length > 0 },
    ];

    const invalidFields = dataIsValid.map((invalidData) => {
      if (Object.values(invalidData).toString() === 'false') {
        return Object.keys(invalidData).toString();
      }
      return null;
    });

    const invalidFieldsTranslated = invalidFields.map((entry) => {
      switch (entry) {
        case 'providerId':
          return I18n.t('forms.product.invalidFields.providerId');
        case 'name':
          return I18n.t('forms.product.invalidFields.name');
        case 'weight':
          return I18n.t('forms.product.invalidFields.weight');
        case 'width':
          return I18n.t('forms.product.invalidFields.width');
        case 'height':
          return I18n.t('forms.product.invalidFields.height');
        case 'length':
          return I18n.t('forms.product.invalidFields.length');
        case 'unitId':
          return I18n.t('forms.product.invalidFields.unitId');
        case 'minimum':
          return I18n.t('forms.product.invalidFields.minimum');
        case 'multiplier':
          return I18n.t('forms.product.invalidFields.multiplier');
        case 'prices':
          return I18n.t('forms.product.invalidFields.prices');
        case 'categories':
          return I18n.t('forms.product.invalidFields.categories');
        default:
          return null;
      }
    });

    const invalidFiledFilteredTranslated = invalidFieldsTranslated.filter((filtered) => filtered !== null);

    if (invalidFiledFilteredTranslated.length > 0) {
      return notification.error({
        message: I18n.t('forms.product.invalidFields.validation.required.title'),
        description: I18n.t('forms.product.invalidFields.validation.required.description', {
          fields: invalidFiledFilteredTranslated.map((string) => string),
        }),
      });
    }

    if (
      !payload.providerUnits ||
      !payload.providerUnits?.length ||
      (payload.providerUnits && !payload?.providerUnits.filter((o) => !!o.id).length)
    ) {
      return notification.error({
        message: I18n.t('forms.product.errors.required.notifications.noUnitsAssociate.message'),
        description: I18n.t('forms.product.errors.required.notifications.noUnitsAssociate.description'),
      });
    }

    let success;

    if (!productId) {
      success = await dispatch(ProductActions.createProduct(values.provider.id, payload));
    } else {
      success = await dispatch(ProductActions.updateProduct(values.provider.id, values.id, payload));
    }

    if (success) {
      notification.success({
        message:
          type === ViewTypes.CREATE
            ? I18n.t('forms.product.createSuccessTitle')
            : I18n.t('forms.product.updateSuccessTitle'),
        description:
          type === ViewTypes.CREATE
            ? I18n.t('forms.product.createSuccessDescription')
            : I18n.t('forms.product.updateSuccessDescription'),
      });
      onCloseForm(true);
    }
  };

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={type === ViewTypes.CREATE ? I18n.t('forms.product.createTitle') : I18n.t('forms.product.updateTitle')}
      onClose={() => onCloseForm()}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton
            type="link"
            text={I18n.t('forms.cancelButtonText')}
            onClick={() => {
              onCloseForm();
            }}
          />
          {!disabled && (
            <AdvancedButton
              loading={productSelector.isUpdateProductOnRequest || productSelector.isCreateProductOnRequest}
              text={I18n.t('forms.submitButtonText')}
              onClick={onSubmit}
            />
          )}
        </div>
      }
    >
      {visible && (
        <div className="product-form">
          {productSelector.isGetProductOnRequest && (
            <div className="product-form__loading">
              <Loading />
            </div>
          )}

          <InnerProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />

          <Collapse destroyInactivePanel={false}>
            <Panel header={I18n.t('forms.product.items.sizes.label')} key="sizes">
              <InnerSizesProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />
            </Panel>
            <Panel header={I18n.t('forms.product.items.prices.header.title')} key="prices">
              <InnerValuesProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />
            </Panel>
            <Panel header={I18n.t('forms.product.items.categories.title')} key="categories">
              <InnerCategoriesProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />
            </Panel>
            <Panel
              header={I18n.t('forms.product.items.providerUnits.title')}
              extra={
                !form?.values.provider?.id && (
                  <strong>{I18n.t('forms.product.items.providerUnits.messages.selectProviderFirst')}</strong>
                )
              }
              collapsible={!form?.values.provider?.id ? 'disabled' : 'header'}
              key="providerUnits"
            >
              <InnerProviderUnitsProductForm
                updateProductData={form.setForm}
                product={form.values}
                disabled={disabled}
              />
            </Panel>
            <Panel header={I18n.t('forms.product.items.nutritional.title')} key="nutritional">
              <InnerNutritionProductForm
                updateProductData={form.setForm}
                product={form.values}
                productById={productById}
                disabled={disabled}
              />
            </Panel>
            <Panel header={I18n.t('forms.product.items.promotional.title')} key="promotional">
              <InnerPromotionsProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />
            </Panel>
            <Panel header={I18n.t('forms.product.items.pictures.title')} key="pictures">
              <InnerImagesProductForm updateProductData={form.setForm} product={form.values} disabled={disabled} />
            </Panel>
          </Collapse>
        </div>
      )}
    </Drawer>
  );
}

export default ProductForm;
