import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Col, Divider, Row } from 'antd';

import AdvancedInput from '../../../shared/AdvancedInput';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import AdvancedSelect from '../../../shared/AdvancedSelect';
import AdvancedSwitch from '../../../shared/AdvancedSwitch';
import AdvancedTextArea from '../../../shared/AdvancedTextArea';

function InnerProductForm({ updateProductData, product, disabled }) {
  const { auth } = useReduxState();

  const handleInputChange = (name, value) => {
    const productCopy = { ...product };
    productCopy[name] = value;
    updateProductData(productCopy);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <p>{I18n.t('forms.product.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <strong>{I18n.t('shared.requiredFields')}</strong>
          <Divider />
        </Col>
      </Row>
      {product?.id && (
        <Row>
          <Col span={24}>
            <AdvancedInput label={I18n.t('forms.product.items.id.label')} value={product?.id} disabled />
          </Col>
        </Row>
      )}
      {!product?.id && (
        <Row>
          <Col span={24}>
            <AdvancedSelect
              label={I18n.t('forms.product.items.provider.label')}
              onChange={(val) => handleInputChange('provider', { id: val })}
              value={product?.provider?.id}
              options={auth?.providers}
              showSearch
              required
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <AdvancedSwitch
            label={I18n.t('forms.product.items.isVisible.label')}
            onChange={(val) => handleInputChange('isVisible', val)}
            value={product?.isVisible}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInput
            label={I18n.t('forms.product.items.name.label')}
            onChange={(val) => handleInputChange('name', val)}
            value={product?.name}
            required
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInput
            label={I18n.t('forms.product.items.code.label')}
            onChange={(val) => handleInputChange('code', val)}
            value={product?.code}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedTextArea
            label={I18n.t('forms.product.items.description.label')}
            onChange={(val) => handleInputChange('description', val)}
            value={product?.description}
            maxLength={2000}
            disabled={disabled}
          />
        </Col>
      </Row>
    </div>
  );
}

export default InnerProductForm;
