import CategoryRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/category/categoryRequestsV1';
import * as CategorySelector from '../reducers/category';

export const ACTION_GET_CATEGORIES_IS_ON_REQUEST = 'ACTION_GET_CATEGORIES_IS_ON_REQUEST:category';
export const ACTION_GET_CATEGORIES_DATA = 'ACTION_GET_CATEGORIES_DATA:category';

export function getCategories() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_GET_CATEGORIES_IS_ON_REQUEST, payload: true });
      if (!CategorySelector.getCategories(getState()).length) {
        const categories = await CategoryRequestsV1.getAll();
        dispatch({ type: ACTION_GET_CATEGORIES_DATA, payload: categories });
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_CATEGORIES_IS_ON_REQUEST, payload: false });
    }
  };
}
