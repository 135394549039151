import { notification } from 'antd';
import { I18n } from 'react-redux-i18n';
import CollectionPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/collection/panel/collectionPanelRequestsV1';
import { CollectionType } from '../../enum/collection_type';

export const ACTION_GET_COLLECTIONS_IS_ON_REQUEST = 'ACTION_GET_COLLECTIONS_IS_ON_REQUEST:collection';
export const ACTION_GET_COLLECTIONS_DATA = 'ACTION_GET_COLLECTIONS_DATA:collection';

export const ACTION_UPDATE_COLLECTION_IS_ON_REQUEST = 'ACTION_UPDATE_COLLECTION_IS_ON_REQUEST:collection';

export const ACTION_DELETE_COLLECTION_IS_ON_REQUEST = 'ACTION_DELETE_COLLECTION_IS_ON_REQUEST:collection';

export const ACTION_CREATE_COLLECTION_IS_ON_REQUEST = 'ACTION_CREATE_COLLECTION_IS_ON_REQUEST:collection';

export function getCollections(params) {
  return async (dispatch) => {
    try {
      params = {
        ...params,
        search: params?.search || '',
        limit: params?.limit || 10,
        page: Number(params?.page) - 1 || 0,
        type: [CollectionType.PLATFORM],
        isWithInactiveProduct: true,
      };
      dispatch({ type: ACTION_GET_COLLECTIONS_IS_ON_REQUEST, payload: true });

      const payload = await CollectionPanelRequestsV1.getCollections(params);
      dispatch({ type: ACTION_GET_COLLECTIONS_DATA, payload });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_COLLECTIONS_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateCollection(id, payload, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTION_UPDATE_COLLECTION_IS_ON_REQUEST,
        payload: true,
      });
      const response = await CollectionPanelRequestsV1.updateCollection(id, payload);

      if (response) {
        notification.success({
          message: I18n.t('forms.collection.notification.success.updateCollection.title'),
        });

        if (typeof callback === 'function') {
          callback();
        }
      }

      return !!response;
    } finally {
      dispatch({
        type: ACTION_UPDATE_COLLECTION_IS_ON_REQUEST,
        payload: false,
      });
    }
  };
}

export function deleteCollection(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTION_DELETE_COLLECTION_IS_ON_REQUEST,
        payload: true,
      });
      const response = await CollectionPanelRequestsV1.removeCollection(id);

      if (response) {
        notification.success({
          message: I18n.t('forms.collection.notification.success.deleteCollection.title'),
        });

        if (typeof callback === 'function') {
          callback();
        }
      }

      return !!response;
    } finally {
      dispatch({
        type: ACTION_DELETE_COLLECTION_IS_ON_REQUEST,
        payload: false,
      });
    }
  };
}

export const createCollection = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_COLLECTION_IS_ON_REQUEST, payload: true });
    const response = await CollectionPanelRequestsV1.createCollection(payload);

    if (response) {
      notification.success({
        message: I18n.t('forms.collection.notification.success.createCollection.title'),
      });

      if (typeof callback === 'function') {
        callback();
      }
    }
    return !!response;
  } finally {
    dispatch({ type: ACTION_CREATE_COLLECTION_IS_ON_REQUEST, payload: false });
  }
};
