import React, { useState, useEffect } from 'react';
import { FiTrash2, FiPlus, FiAlertCircle } from 'react-icons/fi';
import { Button, Card, notification, Tag } from 'antd';
import moment from 'moment';

import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import { getOrderTotalValue, getPrice } from '../../../../../app/utils/prices';

import AdvancedDatePicker from '../../../../shared/AdvancedDatePicker/AdvancedDatePicker';
import AdvancedInput from '../../../../shared/AdvancedInput';
import { OrderStatus } from '../../../../../app/enum/order_status';

const initialFormState = {
  newDueDate: '',
};

function CreateBankSlipInstallmentForm({ order, updateInstallments, isCreditTiffins }) {
  const [formItems, setFormItems] = useState([initialFormState]);

  const bankSlipInstallmentValue = getOrderTotalValue(order);

  const handleNewDueDateChange = (value, index) => {
    const newFormValues = [...formItems];

    if (index !== 0 && moment(value).isSameOrBefore(formItems[index - 1].newDueDate, 'days', true)) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidBankSlipInstallmentsError.message'),
        description: I18n.t('forms.order.notifications.invalidBankSlipInstallmentsError.description'),
      });
    }

    if (
      formItems.length > 1 &&
      moment(value).isSameOrAfter(formItems[index + 1] ? formItems[index + 1].newDueDate : null, 'days', true)
    ) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidBankSlipInstallmentsError.message'),
        description: I18n.t('forms.order.notifications.invalidBankSlipInstallmentsError.description'),
      });
    }

    newFormValues[index].newDueDate = value;
    setFormItems(newFormValues);
    updateInstallments(newFormValues);
  };

  const addFormFields = (index) => {
    if (!formItems[index].newDueDate) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidInstallmentFieldError.message'),
        description: I18n.t('forms.order.notifications.invalidInstallmentFieldError.description'),
      });
    }

    setFormItems([
      ...formItems,
      {
        newDueDate: '',
      },
    ]);
    updateInstallments([
      ...formItems,
      {
        newDueDate: '',
      },
    ]);
  };

  const removeFormFields = (index) => {
    const newFormValues = [...formItems];
    newFormValues.splice(index, 1);
    setFormItems(newFormValues);
  };

  useEffect(() => {
    if (isCreditTiffins) {
      const orderOptionSplited = order?.option?.split('_')?.filter((o) => !isNaN(o));
      const orderInstallmentParsed = orderOptionSplited?.map((o) => {
        return {
          newDueDate: moment().add(Number(o), 'day'),
        };
      });

      setFormItems([...orderInstallmentParsed]);
      updateInstallments([...orderInstallmentParsed]);
    } else {
      setFormItems([initialFormState]);
    }
  }, [order]);

  return (
    <>
      {[OrderStatus.PENDING, OrderStatus.WAITING_FREIGHT_CONFIRMATION, OrderStatus.WAITING_FREIGHT_VALUE].includes(
        order.status,
      ) && (
        <Card className="order-form__warning__status-warning">
          <div className="order-form__warning__status-warning__card-content">
            <div>
              <FiAlertCircle size={42} />
            </div>

            {I18n.t('forms.order.payment.warnings.bankSlipStatus')}
          </div>
        </Card>
      )}

      <div style={{ marginBottom: 10 }}>
        {I18n.t('forms.order.payment.credit.title')}:
        <Tag style={{ marginLeft: 10 }} color={isCreditTiffins ? 'yellow' : 'blue'}>
          {isCreditTiffins
            ? I18n.t('forms.order.payment.credit.tiffins')
            : I18n.t('forms.order.payment.credit.provider')}
        </Tag>
      </div>

      {formItems.map((item, index) => (
        <div key={item.id} className="order-form__installments-form__container">
          <div className="order-form__installments-form__container__data-input">
            <AdvancedDatePicker
              label={`${index + 1}${I18n.t('forms.order.items.installmetNumber.label')}`}
              format="DD/MM/YYYY"
              value={formItems[index].newDueDate}
              onChange={(value) => handleNewDueDateChange(value, index)}
            />
          </div>
          <AdvancedInput
            label={I18n.t('forms.order.items.installmetValue.label')}
            value={getPrice(bankSlipInstallmentValue / formItems.length)}
            disabled
          />
          <div className="order-form__installments-form__container__actions">
            <Button
              type="text"
              onClick={() => removeFormFields(index)}
              disabled={index === 0 || index !== formItems.length - 1}
              className={classNames({
                'order-form__installments-form__container__installmet-action-button': true,
                'order-form__installments-form__container__installmet-action-button--invisible':
                  index !== formItems.length - 1,
              })}
            >
              <FiTrash2 size={18} color={index === 0 || index !== formItems.length - 1 ? 'gray' : 'red'} />
            </Button>
            <Button
              type="text"
              onClick={() => addFormFields(index)}
              disabled={index !== formItems.length - 1}
              className={classNames({
                'order-form__installments-form__container__installmet-action-button': true,
                'order-form__installments-form__container__installmet-action-button--invisible':
                  index !== formItems.length - 1,
              })}
            >
              <FiPlus size={18} color={index !== formItems.length - 1 ? 'gray' : 'black'} />
            </Button>
          </div>
        </div>
      ))}
    </>
  );
}

export default CreateBankSlipInstallmentForm;
