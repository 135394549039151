import React, { useCallback, useEffect, useMemo } from 'react';

import { I18n } from 'react-redux-i18n';

import { Button, Col, Collapse, Drawer, Row, notification } from 'antd';
import { MdClose } from 'react-icons/md';

import { useDispatch } from 'react-redux';
import AdvancedInput from '../../shared/AdvancedInput';
import AdvancedTextArea from '../../shared/AdvancedTextArea';

import useForm from '../../../app/hooks/useForm';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { CollectionActions } from '../../../app/redux/actions';
import InnerCollectionProductsForm from './innerForms/InnerCollectionProductsForm';
import { ViewTypes } from '../../../app/enum/view_types';
import Loading from '../../shared/Loading/Loading';
import { CollectionType } from '../../../app/enum/collection_type';

const getInitialState = {
  title: null,
  description: null,
  products: [],
};

function CollectionDetailForm({ onClose, id, visible, type, onRefresh }) {
  const { collection: CollectionSelectors } = useReduxState();
  const dispatch = useDispatch();

  const { Panel } = Collapse;

  const form = useForm(getInitialState);

  const loadingState = useMemo(
    () => CollectionSelectors.isUpdateCollectionDetailOnRequest || CollectionSelectors.isCreateCollectionOnRequest,
    [CollectionSelectors.isUpdateCollectionDetailOnRequest, CollectionSelectors.isCreateCollectionOnRequest],
  );

  const onGetCollectionDetail = useCallback(async () => {
    const collectionSelected = CollectionSelectors?.collectionsPaginated?.rows?.find(
      (collection) => collection.id === Number(id),
    );

    if (type === ViewTypes.EDIT && collectionSelected) {
      form.setForm({
        ...collectionSelected,
        products: collectionSelected?.products?.map((o) => o.product) || [],
      });
    }
  }, [id]);

  const onCloseForm = useCallback(() => {
    form.setForm(getInitialState);
    onClose();
  }, [form, onClose]);

  const onRefreshForm = useCallback(() => {
    form.setForm(getInitialState);
    onRefresh();
  }, [form, onRefresh]);

  useEffect(() => {
    if (visible && id) {
      onGetCollectionDetail();
    }
  }, [id, visible]);

  const onSubmit = useCallback(async () => {
    if (!form.values.title) {
      return notification.error({
        message: I18n.t('forms.collection.notification.error.noTitle.title'),
        description: I18n.t('forms.collection.notification.error.noTitle.description'),
      });
    }

    if (form.values.products.length < 1) {
      return notification.error({
        message: I18n.t('forms.collection.notification.error.emptyItens.title'),
        description: I18n.t('forms.collection.notification.error.emptyItens.description'),
      });
    }

    const collectionProductsUpdate = form.values.products?.map((o) => o.id);

    if (type === ViewTypes.CREATE) {
      return dispatch(
        CollectionActions.createCollection(
          {
            ...form.values,
            products: collectionProductsUpdate,
            isPublic: true,
            type: CollectionType.PLATFORM,
          },
          onRefreshForm,
        ),
      );
    }

    return dispatch(
      CollectionActions.updateCollection(
        id,
        {
          ...form.values,
          products: collectionProductsUpdate,
        },
        onRefreshForm,
      ),
    );
  }, [form, id, onRefreshForm, type]);

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={I18n.t('forms.collection.title')}
      onClose={onCloseForm}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div className="collection-form__footer">
          <Button type="link" onClick={onCloseForm} loading={loadingState}>
            {I18n.t('forms.cancelButtonText')}
          </Button>

          <Button type="primary" onClick={onSubmit} loading={loadingState}>
            {I18n.t('forms.submitButtonText')}
          </Button>
        </div>
      }
    >
      <div className="collection-form">
        {!loadingState && (
          <>
            <Row>
              <Col span={24}>
                <AdvancedInput
                  label={I18n.t('forms.collection.items.title.label')}
                  onChange={(val) => form.handleInputChange('title', val)}
                  value={form.values.title}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <AdvancedTextArea
                  label={I18n.t('forms.collection.items.description.label')}
                  onChange={(val) => form.handleInputChange('description', val)}
                  value={form.values.description}
                />
              </Col>
            </Row>

            <Collapse
              accordion
              style={{ marginTop: 10 }}
              collapsible={loadingState ? 'disabled' : undefined}
              defaultActiveKey={['1']}
            >
              <Panel
                header={`${I18n.t('forms.collection.items.providerProducts.header')} (${
                  form.values.products?.length || 0
                })`}
                key="1"
              >
                <InnerCollectionProductsForm products={form.values.products || []} form={form} />
              </Panel>
            </Collapse>
          </>
        )}

        <Loading size={40} loading={loadingState} />
      </div>
    </Drawer>
  );
}

export default CollectionDetailForm;
