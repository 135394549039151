import React from 'react';
import { Divider, Layout, Row, Col, message, Breadcrumb, Collapse, Menu, Dropdown, Modal, Popover } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { isMobile } from 'react-device-detect';
import {
  DashboardFilled,
  SearchOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
  QuestionCircleFilled,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import * as BooleanDefaultStatus from '../../../app/enum/boolean_default_status';
import { ViewTypes } from '../../../app/enum/view_types';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import ImportModal from '../../../components/panel/ImportModal';
import ProductForm from '../../../components/product/form';
import * as DateUtils from '../../../app/utils/date';
import ExportModal from '../../../components/panel/ExportModal';

import { ProductActions, CategoryActions, ProviderActions } from '../../../app/redux/actions';
import { AuthSelectors, ProductSelectors, ProviderSelectors } from '../../../app/redux/reducers';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { UserTypes } from '../../../app/enum/user_types';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      productFormVisible: false,
      importModalVisible: false,
      exportModalVisible: false,
      filteredInfo: null,
      form: {
        type: null,
        id: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    this.getCategories();
    this.getProductsUnits();
  }

  getCategories = () => {
    const { onGetCategories } = this.props;
    onGetCategories();
  };

  getProductsUnits = async () => {
    const { onGetProductsUnits } = this.props;
    await onGetProductsUnits();
  };

  getProducts = (params) => {
    const { onGetProductsPaginated } = this.props;
    const { search, filters, providerId } = this.state;

    if (params) {
      this.setState({ filters: params });
      onGetProductsPaginated({ ...params, search, providerId });
    } else {
      onGetProductsPaginated({ ...filters, search, providerId });
    }
  };

  fieldChange = (name, value) => {
    const { state } = this;
    state[name] = value ? value.toString() : null;
    this.setState(state);
  };

  onCleanAdvancedFilters = async () => {
    await this.setState({ filters: null, search: '', providerId: null, filteredInfo: null });
    this.dataTableRef.current.reset();
    this.getProducts();
  };

  onChangeAdvancedFilterProviderSelector = async (providerId = null) => {
    await this.setState({ providerId });
    this.getProducts();
  };

  onUploadFileSuccess = () => {
    message.success(I18n.t('routes.panel.products.importCsvModal.messages.success.import'));
    this.setState({ importModalVisible: false });
  };

  removeProduct = async (providerId, productId) => {
    const { onDeleteProduct } = this.props;
    const success = await onDeleteProduct(providerId, productId);
    if (success) {
      message.success(I18n.t('routes.panel.products.messages.success.delete'));
      this.getProducts();
    }
  };

  onActionsClick = async (productId, providerId, item, key) => {
    switch (key) {
      case '1':
        return this.onProductFormStateChange(true, productId, providerId, ViewTypes.EDIT);
      case '2':
        return this.onShowDeleteConfirm(providerId, productId);
      default:
    }
  };

  onProductFormStateChange = (visible, productId = null, providerId = null, type = null) => {
    this.setState({ productFormVisible: visible, form: { productId, providerId, type } });
  };

  onShowDeleteConfirm = (providerId, productId) => {
    const { confirm } = Modal;
    const { isDeleteProductOnRequest } = this.props;

    confirm({
      title: 'Atenção, deseja realmente deletar esse produto?',
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: 'Sim',
      okType: 'danger',
      centered: true,
      cancelText: 'Cancelar',
      confirmLoading: isDeleteProductOnRequest,
      onOk: () => this.removeProduct(providerId, productId),
    });
  };

  handleSearch = () => {
    this.dataTableRef.current.reset();
    this.getProducts();
  };

  handleChange = (pagination, filters) => {
    this.setState({ filteredInfo: filters });
  };

  exportModalHandleSubmit = async (providerId, providerName) => {
    const { onExportProviderProducts } = this.props;
    const success = await onExportProviderProducts(providerId, providerName);

    if (success) {
      this.setState({ exportModalVisible: false });
    }
  };

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const {
      importModalVisible,
      search,
      productFormVisible,
      form,
      providerId: selectedProviderId,
      exportModalVisible,
    } = this.state;

    const {
      getMyProviders,
      getProductsPaginated,
      isGetProductsPaginatedOnRequest,
      isUploadProductsOnRequest,
      onUploadProducts,
      isGetExportProviderProducts,
      me,
    } = this.props;

    const { type, providerId, productId } = form;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.products.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel header={<strong>{I18n.t('routes.panel.products.advancedFilters.title')}</strong>} key="1">
            <Row>
              <AdvancedInput
                style={{ width: 240, marginRight: 10 }}
                value={search}
                onChange={(val) => this.fieldChange('search', val)}
                placeholder={I18n.t('shared.typeToContinue')}
                onPressEnter={this.handleSearch}
              />
              <Popover placement="topLeft" content={I18n.t('routes.panel.products.advancedFilters.searchFieldTitle')}>
                <QuestionCircleFilled style={{ fontSize: 20, color: '#000000', marginTop: 5 }} />
              </Popover>
            </Row>

            {getMyProviders.length && (
              <AdvancedSelect
                allowClear
                showSearch
                style={{ width: 240 }}
                value={selectedProviderId}
                onClear={() => this.onChangeAdvancedFilterProviderSelector()}
                onChange={this.onChangeAdvancedFilterProviderSelector}
                placeholder={I18n.t('header.providerSelectPlaceholder')}
                options={getMyProviders.map((provider) => ({
                  id: provider.id,
                  name: provider.name,
                }))}
              />
            )}

            <Row>
              <AdvancedButton
                type="default"
                text={I18n.t('routes.panel.products.advancedFilters.clearButtonText')}
                onClick={this.onCleanAdvancedFilters}
              />
              <AdvancedButton
                style={{ marginLeft: 10 }}
                text={I18n.t('routes.panel.products.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={this.handleSearch}
              />
            </Row>
          </Panel>
        </Collapse>

        <Content className="panel__layout__content panel__layout__content--content-data">
          <Row align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="panel__layout__content__actions">
                <div className="panel__layout__content__actions__left">
                  {hasAccess([accessTypes.PRODUCTS], [accessActionTypes.CREATE]) && (
                    <AdvancedButton
                      onClick={() => {
                        this.onProductFormStateChange(true, null, null, ViewTypes.CREATE);
                      }}
                      text={I18n.t('routes.panel.products.addNewButtonText')}
                      iconLeft={<PlusOutlined />}
                    />
                  )}
                </div>
                <div className="panel__layout__content__actions__right">
                  <AdvancedButton
                    onClick={() => {
                      this.setState({ exportModalVisible: true });
                    }}
                    text={I18n.t('routes.panel.products.exportModal.okText')}
                    iconLeft={<DownOutlined />}
                  />
                  {hasAccess([accessTypes.PRODUCTS], [accessActionTypes.CREATE]) && (
                    <AdvancedButton
                      style={{ marginLeft: 15 }}
                      onClick={() => {
                        this.setState({ importModalVisible: !importModalVisible });
                      }}
                      text={I18n.t('routes.panel.products.importCsvButtonText')}
                      iconLeft={<UpOutlined />}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Divider />

          <AdvancedDataTable
            refresh={this.getProducts}
            getMethod={(parameters) => this.getProducts(parameters)}
            data={getProductsPaginated}
            sticky
            loading={isGetProductsPaginatedOnRequest}
            ref={this.dataTableRef}
            container="products"
            onChange={this.handleChange}
            columns={[
              {
                key: I18n.t('routes.panel.products.dataTable.columns.id.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.id.title'),
                fixed: !isMobile ? 'left' : false,
                width: 100,
                render: (value) => value || '--',
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.code.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.code.title'),
                width: 150,
                render: (code) => code || '--',
                editable: hasAccess([accessTypes.PRODUCTS], [accessActionTypes.UPDATE]),
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.name.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.name.title'),
                width: 250,
                render: (name) => name || '--',
                editable: hasAccess([accessTypes.PRODUCTS], [accessActionTypes.UPDATE]),
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.providerId.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.providerId.title'),
                width: 120,
                render: (value) => (value ? value.id || 'N/A' : 'N/A'),
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.providerName.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.providerName.title'),
                width: 200,
                render: (value) => (value ? value.name || 'N/A' : 'N/A'),
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.isVisible.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.isVisible.title'),
                width: 120,
                render: (value) => BooleanDefaultStatus.GetBooleanDefaultStatusName(value) || '--',
                filters: BooleanDefaultStatus.GetBooleanDefaultStatusFilters,
                filteredValue: this.state.filteredInfo?.isVisible || null,
                editable: hasAccess([accessTypes.PRODUCTS], [accessActionTypes.UPDATE]),
                selector: BooleanDefaultStatus.GetBooleanDefaultStatusArray,
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.createdAt.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.createdAt.title'),
                width: 120,
                render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.updatedAt.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.updatedAt.title'),
                width: 120,
                render: (updatedAt) => DateUtils.humanizeDateTime(updatedAt, 'DD/MM/YYYY HH:mm'),
                sorter: true,
              },
              {
                key: I18n.t('routes.panel.products.dataTable.columns.actions.key'),
                title: I18n.t('routes.panel.products.dataTable.columns.actions.title'),
                fixed: 'right',
                width: 65,
                render: (id, product) => (
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={({ item, key, keyPath, domEvent }) =>
                          this.onActionsClick(id, product.provider.id, item, key, keyPath, domEvent)
                        }
                      >
                        <Menu.Item key="1">
                          {I18n.t('routes.panel.products.dataTable.columns.actions.goToDetailsText')}
                        </Menu.Item>
                        {hasAccess([accessTypes.PRODUCTS], [accessActionTypes.REMOVE]) && (
                          <Menu.Item key="2">
                            {I18n.t('routes.panel.products.dataTable.columns.actions.removeText')}
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <div className="ant-dropdown-link">
                      <MoreOutlined style={{ fontSize: 20 }} />
                    </div>
                  </Dropdown>
                ),
              },
            ]}
          />
        </Content>
        <ProductForm
          providerId={providerId}
          productId={productId}
          type={type}
          visible={productFormVisible}
          onCloseForm={(refresh) => {
            this.onProductFormStateChange(false);
            if (refresh) {
              this.getProducts();
            }
          }}
          disabled={!hasAccess([accessTypes.PRODUCTS], [accessActionTypes.UPDATE])}
        />
        <ImportModal
          visible={importModalVisible}
          title={I18n.t('routes.panel.products.importCsvModal.title')}
          loading={isUploadProductsOnRequest}
          acceptFilesTypes=".xlsx, .csv"
          templatePath="/assets/files/product-import-template.xlsx"
          cancelButtonText={I18n.t('routes.panel.products.importCsvModal.cancelText')}
          uploadButtonText={I18n.t('routes.panel.products.importCsvModal.okText')}
          templateButtonText={I18n.t('routes.panel.products.importCsvModal.templateButtonText')}
          templateButtonTextDescription={I18n.t('routes.panel.products.importCsvModal.templateButtonTextDescription')}
          selectFileTitle={I18n.t('routes.panel.products.importCsvModal.selectFileTitle')}
          selectFileDescription={I18n.t('routes.panel.products.importCsvModal.selectFileDescription')}
          onUploadFileMethod={(providerIdToUpload, filesToUpload) =>
            onUploadProducts(providerIdToUpload, filesToUpload)
          }
          onUploadFileSuccess={() => this.onUploadFileSuccess()}
          onCancelButtonClick={() => this.setState({ importModalVisible: false })}
        />
        <ExportModal
          visible={exportModalVisible}
          title={I18n.t('routes.panel.products.exportModal.title')}
          loading={isGetExportProviderProducts}
          onSubmit={this.exportModalHandleSubmit}
          onCancel={() => this.setState({ exportModalVisible: false })}
          providerList={getMyProviders}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMyProviders: AuthSelectors.getProviders(state),
  getProductsPaginated: ProductSelectors.getProductsPaginated(state),
  isGetProductsPaginatedOnRequest: ProductSelectors.isGetProductsPaginatedOnRequest(state),
  isDeleteProductOnRequest: ProductSelectors.isDeleteProductOnRequest(state),
  isUploadProductsOnRequest: ProductSelectors.isUploadProductsOnRequest(state),
  isGetExportProviderProducts: ProviderSelectors.isGetExportProviderProductsOnRequest(state),
  getExportProviderProducts: ProviderSelectors.getExportProviderProducts(state),
  me: AuthSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductsPaginated: (parameters) => dispatch(ProductActions.getProductsPaginated(parameters)),
  onUploadProducts: (providerId, filesToUpload) => dispatch(ProductActions.uploadProducts(providerId, filesToUpload)),
  onDeleteProduct: (providerId, productId) => dispatch(ProductActions.deleteProduct(providerId, productId)),
  onGetCategories: () => dispatch(CategoryActions.getCategories()),
  onGetProductsUnits: () => dispatch(ProductActions.getProductsUnits()),
  onExportProviderProducts: (providerId, providerName) =>
    dispatch(ProviderActions.getExportProviderProducts(providerId, providerName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
