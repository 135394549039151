import React from 'react';
import './HeaderDefault.less';
import { connect } from 'react-redux';
import { Layout, Menu, Row, Avatar, Dropdown, notification, Card, Typography, Modal } from 'antd';
import { MdMenu } from 'react-icons/md';
import { I18n } from 'react-redux-i18n';
import { ChatActions } from 'tiffin-foods-chat-component/lib/cjs/actions';
import {
  ChatMessageTypeEnumerator,
  ChatReferenceTypeEnumerator,
} from 'tiffin-foods-chat-component/lib/cjs/enumerators';

import AdvancedButton from '../shared/AdvancedButton';
import { getInitials } from '../../app/utils/string';

import { UserTypes } from '../../app/enum/user_types';

import { AuthActions } from '../../app/redux/actions';
import { AuthSelectors } from '../../app/redux/reducers';
import AdvancedSelect from '../shared/AdvancedSelect/AdvancedSelect';
import { hasAccess } from '../../app/services/access';
import { accessTypes } from '../../app/enum/access_types';
import { accessActionTypes } from '../../app/enum/access_action_types';

class HeaderDefault extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProviderId: null,
    };
  }

  onOpenNotification = () => {
    const { onLogout } = this.props;
    const key = `open${Date.now()}`;
    const btn = (
      <AdvancedButton
        type="primary"
        text={I18n.t('header.logout.confirmButton')}
        onClick={() => {
          onLogout();
          notification.close(key);
        }}
      />
    );

    notification.open({
      message: I18n.t('header.logout.confirmation'),
      btn,
      key,
    });
  };

  onChangeAdvancedFilterProviderSelector = (id = null) => {
    this.setState({ selectedProviderId: id });
  };

  onSelectProviderSupport = async () => {
    const { selectedProviderId } = this.state;

    const payload = {
      type: ChatMessageTypeEnumerator.window,
      windowTitle: 'nova mensagem',
      createdById: selectedProviderId,
      createdByType: ChatReferenceTypeEnumerator.provider,
    };

    try {
      await ChatActions.createEmptyChat(payload);
    } catch (e) {
      // nothing to see here
    }
  };

  onOpenSupportChat = async () => {
    const { getProviders } = this.props;

    Modal.confirm({
      title: 'Para qual fornecedor você deseja suporte?',
      content: (
        <div>
          {getProviders.length && (
            <AdvancedSelect
              allowClear
              showSearch
              style={{ marginTop: 15, width: 240 }}
              onClear={() => this.onChangeAdvancedFilterProviderSelector()}
              onChange={this.onChangeAdvancedFilterProviderSelector}
              placeholder={I18n.t('header.providerSelectPlaceholder')}
              options={getProviders.map((provider) => ({
                id: provider.id,
                name: provider.name,
              }))}
            />
          )}
        </div>
      ),
      onOk: () => {
        this.onSelectProviderSupport();
      },
    });
  };

  render() {
    const { Header } = Layout;

    const { Text } = Typography;
    const { getUser, showDrawer, onToggleSideMenu, headerStyle = {} } = this.props;

    return (
      <Header style={headerStyle} className="header">
        <Card className="header__wrapper">
          <Row>
            {showDrawer && <MdMenu className="header__trigger__button" onClick={onToggleSideMenu} />}
            <Row className="header__logo">
              <img alt="login logo" className="header__logo__img" src="/assets/img/img_logo_t.png" />
              <Text className="header__logo__title">{I18n.t('application.title')}</Text>
            </Row>
            {getUser && (
              <div className="header__avatar">
                {![UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type) &&
                  hasAccess([accessTypes.CHAT], [accessActionTypes.READ]) && (
                    <AdvancedButton
                      text={I18n.t('header.startSupportChat')}
                      onClick={() => this.onOpenSupportChat()}
                      loading={false}
                      style={{ marginRight: 10 }}
                    />
                  )}
                <Dropdown
                  trigger="click"
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <div className="header__avatar__userdata">
                          <strong>{getUser.name}</strong>
                          <small>{getUser.email}</small>
                        </div>
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item onClick={() => this.onOpenNotification()}>
                        <AdvancedButton
                          style={{ padding: 0, margin: 0 }}
                          text={I18n.t('header.logout.menuText')}
                          type="link"
                        />
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Avatar className="header__avatar__item" size={27}>
                    {getInitials(getUser.name)}
                  </Avatar>
                </Dropdown>
              </div>
            )}
          </Row>
        </Card>
      </Header>
    );
  }
}

const mapStateToProps = (state) => ({
  getUser: AuthSelectors.getUser(state),
  getUserId: AuthSelectors.getUserId(state),
  getProviders: AuthSelectors.getProviders(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(AuthActions.logout(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDefault);
