import React from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import AdvancedButton from '../../components/shared/AdvancedButton';

import { AuthActions } from '../../app/redux/actions';

class Forbidden extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { Content } = Layout;

    return (
      <Content className="forbidden__container">
        <Row className="forbidden__container__content" align="middle" justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="forbidden__container__content__text">
              <h1>{I18n.t('routes.forbidden.title')}</h1>

              <h2>{I18n.t('routes.forbidden.description')}</h2>

              <AdvancedButton
                style={{ marginTop: 20 }}
                type="primary"
                size="large"
                shape="round"
                onClick={() => this.props.onVerifyInitialFlow()}
                text={I18n.t('routes.forbidden.buttonText')}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="forbidden__container__content__logo">
              <img
                alt="forbidden"
                className="forbidden__container__content__logo__img"
                src="/assets/img/img_forbidden.png"
              />
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onVerifyInitialFlow: () => dispatch(AuthActions.verifyInitialFlow()),
});

export default connect(null, mapDispatchToProps)(Forbidden);
