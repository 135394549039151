import {
  ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_DATA,
  ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ACCESS_GROUPS_FEATURES_OBTAINED,
} from '../actions/accessGroupFeatures';

const initialState = {
  accessGroupsFeaturesPaginated: null,
  getAccessGroupsFeaturesPaginatedIsOnRequest: false,
  accessGroupsFeaturesPaginatedObtained: false,
};

export default function accessGroupFeatures(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_DATA:
      return {
        ...state,
        accessGroupsFeaturesPaginated: action.payload,
      };
    case ACTION_GET_ACCESS_GROUPS_FEATURES_PAGINATED_IS_ON_REQUEST:
      return {
        ...state,
        getAccessGroupsFeaturesPaginatedIsOnRequest: action.payload,
      };
    case ACTION_GET_ACCESS_GROUPS_FEATURES_OBTAINED:
      return {
        ...state,
        accessGroupsFeaturesPaginatedObtained: action.payload,
      };
    default:
      return state;
  }
}
