import React from 'react';
import { I18n } from 'react-redux-i18n';
import MaskedInput from 'antd-mask-input';
import { connect } from 'react-redux';
import { ContainerTitle, ContainerText, HiddenBar } from './styles';
import { UserSelectors } from '../../../../app/redux/reducers';
import { UserActions } from '../../../../app/redux/actions';

function TypeUserStep({ signupUser, onSetSignupUser }) {
  return (
    <div>
      <ContainerTitle>{I18n.t('routes.signup.content.typeOfAccount.userLegal.radioText')}</ContainerTitle>
      <ContainerText>{I18n.t('routes.signup.content.typeOfAccount.userLegal.text.line1')}</ContainerText>
      <HiddenBar>
        <MaskedInput
          value={signupUser?.document}
          mask="00.000.000/0000-00"
          onChange={({ target }) => onSetSignupUser('document', target.value)}
          placeholder={I18n.t('routes.signup.content.CNPJInputPlaceholder')}
          required
        />
      </HiddenBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  signupUser: UserSelectors.getSignupUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeUserStep);
