import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { Collapse, Button, notification, Tag, Typography } from 'antd';
import classnames from 'classnames';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import { useReduxState } from '../../../../app/hooks/useReduxState';

import { CardActions, OrderActions } from '../../../../app/redux/actions';

import * as OrderPaymentTypes from '../../../../app/enum/payment_types';
import { UserTypes } from '../../../../app/enum/user_types';
import { PaymentStatus } from '../../../../app/enum/payment_status';
import { humanizeDate } from '../../../../app/utils/date';
import { getOrderFees, getOrderTotalValueWithoutFees, getPrice } from '../../../../app/utils/prices';

import AdvancedSelect from '../../../shared/AdvancedSelect';
import AdvancedTextArea from '../../../shared/AdvancedTextArea';
import AdvancedModal from '../../../shared/AdvancedModal/AdvancedModal';

import CreateBankSlipInstallmentForm from './components/CreateBankSlipInstallmentForm';
import UpdateBankSlipInstallmentForm from './components/UpdateBankSlipInstallmentForm';
import { accessActionTypes } from '../../../../app/enum/access_action_types';
import { accessTypes } from '../../../../app/enum/access_types';
import { hasAccess } from '../../../../app/services/access';
import { OrderStatus } from '../../../../app/enum/order_status';

function InnerPaymentOrderForm({
  order,
  onUpdateOrderValues,
  orderFormInit,
  validPayments,
  disabled,
  onCloseForm,
  coinValue,
}) {
  const [selectedCard, setSelectedCard] = useState(order?.jsonPayment?.creditCardId || null);
  const [installments, setInstallments] = useState([]);
  const [option, setOption] = useState(
    order?.credit ? `${order?.jsonInstallments?.join('_')}_tiffin` || '' : order?.jsonInstallments?.join('_') || '',
  );
  const [generateBankSlipInstallmentsModalState, setGenerateBankSlipInstallmentsModalState] = useState({
    isVisible: false,
    isCreditTiffins: false,
  });
  const [isUpdateBankSlipInstallmentsModalVisible, setIsUpdateBankSlipInstallmentsModalVisible] = useState(false);
  const [newBankSlipInstallments, setNewBankSlipInstallments] = useState([]);
  const [installmentUpdateInfo, setInstallmentUpdateInfo] = useState();
  const [newInstallmentUpdateInfo, setNewInstallmentUpdateInfo] = useState();

  const { Text } = Typography;

  const { auth: authSelector, order: orderSelector, payment: paymentSelector, card: cardSelector } = useReduxState();

  const dispatch = useDispatch();
  const { Panel } = Collapse;

  const paymentTypes = Object.values(OrderPaymentTypes.PaymentTypes);

  const creditCardsAvailableForOrder = validPayments?.filter(
    (o) =>
      o.payment?.parent === OrderPaymentTypes.PaymentTypes.CREDIT_CARD &&
      o.paymentType !== 'credit_card_add' &&
      !!o.payment?.option,
  );

  const orderInstallment = () => {
    if (order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
      return Number(option);
    }

    return option;
  };

  const installmentsAvailableForOrder = async () => {
    const OrderInstallments = [];

    if (order && order.paymentType !== order.payment?.parent) {
      setOption('');
    }

    OrderInstallments.push({
      id: 'new_installments',
      name: I18n.t('forms.order.payment.items.createNewInstallments.description'),
    });

    if (order && order.paymentType === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT) {
      if (
        order.payment &&
        !order.payment?.option &&
        order.payment?.parent === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT
      ) {
        if (order?.credit) {
          const existInstallment = validPayments?.filter(
            (o) =>
              o.payment?.parent === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT &&
              o.payment?.option === `${order.jsonInstallments?.join('_')}_tiffin`,
          );

          if (existInstallment?.length === 0) {
            OrderInstallments.push({
              id: `${order.jsonInstallments?.join('_')}_tiffin`,
              name: `${I18n.t('forms.order.payment.items.creditTiffinsOption')} ${I18n.t(
                'forms.order.payment.items.bankslipInstallmetOption.prefix',
              )} ${order.jsonInstallments?.length} ${I18n.t(
                'forms.order.payment.items.bankslipInstallmetOption.interfix',
              )} ${order.jsonInstallments?.join('/')} ${I18n.t(
                'forms.order.payment.items.bankslipInstallmetOption.suffix',
              )}`,
            });
          }
        } else {
          const existInstallment = validPayments?.filter(
            (o) =>
              o.payment?.parent === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT &&
              o.payment?.option === order.jsonInstallments?.join('_'),
          );

          if (existInstallment?.length === 0 && order.jsonInstallments) {
            OrderInstallments.push({
              id: order.jsonInstallments?.join('_'),
              name: `${I18n.t('forms.order.payment.items.bankslipInstallmetOption.prefix')} ${
                order.jsonInstallments?.length
              } ${I18n.t('forms.order.payment.items.bankslipInstallmetOption.interfix')} ${order.jsonInstallments?.join(
                '/',
              )} ${I18n.t('forms.order.payment.items.bankslipInstallmetOption.suffix')}`,
            });
          }
        }
      }

      setInstallments([
        ...OrderInstallments,
        ...validPayments
          .filter(
            (o) =>
              o.payment?.parent === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT &&
              o.payment?.parent?.length < o.paymentType?.length,
          )
          .map((e) => {
            if (e.payment?.option?.split('_').reverse()[0] === 'tiffin') {
              return {
                id: e.payment?.option,
                name: `${I18n.t('forms.order.payment.items.creditTiffinsOption')} ${e.payment?.optionName}`,
              };
            }

            return { id: e.payment?.option, name: e.payment?.optionName };
          }),
      ]);
    } else if (order && order.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
      const creditCardPaymentMethods = cardSelector.cardInstallmentTax?.map((o) => {
        const paymentFees = getOrderFees({
          ...order,
          fees: parseFloat(o.buyerTax),
        });
        const orderTotalValue = getOrderTotalValueWithoutFees({ ...order, coinValue }) + paymentFees;
        return {
          id: o.installment,
          name: `${o.installment}x ${getPrice(orderTotalValue / o.installment)},  juros ${getPrice(
            paymentFees,
          )}, total ${getPrice(orderTotalValue)}`,
        };
      });

      const creditCardPaymentMethodsSorted = [...creditCardPaymentMethods]?.sort((a, b) => a.id - b.id);

      setInstallments([...creditCardPaymentMethodsSorted]);
    }
  };

  const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');

  const onRenewOrderPayment = async (orderId, type) => {
    const success = await dispatch(OrderActions.renewOrderPayment(orderId));
    if (success) {
      if (type === OrderPaymentTypes.PaymentTypes.PIX) {
        notification.success({
          message: I18n.t('forms.order.renewOrderPayment.pix.title'),
          description: I18n.t('forms.order.renewOrderPayment.pix.description'),
        });
      }
      if (type === OrderPaymentTypes.PaymentTypes.BANK_SLIP) {
        notification.success({
          message: I18n.t('forms.order.renewOrderPayment.bankSlip.title'),
          description: I18n.t('forms.order.renewOrderPayment.bankSlip.description'),
        });
      }
    }
    orderFormInit();
  };

  const openInstallment = (url) => {
    window.open(url, '_blank');
  };

  const onCopyCode = (type, code) => {
    navigator.clipboard.writeText(code);

    if (type === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT) {
      return notification.success({
        message: I18n.t('forms.order.copyInstallmentCodeToClipboard.title'),
        description: I18n.t('forms.order.copyInstallmentCodeToClipboard.description'),
      });
    }
    if (type === OrderPaymentTypes.PaymentTypes.PIX) {
      return notification.success({
        message: I18n.t('forms.order.copyPixToClipboard.title'),
        description: I18n.t('forms.order.copyPixToClipboard.description'),
      });
    }
  };

  const displayPaymentStatus = (jsonPayment) => {
    if (jsonPayment.paymentAt) {
      return I18n.t('forms.order.payment.items.paymentAt.paid');
    }
    if (currentDate > jsonPayment.dueDate) {
      return I18n.t('forms.order.payment.items.paymentAt.outDated');
    }
    return I18n.t('forms.order.payment.items.paymentAt.notPaid');
  };

  const displayPaymentStatusColor = (jsonPayment) => {
    if (jsonPayment.paymentAt) {
      return 'green';
    }
    if (currentDate > jsonPayment.dueDate) {
      return 'red';
    }
    return 'yellow';
  };

  const onGetCardInstallmentTax = async (value) => {
    if (
      value === OrderPaymentTypes.PaymentTypes.CREDIT_CARD ||
      order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD
    ) {
      await dispatch(CardActions.getInstallmentTax());
    }
  };

  const updateOrderPaymentTypes = async (value) => {
    onGetCardInstallmentTax(value);

    onUpdateOrderValues([{ key: 'paymentType', value }]);
    installmentsAvailableForOrder();
  };

  const updatePaymentMethods = (installment) => {
    if (order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
      onUpdateOrderValues([
        { key: 'option', value: `${selectedCard || order?.payment?.option}_${installment || option}` },
      ]);
    } else if (order?.paymentType === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT) {
      if (installment === 'new_installments') {
        setGenerateBankSlipInstallmentsModalState({ isVisible: true, isCreditTiffins: false });
      } else if (installment?.includes('tiffin')) {
        setGenerateBankSlipInstallmentsModalState({ isVisible: true, isCreditTiffins: true });
        onUpdateOrderValues([{ key: 'option', value: installment }]);
      } else {
        onUpdateOrderValues([{ key: 'option', value: installment }]);
      }
    }

    if (installment !== 'new_installments') {
      setOption(installment || option);
    }
  };

  const onCloseBankSlipInstallmentModal = () => {
    setGenerateBankSlipInstallmentsModalState({ isVisible: false, isCreditTiffins: false });
  };

  const onCloseUpdateBankSlipInstallmentModal = () => {
    setIsUpdateBankSlipInstallmentsModalVisible(false);
  };

  const onShowUpdateBankSlipInstallmentModal = ({ dueDate, transactionId }) => {
    setIsUpdateBankSlipInstallmentsModalVisible(true);
    setInstallmentUpdateInfo({ dueDate, transactionId });
  };

  const updateOrderPayment = async () => {
    let isEmpty = false;

    const updatedInstallment = newBankSlipInstallments?.map((o) => {
      if (!o.newDueDate) {
        isEmpty = true;
      }

      return Math.round(moment(o.newDueDate).diff(moment(), 'days', true));
    });

    if (isEmpty || newBankSlipInstallments?.length === 0) {
      return notification.error({
        message: I18n.t('forms.order.notifications.invalidInstallmentFieldError.message'),
        description: I18n.t('forms.order.notifications.invalidInstallmentFieldError.description'),
      });
    }

    const response = await dispatch(
      OrderActions.updateBankSlip(order.id, {
        installments: updatedInstallment,
        option: generateBankSlipInstallmentsModalState?.isCreditTiffins ? 'tiffin' : null,
      }),
    );

    setInstallments([]);
    setNewBankSlipInstallments([]);

    setGenerateBankSlipInstallmentsModalState({ isVisible: false, isCreditTiffins: false });
    onCloseForm(true);

    if (response) {
      return notification.success({
        message: I18n.t('forms.order.notifications.generateBankSlipInstallmentsSuccess.message'),
        description: I18n.t('forms.order.notifications.generateBankSlipInstallmentsSuccess.description'),
      });
    }
  };

  const updateBankSlipInstallmentDueDate = async () => {
    if (newInstallmentUpdateInfo?.dueDate) {
      const success = await dispatch(
        OrderActions.updateBankSlipInstallmentDueDate(order.id, newInstallmentUpdateInfo?.transactionId, {
          dueDate: newInstallmentUpdateInfo?.dueDate,
        }),
      );

      if (success) {
        setIsUpdateBankSlipInstallmentsModalVisible(false);
        setNewInstallmentUpdateInfo({ transactionId: null, dueDate: null });
        onCloseForm(true);

        return notification.success({
          message: I18n.t('forms.order.notifications.updateBankSlipInstallmentSuccess.message'),
          description: I18n.t('forms.order.notifications.updateBankSlipInstallmentSuccess.description'),
        });
      }

      return notification.error({
        message: I18n.t('forms.order.notifications.updateBankSlipInstallmentError.message'),
        description: I18n.t('forms.order.notifications.updateBankSlipInstallmentError.description'),
      });
    }
  };

  useEffect(() => {
    installmentsAvailableForOrder();
    onGetCardInstallmentTax();
  }, [order?.paymentType]);

  useEffect(() => {
    if (option) updatePaymentMethods();
    onGetCardInstallmentTax();
  }, [selectedCard]);

  useEffect(() => {
    if (order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD) {
      onGetCardInstallmentTax();
    }
  }, []);

  return (
    <>
      <AdvancedSelect
        value={order?.paymentType}
        options={paymentTypes?.map((e) => ({
          id: e,
          name: OrderPaymentTypes.PaymentTypesOptions[e.toLocaleUpperCase()],
        }))}
        label={I18n.t('forms.order.payment.items.paymentType.label')}
        placeholder={I18n.t('shared.selectSomeValue')}
        disabled={
          (!hasAccess([accessTypes.ORDER_PAYMENT_CONFIG], [accessActionTypes.UPDATE]) &&
            [OrderStatus.WAITING_DELIVERY, OrderStatus.ON_CARRIAGE, OrderStatus.DELIVERED].includes(order?.status)) ||
          disabled
        }
        onChange={updateOrderPaymentTypes}
      />

      {order &&
        order.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD &&
        creditCardsAvailableForOrder?.length > 0 && (
          <AdvancedSelect
            value={selectedCard}
            options={creditCardsAvailableForOrder?.map((e) => ({
              id: e.payment?.option,
              name: e.payment?.typeName,
            }))}
            label={I18n.t('forms.order.payment.items.creditCardOption.label')}
            placeholder={I18n.t('shared.selectSomeValue')}
            disabled={
              (!hasAccess([accessTypes.ORDER_PAYMENT_CONFIG], [accessActionTypes.UPDATE]) &&
                [OrderStatus.WAITING_DELIVERY, OrderStatus.ON_CARRIAGE, OrderStatus.DELIVERED].includes(
                  order?.status,
                )) ||
              disabled
            }
            onChange={(value) => setSelectedCard(value)}
            emptyDescription={I18n.t('forms.order.payment.items.creditCardOption.noCard')}
          />
        )}

      {order &&
        order.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD &&
        creditCardsAvailableForOrder?.length === 0 && (
          <div className="order-form__warning__noCard">
            <Text type="warning">{I18n.t('forms.order.payment.items.creditCardOption.noCard')}</Text>
          </div>
        )}

      {((order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD && selectedCard) ||
        order?.paymentType === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT) && (
        <AdvancedSelect
          value={orderInstallment()}
          options={installments}
          label={I18n.t('forms.order.payment.items.paymentInstallmentOption.label')}
          placeholder={I18n.t('shared.selectSomeValue')}
          disabled={
            (!hasAccess([accessTypes.ORDER_PAYMENT_CONFIG], [accessActionTypes.UPDATE]) &&
              [OrderStatus.WAITING_DELIVERY, OrderStatus.ON_CARRIAGE, OrderStatus.DELIVERED].includes(order?.status)) ||
            disabled
          }
          onSelect={updatePaymentMethods}
        />
      )}

      {order?.paymentType === OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT && order?.jsonInstallments && (
        <div style={{ marginBottom: 10 }}>
          {I18n.t('forms.order.payment.credit.title')}:
          <Tag style={{ marginLeft: 10 }} color={order?.credit ? 'yellow' : 'blue'}>
            {order?.credit
              ? I18n.t('forms.order.payment.credit.tiffins')
              : I18n.t('forms.order.payment.credit.provider')}
          </Tag>
        </div>
      )}

      <AdvancedSelect
        value={order?.paymentStatus}
        options={paymentSelector.paymentStatus?.map(({ type, name }) => ({ id: type, name }))}
        label={I18n.t('forms.order.payment.items.paymentStatus.label')}
        placeholder={I18n.t('shared.selectSomeValue')}
        disabled={![UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) || disabled}
        onChange={(value) => onUpdateOrderValues([{ key: 'paymentStatus', value }])}
      />

      {order?.jsonPayment?.paymentStatus === PaymentStatus.DENIED &&
        order?.paymentType === OrderPaymentTypes.PaymentTypes.CREDIT_CARD && (
          <AdvancedTextArea
            style={{ marginBottom: 10 }}
            value={order?.jsonPayment?.description}
            disabled
            label={I18n.t('forms.order.payment.items.paymentStatusDetail.label')}
          />
        )}

      <>
        {order?.jsonPayment && [OrderPaymentTypes.PaymentTypes.BANK_SLIP].includes(order?.paymentType) && (
          <Collapse accordion>
            <Panel header={I18n.t('forms.order.payment.items.payments.singular')}>
              <div className="order-form__installment-box">
                <div className="order-form__installment-box__installment-infos">
                  <div>
                    {I18n.t('forms.order.payment.items.paymentItemStatus.label')}:{' '}
                    <Tag style={{ marginLeft: 5 }} color={displayPaymentStatusColor(order.jsonPayment)}>
                      {displayPaymentStatus(order.jsonPayment)}
                    </Tag>
                  </div>

                  {order.jsonPayment.dueDate && (
                    <span style={{ marginTop: 10 }}>
                      {I18n.t('forms.order.payment.items.dueDate.label')}:{' '}
                      <span>
                        <strong>{humanizeDate(order.jsonPayment.dueDate, 'DD/MM/YYYY HH:mm')}</strong>
                      </span>
                    </span>
                  )}

                  {order.jsonPayment.paymentAt && (
                    <span style={{ marginTop: 10 }}>
                      {I18n.t('forms.order.payment.items.paymentAt.label')}:{' '}
                      <strong>{humanizeDate(order.jsonPayment.paymentAt, 'DD/MM/YYYY HH:mm')}</strong>
                    </span>
                  )}

                  {order.jsonPayment.bankSlipDigitableLine && (
                    <AdvancedTextArea
                      style={{ marginTop: 10 }}
                      value={order.jsonPayment.bankSlipDigitableLine}
                      disabled
                      label={`${I18n.t('forms.order.payment.items.bankSlipDigitableLine.label')}:`}
                      rows={3}
                    />
                  )}

                  {order.jsonPayment.bankSlipDigitableLine && (
                    <Button
                      className="order-form__installment-box__button"
                      onClick={() => onCopyCode('bank_slip_installment', order.jsonPayment.bankSlipDigitableLine)}
                    >
                      {I18n.t('forms.order.payment.items.copyBarcodeItem.label')}
                    </Button>
                  )}

                  {order.jsonPayment.bankSlipUrl && (
                    <Button
                      className="order-form__installment-box__button"
                      onClick={() => openInstallment(order.jsonPayment.bankSlipUrl)}
                    >
                      {I18n.t('forms.order.payment.items.viewBankSlipItem.label')}
                    </Button>
                  )}

                  {!disabled &&
                    !order.jsonPayment.paymentAt &&
                    currentDate > order.jsonPayment.dueDate &&
                    [UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
                      <Button
                        className="order-form__installment-box__button"
                        loading={orderSelector.isRenewOrderPaymentOnRequest}
                        onClick={() => onRenewOrderPayment(order.id, OrderPaymentTypes.PaymentTypes.BANK_SLIP)}
                      >
                        {I18n.t('forms.order.payment.items.generateNewBankSlip.label')}
                      </Button>
                    )}
                </div>
              </div>
            </Panel>
          </Collapse>
        )}

        {order.jsonPayment && [OrderPaymentTypes.PaymentTypes.PIX].includes(order?.paymentType) && (
          <Collapse accordion>
            <Panel header={I18n.t('forms.order.pix.header.label')}>
              <div className="order-form__installment-box">
                <div className="order-form__installment-box__intallment-infos">
                  <div>
                    {I18n.t('forms.order.payment.items.paymentItemStatus.label')}:{' '}
                    <Tag style={{ marginLeft: 5 }} color={displayPaymentStatusColor(order.jsonPayment)}>
                      {displayPaymentStatus(order.jsonPayment)}
                    </Tag>
                  </div>

                  {order.jsonPayment.dueDate && (
                    <div style={{ marginTop: 10 }}>
                      {I18n.t('forms.order.payment.items.dueDate.label')}:{' '}
                      <span>
                        <strong>{humanizeDate(order.jsonPayment.dueDate, 'DD/MM/YYYY HH:mm')}</strong>
                      </span>
                    </div>
                  )}

                  {order.jsonPayment.paymentAt && (
                    <div style={{ marginTop: 10 }}>
                      {I18n.t('forms.order.payment.items.paymentAt.label')}:{' '}
                      <strong>{humanizeDate(order.jsonPayment.paymentAt, 'DD/MM/YYYY HH:mm')}</strong>
                    </div>
                  )}

                  <AdvancedTextArea
                    style={{ marginTop: 10 }}
                    value={order.jsonPayment.pix}
                    disabled
                    label={`${I18n.t('forms.order.pix.code.label')}:`}
                    rows={3}
                  />

                  {!!order.jsonPayment.pix && (
                    <Button
                      className="order-form__installment-box__button"
                      style={{ marginTop: 10 }}
                      onClick={() => onCopyCode('pix', order.jsonPayment.pix)}
                    >
                      {I18n.t('forms.order.pix.copyCode.label')}
                    </Button>
                  )}

                  {!disabled &&
                    !order.jsonPayment.paymentAt &&
                    currentDate > order.jsonPayment.dueDate &&
                    [UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
                      <Button
                        className="order-form__installment-box__button"
                        loading={orderSelector.isRenewOrderPaymentOnRequest}
                        onClick={() => onRenewOrderPayment(order.id, OrderPaymentTypes.PaymentTypes.PIX)}
                      >
                        {I18n.t('forms.order.pix.generateNewCode.label')}
                      </Button>
                    )}
                </div>
              </div>
            </Panel>
          </Collapse>
        )}

        {order.jsonPaymentInstallment &&
          [OrderPaymentTypes.PaymentTypes.BANK_SLIP_INSTALLMENT].includes(order?.paymentType) && (
            <Collapse accordion>
              <Panel header={I18n.t('forms.order.payment.items.payments.plural')}>
                {order.jsonPaymentInstallment.map((installment, indexInstallment) => {
                  const { paymentAt, bankSlipUrl, bankSlipDigitableLine, dueDate, transactionId } = installment;
                  return (
                    <div
                      className={classnames({
                        'order-form__installment-box': true,
                        'order-form__installment-box--not-first': indexInstallment !== 0,
                      })}
                      key={indexInstallment.toString()}
                    >
                      <div className="order-form__installment-box__installment-infos">
                        <span style={{ display: 'flex' }}>
                          <strong>
                            {I18n.t('forms.order.payment.items.bankSlipItem.label')} {indexInstallment + 1}
                          </strong>
                        </span>

                        <div style={{ marginTop: 10 }}>
                          {I18n.t('forms.order.payment.items.paymentItemStatus.label')}:{' '}
                          <Tag style={{ marginLeft: 5 }} color={displayPaymentStatusColor(installment)}>
                            {displayPaymentStatus(installment)}
                          </Tag>
                        </div>

                        {dueDate && (
                          <span style={{ marginTop: 10 }}>
                            {I18n.t('forms.order.payment.items.dueDate.label')}:{' '}
                            <span>
                              <strong>{humanizeDate(dueDate, 'DD/MM/YYYY HH:mm')}</strong>
                            </span>
                          </span>
                        )}

                        {paymentAt && (
                          <span style={{ marginTop: 10 }}>
                            {I18n.t('forms.order.payment.items.paymentAt.label')}:{' '}
                            <strong>{humanizeDate(paymentAt, 'DD/MM/YYYY HH:mm')}</strong>
                          </span>
                        )}

                        {bankSlipDigitableLine && (
                          <AdvancedTextArea
                            style={{ marginTop: 10 }}
                            value={bankSlipDigitableLine}
                            disabled
                            label={`${I18n.t('forms.order.payment.items.bankSlipDigitableLine.label')}:`}
                            rows={3}
                          />
                        )}

                        {!!bankSlipDigitableLine && (
                          <Button
                            className="order-form__installment-box__button"
                            onClick={() => onCopyCode('bank_slip_installment', bankSlipDigitableLine)}
                          >
                            {I18n.t('forms.order.payment.items.copyBarcodeItem.label')}
                          </Button>
                        )}

                        {!!bankSlipUrl && (
                          <Button
                            className="order-form__installment-box__button"
                            onClick={() => openInstallment(bankSlipUrl)}
                          >
                            {I18n.t('forms.order.payment.items.viewBankSlipItem.label')}
                          </Button>
                        )}

                        {!!bankSlipUrl && !paymentAt && (
                          <Button
                            className="order-form__installment-box__button"
                            onClick={() => onShowUpdateBankSlipInstallmentModal({ dueDate, transactionId })}
                            disabled={
                              !hasAccess([accessTypes.ORDER_PAYMENT_CONFIG], [accessActionTypes.UPDATE]) || disabled
                            }
                          >
                            {I18n.t('forms.order.payment.items.editBankSlipInstallment.label')}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
          )}

        <AdvancedModal
          title={I18n.t('forms.order.items.generateInstallmentText.label')}
          visible={generateBankSlipInstallmentsModalState.isVisible}
          onCloseModal={onCloseBankSlipInstallmentModal}
          width="450px"
          className="order-form__installments-modal"
          okText={I18n.t('forms.order.items.generateInstallmentText.label')}
          onOk={updateOrderPayment}
          confirmLoading={orderSelector.isUpdateBankSlipIsOnRequest}
        >
          <CreateBankSlipInstallmentForm
            order={order}
            updateInstallments={setNewBankSlipInstallments}
            isCreditTiffins={generateBankSlipInstallmentsModalState.isCreditTiffins}
          />
        </AdvancedModal>

        <AdvancedModal
          title={I18n.t('forms.order.items.updateInstallmentText.label')}
          visible={isUpdateBankSlipInstallmentsModalVisible}
          onCloseModal={onCloseUpdateBankSlipInstallmentModal}
          width="450px"
          className="order-form__installments-modal"
          okText={I18n.t('forms.order.items.updateInstallmentText.label')}
          onOk={updateBankSlipInstallmentDueDate}
          confirmLoading={orderSelector.isUpdateBankSlipInstallmentDueDateIsOnRequest}
        >
          <UpdateBankSlipInstallmentForm
            order={order}
            installmentUpdateInfo={installmentUpdateInfo}
            updateInstallmentDueDate={setNewInstallmentUpdateInfo}
          />
        </AdvancedModal>
      </>
    </>
  );
}

export default InnerPaymentOrderForm;
