import Immutable from 'seamless-immutable';

import {
  ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_DATA,
  ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ACCESS_GROUPS_USERS_OBTAINED,
} from '../actions/accessGroupUsers';

const initialState = Immutable({
  accessGroupsUsersPaginated: null,
  getaccessGroupsUsersPaginatedIsOnRequest: false,
  accessGroupsUsersPaginatedObtained: false,
});

export default function accessGroupUsers(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_DATA:
      return state.merge({
        accessGroupsUsersPaginated: action.payload,
      });
    case ACTION_GET_ACCESS_GROUPS_USERS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        getaccessGroupsUsersPaginatedIsOnRequest: action.payload,
      });
    case ACTION_GET_ACCESS_GROUPS_USERS_OBTAINED:
      return state.merge({
        accessGroupsUsersPaginatedObtained: action.payload,
      });
    default:
      return state;
  }
}
