import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { ContainerFooterButton, SelectStyled } from './styles';
import AdvancedButton from '../../../shared/AdvancedButton/AdvancedButton';

import { ProviderActions } from '../../../../app/redux/actions';
import { ProviderSelectors } from '../../../../app/redux/reducers';

function RepresentativeProviderStep({
  user,
  setUser,
  stepCurrent,
  setStepCurrent,
  onGetAllProviders,
  isGetAllProvidersOnRequest,
}) {
  const [providers, setProviders] = useState();
  const [userInside, setUserInside] = useState(user);

  const getProviders = useCallback(async () => {
    const result = await onGetAllProviders();
    setProviders(result);
  }, [onGetAllProviders]);

  const handleNextButton = useCallback(() => {
    setUser(userInside);
    setStepCurrent(stepCurrent + 1);
  }, [setStepCurrent, setUser, stepCurrent, userInside]);

  const handlePrevButton = useCallback(() => {
    setStepCurrent(stepCurrent - 1);
  }, [setStepCurrent, stepCurrent]);

  useEffect(() => {
    if (!providers) {
      getProviders();
    }
  }, [getProviders, providers]);

  return (
    <>
      <div className="signup__container__register__container__form__headerDescription">
        {I18n.t('routes.signup.content.representativeProviderContent.content.headerText')}
      </div>
      {providers && (
        <SelectStyled
          placeholder={I18n.t('shared.selectSomeValue')}
          defaultValue="Fornecedores"
          onChange={(id) => {
            setUserInside({ ...userInside, associatedBy: id });
          }}
        >
          {providers.map((providerInside) => (
            <SelectStyled.Option value={providerInside.id} key={providerInside.id}>
              {providerInside.name}
            </SelectStyled.Option>
          ))}
        </SelectStyled>
      )}
      <ContainerFooterButton>
        <AdvancedButton
          text={I18n.t('routes.signup.content.prevButton')}
          loading={isGetAllProvidersOnRequest}
          onClick={handlePrevButton}
        />
        <AdvancedButton
          text={I18n.t('routes.signup.content.nextButton')}
          loading={isGetAllProvidersOnRequest}
          onClick={handleNextButton}
        />
      </ContainerFooterButton>
    </>
  );
}

const mapStateToProps = (state) => ({
  isGetAllProvidersOnRequest: ProviderSelectors.isGetAllProvidersOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllProviders: () => dispatch(ProviderActions.getAllProviders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeProviderStep);
