import React from 'react';
import { Translate } from 'react-redux-i18n';

export const OrderDeliveryType = {
  DELIVERY: 'delivery',
  ON_PROVIDER: 'on_provider',
};

export const GetOrderDeliveryTypeArray = [
  { id: OrderDeliveryType.DELIVERY, name: <Translate value="enum.deliveryType.delivery" /> },
  { id: OrderDeliveryType.ON_PROVIDER, name: <Translate value="enum.deliveryType.onProvider" /> },
];

export const GetOrderDeliveryTypeFilters = GetOrderDeliveryTypeArray.map((type) => ({
  value: type.id,
  text: type.name,
}));

export const GetOrderDeliveryTypeName = (value) => GetOrderDeliveryTypeArray.find((o) => o.id === value).name;
