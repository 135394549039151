import Immutable from 'seamless-immutable';
import {
  ACTION_GET_PRODUCTS_PAGINATED,
  ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_PRODUCT_IS_ON_REQUEST,
  ACTION_CREATE_PRODUCT_IS_ON_REQUEST,
  ACTION_DELETE_PRODUCT_IS_ON_REQUEST,
  ACTION_UPDATE_PRODUCT_IS_ON_REQUEST,
  ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST,
  ACTION_GET_PRODUCTS_UNITS_IS_ON_REQUEST,
  ACTION_GET_PRODUCTS_UNITS,
  ACTION_GET_PRODUCT_TO_SAMPLE_IS_ON_REQUEST,
  ACTION_GET_PRODUCT_TO_SAMPLE_DATA,
  ACTION_UPLOAD_PRODUCTS_ERRORS,
  ACTION_GET_PRODUCTS_BY_HIGHLIGHT,
  ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST,
  ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST,
  ACTION_GET_PRODUCTS_STOCK_DATA,
} from '../actions/product';

const initialState = Immutable({
  productsPaginated: null,
  isGetProductOnRequest: false,
  productsUnits: null,
  isGetProductsPaginatedOnRequest: false,
  isGetProductsUnitsOnRequest: false,
  isDeleteProductOnRequest: false,
  isCreateProductOnRequest: false,
  isUpdateProductOnRequest: false,
  isUploadProductsOnRequest: false,
  isGetProductToSampleIsOnRequest: false,
  getProductToSample: {},
  uploadProductsErrors: null,
  productsByHighlight: [],
  isGetProductsByHighlightOnRequest: false,
  productsStock: [],
  isGetProductsStockOnRequest: false,
});

export default function product(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_PRODUCTS_PAGINATED:
      return state.merge({
        productsPaginated: action.payload,
      });
    case ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetProductsPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_PRODUCT_IS_ON_REQUEST:
      return state.merge({
        isGetProductOnRequest: action.payload,
      });
    case ACTION_DELETE_PRODUCT_IS_ON_REQUEST:
      return state.merge({
        isDeleteProductOnRequest: action.payload,
      });
    case ACTION_CREATE_PRODUCT_IS_ON_REQUEST:
      return state.merge({
        isCreateProductOnRequest: action.payload,
      });
    case ACTION_UPDATE_PRODUCT_IS_ON_REQUEST:
      return state.merge({
        isUpdateProductOnRequest: action.payload,
      });
    case ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST:
      return state.merge({
        isUploadProductsOnRequest: action.payload,
      });
    case ACTION_GET_PRODUCTS_UNITS_IS_ON_REQUEST:
      return state.merge({
        isGetProductsUnitsOnRequest: action.payload,
      });
    case ACTION_GET_PRODUCTS_UNITS:
      return state.merge({
        productsUnits: action.payload,
      });
    case ACTION_GET_PRODUCT_TO_SAMPLE_IS_ON_REQUEST:
      return state.merge({
        isGetProductToSampleIsOnRequest: action.payload,
      });
    case ACTION_GET_PRODUCT_TO_SAMPLE_DATA:
      return state.merge({
        getProductToSample: action.payload,
      });
    case ACTION_UPLOAD_PRODUCTS_ERRORS:
      return state.merge({
        uploadProductsErrors: action.payload,
      });
    case ACTION_GET_PRODUCTS_BY_HIGHLIGHT:
      return state.merge({
        productsByHighlight: action.payload,
      });
    case ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST:
      return state.merge({
        isGetProductsByHighlightOnRequest: action.payload,
      });
    case ACTION_GET_PRODUCTS_STOCK_DATA:
      return state.merge({
        productsStock: action.payload,
      });
    case ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST:
      return state.merge({
        isGetProductsStockOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getProductsPaginated(state) {
  return state.product.productsPaginated;
}

export function getProductsUnits(state) {
  return state.product.productsUnits;
}

export function isGetProductsPaginatedOnRequest(state) {
  return state.product.isGetProductsPaginatedOnRequest;
}

export function isGetProductOnRequest(state) {
  return state.product.isGetProductOnRequest;
}

export function isGetProductsUnitsOnRequest(state) {
  return state.product.isGetProductsUnitsOnRequest;
}

export function isDeleteProductOnRequest(state) {
  return state.product.isDeleteProductOnRequest;
}

export function isCreateProductOnRequest(state) {
  return state.product.isCreateProductOnRequest;
}

export function isUpdateProductOnRequest(state) {
  return state.product.isUpdateProductOnRequest;
}

export function isUploadProductsOnRequest(state) {
  return state.product.isUploadProductsOnRequest;
}

export function isGetProductToSampleIsOnRequest(state) {
  return state.product.isGetProductToSampleIsOnRequest;
}

export function getProductToSample(state) {
  return state.product.getProductToSample;
}

export function getUploadProductsErrors(state) {
  return state.product.uploadProductsErrors;
}

export function isGetProductsStockOnRequest(state) {
  return state.product.isGetProductsStockOnRequest;
}

export function getProductsStock(state) {
  return state.product.productsStock;
}
