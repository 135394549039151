import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';

import { I18n } from 'react-redux-i18n';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import UserPreferencesSection from '../../section/preference/UserPreferencesSection';

import Loading from '../../../shared/Loading';
import AdvancedButton from '../../../shared/AdvancedButton';

import { useReduxState } from '../../../../app/hooks/useReduxState';

import { UserActions, QuestionActions } from '../../../../app/redux/actions';

function UserPreferencesForm({ id, userType, visible, onCloseForm }) {
  const dispatch = useDispatch();
  const { question: QuestionSelectors, user: UserSelectors } = useReduxState();

  const [userPreferences, setUserPreferences] = useState(null);

  const getQuestionsAndPreferences = async () => {
    const [preferences] = await Promise.all([
      dispatch(UserActions.getUserPreferences(id)),
      dispatch(QuestionActions.getQuestions(userType)),
    ]);

    setUserPreferences(preferences);
  };

  useEffect(() => {
    if (id && visible) getQuestionsAndPreferences();
  }, [id, visible]);

  const isLoading = UserSelectors.isGetUserPreferencesIsOnRequest || QuestionSelectors.isGetQuestionsIsOnRequest;

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={I18n.t('forms.user.preferences.form.title')}
      onClose={() => {
        setUserPreferences(null);
        onCloseForm();
      }}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton
            type="link"
            text="Sair"
            onClick={() => {
              setUserPreferences(null);
              onCloseForm();
            }}
          />
        </div>
      }
    >
      {isLoading ? <Loading size={40} loading={isLoading} /> : <UserPreferencesSection preferences={userPreferences} />}
    </Drawer>
  );
}

export default UserPreferencesForm;
