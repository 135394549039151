import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import HeaderDefault from '../../components/header/HeaderDefault';

import { AuthSelectors } from '../redux/reducers';

class Public extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, container, getUser } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {getUser && (getUser.emailValidated || getUser.phoneValidated) && (
          <HeaderDefault headerStyle={{ position: 'absolute', width: '100%' }} />
        )}

        {container}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  getUser: AuthSelectors.getUser(state),
});

export default connect(mapStateToProps)(Public);
