import React, { useState, useEffect } from 'react';
import { Drawer, Layout, Row, Tag } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { MdClose } from 'react-icons/md';
import { TagSelectors, ProviderSelectors } from '../../../app/redux/reducers';
import AdvancedButton from '../../shared/AdvancedButton';
import { ProviderActions } from '../../../app/redux/actions';
import Loading from '../../shared/Loading/Loading';

function ProviderTagsForm({
  id,
  visible,
  isGetTagsIsOnRequest,
  getTagsData,
  onCloseForm,
  onGetProviderTags,
  isUpdateProviderTags,
  onUpdateProviderTags,
  isGetProviderTagsIsOnRequest,
  disabled,
}) {
  const { Content } = Layout;
  const { CheckableTag } = Tag;

  const [tagIdsArray, setTagIdsArray] = useState([]);

  const handleSelectTag = (tagId) => {
    const tagForRemove = tagIdsArray.find((o) => o === tagId);
    if (tagForRemove) {
      const tagIdsArrayCopy = [...tagIdsArray];
      const tagIdsArrayFiltered = tagIdsArrayCopy.filter((o) => o !== tagId);
      return setTagIdsArray(tagIdsArrayFiltered);
    }
    return setTagIdsArray([...tagIdsArray, tagId]);
  };

  const onSubmit = async () => {
    await onUpdateProviderTags(id, tagIdsArray);
    onCloseForm();
  };

  const onConfigProviderTags = async () => {
    if (visible && id) {
      const tags = await onGetProviderTags(id);
      setTagIdsArray(tags?.map((o) => o.tagId));
    } else setTagIdsArray([]);
  };

  useEffect(() => {
    onConfigProviderTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, id]);

  return (
    <Drawer
      maskClosable={false}
      visible={visible}
      className="drawer"
      title="Tags"
      onClose={() => onCloseForm()}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={() => onCloseForm()} />
          {!disabled ? (
            <AdvancedButton
              loading={isGetTagsIsOnRequest || isUpdateProviderTags}
              text={I18n.t('forms.submitButtonText')}
              onClick={() => onSubmit()}
            />
          ) : (
            <div />
          )}
        </div>
      }
    >
      {!isGetTagsIsOnRequest && !isGetProviderTagsIsOnRequest && (
        <Content>
          <Row>
            <h3>{I18n.t('forms.providerTags.items.title')}</h3>
          </Row>
          <Row>
            {getTagsData?.rows?.map((o) => (
              <CheckableTag
                checked={tagIdsArray.indexOf(o.id) > -1}
                onChange={() => !disabled && handleSelectTag(o.id)}
                key={o.id}
                style={{
                  border: '1px solid black',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                {o.text}
              </CheckableTag>
            ))}
          </Row>
        </Content>
      )}
      <Loading size={40} loading={isGetTagsIsOnRequest || isGetProviderTagsIsOnRequest} />
    </Drawer>
  );
}

const mapStateToProps = (state) => ({
  isGetTagsIsOnRequest: TagSelectors.getTagsIsOnRequest(state),
  getTagsData: TagSelectors.getTags(state),
  isGetProviderTagsIsOnRequest: ProviderSelectors.isGetProviderTagsIsOnRequest(state),
  isUpdateProviderTags: ProviderSelectors.updateProviderTagsIsOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProviderTags: (id) => dispatch(ProviderActions.getProviderTags(id)),
  onUpdateProviderTags: (id, providerTagsData) => dispatch(ProviderActions.updateProviderTags(id, providerTagsData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderTagsForm);
