import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { FiLogOut } from 'react-icons/fi';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import AdvancedInput from '../AdvancedInput';
import AdvancedButton from '../AdvancedButton';
import PasswordStrengthChecker from '../PasswordStrengthChecker/PasswordStrengthChecker';

import useForm from '../../../app/hooks/useForm';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { AuthActions, UserActions } from '../../../app/redux/actions';

function UserPasswordModal() {
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const { auth, user } = useReduxState();
  const dispatch = useDispatch();

  const defaultState = {
    email: auth?.user?.email,
    password: '',
    passwordConfirm: '',
  };

  const form = useForm(defaultState);

  const onLogout = () => {
    dispatch(AuthActions.logout(true));
  };

  const onSubmit = () => {
    dispatch(UserActions.passwordChange({ password: form.values.password }, true));
  };

  return (
    <div className="user-password-modal">
      <div className="user-password-modal__header">{I18n.t('shared.userPasswordModal.header.title')}</div>
      <div className="user-password-modal__content">
        <div className="user-password-modal__content__info">
          <div className="user-password-modal__content__info__description">
            {I18n.t('shared.userPasswordModal.info.description')}
          </div>
          <div className="user-password-modal__content__info__description__button">
            <AdvancedButton
              onClick={onLogout}
              disabled={user.isPostPasswordRecoverOnRequest}
              text={I18n.t('shared.userPasswordModal.info.confirmText')}
              iconRight={
                <span className="user-password-modal__content__info__description__button__icon">
                  <FiLogOut />
                </span>
              }
            />
          </div>
        </div>
        <div className="user-password-modal__content__form">
          <Row gutter={15}>
            <Col span={24}>
              <div className="user-password-modal__content__single">
                <AdvancedInput
                  label={I18n.t('shared.userPasswordModal.form.email')}
                  value={form.values.email}
                  onChange={(val) => form.handleInputChange('email', val)}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xl={12} md={24} sm={24} xs={24}>
              <div className="user-password-modal__content__single">
                <AdvancedInput
                  label={I18n.t('shared.userPasswordModal.form.password')}
                  value={form.values.password}
                  onChange={(val) => form.handleInputChange('password', val)}
                  isPassword
                />
              </div>
            </Col>
            <Col xl={12} md={24} sm={24} xs={24}>
              <div className="user-password-modal__content__single">
                <AdvancedInput
                  label={I18n.t('shared.userPasswordModal.form.passwordConfirm')}
                  value={form.values.passwordConfirm}
                  onChange={(val) => form.handleInputChange('passwordConfirm', val)}
                  isPassword
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="user-password-modal__content__validation">
          <PasswordStrengthChecker
            password={form.values.password}
            passwordConfirm={form.values.passwordConfirm}
            setValidated={(val) => setPasswordConfirmed(val)}
          />
        </div>
      </div>

      <div className="user-password-modal__footer">
        <AdvancedButton
          text={I18n.t('shared.userPasswordModal.form.submit')}
          disabled={!passwordConfirmed || user.isPostPasswordRecoverOnRequest}
          onClick={onSubmit}
          loading={user.isPostPasswordRecoverOnRequest}
          iconRight={
            <span className="user-password-modal__footer__icon">
              <FaArrowRight />
            </span>
          }
        />
      </div>
    </div>
  );
}

export default UserPasswordModal;
