export function removeUnusedKeys(obj = {}) {
  const removeKey = (key) => {
    if (obj[key] === null) delete obj[key];
    if (obj[key] === undefined) delete obj[key];
    if (obj[key] === '') delete obj[key];
  };

  Object.keys(obj).forEach(removeKey);
}

export function parseFormData(form) {
  const formData = new FormData();
  Object.entries(form).forEach(([key, value]) => {
    if (key === 'file') {
      formData.set(key, form.file);
    } else if (value !== undefined && key !== 'reader') {
      formData.set(key, `${form[key]}`);
    }
  });

  return formData;
}
