import React, { useState } from 'react';
import { Collapse, Button, notification, Typography, AutoComplete } from 'antd';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PromotionType from '../../../../app/enum/promotion_type';
import { ProductActions } from '../../../../app/redux/actions';
import { localeCompare } from '../../../../app/utils/string';
import AdvancedInput from '../../../shared/AdvancedInput';
import AdvancedTextArea from '../../../shared/AdvancedTextArea';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';
import { UserTypes } from '../../../../app/enum/user_types';
import * as PriceUtils from '../../../../app/utils/prices';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { getProviderProductsById } from '../../../../app/utils/selectors/provider';
import { calculateProductStock } from '../../../../app/utils/product-stock';
import AdvancedModal from '../../../shared/AdvancedModal/AdvancedModal';
import UpdateProductsStockForm from './components/UpdateProductsStockForm';

function InnerProductsOrderForm({
  order,
  orderOriginal,
  onUpdateOrderValues,
  disabled,
  updateProductsQuantity,
  isProductsStockUpdateModalVisible,
  setIsProductsStockUpdateModalVisible,
  onSubmit,
}) {
  const [productSearch, setProductSearch] = useState();
  const { Panel } = Collapse;
  const { provider: providerSelector, auth: authSelector, product: productSelector } = useReduxState();
  const dispatch = useDispatch();
  const orderWithoutStockOn = order.provider?.orderWithoutStockOn;

  const { Text } = Typography;
  const { Option } = AutoComplete;

  const getDiscount = (item, quantity) =>
    item.discount
      ? (Number(item.discount) / Number(item.quantity)) * Number(quantity)
      : PriceUtils.getItemDiscount(item.product, Number(quantity));

  const updateFooterValues = (items) => {
    const discountItems = items.reduce((total, itemSingle) => {
      const discountSingle = getDiscount(itemSingle, Number(itemSingle.quantity));
      if (discountSingle) {
        return total + Number(discountSingle);
      }
      return total;
    }, 0);

    const orderItemsParsed = items.map((item) => ({
      value: item.value,
      quantity: item.quantity,
      discount: item.discount,
      productId: item.productId,
      taxTiffin: item.taxTiffin,
      comission: item.comission,
      product: {
        id: item.product.id,
        name: item.product.name,
        prices: item.product.prices,
        promotion: item.product.promotion,
        unit: item.product.unit,
        multiplier: item.product.multiplier,
        minimum: item.product.minimum,
      },
    }));

    const discountExtra = Number(order?.discountExtra || 0);
    let discountCoupon = 0;

    const { coupon } = order;
    if (coupon) {
      const { value, type } = coupon;
      discountCoupon = type === PromotionType.VALUE ? Number(value) : PriceUtils.getCartTotal({ items }) * value * 0.01;
    }

    const discountTotal = Number(discountItems) + discountExtra + discountCoupon;

    onUpdateOrderValues([
      { key: 'items', value: orderItemsParsed },
      { key: 'value', value: PriceUtils.getCartSubtotal(items) },
      { key: 'taxTiffin', value: PriceUtils.getCartTiffinTax(items) },
      { key: 'discount', value: discountTotal },
      { key: 'discountItems', value: discountItems },
      { key: 'discountCoupon', value: discountCoupon },
      { key: 'discountExtra', value: discountExtra },
      { key: 'commission', value: PriceUtils.getCartCommission({ items }) },
    ]);
  };

  const onChangeOrderItemQuantity = async ({ item, quantity }) => {
    if (quantity < 1) return;
    const updateOrderItems = order.items.map((itemSingle) => {
      if (itemSingle.productId === item.productId) {
        return {
          ...itemSingle,
          quantity,
          taxTiffin: PriceUtils.getItemTiffinTax(itemSingle.product, quantity, !!order.representativeId),
          commission: PriceUtils.getItemCommission({
            product: itemSingle.product,
            quantity,
            isPrivateRepresentative: !!order.representativeId,
          }),
          discount: getDiscount(item, quantity),
          value: PriceUtils.getItemSubtotal(item.product, quantity),
        };
      }
      return itemSingle;
    });

    updateFooterValues(updateOrderItems);
  };

  const onRemoveOrderItems = async (index) => {
    const { items = [] } = order;
    if (items.length <= 1) {
      notification.error({
        message: I18n.t('forms.order.openNotificationMinItemsPermitted.title'),
        description: I18n.t('forms.order.openNotificationMinItemsPermitted.description'),
      });
    } else {
      items.splice(index, 1);
      updateFooterValues(items);

      if (!orderOriginal.provider.orderWithoutStockOn) {
        const productsIdsArray = items.map((item) => item.productId);
        await dispatch(ProductActions.getOrderProductsStock(productsIdsArray));
      }
    }
  };

  const onAddOrderItems = async (productId) => {
    const { items = [] } = order;

    const idExists = items.find((o) => o.product.id === Number(productId));

    if (idExists) {
      return notification.error({
        message: I18n.t('forms.order.notifications.itemAlreadyAddedError.message'),
        description: I18n.t('forms.order.notifications.itemAlreadyAddedError.description'),
      });
    }

    const product = getProviderProductsById(providerSelector.providerProducts, productId);

    items.unshift({
      product,
      productId,
      quantity: product.minimum,
      value: PriceUtils.getItemSubtotal(product, product.minimum),
      discount: PriceUtils.getItemDiscount(product, product.minimum),
      taxTiffin: PriceUtils.getItemTiffinTax(product, product.minimum),
      comission: PriceUtils.getItemCommission({ product, quantity: product.minimum }),
      isNew: true,
    });

    updateFooterValues(items);

    if (!orderOriginal.provider.orderWithoutStockOn) {
      const productsIdsArray = items.map((item) => item.productId);
      await dispatch(ProductActions.getOrderProductsStock(productsIdsArray));
    }
  };

  const filterProviderProducts = (searchText) => {
    let productsWithStock = providerSelector.providerProducts?.map((item) => ({
      ...item,
      totalProductStock: calculateProductStock(item?.units),
    }));

    if (searchText) {
      productsWithStock = productsWithStock.filter((product) => localeCompare(product?.name, searchText));
    }

    return productsWithStock?.map(({ name, id, totalProductStock }) => {
      const result = {
        value: id.toString(),
      };

      if (!orderWithoutStockOn) {
        result.disabled = totalProductStock <= 0;
        result.label =
          totalProductStock > 0 ? name : `${I18n.t('forms.order.items.searchProducts.unavailable')} ${name}`;
      } else {
        result.label = name;
      }

      return result;
    });
  };

  const getCorrectItemStock = (productId) => {
    let response = 0;

    const originalItem = orderOriginal?.items?.find((o) => o.productId === productId);

    if (originalItem) {
      const quantity = !originalItem.isNew ? parseInt(originalItem.quantity, 10) : 0;
      const availableProductStock =
        productSelector.productsStock.find((product) => Number(product.id) === Number(originalItem?.productId))
          ?.stock || 0;

      response = quantity + availableProductStock;
    } else {
      response =
        productSelector?.productsStock?.find((product) => Number(product.id) === Number(productId))?.stock || 0;
    }

    return response;
  };

  return (
    <>
      {!orderWithoutStockOn && (
        <Text className="order-form__products__stock-warning">
          {I18n.t('forms.order.items.searchProducts.productStockWarning.prefix')}
          <span style={{ fontWeight: 'bold' }}>{I18n.t('forms.order.items.searchProducts.unavailable')}</span>
          {I18n.t('forms.order.items.searchProducts.productStockWarning.suffix')}
        </Text>
      )}
      <div className="order-form__products__searchInput">
        <div className="order-form__products__searchInput__wrap">
          <span className="order-form__products__searchInput__wrap__label">
            {I18n.t('forms.order.items.searchProducts.label')}
          </span>
          <AutoComplete
            onSearch={(productSearchProp) => setProductSearch(productSearchProp)}
            onSelect={(productId) => onAddOrderItems(productId)}
            style={{ fontSize: 14 }}
            placeholder={I18n.t('forms.order.items.searchProducts.placeholder')}
          >
            {filterProviderProducts(productSearch)?.map((item) => (
              <Option key={item.value} value={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </AutoComplete>
          <div className="order-form__products__searchInput__wrap__tip">{I18n.t('forms.order.addedTipMessage')}</div>
        </div>
      </div>

      <Collapse accordion>
        {order?.items.map((item, index) => (
          <Panel header={`${item?.quantity}x ${item?.product?.name}`} key={item?.product?.id}>
            <AdvancedInput
              value={item?.productId}
              disabled
              label={I18n.t('forms.order.items.searchProducts.items.productId.label')}
            />

            {item?.product?.code && (
              <AdvancedInput
                value={item.product.code}
                disabled
                label={I18n.t('forms.order.items.searchProducts.items.code.label')}
              />
            )}

            <AdvancedTextArea
              value={item?.product?.name}
              disabled
              label={I18n.t('forms.order.items.searchProducts.items.productName.label')}
              rows={3}
            />

            {!order.provider.orderWithoutStockOn && (
              <AdvancedInputNumber
                value={getCorrectItemStock(item.productId) || 0}
                label={I18n.t('forms.order.items.searchProducts.items.stock.label')}
                disabled
              />
            )}

            <AdvancedInputNumber
              value={item?.quantity}
              label={I18n.t('forms.order.items.searchProducts.items.quantity.label')}
              placeholder={I18n.t('shared.typeSomething')}
              min={item?.product?.minimum}
              step={item?.product?.multiplier}
              onChange={(value) => onChangeOrderItemQuantity({ item, quantity: value })}
              disabled={disabled || (!order.provider.orderWithoutStockOn && getCorrectItemStock(item.productId) === 0)}
            />

            <AdvancedInputNumber
              value={PriceUtils.getPrice(item?.value)}
              disabled
              label={I18n.t('forms.order.items.searchProducts.items.value.label')}
              formatter={(value) => PriceUtils.getPrice(value)}
            />

            <AdvancedInputNumber
              value={PriceUtils.getPrice(item?.discount)}
              disabled
              label={I18n.t('forms.order.items.searchProducts.items.discount.label')}
              formatter={(value) => PriceUtils.getPrice(value)}
            />

            <AdvancedInputNumber
              value={PriceUtils.getPrice(Number(item?.value) - Number(item?.discount))}
              disabled
              label={I18n.t('forms.order.items.searchProducts.items.totalValue.label')}
              formatter={(value) => PriceUtils.getPrice(value)}
            />

            {[UserTypes.ADMIN, UserTypes.SUPPORT].includes(authSelector.user.type) && (
              <>
                <AdvancedInputNumber
                  value={PriceUtils.getPrice(item?.taxTiffin)}
                  disabled
                  label={I18n.t('forms.order.items.searchProducts.items.taxTiffin.label')}
                  formatter={(value) => PriceUtils.getPrice(value)}
                />

                <AdvancedInputNumber
                  value={PriceUtils.getPrice(item?.commission)}
                  disabled
                  label={I18n.t('forms.order.items.searchProducts.items.commission.label')}
                  formatter={(value) => PriceUtils.getPrice(value)}
                />
              </>
            )}

            {!disabled && (
              <Button danger onClick={() => onRemoveOrderItems(index)}>
                {I18n.t('forms.order.items.searchProducts.items.removeOrderItems.label')}
              </Button>
            )}
          </Panel>
        ))}
      </Collapse>

      <AdvancedModal
        visible={isProductsStockUpdateModalVisible}
        title={I18n.t('forms.order.product.advancedModal.stockUpdate.title')}
        width="830px"
        bodyStyle={{ padding: '0px' }}
        onCloseModal={() => setIsProductsStockUpdateModalVisible(false)}
        onOk={onSubmit}
        okText={I18n.t('forms.order.product.advancedModal.okText')}
        confirmLoading={productSelector.isGetProductsStockOnRequest}
      >
        <UpdateProductsStockForm
          updateProductsQuantity={updateProductsQuantity}
          order={order}
          orderOriginal={orderOriginal}
          onChangeOrderItemQuantity={onChangeOrderItemQuantity}
          onUpdateOrderValues={onUpdateOrderValues}
          onRemoveOrderItems={onRemoveOrderItems}
          getCorrectItemStock={getCorrectItemStock}
        />
      </AdvancedModal>
    </>
  );
}

export default InnerProductsOrderForm;
