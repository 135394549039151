export const accessTypes = {
  USERS: 'users',
  ORDERS: 'orders',
  PROVIDERS: 'providers',
  PROVIDER_RATINGS: 'provider_ratings',
  CHAT: 'chat',
  DASHBOARD: 'dashboard',
  PUSH_NOTIFICATION: 'push_notification',
  PRODUCTS: 'products',
  PROVIDER_BILLING: 'provider_billing',
  PROVIDER_MANAGERS: 'provider_managers',
  ORDER_SHIPMENT: 'order_shipment',
  ORDER_PAYMENT_CONFIG: 'order_payment_config',
  CUSTOMERS: 'customers',
  ADMIN_TAGS: 'admin_tags',
  ADMIN_HIGHLIGHTS: 'admin_highlights',
  ADMIN_ACCESS_GROUPS: 'admin_access_groups',
  ADMIN_ACCESS_FEATURES: 'admin_access_features',
  ADMIN_ACCESS_GROUPS_USERS: 'admin_access_groups_users',
  ADMIN_ACCESS_FEATURES_USERS: 'admin_access_features_users',
  ADMIN_ACCESS_GROUPS_FEATURES: 'admin_access_groups_features',
  CONFIG_ENOTAS: 'config_enotas',
  CREATE_INVOICES_FUL: 'create_invoices_ful',
  ADMIN_COLLECTIONS: 'admin_collections',
};
