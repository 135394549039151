import React, { useEffect } from 'react';

import { I18n } from 'react-redux-i18n';

import { Col, Drawer, notification, Row } from 'antd';
import { MdClose } from 'react-icons/md';

import { useDispatch } from 'react-redux';
import AdvancedInput from '../../shared/AdvancedInput';
import Loading from '../../shared/Loading';
import AdvancedButton from '../../shared/AdvancedButton';
import AdvancedSelect from '../../shared/AdvancedSelect';

import useForm from '../../../app/hooks/useForm';
import { useReduxState } from '../../../app/hooks/useReduxState';
import { AccessGroupActions } from '../../../app/redux/actions';
import { GetUserTypeArray, UserTypes } from '../../../app/enum/user_types';

function AccessGroupForm({ onCloseForm, id, visible }) {
  const { accessGroup } = useReduxState();
  const dispatch = useDispatch();

  const getInitialState = () => ({
    name: null,
  });

  const form = useForm(getInitialState());

  useEffect(() => {
    if (!visible) {
      form.setForm(getInitialState());
    }
  }, [visible]);

  const onSubmit = async () => {
    const { values } = form;
    const payload = {
      name: values.name,
      defaultUserType: values.defaultUserType,
    };

    const dataIsValid = [{ name: !!payload.name }];
    const invalidFields = dataIsValid.map((invalidData) => {
      if (Object.values(invalidData).toString() === 'false') {
        return Object.keys(invalidData).toString();
      }
      return null;
    });

    const invalidFieldsTranslated = invalidFields.map((entry) => {
      switch (entry) {
        case 'name':
          return I18n.t('forms.accessGroup.invalidFields.name');
        default:
          return null;
      }
    });

    const invalidFiledFilteredTranslated = invalidFieldsTranslated.filter((filtered) => filtered !== null);

    if (invalidFiledFilteredTranslated.length > 0) {
      return notification.error({
        message: I18n.t('forms.accessGroup.invalidFields.validation.required.title'),
        description: I18n.t('forms.accessGroup.invalidFields.validation.required.description', {
          fields: invalidFiledFilteredTranslated.map((string) => string),
        }),
      });
    }
    let success;

    if (!id) {
      success = await dispatch(AccessGroupActions.createAccessGroup(payload));
    } else {
      success = await dispatch(AccessGroupActions.updateAccessGroupById(id, payload));
    }

    if (success) {
      form.setForm(getInitialState());
      notification.success({
        message: id ? I18n.t('forms.accessGroup.updateSuccessTitle') : I18n.t('forms.accessGroup.createSuccessTitle'),
        description: id
          ? I18n.t('forms.accessGroup.updateSuccessDescription')
          : I18n.t('forms.accessGroup.createSuccessDescription'),
      });
      onCloseForm(true);
    }
  };

  useEffect(() => {
    if (id && accessGroup.accessGroupById) {
      form.setForm(accessGroup.accessGroupById);
    }
  }, [id, accessGroup.accessGroupById]);

  return (
    <Drawer
      maskClosable={false}
      className="drawer"
      title={id ? I18n.t('forms.accessGroup.updateTitle') : I18n.t('forms.accessGroup.createTitle')}
      onClose={() => onCloseForm()}
      visible={visible}
      headerStyle={{ background: '#FFEC00', borderRadius: 0 }}
      bodyStyle={{ paddingBottom: 80 }}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton
            type="link"
            text={I18n.t('forms.cancelButtonText')}
            onClick={() => {
              onCloseForm();
            }}
          />
          <AdvancedButton
            loading={
              accessGroup.createAccessGroupIsOnRequest ||
              accessGroup.updateAccessGroupIsOnRequest ||
              accessGroup.accessGroupByIdIsOnRequest
            }
            text={I18n.t('forms.submitButtonText')}
            onClick={onSubmit}
          />
        </div>
      }
    >
      {visible && (
        <div className="product-form">
          {accessGroup.accessGroupByIdIsOnRequest && (
            <div className="product-form__loading">
              <Loading />
            </div>
          )}
          <Row>
            <Col span={24}>
              <AdvancedInput
                label={I18n.t('forms.accessGroup.items.name.label')}
                onChange={(val) => form.handleInputChange('name', val)}
                value={form.values.name}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AdvancedSelect
                label={I18n.t('forms.accessGroup.items.defaultUserType.label')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(val) => form.handleInputChange('defaultUserType', val)}
                value={form.values.defaultUserType}
                options={GetUserTypeArray.filter((o) => o.id !== UserTypes.ANYUSER)}
                showSearch
              />
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
}

export default AccessGroupForm;
