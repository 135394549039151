import React, { useEffect } from 'react';
import { Space, Checkbox, Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import {
  REACT_APP_PRIVACY_LINK,
  REACT_APP_TERMS_USER_LEGAL_LINK,
  REACT_APP_TERMS_LINK,
} from '../../../../app/config/env.config';
import AdvancedInput from '../../../shared/AdvancedInput';
import { QuestionSelectors, UserSelectors } from '../../../../app/redux/reducers';
import { UserActions } from '../../../../app/redux/actions';
import { QuestionTypes } from '../../../../app/enum/question_types';

function AccessDataStep({ questions, signupUser, onSetSignupUser }) {
  useEffect(() => {
    if (questions.length && !signupUser.preferences) {
      const preferences = questions.map((o) => {
        const newPreferences =
          o.type === QuestionTypes.INPUT ? [{ preferenceId: o.preference[0].id, value: null }] : [];

        return {
          questionId: o.id,
          required: o.required,
          type: o.type,
          mask: o.mask,
          preferences: newPreferences,
        };
      });

      onSetSignupUser('preferences', preferences);
    }
  }, []);

  return (
    <>
      <div className="signup__container__register__container__form__headerDescription">
        {I18n.t('routes.signup.content.dataAccessContent.contentText.line1')}
        {I18n.t('routes.signup.content.dataAccessContent.contentText.line2')}
      </div>

      <AdvancedInput
        label={I18n.t('routes.signup.content.email')}
        placeholder={I18n.t('routes.signup.content.emailPlaceholder')}
        type="email"
        onChange={(value) => onSetSignupUser('email', value)}
        value={signupUser.email}
        name="email"
      />

      <Row gutter={15}>
        <Col md={12} sm={24} xs={24}>
          <AdvancedInput
            label={I18n.t('routes.signup.content.password')}
            placeholder={I18n.t('routes.signup.content.passwordPlaceholder')}
            type="password"
            value={signupUser.password}
            onChange={(value) => onSetSignupUser('password', value)}
            isPassword
          />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <AdvancedInput
            label={I18n.t('routes.signup.content.passwordConfirmation')}
            placeholder={I18n.t('routes.signup.content.passwordConfirmationPlaceholder')}
            type="password"
            value={signupUser.passwordConfirmation}
            onChange={(value) => onSetSignupUser('passwordConfirmation', value)}
            isPassword
          />
        </Col>
      </Row>

      <Space direction="vertical">
        <Checkbox
          onChange={() => onSetSignupUser('checkedPrivacityTerms', !signupUser?.checkedPrivacityTerms)}
          checked={!!signupUser?.checkedPrivacityTerms}
        >
          {I18n.t('routes.signup.content.termsOfUse.textCheckbox')}
          <strong className="signup__terms__links">
            <a href={REACT_APP_PRIVACY_LINK} target="_blank" rel="noreferrer">
              {I18n.t('routes.signup.content.termsOfUse.policyOfPrivacity')}
            </a>
          </strong>
          {I18n.t('shared.and')}
          <strong className="signup__terms__links">
            <a href={REACT_APP_TERMS_LINK} target="_blank" rel="noreferrer">
              {I18n.t('routes.signup.content.termsOfUse.termsOfUse')}
            </a>
          </strong>
        </Checkbox>
        <Checkbox
          onChange={() => onSetSignupUser('checkedLegalTerms', !signupUser?.checkedLegalTerms)}
          checked={!!signupUser?.checkedLegalTerms}
        >
          {I18n.t('routes.signup.content.termsOfLegal.textCheckbox')}
          <strong className="signup__terms__links">
            <a href={REACT_APP_TERMS_USER_LEGAL_LINK} target="_blank" rel="noreferrer">
              {I18n.t('routes.signup.content.termsOfLegal.terms')}
            </a>
          </strong>
        </Checkbox>
      </Space>
    </>
  );
}

const mapStateToProps = (state) => ({
  questions: QuestionSelectors.getQuestions(state),
  signupUser: UserSelectors.getSignupUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessDataStep);
