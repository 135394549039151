import React, { useState, useRef } from 'react';
import { Modal, Layout, Row, Form } from 'antd';
import { MdClose } from 'react-icons/md';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedColorPicker from '../../shared/AdvancedColorPicker/AdvancedColorPicker';
import AdvancedButton from '../../shared/AdvancedButton/AdvancedButton';
import { TagActions, ProductActions } from '../../../app/redux/actions';
import { TagSelectors, ProductSelectors } from '../../../app/redux/reducers';
import AdvancedPreviewTag from '../../shared/AdvancedPreviewTag/AdvancedPreviewTag';

function TagsModal({
  getProductToSample,
  onGetProductToSample,
  onCloseForm,
  visible,
  isCreateTagsOnRequest,
  onCreateTag,
}) {
  const defaultValues = {
    text: I18n.t('routes.panel.administration.tags.tagsModal.defaultValues.text'),
    color: 'FFFFFF',
    textColor: '000000',
  };

  const colorRef = useRef();
  const textColorRef = useRef();

  const [text, setText] = useState(defaultValues.text);
  const [color, setColor] = useState(defaultValues.color);
  const [textColor, setTextColor] = useState(defaultValues.textColor);

  const { Content } = Layout;
  const { Item } = Form;

  const onCloseModal = () => {
    onGetProductToSample();
    setText(defaultValues.text);
    setColor(defaultValues.color);
    setTextColor(defaultValues.textColor);
    colorRef.current.reset();
    textColorRef.current.reset();
    onCloseForm();
  };

  const onSubmit = async () => {
    const payload = {
      text,
      color: color.includes('#') ? color.substring(1, color.length).toUpperCase() : color.toUpperCase(),
      textColor: textColor.includes('#')
        ? textColor.substring(1, textColor.length).toUpperCase()
        : textColor.toUpperCase(),
    };
    await onCreateTag(payload);
    onCloseModal();
  };

  return (
    <Modal
      className="drawer"
      title={I18n.t('routes.panel.administration.tags.tagsModal.title')}
      onCancel={onCloseModal}
      open={visible}
      closeIcon={<MdClose color="black" size="25px" />}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={onCloseModal} />
          <AdvancedButton loading={isCreateTagsOnRequest} text={I18n.t('forms.submitButtonText')} onClick={onSubmit} />
        </div>
      }
    >
      <Content>
        <Row className="Tags-form__preview">
          <AdvancedPreviewTag
            colorProp={color}
            textColorProp={textColor}
            setTextTag={setText}
            item={getProductToSample}
          />
          <Row className="Tags-form__row">
            <Item label={I18n.t('routes.panel.administration.tags.tagsModal.items.tagColor')}>
              <AdvancedColorPicker setColorStateReturn={setColor} value={color} ref={colorRef} defaultColor="FFFFFF" />
            </Item>
            <Item label={I18n.t('routes.panel.administration.tags.tagsModal.items.textColor')}>
              <AdvancedColorPicker
                setColorStateReturn={setTextColor}
                value={textColor}
                ref={textColorRef}
                defaultColor="000000"
              />
            </Item>
          </Row>
        </Row>
      </Content>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isCreateTagsOnRequest: TagSelectors.createTagsIsOnRequest(state),
  getProductToSample: ProductSelectors.getProductToSample(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCreateTag: (tagsData) => dispatch(TagActions.createTags([tagsData])),
  onGetProductToSample: () => dispatch(ProductActions.getProductToSample()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsModal);
