import { Link, navigate } from '@reach/router';
import React, { useState, useRef, useEffect } from 'react';
import { Layout, Breadcrumb, Collapse, Row, Popover, Col, Divider, Dropdown, Menu, Modal, notification } from 'antd';
import {
  DashboardFilled,
  QuestionCircleFilled,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { ACTION_GET_ACCESS_GROUP_BY_ID } from '../../../../app/redux/actions/accessGroup';
import AccessGroupForm from '../../../../components/provider/form/AccessGroupForm';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { AccessGroupActions } from '../../../../app/redux/actions';
import { GetUserTypeName } from '../../../../app/enum/user_types';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const defaultAdvancedFilters = {
  search: '',
};

const { Content } = Layout;
const { Panel } = Collapse;

function AccessGroups() {
  const { accessGroup } = useReduxState();
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(defaultAdvancedFilters);
  const [editingId, setEditingId] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const onSearch = (params) => {
    dispatch(AccessGroupActions.getAccessGroupsPaginated(params || advancedFilters));
  };

  useEffect(() => {
    onSearch();
  }, []);

  const dataTableRef = useRef();

  const cleanAdvancedFilters = () => {
    setAdvancedFilters(defaultAdvancedFilters);
    dataTableRef.current.reset();
    onSearch({ search: null });
  };

  const handleOnDeleteAccessGroupById = async (id) => {
    dispatch(
      AccessGroupActions.deleteAccessGroupById(id, () => {
        onSearch();
        notification.success({
          message: I18n.t('routes.panel.administration.accessGroups.confirmation.title'),
          description: I18n.t('routes.panel.administration.accessGroups.confirmation.delete'),
        });
      }),
    );
  };

  const onShowDeleteConfirm = (id) => {
    const { confirm } = Modal;

    confirm({
      title: I18n.t('routes.panel.administration.accessGroups.popup.deleteConfirm'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('routes.panel.administration.accessGroups.popup.okText'),
      okType: 'danger',
      centered: true,
      cancelText: I18n.t('routes.panel.administration.accessGroups.popup.cancelText'),
      confirmLoading: accessGroup.deleteAccessGroupIsOnRequest,
      onOk: () => handleOnDeleteAccessGroupById(id),
    });
  };

  const onAddNewAccessGroup = () => {
    setEditingId(null);
    setIsFormVisible(true);
    dispatch({ type: ACTION_GET_ACCESS_GROUP_BY_ID, payload: null });
  };

  const onEditAccessGroup = (accessGroupId) => {
    dispatch(AccessGroupActions.getAccessGroupById(accessGroupId));
    setEditingId(accessGroupId);
    setIsFormVisible(true);
  };

  const onEditAccessGroupUsers = (accessGroupId) => {
    return navigate(`${I18n.t('routes.panel.administration.accessGroupsUsers.url')}/${accessGroupId}`);
  };

  const onEditAccessGroupFeatures = (accessGroupId) => {
    return navigate(`${I18n.t('routes.panel.administration.accessGroupsFeatures.url')}/${accessGroupId}`);
  };

  const onActionsClick = async (accessGroupId, item, key) => {
    switch (key) {
      case '1':
        return onEditAccessGroup(accessGroupId);
      case '2':
        return onEditAccessGroupFeatures(accessGroupId);
      case '3':
        return onEditAccessGroupUsers(accessGroupId);
      case '4':
        await onShowDeleteConfirm(accessGroupId);
        break;
      default:
    }
  };

  const onChangeField = (name, value) => {
    const advancedFiltersCopy = { ...advancedFilters };
    advancedFiltersCopy[name] = value;
    setAdvancedFilters(advancedFiltersCopy);
  };

  const getColumns = () => {
    const columns = [
      {
        key: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.name.title'),
        fixed: !isMobile ? 'left' : false,
        width: '30%',
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.defaultUserType.key'),
        title: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.defaultUserType.title'),
        width: '30%',
        render: (value) => GetUserTypeName(value) || '--',
      },
    ];

    if (hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.READ])) {
      columns.push({
        key: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsFeatures.key'),
        title: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsFeatures.title'),
        width: '15%',
        render: (value, row) => (
          <div>
            <Link to={`${I18n.t('routes.panel.administration.accessGroupsFeatures.url')}/${row.id}`}>
              <span>
                {value?.length || 0}
                &nbsp;
                {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsFeatures.amountTitle')}
                &nbsp;
                <span>
                  <FiExternalLink />
                </span>
              </span>
            </Link>
          </div>
        ),
      });
    }

    if (hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_USERS], [accessActionTypes.READ])) {
      columns.push({
        key: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsUsers.key'),
        title: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsUsers.title'),
        width: '15%',
        render: (value, row) => (
          <div>
            <Link to={`${I18n.t('routes.panel.administration.accessGroupsUsers.url')}/${row.id}`}>
              <span>
                {value?.length || 0}
                &nbsp;
                {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.accessGroupsUsers.amountTitle')}
                &nbsp;
                <span>
                  <FiExternalLink />
                </span>
              </span>
            </Link>
          </div>
        ),
      });
    }

    if (
      hasAccess(
        [
          accessTypes.ADMIN_ACCESS_GROUPS,
          accessTypes.ADMIN_ACCESS_GROUPS_FEATURES,
          accessTypes.ADMIN_ACCESS_GROUPS_USERS,
        ],
        [accessActionTypes.UPDATE, accessActionTypes.READ, accessActionTypes.REMOVE],
      )
    ) {
      columns.push({
        key: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.id.key'),
        title: I18n.t('routes.panel.administration.accessGroups.dataTable.columns.actions.title'),
        fixed: 'right',
        align: 'right',
        width: '10%',
        render: (id) => (
          <Dropdown
            overlay={
              <Menu onClick={({ item, key, keyPath, domEvent }) => onActionsClick(id, item, key, keyPath, domEvent)}>
                {hasAccess([accessTypes.ADMIN_ACCESS_GROUPS], [accessActionTypes.UPDATE]) && (
                  <Menu.Item key="1">
                    {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.actions.edit')}
                  </Menu.Item>
                )}
                {hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.READ]) && (
                  <Menu.Item key="2">
                    {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.actions.editFeatures')}
                  </Menu.Item>
                )}
                {hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_USERS], [accessActionTypes.READ]) && (
                  <Menu.Item key="3">
                    {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.actions.editUsers')}
                  </Menu.Item>
                )}
                {hasAccess([accessTypes.ADMIN_ACCESS_GROUPS], [accessActionTypes.REMOVE]) && (
                  <Menu.Item key="4">
                    {I18n.t('routes.panel.administration.accessGroups.dataTable.columns.actions.delete')}
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <div className="ant-dropdown-link">
              <MoreOutlined style={{ fontSize: 20 }} />
            </div>
          </Dropdown>
        ),
      });
    }

    return columns;
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.accessGroups.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.accessGroups.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilters.search}
              onChange={(val) => onChangeField('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.accessGroups.advancedFilters.ribbon')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.accessGroups.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.administration.accessGroups.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        <Row align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="panel__layout__content__actions">
              <div className="panel__layout__content__actions__left">
                <AdvancedButton
                  loading={accessGroup.getAccessGroupsPaginatedIsOnRequest || accessGroup.deleteAccessGroupIsOnRequest}
                  onClick={onAddNewAccessGroup}
                  text={I18n.t('routes.panel.administration.accessGroups.addNewButtonText')}
                  iconLeft={<PlusOutlined />}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
        <AdvancedDataTable
          refresh={onSearch}
          getMethod={onSearch}
          data={accessGroup.accessGroupsPaginated}
          loading={accessGroup.getAccessGroupsPaginatedIsOnRequest || accessGroup.deleteAccessGroupIsOnRequest}
          ref={dataTableRef}
          columns={getColumns()}
        />
      </Content>

      <AccessGroupForm
        id={editingId}
        visible={isFormVisible}
        onCloseForm={(refresh) => {
          setIsFormVisible(false);
          if (refresh) {
            onSearch();
          }
        }}
      />
    </>
  );
}

export default AccessGroups;
