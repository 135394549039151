import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import MaskedInput from 'antd-mask-input';
import { Col, Row } from 'antd';
import AdvancedInput from '../../../shared/AdvancedInput';
import { UserSelectors } from '../../../../app/redux/reducers';
import { UserActions } from '../../../../app/redux/actions';
import AdvancedMaskedInput from '../../../shared/AdvancedMaskedInput';

function PersonalDataStep({ signupUser, onSetSignupUser }) {
  return (
    <>
      <div className="signup__container__register__container__form__headerDescription">
        {I18n.t('routes.signup.content.personalDataContent.content.headerText')}
      </div>
      <AdvancedInput
        defaultValue={signupUser.name}
        label={I18n.t('routes.signup.content.personalDataContent.content.name.label')}
        placeholder={I18n.t('routes.signup.content.personalDataContent.content.name.placeholder')}
        type="text"
        value={signupUser.name}
        onChange={(value) => onSetSignupUser('name', value)}
        required
      />
      <Row gutter={15}>
        <Col md={12} sm={24} xs={24}>
          <AdvancedInput
            defaultValue={signupUser.email}
            label={I18n.t('routes.signup.content.email')}
            type="email"
            value={signupUser.email}
            disabled
            required
          />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <AdvancedMaskedInput
            label={I18n.t('routes.signup.content.personalDataContent.content.phone.label')}
            placeholder={I18n.t('routes.signup.content.personalDataContent.content.phone.placeholder')}
            value={signupUser.phone}
            onChange={(value) => onSetSignupUser('phone', value)}
            kind="cel-phone"
            options={{
              withDDD: true,
              dddMask: '(99) ',
            }}
            required
          />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  signupUser: UserSelectors.getSignupUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetSignupUser: (key, value) => dispatch(UserActions.setSignupUser(key, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataStep);
