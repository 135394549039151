export const ShelfLifeTimeTypes = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
};

export const GetShelfLifeTimeTypesArray = [
  { id: ShelfLifeTimeTypes.DAYS, name: 'Dias', singular: 'Dia' },
  { id: ShelfLifeTimeTypes.WEEKS, name: 'Semanas', singular: 'Semana' },
  { id: ShelfLifeTimeTypes.MONTHS, name: 'Meses', singular: 'Mês' },
  { id: ShelfLifeTimeTypes.YEARS, name: 'Anos', singular: 'Ano' },
];

export const formatShelfLifeType = (shelfLifeTime, shelfLifeType) => {
  let type;

  if (shelfLifeTime === 1) {
    type = GetShelfLifeTimeTypesArray.find(({ id }) => shelfLifeType === id);
    if (type) return type.singular;
  }

  type = GetShelfLifeTimeTypesArray.find(({ id }) => shelfLifeType === id);
  if (type) return type.name;

  return shelfLifeType;
};
