import React, { useState, useRef, useEffect } from 'react';
import { Layout, Breadcrumb, Collapse, Row, Popover, Col, Divider, Tag, Dropdown, Menu, Modal } from 'antd';
import {
  DashboardFilled,
  QuestionCircleFilled,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { TagSelectors, ProductSelectors } from '../../../../app/redux/reducers';
import { TagActions, ProductActions } from '../../../../app/redux/actions';
import TagsModal from '../../../../components/panel/TagsModal/TagsModal';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

function Tags({
  isDeleteTagByIdOnRequest,
  getTagsPaginatedData,
  isGetTagsPaginatedOnRequest,
  onGetTagsPaginated,
  onDeleteTagById,
  onGetProductToSample,
  isGetProductToSampleOnRequest,
}) {
  const { Content } = Layout;
  const { Panel } = Collapse;

  const dataTableRef = useRef();

  const [search, setSearch] = useState();
  const [visibleTagsModal, setVisibleTagsModal] = useState(false);

  const cleanAdvancedFilters = () => {
    setSearch(null);
    dataTableRef.current.reset();
  };

  const handleSearch = () => {
    onGetTagsPaginated({ search });
  };

  const onHandleOpenTagsForm = () => {
    setVisibleTagsModal(true);
  };

  const onHandleCloseTagsForm = () => {
    onGetTagsPaginated();
    setVisibleTagsModal(false);
  };

  const handleOnDeleteTagById = (id) => {
    onDeleteTagById(id, () => onGetTagsPaginated());
  };

  const onShowDeleteConfirm = (id) => {
    const { confirm } = Modal;

    confirm({
      title: I18n.t('routes.panel.administration.tags.popup.deleteConfirm'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('routes.panel.administration.tags.popup.okText'),
      okType: 'danger',
      centered: true,
      cancelText: I18n.t('routes.panel.administration.tags.popup.cancelText'),
      confirmLoading: isDeleteTagByIdOnRequest,
      onOk: () => handleOnDeleteTagById(id),
    });
  };

  const onActionsClick = async (id, item, key) => {
    switch (key) {
      case '1':
        await onShowDeleteConfirm(id);
        break;
      default:
    }
  };

  useEffect(() => {
    onGetProductToSample();
  }, []);

  const getColumns = () => {
    const columns = [
      {
        key: I18n.t('routes.panel.administration.tags.dataTable.columns.id.key'),
        title: I18n.t('routes.panel.administration.tags.dataTable.columns.id.title'),
        width: 15,
        render: (value) => value || '--',
        sorter: true,
      },
      {
        key: I18n.t('routes.panel.administration.tags.dataTable.columns.text.key'),
        title: I18n.t('routes.panel.administration.tags.dataTable.columns.text.title'),
        width: 100,
        render: (value, record) => {
          return (
            (
              <Tag
                className="Administration-tags__tag-cell"
                style={{ backgroundColor: `#${record.color}`, color: `#${record.textColor}` }}
              >
                {value}
              </Tag>
            ) || '--'
          );
        },
        sorter: true,
        editable: hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.UPDATE]),
      },
      {
        key: I18n.t('routes.panel.administration.tags.dataTable.columns.textColor.key'),
        title: I18n.t('routes.panel.administration.tags.dataTable.columns.textColor.title'),
        width: 100,
        editable: hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.UPDATE]),
        type: 'color',
        render: (value) => {
          return (
            <Row className="Administration-tags__inner-color-cell__row">
              <div className="Administration-tags__inner-color-cell" style={{ backgroundColor: `#${value}` }} />
              {value || '--'}
            </Row>
          );
        },
      },
      {
        key: I18n.t('routes.panel.administration.tags.dataTable.columns.color.key'),
        title: I18n.t('routes.panel.administration.tags.dataTable.columns.color.title'),
        editable: hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.UPDATE]),
        width: 100,
        type: 'color',
        render: (value) => {
          return (
            <Row>
              <div className="Administration-tags__inner-color-cell" style={{ backgroundColor: `#${value}` }} />
              {value || '--'}
            </Row>
          );
        },
      },
    ];

    if (hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.REMOVE])) {
      columns.push({
        key: I18n.t('routes.panel.administration.tags.dataTable.columns.id.key'),
        title: I18n.t('routes.panel.administration.tags.dataTable.columns.actions.title'),
        fixed: 'right',
        width: 15,
        render: (tagId) => (
          <Dropdown
            overlay={
              <Menu onClick={({ item, key, keyPath, domEvent }) => onActionsClick(tagId, item, key, keyPath, domEvent)}>
                <Menu.Item key="1">
                  {I18n.t('routes.panel.administration.tags.dataTable.columns.actions.delete')}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div className="ant-dropdown-link">
              <MoreOutlined style={{ fontSize: 20 }} />
            </div>
          </Dropdown>
        ),
      });
    }

    return columns;
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.tags.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel header={<strong>{I18n.t('routes.panel.providers.advancedFilters.title')}</strong>} key="1">
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={search}
              onChange={(val) => setSearch(val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />

            <Popover placement="topLeft" content={I18n.t('routes.panel.providers.advancedFilters.searchFieldTitle')}>
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.providers.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.providers.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={handleSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {hasAccess([accessTypes.ADMIN_TAGS], [accessActionTypes.CREATE]) && (
          <>
            <Row align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="panel__layout__content__actions">
                  <div className="panel__layout__content__actions__left">
                    <AdvancedButton
                      loading={isGetProductToSampleOnRequest || isGetTagsPaginatedOnRequest || isDeleteTagByIdOnRequest}
                      onClick={onHandleOpenTagsForm}
                      text={I18n.t('routes.panel.providers.addNewButtonText')}
                      iconLeft={<PlusOutlined />}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <AdvancedDataTable
          refresh={onGetTagsPaginated}
          getMethod={onGetTagsPaginated}
          data={getTagsPaginatedData}
          loading={isGetProductToSampleOnRequest || isGetTagsPaginatedOnRequest || isDeleteTagByIdOnRequest}
          ref={dataTableRef}
          container="tags"
          columns={getColumns()}
        />
      </Content>
      <TagsModal visible={visibleTagsModal} onCloseForm={onHandleCloseTagsForm} />
    </>
  );
}

const mapStateToProps = (state) => ({
  getTagsPaginatedData: TagSelectors.getTagsPaginated(state),
  isGetTagsPaginatedOnRequest: TagSelectors.getTagsIsOnRequest(state),
  isDeleteTagByIdOnRequest: TagSelectors.deleteTagsIsOnRequest(state),
  isGetProductToSampleOnRequest: ProductSelectors.isGetProductToSampleIsOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductToSample: () => dispatch(ProductActions.getProductToSample()),
  onGetTagsPaginated: (params) => dispatch(TagActions.getTagsPaginated(params)),
  onDeleteTagById: (tagId, callback) => dispatch(TagActions.deleteTagById(tagId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
