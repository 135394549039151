import styled from 'styled-components';

export const ContainerFooterButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 23px;
  border-top: 1px solid #f0f0f0;

  @media all and (max-width: 1100px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
`;
