import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Layout, Breadcrumb, Row, Col, Divider, Dropdown, Menu, Modal, Collapse, Popover } from 'antd';
import {
  DashboardFilled,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
  QuestionCircleFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { CollectionActions, ProviderActions } from '../../../../app/redux/actions';
import { ViewTypes } from '../../../../app/enum/view_types';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { hasAccess } from '../../../../app/services/access';

import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';
import CollectionDetailForm from '../../../../components/panel/form/CollectionDetailForm';
import { getCollectionType } from '../../../../app/utils/collection';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';

const { Content } = Layout;
const { Panel } = Collapse;

function Collections() {
  const { collection: CollectionSelectors, auth: AuthSelectors } = useReduxState();
  const dispatch = useDispatch();
  const dataTableRef = useRef();
  const [search, setSearch] = useState('');
  const [visibleCollectionDetailForm, setVisibleCollectionDetailForm] = useState(false);
  const [collectionDetailId, setCollectionDetailId] = useState(null);
  const [type, setType] = useState(ViewTypes.CREATE);

  const providerId = useMemo(() => AuthSelectors.providers[0]?.id, [AuthSelectors.providers]);

  const getCollectionsPaginated = useCallback((params) => dispatch(CollectionActions.getCollections(params)), []);

  const getCollections = useCallback(
    (params, filters) => {
      if (params) {
        getCollectionsPaginated({ ...params, search });
      } else {
        getCollectionsPaginated({ ...filters, search });
      }
    },
    [search, getCollectionsPaginated],
  );

  const onHandleCollectionDetailForm = useCallback((id) => {
    setVisibleCollectionDetailForm(true);
    setCollectionDetailId(id);
    setType(ViewTypes.EDIT);
  }, []);

  const onHandleCollectionCreateForm = useCallback(() => {
    setVisibleCollectionDetailForm(true);
    setType(ViewTypes.CREATE);
  }, []);

  const onCloseCollectionDetailForm = useCallback(() => {
    setVisibleCollectionDetailForm(false);
    setCollectionDetailId(null);
  }, []);

  const onRefreshCollectionDetailForm = useCallback(() => {
    onCloseCollectionDetailForm();
    getCollections();
  }, [getCollections, onCloseCollectionDetailForm]);

  const cleanAdvancedFilters = () => {
    setSearch(null);
    getCollectionsPaginated();
    dataTableRef.current.reset();
  };

  const onDeleteCollection = useCallback(
    async (id) => dispatch(CollectionActions.deleteCollection(id, getCollectionsPaginated)),
    [getCollectionsPaginated],
  );

  const handleSearch = () => {
    getCollectionsPaginated({ search });
  };

  const onShowDeleteConfirm = (id) => {
    const { confirm } = Modal;

    confirm({
      title: I18n.t('routes.panel.administration.collections.popup.deleteConfirm'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('routes.panel.administration.collections.popup.okText'),
      okType: 'danger',
      centered: true,
      cancelText: I18n.t('routes.panel.administration.collections.popup.cancelText'),
      confirmLoading: CollectionSelectors.isDeleteCollectionDetailOnRequest,
      onOk: async () => onDeleteCollection(id),
    });
  };

  const onActionsClick = (id, item, key) => {
    switch (key) {
      case '1':
        onHandleCollectionDetailForm(id);
        break;
      case '2':
        onShowDeleteConfirm(id);
        break;
      default:
    }
  };

  const getColumns = () => {
    const columns = [
      {
        key: I18n.t('routes.panel.administration.collections.dataTable.columns.id.key'),
        title: I18n.t('routes.panel.administration.collections.dataTable.columns.id.title'),
        width: 15,
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.collections.dataTable.columns.userId.key'),
        title: I18n.t('routes.panel.administration.collections.dataTable.columns.userId.title'),
        width: 15,
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.collections.dataTable.columns.type.key'),
        title: I18n.t('routes.panel.administration.collections.dataTable.columns.type.title'),
        width: 15,
        render: (value) => getCollectionType(value) || '--',
      },
      {
        key: I18n.t('routes.panel.administration.collections.dataTable.columns.title.key'),
        title: I18n.t('routes.panel.administration.collections.dataTable.columns.title.title'),
        width: 40,
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.collections.dataTable.columns.description.key'),
        title: I18n.t('routes.panel.administration.collections.dataTable.columns.description.title'),
        width: 60,
        render: (value) => value || '--',
      },
    ];

    columns.push({
      key: I18n.t('routes.panel.administration.collections.dataTable.columns.id.key'),
      title: I18n.t('routes.panel.administration.collections.actions.title'),
      fixed: 'right',
      width: 10,
      render: (collectionId, row) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ item, key, keyPath, domEvent }) => {
                onActionsClick(row.id, item, key, keyPath, domEvent);
              }}
            >
              <Menu.Item key="1">
                {I18n.t('routes.panel.administration.collections.actions.goToCollectionDetails')}
              </Menu.Item>
              {hasAccess([accessTypes.ADMIN_COLLECTIONS], [accessActionTypes.REMOVE]) && (
                <Menu.Item key="2">{I18n.t('routes.panel.administration.collections.actions.delete')}</Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="highlight-table-content__ant-dropdown-link">
            <MoreOutlined />
          </div>
        </Dropdown>
      ),
    });

    return columns;
  };

  useEffect(() => {
    (async () => dispatch(ProviderActions.getProviderProducts(providerId)))();
  }, [providerId]);

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.collections.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <div className="collection-content">
        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel
            header={<strong>{I18n.t('routes.panel.administration.collections.advancedFilters.title')}</strong>}
            key="1"
          >
            <Row align="middle">
              <div className="collection-content__search-input">
                <AdvancedInput
                  value={search}
                  onChange={(val) => setSearch(val)}
                  placeholder={I18n.t('shared.typeToContinue')}
                />
              </div>
              <div className="collection-content__search-popover">
                <Popover
                  placement="topLeft"
                  content={I18n.t('routes.panel.administration.collections.advancedFilters.searchFieldTitle')}
                >
                  <QuestionCircleFilled />
                </Popover>
              </div>
            </Row>
            <div className="collection-content__search-filters">
              <Row>
                <div className="collection-content__search-filters__buttons">
                  <AdvancedButton
                    type="default"
                    text={I18n.t('routes.panel.administration.collections.advancedFilters.clearButtonText')}
                    onClick={cleanAdvancedFilters}
                  />
                  <AdvancedButton
                    text={I18n.t('routes.panel.administration.collections.advancedFilters.filterButtonText')}
                    iconLeft={<SearchOutlined />}
                    onClick={handleSearch}
                  />
                </div>
              </Row>
            </div>
          </Panel>
        </Collapse>
      </div>

      <div className="collection-table-content">
        <Content className="panel__layout__content panel__layout__content--content-data">
          {hasAccess([accessTypes.ADMIN_COLLECTIONS], [accessActionTypes.CREATE]) && (
            <>
              <Row align="middle">
                <Col span={24}>
                  <AdvancedButton
                    text={I18n.t('routes.panel.administration.collections.addNewButtonText')}
                    iconLeft={<PlusOutlined />}
                    onClick={onHandleCollectionCreateForm}
                  />
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <AdvancedDataTable
            refresh={getCollectionsPaginated}
            getMethod={getCollections}
            data={CollectionSelectors.collectionsPaginated}
            loading={CollectionSelectors.isGetCollectionsOnRequest}
            ref={dataTableRef}
            container="collections"
            columns={getColumns()}
          />
        </Content>
      </div>

      <CollectionDetailForm
        type={type}
        visible={visibleCollectionDetailForm}
        id={collectionDetailId}
        onClose={onCloseCollectionDetailForm}
        onRefresh={onRefreshCollectionDetailForm}
      />
    </>
  );
}

export default Collections;
