import Immutable from 'seamless-immutable';
import {
  ACTION_GET_COLLECTIONS_DATA,
  ACTION_GET_COLLECTIONS_IS_ON_REQUEST,
  ACTION_UPDATE_COLLECTION_IS_ON_REQUEST,
  ACTION_DELETE_COLLECTION_IS_ON_REQUEST,
  ACTION_CREATE_COLLECTION_IS_ON_REQUEST,
} from '../actions/collection';

const initialState = Immutable({
  collectionsPaginated: [],
  isGetCollectionsOnRequest: false,
  isUpdateCollectionDetailOnRequest: false,
  isDeleteCollectionDetailOnRequest: false,
  isCreateCollectionOnRequest: false,
});

// eslint-disable-next-line default-param-last
export default function user(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_COLLECTIONS_DATA:
      return state.merge({
        collectionsPaginated: action.payload,
      });
    case ACTION_GET_COLLECTIONS_IS_ON_REQUEST:
      return state.merge({
        isGetCollectionsOnRequest: action.payload,
      });
    case ACTION_UPDATE_COLLECTION_IS_ON_REQUEST:
      return state.merge({
        isUpdateCollectionDetailOnRequest: action.payload,
      });
    case ACTION_DELETE_COLLECTION_IS_ON_REQUEST:
      return state.merge({
        isDeleteCollectionDetailOnRequest: action.payload,
      });
    case ACTION_CREATE_COLLECTION_IS_ON_REQUEST:
      return state.merge({
        isCreateCollectionOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getCollectionsPaginated(state) {
  return state.collection.collectionsPaginated;
}

export function isGetCollectionsOnRequest(state) {
  return state.collection.isGetCollectionsOnRequest;
}

export function isUpdateCollectionDetailOnRequest(state) {
  return state.collection.isUpdateCollectionDetailOnRequest;
}

export function isDeleteCollectionDetailOnRequest(state) {
  return state.collection.isDeleteCollectionDetailOnRequest;
}

export function isCreateCollectionOnRequest(state) {
  return state.collection.isCreateCollectionOnRequest;
}
