import { Link, navigate } from '@reach/router';
import React, { useState, useRef, useEffect } from 'react';
import { Layout, Breadcrumb, Collapse, Row, Popover, Col, Dropdown, Menu, Modal, notification, Divider } from 'antd';
import {
  DashboardFilled,
  QuestionCircleFilled,
  SearchOutlined,
  PlusOutlined,
  MoreOutlined,
  ExclamationCircleFilled,
  DownOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useReduxState } from '../../../../app/hooks/useReduxState';
import { ACTION_GET_ACCESS_FEATURE_BY_ID } from '../../../../app/redux/actions/accessFeature';
import AccessFeatureForm from '../../../../components/provider/form/AccessFeatureForm';
import AdvancedInput from '../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDataTable from '../../../../components/shared/AdvancedDataTable/AdvancedDataTable';
import { AccessFeatureActions } from '../../../../app/redux/actions';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const defaultAdvancedFilters = {
  search: '',
};

const { Content } = Layout;
const { Panel } = Collapse;

function AccessFeatures() {
  const { accessFeature } = useReduxState();
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(defaultAdvancedFilters);
  const [editingId, setEditingId] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const onSearch = (params) => {
    dispatch(AccessFeatureActions.getAccessFeaturesPaginated(params || advancedFilters));
  };

  useEffect(() => {
    onSearch();
    setIsFormVisible(false);
  }, []);

  const dataTableRef = useRef();

  const cleanAdvancedFilters = () => {
    setAdvancedFilters(defaultAdvancedFilters);
    dataTableRef.current.reset();
    onSearch({ search: null });
  };

  const handleOnDeleteAccessFeatureById = async (id) => {
    dispatch(
      AccessFeatureActions.deleteAccessFeatureById(id, () => {
        onSearch();
        notification.success({
          message: I18n.t('routes.panel.administration.accessFeature.confirmation.title'),
          description: I18n.t('routes.panel.administration.accessFeature.confirmation.delete'),
        });
      }),
    );
  };

  const onShowDeleteConfirm = (id) => {
    const { confirm } = Modal;

    confirm({
      title: I18n.t('routes.panel.administration.accessFeature.popup.deleteConfirm'),
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: I18n.t('routes.panel.administration.accessFeature.popup.okText'),
      okType: 'danger',
      centered: true,
      cancelText: I18n.t('routes.panel.administration.accessFeature.popup.cancelText'),
      confirmLoading: accessFeature.deleteAccessFeatureIsOnRequest,
      onOk: () => handleOnDeleteAccessFeatureById(id),
    });
  };

  const onAddNewAccessFeature = () => {
    setEditingId(null);
    setIsFormVisible(true);
    dispatch({ type: ACTION_GET_ACCESS_FEATURE_BY_ID, payload: null });
  };

  const onEditAccessFeature = (accessFeatureId) => {
    dispatch(AccessFeatureActions.getAccessFeatureById(accessFeatureId));
    setEditingId(accessFeatureId);
    setIsFormVisible(true);
  };

  const onEditAccessFeaturesUsers = (accessFeatureId) => {
    return navigate(`${I18n.t('routes.panel.administration.accessFeaturesUsers.url')}/${accessFeatureId}`);
  };

  const onActionsClick = async (accessFeatureId, item, key) => {
    switch (key) {
      case '1':
        onEditAccessFeature(accessFeatureId);
        break;
      case '2':
        onEditAccessFeaturesUsers(accessFeatureId);
        break;
      case '3':
        await onShowDeleteConfirm(accessFeatureId);
        break;
      default:
    }
  };

  const onChangeField = (name, value) => {
    const advancedFiltersCopy = { ...advancedFilters };
    advancedFiltersCopy[name] = value;
    setAdvancedFilters(advancedFiltersCopy);
  };

  const getColumns = () => {
    const columns = [
      {
        key: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.name.title'),
        fixed: !isMobile ? 'left' : false,
        width: '20%',
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.description.key'),
        title: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.description.title'),
        render: (value) => value || '--',
        width: '60%',
      },
    ];

    if (hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.READ])) {
      columns.push({
        key: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.accessFeaturesUsers.key'),
        title: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.accessFeaturesUsers.title'),
        width: '20%',
        render: (value, row) => (
          <div>
            <Link to={`${I18n.t('routes.panel.administration.accessFeaturesUsers.url')}/${row.id}`}>
              <span>
                {value?.length || 0}
                &nbsp;
                {I18n.t('routes.panel.administration.accessFeature.dataTable.columns.accessFeaturesUsers.amountTitle')}
                &nbsp;
                <span>
                  <FiExternalLink />
                </span>
              </span>
            </Link>
          </div>
        ),
      });
    }

    if (hasAccess([accessTypes.ADMIN_ACCESS_GROUPS_FEATURES], [accessActionTypes.READ])) {
      columns.push({
        key: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.accessGroupsFeatures.key'),
        title: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.accessGroupsFeatures.title'),
        width: '20%',
        render: (_, row) => (
          <div>
            <Dropdown
              overlay={
                row.accessGroupsFeatures?.length ? (
                  <Menu>
                    {[...(row.accessGroupsFeatures || [])]
                      ?.sort((a, b) => {
                        if (a.accessGroup?.name < b.accessGroup?.name) {
                          return -1;
                        }
                        if (b.accessGroup?.name < a.accessGroup?.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((feature, featureIndex) => (
                        <Menu.Item key={featureIndex.toString()}>
                          <Link
                            to={`${I18n.t('routes.panel.administration.accessGroupsFeatures.url')}/${
                              feature.accessGroup?.id
                            }`}
                          >
                            <span className="panel__layout__content__link">{feature.accessGroup?.name}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                  </Menu>
                ) : (
                  <span />
                )
              }
              trigger={['click']}
            >
              <div className="ant-dropdown-link">
                <span className="panel__layout__content__link">
                  {I18n.t(
                    'routes.panel.administration.accessFeature.dataTable.columns.accessGroupsFeatures.amountTitle',
                    {
                      amount: row.accessGroupsFeatures?.length || 0,
                    },
                  )}
                  <span className="ant-dropdown-link">
                    <DownOutlined />
                  </span>
                </span>
              </div>
            </Dropdown>
          </div>
        ),
      });
    }

    columns.push({
      key: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.id.key'),
      title: I18n.t('routes.panel.administration.accessFeature.dataTable.columns.actions.title'),
      fixed: 'right',
      align: 'right',
      width: '10%',
      render: (tagId) => (
        <Dropdown
          overlay={
            <Menu onClick={({ item, key, keyPath, domEvent }) => onActionsClick(tagId, item, key, keyPath, domEvent)}>
              {hasAccess([accessTypes.ADMIN_ACCESS_FEATURES], [accessActionTypes.UPDATE]) && (
                <Menu.Item key="1">
                  {I18n.t('routes.panel.administration.accessFeature.dataTable.columns.actions.edit')}
                </Menu.Item>
              )}
              {hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.READ]) && (
                <Menu.Item key="2">
                  {I18n.t('routes.panel.administration.accessFeature.dataTable.columns.actions.editUsers')}
                </Menu.Item>
              )}
              {hasAccess([accessTypes.ADMIN_ACCESS_FEATURES], [accessActionTypes.REMOVE]) && (
                <Menu.Item key="3">
                  {I18n.t('routes.panel.administration.accessFeature.dataTable.columns.actions.delete')}
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="ant-dropdown-link">
            <MoreOutlined style={{ fontSize: 20 }} />
          </div>
        </Dropdown>
      ),
    });

    return columns;
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.accessFeature.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.accessFeature.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilters.search}
              onChange={(val) => onChangeField('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.accessFeature.advancedFilters.ribbon')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.accessFeature.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.administration.accessFeature.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onSearch}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {hasAccess([accessTypes.ADMIN_ACCESS_FEATURES], [accessActionTypes.CREATE]) && (
          <Row align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="panel__layout__content__actions">
                <div className="panel__layout__content__actions__left">
                  <AdvancedButton
                    loading={
                      accessFeature.getAccessFeaturesPaginatedIsOnRequest ||
                      accessFeature.deleteAccessFeatureIsOnRequest
                    }
                    onClick={onAddNewAccessFeature}
                    text={I18n.t('routes.panel.administration.accessFeature.addNewButtonText')}
                    iconLeft={<PlusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Divider />
          </Row>
        )}
        <AdvancedDataTable
          refresh={onSearch}
          getMethod={onSearch}
          data={accessFeature.accessFeaturesPaginated}
          loading={accessFeature.getAccessFeaturesPaginatedIsOnRequest || accessFeature.deleteAccessFeatureIsOnRequest}
          ref={dataTableRef}
          container="tags"
          columns={getColumns()}
        />
      </Content>
      <AccessFeatureForm
        id={editingId}
        visible={isFormVisible}
        onCloseForm={(refresh) => {
          setIsFormVisible(false);
          if (refresh) {
            onSearch();
          }
        }}
      />
    </>
  );
}

export default AccessFeatures;
