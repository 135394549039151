import Immutable from 'seamless-immutable';
import {
  ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_PRODUCTS_DATA,
  ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_PROVIDERS_PAGINATED,
  ACTION_GET_PROVIDER_IS_ON_REQUEST,
  ACTION_DELETE_PROVIDER_IS_ON_REQUEST,
  ACTION_UPDATE_PROVIDER_IS_ON_REQUEST,
  ACTION_CREATE_PROVIDER_IS_ON_REQUEST,
  ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST,
  ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST,
  ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST,
  ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST,
  ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST,
  ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST,
  ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_BILLING_PAGINATED_DATA,
  ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST,
  ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST,
  ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST,
  ACTION_GET_EXPORT_PROVIDER_PRODUCTS_DATA,
  ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_ADMINS_DATA,
  ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST,
  ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_RATINGS_DATA,
  ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST,
  ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_ERROR,
  ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST,
  ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_ERROR,
  ACTION_GET_PROVIDER_CERTIFICATE_DATA,
  ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST,
} from '../actions/provider';

const initialState = Immutable({
  providerProducts: [],
  providersPaginated: null,
  isGetProviderOnRequest: false,
  isGetProviderProductsOnRequest: false,
  isGetProviderPaymentsOnRequest: false,
  isGetProviderPaymentsByUserOnRequest: false,
  isGetProvidersPaginatedOnRequest: false,
  isDeleteProviderOnRequest: false,
  isUpdateProviderOnRequest: false,
  isCreateProviderOnRequest: false,
  isProviderUnitsOnRequest: false,
  isGetAllProvidersOnRequest: false,
  isGetProviderUnitsOnRequest: false,
  isCreateProviderUnitOnRequest: false,
  isUpdateProviderUnitOnRequest: false,
  isDeleteProviderUnitOnRequest: false,
  isGetProviderPaymentAccountOnRequest: false,
  isDeleteProviderPaymentAccountOnRequest: false,
  isCreateProviderPaymentAccountOnRequest: false,
  isUpdateProviderPaymentAccountOnRequest: false,
  providerBillingPaginated: null,
  isGetProviderBillingPaginatedOnRequest: false,
  isGetProviderTagsOnRequest: false,
  isUpdateProviderTagsOnRequest: false,
  isGetExportProviderProductsOnRequest: false,
  exportProviderProducts: null,
  isGetProviderAdminsOnRequest: false,
  providerAdmins: null,
  isDeleteProviderAdminOnRequest: false,
  isCreateProviderAdminOnRequest: false,
  isGetProviderRatingsIsOnRequest: false,
  providerRatings: [],
  isGetProviderCertificateIsOnRequest: false,
  isCreateProviderCertificateIsOnRequest: false,
  isGetProviderCertificateIsOnError: false,
  isCreateProviderCertificateIsOnError: false,
  providerCertification: null,
  isGetProviderIntegrationsIsOnRequest: false,
});

export default function provider(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_PROVIDER_PAYMENTS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderPaymentsOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_PAYMENTS_BY_USER_IS_ON_REQUEST:
      return state.merge({
        isGetProviderPaymentsByUserOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_PRODUCTS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderProductsOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_PRODUCTS_DATA:
      return state.merge({
        providerProducts: action.payload.rows,
      });
    case ACTION_GET_PROVIDERS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetProvidersPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDERS_PAGINATED:
      return state.merge({
        providersPaginated: action.payload,
      });
    case ACTION_GET_PROVIDER_IS_ON_REQUEST:
      return state.merge({
        isGetProviderOnRequest: action.payload,
      });
    case ACTION_DELETE_PROVIDER_IS_ON_REQUEST:
      return state.merge({
        isDeleteProviderOnRequest: action.payload,
      });
    case ACTION_UPDATE_PROVIDER_IS_ON_REQUEST:
      return state.merge({
        isUpdateProviderOnRequest: action.payload,
      });
    case ACTION_CREATE_PROVIDER_IS_ON_REQUEST:
      return state.merge({
        isCreateProviderOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_UNITS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderUnitsOnRequest: action.payload,
      });
    case ACTION_CREATE_PROVIDER_UNIT_IS_ON_REQUEST:
      return state.merge({
        isCreateProviderUnitOnRequest: action.payload,
      });
    case ACTION_UPDATE_PROVIDER_UNIT_IS_ON_REQUEST:
      return state.merge({
        isUpdateProviderUnitOnRequest: action.payload,
      });
    case ACTION_DELETE_PROVIDER_UNIT_IS_ON_REQUEST:
      return state.merge({
        isDeleteProviderUnitOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:
      return state.merge({
        isGetProviderPaymentAccountOnRequest: action.payload,
      });
    case ACTION_CREATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:
      return state.merge({
        isDeleteProviderPaymentAccountOnRequest: action.payload,
      });
    case ACTION_UPDATE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:
      return state.merge({
        isCreateProviderPaymentAccountOnRequest: action.payload,
      });
    case ACTION_DELETE_PROVIDER_PAYMENT_ACCOUNT_IS_ON_REQUEST:
      return state.merge({
        isUpdateProviderPaymentAccountOnRequest: action.payload,
      });
    case ACTION_GET_ALL_PROVIDERS_IS_ON_REQUEST:
      return state.merge({
        isGetAllProvidersOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_BILLING_PAGINATED_DATA:
      return state.merge({
        providerBillingPaginated: action.payload,
      });
    case ACTION_GET_PROVIDER_BILLING_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        isGetProviderBillingPaginatedOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_TAGS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderTagsOnRequest: action.payload,
      });
    case ACTION_UPDATE_PROVIDER_TAGS_IS_ON_REQUEST:
      return state.merge({
        isUpdateProviderTagsOnRequest: action.payload,
      });
    case ACTION_GET_EXPORT_PROVIDER_PRODUCTS_IS_ON_REQUEST:
      return state.merge({
        isGetExportProviderProductsOnRequest: action.payload,
      });
    case ACTION_GET_EXPORT_PROVIDER_PRODUCTS_DATA:
      return state.merge({
        exportProviderProducts: action.payload,
      });
    case ACTION_GET_PROVIDER_ADMINS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderAdminsOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_ADMINS_DATA:
      return state.merge({
        providerAdmins: action.payload,
      });
    case ACTION_DELETE_PROVIDER_ADMIN_IS_ON_REQUEST:
      return state.merge({
        isDeleteProviderAdminOnRequest: action.payload,
      });
    case ACTION_CREATE_PROVIDER_ADMIN_IS_ON_REQUEST:
      return state.merge({
        isCreateProviderAdminOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_RATINGS_DATA:
      return state.merge({
        providerRatings: action.payload,
      });
    case ACTION_GET_PROVIDER_RATINGS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderRatingsIsOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_REQUEST:
      return state.merge({
        isGetProviderCertificateIsOnRequest: action.payload,
      });
    case ACTION_GET_PROVIDER_CERTIFICATE_IS_ON_ERROR:
      return state.merge({
        isGetProviderCertificateIsOnError: action.payload,
      });
    case ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_REQUEST:
      return state.merge({
        isCreateProviderCertificateIsOnRequest: action.payload,
      });
    case ACTION_CREATE_PROVIDER_CERTIFICATE_IS_ON_ERROR:
      return state.merge({
        isCreateProviderCertificateIsOnError: action.payload,
      });
    case ACTION_GET_PROVIDER_CERTIFICATE_DATA:
      return state.merge({
        providerCertification: action.payload,
      });
    case ACTION_GET_PROVIDER_INTEGRATIONS_IS_ON_REQUEST:
      return state.merge({
        isGetProviderIntegrationsIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function isGetProviderOnRequest(state) {
  return state.provider.isGetProviderOnRequest;
}

export function isGetProviderPaymentsOnRequest(state) {
  return state.provider.isGetProviderPaymentsOnRequest;
}

export function isGetProviderPaymentsbyUserOnRequest(state) {
  return state.provider.isGetProviderPaymentsByUserOnRequest;
}

export function getProviderProducts(state) {
  return state.provider.providerProducts;
}

export function getProviderProductsById(state, productId) {
  return state.provider.providerProducts.find(({ id }) => productId === id.toString());
}

export function isGetProviderProductsOnRequest(state) {
  return state.provider.isGetProviderProductsOnRequest;
}

export function getProvidersPaginated(state) {
  return state.provider.providersPaginated;
}

export function isDeleteProviderOnRequest(state) {
  return state.provider.isDeleteProviderOnRequest;
}

export function isUpdateProviderOnRequest(state) {
  return state.provider.isUpdateProviderOnRequest;
}

export function isCreateProviderOnRequest(state) {
  return state.provider.isCreateProviderOnRequest;
}

export function isGetProvidersPaginatedOnRequest(state) {
  return state.provider.isGetProvidersPaginatedOnRequest;
}

export function isGetProviderUnitsOnRequest(state) {
  return state.provider.isGetProviderUnitsOnRequest;
}

export function isDeleteProviderUnitOnRequest(state) {
  return state.provider.isDeleteProviderUnitOnRequest;
}

export function isUpdateProviderUnitOnRequest(state) {
  return state.provider.isUpdateProviderUnitOnRequest;
}

export function isCreateProviderUnitOnRequest(state) {
  return state.provider.isCreateProviderUnitOnRequest;
}

export function isGetProviderPaymentAccountOnRequest(state) {
  return state.provider.isGetProviderPaymentAccountOnRequest;
}

export function isDeleteProviderPaymentAccountOnRequest(state) {
  return state.provider.isDeleteProviderPaymentAccountOnRequest;
}

export function isCreateProviderPaymentAccountOnRequest(state) {
  return state.provider.isCreateProviderPaymentAccountOnRequest;
}

export function isUpdateProviderPaymentAccountOnRequest(state) {
  return state.provider.isUpdateProviderPaymentAccountOnRequest;
}

export function isGetAllProvidersOnRequest(state) {
  return state.provider.isGetAllProvidersOnRequest;
}

export function getProviderBillingPaginated(state) {
  return state.provider.providerBillingPaginated;
}

export function isGetProviderBillingPaginatedOnRequest(state) {
  return state.provider.isGetProviderBillingPaginatedOnRequest;
}

export function isGetProviderTagsIsOnRequest(state) {
  return state.provider.isGetProviderTagsOnRequest;
}

export function updateProviderTagsIsOnRequest(state) {
  return state.provider.isUpdateProviderTagsOnRequest;
}

export function isGetExportProviderProductsOnRequest(state) {
  return state.provider.isGetExportProviderProductsOnRequest;
}

export function getExportProviderProducts(state) {
  return state.provider.exportProviderProducts;
}

export function getProviderAdmins(state) {
  return state.provider.providerAdmins;
}

export function isGetProviderAdminsIsOnRequest(state) {
  return state.provider.isGetProviderAdminsOnRequest;
}

export function isDeleteProviderAdminIsOnRequest(state) {
  return state.provider.isDeleteProviderAdminOnRequest;
}

export function isCreateProviderAdminIsOnRequest(state) {
  return state.provider.isCreateProviderAdminOnRequest;
}

export function isGetProviderRatingsIsOnRequest(state) {
  return state.provider.isGetProviderRatingsIsOnRequest;
}

export function getProviderRatings(state) {
  return state.provider.providerRatings;
}

export function isCreateProviderCertificateIsOnRequest(state) {
  return state.provider.isCreateProviderCertificateIsOnRequest;
}

export function isGetProviderCertificateIsOnRequest(state) {
  return state.provider.isGetProviderCertificateIsOnRequest;
}

export function getProviderCertification(state) {
  return state.provider.providerCertification;
}

export function isGetProviderIntegrationsIsOnRequest(state) {
  return state.provider.isGetProviderIntegrationsIsOnRequest;
}
