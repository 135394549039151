/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Row, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import { I18n } from 'react-redux-i18n';

const AdvancedColorPicker = forwardRef(({ value, setColorStateReturn, defaultColor }, ref) => {
  value = value && `#${value}`;
  const [visiblePopOver, setVisiblePopOver] = useState();
  const [color, setColor] = useState(value);

  const handleVisibleChange = () => {
    setVisiblePopOver(!visiblePopOver);
  };
  const handleChangeColor = (hex) => {
    setColor(hex);
    setColorStateReturn(hex);
  };

  const reset = () => {
    setColor(`#${defaultColor}`);
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const popoverContent = () => {
    return <SketchPicker color={color} onChangeComplete={({ hex }) => handleChangeColor(hex)} />;
  };
  return (
    <Row>
      <Popover
        placement="top"
        title={I18n.t('shared.advancedColorPicker.title')}
        content={popoverContent}
        trigger="click"
        visible={visiblePopOver}
        onVisibleChange={handleVisibleChange}
      >
        <button
          type="button"
          onClick={handleVisibleChange}
          className="Advanced-color-picker__square"
          style={{ backgroundColor: color }}
        />
      </Popover>
      {color.toUpperCase()}
    </Row>
  );
});

AdvancedColorPicker.displayName = 'AdvancedColorPicker';

export default AdvancedColorPicker;
