import React from 'react';
import { Layout, Row, message, Breadcrumb, Collapse, Modal, Popover } from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DashboardFilled, SearchOutlined, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { ViewTypes } from '../../../app/enum/view_types';
import AdvancedDataTable from '../../../components/shared/AdvancedDataTable';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import OrderForm from '../../../components/order/form';

import {
  OrderActions,
  PaymentActions,
  DistributorActions,
  UserActions,
  ShipmentActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  OrderSelectors,
  UserSelectors,
  PaymentSelectors,
  SettingSelectors,
  DistributorSelectors,
} from '../../../app/redux/reducers';
import { UserTypes } from '../../../app/enum/user_types';

import OrderColumnsSupporters from '../../../app/utils/dataTable/columns/supporters/OrderColumnsSupporters';
import OrderColumnsProviders from '../../../app/utils/dataTable/columns/providers/OrderColumnsProviders';
import { hasAccess } from '../../../app/services/access';
import { accessTypes } from '../../../app/enum/access_types';
import { accessActionTypes } from '../../../app/enum/access_action_types';
import { OrderLogStatusTypes } from '../../../app/enum/order_log_status';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      orderFormVisible: false,
      importModalVisible: false,
      filteredInfo: null,
      form: {
        type: null,
        id: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { onGetPayments, onGetDistributors, onGetOrderProblems, onGetPaymentStatus, onGetUsersSupport } = this.props;
    onGetPayments();
    onGetDistributors();
    onGetOrderProblems();
    onGetPaymentStatus();
    onGetUsersSupport();
  }

  getOrders = (params) => {
    const { onGetOrdersPaginated } = this.props;
    const { search, filters, providerId } = this.state;
    if (params) {
      this.setState({ filters: params });
      onGetOrdersPaginated({ ...params, search, providerId });
    } else {
      onGetOrdersPaginated({ ...filters, search, providerId });
    }
  };

  fieldChange = (name, value) => {
    const { state } = this;
    state[name] = value ? value.toString() : null;
    this.setState(state);
  };

  onCleanAdvancedFilters = async () => {
    await this.setState({ filters: null, search: '', providerId: null, filteredInfo: null });
    this.dataTableRef.current.reset();
    this.getOrders();
  };

  onChangeAdvancedFilterProviderSelector = async (providerId = null) => {
    await this.setState({ providerId });
    this.getOrders();
  };

  removeOrder = async (id) => {
    const { onDeleteOrder } = this.props;
    const success = await onDeleteOrder(id);
    if (success) {
      message.success(I18n.t('routes.panel.orders.messages.success.delete'));
      this.getOrders();
    }
  };

  onActionsClick = async (id, item, key) => {
    switch (key) {
      case '1':
        await this.onOrderFormStateChange(true, id, ViewTypes.EDIT);
        break;
      case '2':
        return this.updateShipmentStatusToReadyDelivery(id);
      case '3':
        return this.updateShipmentStatusToOnCarriage(id);
      case '4':
        return this.onShowDeleteConfirm(id);
      default:
    }
  };

  handleSearch = () => {
    this.dataTableRef.current.reset();
    this.getOrders();
  };

  onOrderFormStateChange = (visible, orderId = null, type = null) => {
    this.setState({ orderFormVisible: visible, form: { id: orderId, type } });
  };

  updateShipmentStatusToReadyDelivery = async (id) => {
    const { onUpdateOrderShipmentStatus } = this.props;

    const success = await onUpdateOrderShipmentStatus(id, OrderLogStatusTypes.READY_DELIVERY);
    if (success) {
      this.getOrders();
      return message.success(I18n.t('routes.panel.orders.messages.shipment.success'));
    }

    return message.error(I18n.t('routes.panel.orders.messages.shipment.error'));
  };

  updateShipmentStatusToOnCarriage = async (id) => {
    const { onUpdateOrderShipmentStatus } = this.props;

    const success = await onUpdateOrderShipmentStatus(id, OrderLogStatusTypes.ON_CARRIAGE);
    if (success) {
      this.getOrders();
      return message.success(I18n.t('routes.panel.orders.messages.shipment.success'));
    }

    return message.error(I18n.t('routes.panel.orders.messages.shipment.error'));
  };

  onShowDeleteConfirm = (orderId) => {
    const { confirm } = Modal;
    const { isDeleteOrderOnRequest } = this.props;

    confirm({
      title: 'Atenção, deseja realmente deletar esse pedido?',
      icon: <ExclamationCircleFilled style={{ color: 'red' }} />,
      okText: 'Sim',
      okType: 'danger',
      centered: true,
      cancelText: 'Cancelar',
      confirmLoading: isDeleteOrderOnRequest,
      onOk: () => this.removeOrder(orderId),
    });
  };

  handleChange = (pagination, filters) => {
    this.setState({ filteredInfo: filters });
  };

  render() {
    const { Content } = Layout;
    const { Panel } = Collapse;

    const { search, orderFormVisible, form, providerId: selectedProviderId } = this.state;
    const {
      getProviders,
      getUsersSupports,
      getOrderProblems,
      getOrdersPaginated,
      isGetOrdersPaginatedOnRequest,
      isGetPaymentStatusOnRequest,
      isUpdateOrderOnRequest,
      getPaymentStatus,
      getUser,
      getDefaultCoinValue,
      getDistributors,
    } = this.props;
    const { type, id } = form;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.orders.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
          <Panel header={<strong>{I18n.t('routes.panel.orders.advancedFilters.title')}</strong>} key="1">
            <Row>
              <AdvancedInput
                style={{ width: 240, marginRight: 10 }}
                value={search}
                onChange={(val) => this.fieldChange('search', val)}
                placeholder={I18n.t('shared.typeToContinue')}
                onPressEnter={this.handleSearch}
              />
              <Popover placement="topLeft" content={I18n.t('routes.panel.orders.advancedFilters.searchFieldTitle')}>
                <QuestionCircleFilled style={{ fontSize: 20, color: '#000000', marginTop: 5 }} />
              </Popover>
            </Row>

            {getProviders?.length && (
              <AdvancedSelect
                allowClear
                showSearch
                selectStyle={{ width: 240, padding: 0 }}
                value={selectedProviderId}
                onClear={() => this.onChangeAdvancedFilterProviderSelector()}
                onChange={(providerId) => this.onChangeAdvancedFilterProviderSelector(providerId)}
                placeholder={I18n.t('header.providerSelectPlaceholder')}
                options={getProviders.map((provider) => ({
                  id: provider.id,
                  name: provider.name,
                }))}
              />
            )}

            <Row>
              <AdvancedButton
                type="default"
                text={I18n.t('routes.panel.orders.advancedFilters.clearButtonText')}
                onClick={this.onCleanAdvancedFilters}
              />
              <AdvancedButton
                style={{ marginLeft: 10 }}
                text={I18n.t('routes.panel.orders.advancedFilters.filterButtonText')}
                iconLeft={<SearchOutlined />}
                onClick={this.handleSearch}
              />
            </Row>
          </Panel>
        </Collapse>

        <Content className="panel__layout__content panel__layout__content--content-data orders-panel">
          <AdvancedDataTable
            refresh={this.getOrders}
            getMethod={(parameters) => this.getOrders(parameters)}
            data={getOrdersPaginated}
            loading={isGetOrdersPaginatedOnRequest || isGetPaymentStatusOnRequest || isUpdateOrderOnRequest}
            ref={this.dataTableRef}
            onChange={this.handleChange}
            container="orders"
            columns={
              [UserTypes.ADMIN, UserTypes.SUPPORT].includes(getUser.type)
                ? OrderColumnsSupporters(
                    this.state,
                    this.onActionsClick,
                    getUsersSupports,
                    getOrderProblems,
                    getPaymentStatus,
                    getUser,
                    getDefaultCoinValue,
                    getDistributors,
                  )
                : OrderColumnsProviders(
                    this.state,
                    this.onActionsClick,
                    getUsersSupports,
                    getOrderProblems,
                    getPaymentStatus,
                    getUser,
                    getDefaultCoinValue,
                    getDistributors,
                  )
            }
          />
        </Content>

        <OrderForm
          id={id}
          type={type}
          visible={orderFormVisible}
          disabled={!hasAccess([accessTypes.ORDERS], [accessActionTypes.UPDATE])}
          onCloseForm={(refresh) => {
            this.onOrderFormStateChange(false);
            if (refresh) {
              this.getOrders();
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getProviders: AuthSelectors.getProviders(state),
  isUpdateOrderOnRequest: OrderSelectors.isUpdateOrderOnRequest(state),
  getOrdersPaginated: OrderSelectors.getOrdersPaginated(state),
  isGetOrdersPaginatedOnRequest: OrderSelectors.isGetOrdersPaginatedOnRequest(state),
  isDeleteOrderOnRequest: OrderSelectors.isDeleteOrderOnRequest(state),
  getUsersSupports: UserSelectors.getUsersSupports(state),
  getOrderProblems: OrderSelectors.getOrderProblems(state),
  getPaymentStatus: PaymentSelectors.getPaymentStatus(state),
  isGetPaymentStatusOnRequest: PaymentSelectors.isGetPaymentStatusOnRequest(state),
  getUser: AuthSelectors.getUser(state),
  getDefaultCoinValue: SettingSelectors.getDefaultCoinValue(state),
  getDistributors: DistributorSelectors.getDistributors(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetOrdersPaginated: (parameters) => dispatch(OrderActions.getOrdersPaginated(parameters)),
  onDeleteOrder: (id) => dispatch(OrderActions.deleteOrder(id)),
  onGetPayments: () => dispatch(PaymentActions.getPayments()),
  onGetDistributors: () => dispatch(DistributorActions.getDistributors()),
  onGetOrderProblems: () => dispatch(OrderActions.getOrderProblems()),
  onGetPaymentStatus: () => dispatch(PaymentActions.getPaymentStatus()),
  onGetUsersSupport: () => dispatch(UserActions.getUsersSupport()),
  onUpdateOrderShipmentStatus: (id, type) => dispatch(ShipmentActions.updateOrderShipmentStatus(id, type)),
  onGetOrderShipmentLabel: (id) => dispatch(ShipmentActions.getOrderShipmentLabel(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
