import store from '../redux';

export const hasAccess = (routeAccesses, accessFeatures) => {
  let response = false;

  const { access } = store.store.getState();

  if (access.userAccessObtained) {
    access.userAccess?.forEach((o) => {
      routeAccesses.forEach((routeAccess) => {
        accessFeatures.forEach((accessFeature) => {
          if (o.accessFeatureName === routeAccess && !!o[accessFeature]) {
            response = true;
          }
        });
      });
    });
  } else {
    response = false;
  }

  return response;
};

export const getUserDashboards = () => {
  let response = [];

  const { access } = store.store.getState();

  if (access.userAccessObtained) {
    response = access.userAccess.filter((o) => o.accessFeatureName.indexOf('dashboard_') >= 0) || [];
  }

  if (response && response.length > 0) {
    return response.sort((a, b) => {
      if (a.accessFeatureDescription > b.accessFeatureDescription) {
        return 1;
      }
      if (a.accessFeatureDescription < b.accessFeatureDescription) {
        return -1;
      }

      return 0;
    });
  }

  return response;
};

export const getDashboardById = (accessFeatureId) => {
  return getUserDashboards().find((o) => o.accessFeatureId === parseInt(accessFeatureId, 10));
};
