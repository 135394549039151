import CustomerRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/customer/customerRequestsV1';
import ProviderPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/provider/panel/providerPanelRequestsV1';
import FileUtils from '../../utils/file';

export const ACTION_GET_CUSTOMERS_PAGINATED = 'ACTION_GET_CUSTOMERS_PAGINATED:customer';
export const ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST:customer';
export const ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST = 'ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST:customer';
export const ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST = 'ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST:customer';

export const getCustomersPaginated = (params) => async (dispatch) => {
  try {
    params = {
      ...params,
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
      order: params?.order || 'DESC',
      column: params?.column || 'createdAt',
    };
    dispatch({ type: ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST, payload: true });
    const customers = await CustomerRequestsV1.getAll(params);
    dispatch({ type: ACTION_GET_CUSTOMERS_PAGINATED, payload: customers });
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_CUSTOMERS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const createCustomer = (providerId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST, payload: true });
    await ProviderPanelRequestsV1.createProviderCustomer(providerId, data);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_CREATE_CUSTOMERS_IS_ON_REQUEST, payload: false });
  }
};

export const uploadCustomers = (providerId, file) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST, payload: true });
    const filesForUpload = await FileUtils.filesToUploadPayloadAsync(file);
    const payloadClean = FileUtils.payloadCsvEmails(filesForUpload);
    await ProviderPanelRequestsV1.createProviderCustomer(providerId, payloadClean);
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPLOAD_CUSTOMERS_IS_ON_REQUEST, payload: false });
  }
};
