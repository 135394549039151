import React from 'react';
import { Translate } from 'react-redux-i18n';
import { OrderDeliveryType } from './order_delivery_types';

export const OrderStatus = {
  PENDING: 'pending',
  WAITING_DELIVERY: 'waiting_delivery',
  ON_CARRIAGE: 'on_carriage',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  WAITING_FREIGHT_VALUE: 'waiting_freight_value',
  WAITING_FREIGHT_CONFIRMATION: 'waiting_freight_confirmation',
  WAITING_DELIVERY_ON_PROVIDER: 'waiting_delivery_on_provider',
};

const {
  PENDING,
  WAITING_DELIVERY,
  ON_CARRIAGE,
  DELIVERED,
  CANCELED,
  REJECTED,
  WAITING_FREIGHT_CONFIRMATION,
  WAITING_FREIGHT_VALUE,
  WAITING_DELIVERY_ON_PROVIDER,
} = OrderStatus;

export function isDeliveryCodeAllowed(status) {
  return [DELIVERED, ON_CARRIAGE, WAITING_DELIVERY].includes(status);
}

export const GetOrderStatusArray = [
  { id: PENDING, name: <Translate value="enum.orderStatus.pending" /> },
  { id: WAITING_DELIVERY, name: <Translate value="enum.orderStatus.waitingDelivery" /> },
  { id: ON_CARRIAGE, name: <Translate value="enum.orderStatus.onCarriage" /> },
  { id: DELIVERED, name: <Translate value="enum.orderStatus.delivered" /> },
  { id: CANCELED, name: <Translate value="enum.orderStatus.canceled" /> },
  { id: REJECTED, name: <Translate value="enum.orderStatus.rejected" /> },
  { id: WAITING_FREIGHT_VALUE, name: <Translate value="enum.orderStatus.waitingFreightValue" /> },
  {
    id: WAITING_FREIGHT_CONFIRMATION,
    name: <Translate value="enum.orderStatus.waitingFreightConfirmation" />,
  },
  { id: WAITING_DELIVERY_ON_PROVIDER, name: <Translate value="enum.orderStatus.waitingWithdrawal" /> },
];

export const GetOrderStatusFilters = GetOrderStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

// eslint-disable-next-line react/function-component-definition
export const GetOrderStatusArrayName = ({ status, deliveryType }) => [
  { id: PENDING, name: <Translate value="enum.orderStatus.pending" /> },
  {
    id: WAITING_DELIVERY,
    name: (
      <Translate
        value={
          status === OrderStatus.WAITING_DELIVERY && deliveryType === OrderDeliveryType.ON_PROVIDER
            ? 'enum.orderStatus.waitingWithdrawal'
            : 'enum.orderStatus.waitingDelivery'
        }
      />
    ),
  },
  { id: ON_CARRIAGE, name: <Translate value="enum.orderStatus.onCarriage" /> },
  { id: DELIVERED, name: <Translate value="enum.orderStatus.delivered" /> },
  { id: CANCELED, name: <Translate value="enum.orderStatus.canceled" /> },
  { id: REJECTED, name: <Translate value="enum.orderStatus.rejected" /> },
  { id: WAITING_FREIGHT_VALUE, name: <Translate value="enum.orderStatus.waitingFreightValue" /> },
  {
    id: WAITING_FREIGHT_CONFIRMATION,
    name: <Translate value="enum.orderStatus.waitingFreightConfirmation" />,
  },
];

export const GetOrderStatusName = (order) => {
  return GetOrderStatusArrayName(order).find((o) => o.id === order.status)?.name;
};
