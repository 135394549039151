import styled from 'styled-components';
import { Tag } from 'antd';

const { CheckableTag } = Tag;
export const CheckableTagStyled = styled(CheckableTag)`
  color: black;
  border: 1px solid lightgray;
  border-radius: 20px;
  box-shadow: 1px 1px 0px 0px lightgray;
  margin-top: 5px;
`;
