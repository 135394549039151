import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from '@reach/router';
import { Layout, Breadcrumb, Row, Popover, Collapse } from 'antd';
import { DashboardFilled, QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { GetUserTypeArray, GetUserTypeName, UserTypes } from '../../../../app/enum/user_types';

import { useReduxState } from '../../../../app/hooks/useReduxState';
import { AccessFeatureUsersActions, UserActions, AccessFeatureActions } from '../../../../app/redux/actions';
import { ACTION_GET_ACCESS_FEATURE_BY_ID } from '../../../../app/redux/actions/accessFeature';
import { humanizeDate } from '../../../../app/utils/date';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedCheckbox from '../../../../components/shared/AdvancedCheckbox';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import PaginatedDatatable from '../../../../components/shared/PaginatedDatatable/PaginatedDatatable';
import { hasAccess } from '../../../../app/services/access';
import { accessTypes } from '../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../app/enum/access_action_types';

const { Content } = Layout;
const { Panel } = Collapse;

const defaultFields = {
  search: '',
  onlyAssociated: false,
};

function AccessFeaturesUsers() {
  const { accessFeatureId } = useParams();
  const dispatch = useDispatch();
  const { accessFeatureUser, user, accessFeature } = useReduxState();
  const [advancedFilter, setAdvancedFilter] = useState(defaultFields);
  const [advancedFilterTypes, setAdvancedFilterTypes] = useState(GetUserTypeArray.map((o) => o.id));
  const datatableRef = useRef();

  const onChangeAdvancedFilterTypes = (type, value) => {
    const payload = [...advancedFilterTypes];

    if (!value) {
      const typeIndex = payload.findIndex((o) => o === type);

      if (typeIndex >= 0) {
        payload.splice(typeIndex, 1);
      }
    } else {
      payload.push(type);
    }

    setAdvancedFilterTypes(payload);
  };

  const isActiveUserTypeFilter = (type) => {
    return advancedFilterTypes.find((o) => o === type);
  };

  const getAccessFeatureUsers = () => {
    dispatch(
      AccessFeatureUsersActions.getAccessFeatureUsersPaginated({
        ...advancedFilter,
        accessFeatureId,
        limit: Number.MAX_SAFE_INTEGER,
      }),
    );
  };

  useEffect(() => {
    if (accessFeatureId) {
      dispatch(AccessFeatureActions.getAccessFeatureById(accessFeatureId));
      getAccessFeatureUsers();
    }
  }, [accessFeatureId]);

  const getUsersPaginated = (page) => {
    const payload = {
      ...advancedFilter,
      page,
    };

    if (advancedFilterTypes?.length) {
      payload.type = advancedFilterTypes;
    }

    dispatch(UserActions.getUsersPaginated(payload));
  };

  const onSearchAdvancedFilters = () => {
    datatableRef.current.reset();
    getAccessFeatureUsers();
  };

  useEffect(() => {
    if (accessFeatureUser.accessFeaturesUsersPaginatedObtained) {
      getUsersPaginated(0);
    }
  }, [accessFeatureUser.accessFeaturesUsersPaginatedObtained]);

  const onSelectUser = ({ id }) => {
    const foundUser = accessFeatureUser.accessFeaturesUsersPaginated?.rows?.find((o) => o.userId === id);
    let request = AccessFeatureUsersActions.createUserAndAccessFeaturesAssociation;

    if (foundUser) {
      request = AccessFeatureUsersActions.removeUserAndAccessFeaturesAssociation;
    }

    dispatch(request({ accessFeatureId, userId: id, read: true }, () => getAccessFeatureUsers()));
  };

  const onChangeAdvancedFilters = (name, value) => {
    setAdvancedFilter((otherFields) => ({ ...otherFields, [name]: value }));
  };

  const cleanAdvancedFilters = () => {
    datatableRef.current.reset();
    setAdvancedFilter(defaultFields);
  };

  const formatTablePayload = () => {
    if (!advancedFilter.onlyAssociated) {
      return user.usersPaginated;
    }
    return {
      rows: accessFeatureUser.accessFeaturesUsersPaginated?.rows?.map((o) => o.user),
    };
  };

  useEffect(() => {
    return () => dispatch({ type: ACTION_GET_ACCESS_FEATURE_BY_ID, payload: null });
  }, []);

  const updateFeatureAccess = (userId, key, value) => {
    let payload = {
      userId: parseInt(userId, 10),
      accessFeatureId: parseInt(accessFeatureId, 10),
      [key]: !!value,
    };

    if (!key) {
      payload = {
        ...payload,
        read: true,
        create: true,
        update: true,
        remove: true,
      };
    }

    dispatch(AccessFeatureUsersActions.updateUserAndAccessFeaturesAssociation(payload, getAccessFeatureUsers));
  };

  const getUserFeatureData = (userId) => {
    return accessFeatureUser.accessFeaturesUsersPaginated?.rows?.find((o) => o.userId === userId);
  };

  const onSelectMultiple = (_param, users) => {
    if (users && users.length) {
      dispatch(
        AccessFeatureUsersActions.createUserAndAccessFeaturesAssociationMultiple(
          { accessFeatureId, accessFeatureUserIds: users.filter((o) => !!o).map((o) => o.id), read: true },
          getAccessFeatureUsers,
        ),
      );
    } else {
      dispatch(
        AccessFeatureUsersActions.removeUserAndAccessFeaturesAssociationMultiple(
          {
            accessFeatureId,
            accessFeatureUserIds: formatTablePayload()
              ?.rows?.filter((o) => !!o)
              .map((o) => o.id),
          },
          getAccessFeatureUsers,
        ),
      );
    }
  };

  return (
    <>
      <Content className="panel__layout__content panel__layout__content--breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <DashboardFilled /> <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={I18n.t('routes.panel.administration.accessFeature.url')}>
              <span>{I18n.t('routes.panel.administration.accessFeature.pageTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{accessFeature.accessFeatureById?.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.administration.accessFeaturesUsers.pageTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Collapse className="panel__layout__content panel__layout__content--advanced-filter" defaultActiveKey={['1']}>
        <Panel
          header={<strong>{I18n.t('routes.panel.administration.accessFeaturesUsers.advancedFilters.title')}</strong>}
          key="1"
        >
          <Row align="middle">
            <AdvancedInput
              style={{ width: 240, padding: 0, marginRight: 10 }}
              value={advancedFilter.search}
              onChange={(val) => onChangeAdvancedFilters('search', val)}
              placeholder={I18n.t('shared.typeToContinue')}
            />
            <Popover
              placement="topLeft"
              content={I18n.t('routes.panel.administration.accessFeaturesUsers.advancedFilters.searchFieldTitle')}
            >
              <QuestionCircleFilled style={{ fontSize: 20, color: '#000000' }} />
            </Popover>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <AdvancedCheckbox
              label={I18n.t('routes.panel.administration.accessFeaturesUsers.advancedFilters.onlyAssociated.label')}
              onChange={(val) => onChangeAdvancedFilters('onlyAssociated', val)}
              value={advancedFilter.onlyAssociated}
            />
          </Row>
          <div className="panel__layout__content--advanced-filter__label">
            <strong>{I18n.t('routes.panel.administration.accessGroupsUsers.advancedFilters.type.label')}</strong>
          </div>
          <Row className="panel__layout__content--advanced-filter__item">
            <div className="panel__layout__content--advanced-filter__checks">
              {GetUserTypeArray.filter((o) => o.id !== UserTypes.ANYUSER).map((userType, userTypeIndex) => (
                <div className="panel__layout__content--advanced-filter__checks__item" key={userTypeIndex.toString()}>
                  <AdvancedCheckbox
                    label={userType.name}
                    onChange={(val) => onChangeAdvancedFilterTypes(userType.id, val)}
                    value={isActiveUserTypeFilter(userType.id)}
                  />
                </div>
              ))}
            </div>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <AdvancedButton
              type="default"
              text={I18n.t('routes.panel.administration.accessFeaturesUsers.advancedFilters.clearButtonText')}
              onClick={cleanAdvancedFilters}
            />
            <AdvancedButton
              style={{ marginLeft: 10 }}
              text={I18n.t('routes.panel.administration.accessFeaturesUsers.advancedFilters.filterButtonText')}
              iconLeft={<SearchOutlined />}
              onClick={onSearchAdvancedFilters}
            />
          </Row>
        </Panel>
      </Collapse>

      <Content className="panel__layout__content panel__layout__content--content-data">
        {accessFeature.accessFeatureById?.name && (
          <div className="panel__layout__content__title">
            {I18n.t('routes.panel.administration.accessFeaturesUsers.tableTitle', {
              accessFeature: accessFeature.accessFeatureById?.name,
            })}
          </div>
        )}

        <PaginatedDatatable
          dataSource={formatTablePayload()}
          getMethod={getUsersPaginated}
          onChangeSelection={onSelectUser}
          autoInitalize={false}
          loading={user.isGetUsersPaginatedOnRequest || accessFeatureUser.getaccessFeaturesUsersPaginatedIsOnRequest}
          selectedIds={[...(accessFeatureUser.accessFeaturesUsersPaginated?.rows?.map((o) => o.user?.id) || [])]}
          onSelectAll={onSelectMultiple}
          pageSize={advancedFilter.onlyAssociated ? Number.MAX_SAFE_INTEGER : 10}
          ref={datatableRef}
          disabled={!hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE])}
          columns={[
            {
              dataIndex: 'name',
              title: 'Nome',
              fixed: !isMobile ? 'left' : false,
            },
            {
              dataIndex: 'email',
              title: 'E-mail',
            },
            {
              dataIndex: 'type',
              title: 'Perfil',
              render: (val) => GetUserTypeName(val),
            },
            {
              dataIndex: 'read',
              title: 'Leitura',
              align: 'center',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getUserFeatureData(row.id)?.read || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'read', value)}
                  disabled={
                    !getUserFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'create',
              title: 'Criação',
              align: 'center',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getUserFeatureData(row.id)?.create || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'create', value)}
                  disabled={
                    !getUserFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'update',
              title: 'Atualização',
              align: 'center',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getUserFeatureData(row.id)?.update || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'update', value)}
                  disabled={
                    !getUserFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'remove',
              title: 'Remoção',
              align: 'center',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={getUserFeatureData(row.id)?.remove || false}
                  onChange={(value) => updateFeatureAccess(row.id, 'remove', value)}
                  disabled={
                    !getUserFeatureData(row.id) ||
                    !hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE])
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'all',
              title: 'Todos',
              align: 'center',
              render: (val, row) => (
                <AdvancedCheckbox
                  value={
                    getUserFeatureData(row.id)?.read &&
                    getUserFeatureData(row.id)?.create &&
                    getUserFeatureData(row.id)?.update &&
                    getUserFeatureData(row.id)?.remove
                  }
                  onChange={(value) => updateFeatureAccess(row.id, null, value)}
                  disabled={
                    !hasAccess([accessTypes.ADMIN_ACCESS_FEATURES_USERS], [accessActionTypes.UPDATE]) ||
                    !getUserFeatureData(row.id) ||
                    (getUserFeatureData(row.id)?.read &&
                      getUserFeatureData(row.id)?.create &&
                      getUserFeatureData(row.id)?.update &&
                      getUserFeatureData(row.id)?.remove)
                  }
                  centered
                />
              ),
            },
            {
              dataIndex: 'createdAt',
              title: 'Dt. associação',
              render: (val) => humanizeDate(val, 'DD/MM/YYYY HH:mm'),
            },
          ]}
        />
      </Content>
    </>
  );
}

export default AccessFeaturesUsers;
