export default {
  apiKey: 'AIzaSyC6xjBjxRBbV-N8GG61s2wfnJKb9RMUIB8',
  authDomain: 'tribal-booth-248221.firebaseapp.com',
  databaseURL: 'https://tribal-booth-248221.firebaseio.com',
  projectId: 'tribal-booth-248221',
  storageBucket: 'tribal-booth-248221.appspot.com',
  messagingSenderId: '551651242164',
  appId: '1:551651242164:web:11e480060d55f8fa3527e1',
  measurementId: 'G-L0JSD1XZQY',
};
