import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProviderStatus = {
  DISABLE: 'disable',
  APPROVED: 'approved',
  WAITING_VALIDATION_DATA: 'waiting_validation_data',
};

export const GetProviderStatusArray = [
  { id: ProviderStatus.DISABLE, name: <Translate value="enum.providerStatus.disable" /> },
  { id: ProviderStatus.APPROVED, name: <Translate value="enum.providerStatus.approved" /> },
  { id: ProviderStatus.WAITING_VALIDATION_DATA, name: <Translate value="enum.providerStatus.waitingValidationData" /> },
];

export const GetProviderStatusFilters = GetProviderStatusArray.map((status) => ({
  value: status.id,
  text: status.name,
}));

export const GetProvidersStatusName = (value) => GetProviderStatusArray.find((o) => o.id === value).name;
