import { notification } from 'antd';

import { I18n } from 'react-redux-i18n';
import UserRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/user/userRequestsV1';
import UserPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/user/panel/userPanelRequestsV1';

import { AuthActions } from '.';

import CodeTypes from '../../enum/code_types';
import PlatformTypes from '../../enum/platform_types';

import AuthService from '../../services/auth';
import { getToken } from '../../services/firebase';
import DocumentTypes from '../../enum/document_types';
import { UserTypes } from '../../enum/user_types';

export const ACTION_GET_USERS_PAGINATED = 'ACTION_GET_USERS_PAGINATED:user';
export const ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST:user';

export const ACTION_GET_USER_IS_ON_REQUEST = 'ACTION_GET_USER_IS_ON_REQUEST:user';
export const ACTION_CREATE_USER_IS_ON_REQUEST = 'ACTION_CREATE_USER_IS_ON_REQUEST:user';
export const ACTION_UPDATE_USER_IS_ON_REQUEST = 'ACTION_UPDATE_USER_IS_ON_REQUEST:user';
export const ACTION_DELETE_USER_IS_ON_REQUEST = 'ACTION_DELETE_USER_IS_ON_REQUEST:user';
export const ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST = 'ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST:user';
export const ACTION_CREATE_CODE_IS_ON_REQUEST = 'ACTION_CREATE_CODE_IS_ON_REQUEST:user';
export const ACTION_VALIDATE_CODE_IS_ON_REQUEST = 'ACTION_VALIDATE_CODE_IS_ON_REQUEST:user';
export const ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST = 'ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST:user';

export const ACTION_GET_USER_ORIGIN_IS_ON_REQUEST = 'ACTION_GET_USER_ORIGIN_IS_ON_REQUEST:user';
export const ACTION_GET_USER_ORIGIN = 'ACTION_GET_USER_ORIGIN:user';

export const ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST = 'ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST:user';
export const ACTION_GET_USER_COMPANY_TYPE = 'ACTION_GET_USER_COMPANY_TYPE:user';

export const ACTION_GET_USER_STATUS_IS_ON_REQUEST = 'ACTION_GET_USER_STATUS_IS_ON_REQUEST:user';
export const ACTION_GET_USER_STATUS = 'ACTION_GET_USER_STATUS:user';

export const ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST = 'ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST:user';
export const ACTION_GET_USERS_SUPPORT = 'ACTION_GET_USERS_SUPPORT:user';
export const ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST = 'ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST:user';
export const ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST = 'ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST:user';

export const ACTION_GET_USER_SEARCH_IS_ON_REQUEST = 'ACTION_GET_USER_SEARCH_IS_ON_REQUEST:user';

export const ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST = 'ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST';

export const ACTION_SET_SIGNUP_DATA = 'ACTION_SET_SIGNUP_DATA:user';

export const getUsersPaginated = (parameters) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST, payload: true });
    parameters = {
      ...parameters,
      limit: parameters?.limit || 10,
      page: parameters?.page ? parameters.page - 1 : 0,
    };

    const users = await UserPanelRequestsV1.getAll(parameters);

    dispatch({
      type: ACTION_GET_USERS_PAGINATED,
      payload: users,
    });
  } finally {
    dispatch({ type: ACTION_GET_USERS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const getUserSearch = (search) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_SEARCH_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.getAll(search);
  } finally {
    dispatch({ type: ACTION_GET_USER_SEARCH_IS_ON_REQUEST, payload: false });
  }
};

export function sendUserPushToken() {
  return async () => {
    try {
      const platform = PlatformTypes.WEB;
      const [pushToken, uniqueIdApp, isAuthenticated] = await Promise.all([
        getToken(),
        AuthService.createUniqueIdApp(),
        AuthService.isAuthenticated(),
      ]);
      if (isAuthenticated && pushToken)
        await UserRequestsV1.createPushToken({
          deviceId: uniqueIdApp,
          pushToken,
          platform,
        });
    } catch (err) {
      /* empty */
    }
  };
}

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_IS_ON_REQUEST, payload: true });
    if (id) return await UserPanelRequestsV1.getById(id);
  } finally {
    dispatch({ type: ACTION_GET_USER_IS_ON_REQUEST, payload: false });
  }
};

export const getUserPreferences = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST, payload: true });
    if (id) return await UserPanelRequestsV1.getPreferences(id);
  } finally {
    dispatch({ type: ACTION_GET_USER_PREFERENCES_IS_ON_REQUEST, payload: false });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_USER_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.remove(id);
  } finally {
    dispatch({ type: ACTION_DELETE_USER_IS_ON_REQUEST, payload: false });
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_USER_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.update(id, data);
  } finally {
    dispatch({ type: ACTION_UPDATE_USER_IS_ON_REQUEST, payload: false });
  }
};

export const createUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.create(data);
  } finally {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: false });
  }
};

export const signUpCreateUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: true });
    return await UserRequestsV1.create(data);
  } finally {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: false });
  }
};

export const signUpUpdateUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: true });
    return await UserRequestsV1.update(data.id, data);
  } finally {
    dispatch({ type: ACTION_CREATE_USER_IS_ON_REQUEST, payload: false });
  }
};

export function createCodeValidation({ name, email }) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_CREATE_CODE_IS_ON_REQUEST, payload: true });
      const success = await UserRequestsV1.sendConfirmationCode({
        name,
        type: CodeTypes.EMAIL,
        email,
      });

      if (success) {
        notification.success({
          message: I18n.t('forms.user.createCodeValidation.notifications.success.message'),
          description: I18n.t('forms.user.createCodeValidation.notifications.success.description', {
            inbox: email,
          }),
        });
      }
    } finally {
      dispatch({ type: ACTION_CREATE_CODE_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createUserAndCodeValidation(user) {
  return async (dispatch) => {
    if (user.preferences?.length) {
      let preferences = [];

      user.preferences.forEach((o) => {
        preferences = [...preferences, ...o.preferences];
      });

      user = {
        ...user,
        preferences,
      };
    }

    try {
      const { email, password } = user;
      dispatch({
        type: ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST,
        payload: true,
      });
      const success = await dispatch(signUpCreateUser(user));
      if (success) {
        await dispatch(AuthActions.authenticate(email, password));
        await dispatch(createCodeValidation(user));
        return true;
      }
    } finally {
      dispatch({
        type: ACTION_CREATE_USER_AND_CODE_IS_ON_REQUEST,
        payload: false,
      });
    }
  };
}

export function updateUserAndCodeValidation(user) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST,
        payload: true,
      });
      const result = await dispatch(signUpUpdateUser(user));
      return !!result;
    } finally {
      dispatch({
        type: ACTION_UPDATE_USER_AND_CODE_IS_ON_REQUEST,
        payload: false,
      });
    }
  };
}

export function validateCode(code) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_VALIDATE_CODE_IS_ON_REQUEST, payload: true });

      const success = await UserRequestsV1.confirmTheCode({
        code,
        type: CodeTypes.EMAIL,
      });

      if (success) {
        dispatch(AuthActions.getUserDetails());
        return true;
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_VALIDATE_CODE_IS_ON_REQUEST, payload: false });
    }
  };
}

export const getUserOrigins = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_ORIGIN_IS_ON_REQUEST, payload: true });
    const origins = await UserRequestsV1.getOrigins();
    dispatch({ type: ACTION_GET_USER_ORIGIN, payload: origins });
  } finally {
    dispatch({ type: ACTION_GET_USER_ORIGIN_IS_ON_REQUEST, payload: false });
  }
};

export const getUserCompanyTypes = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST, payload: true });
    const companyTypes = await UserRequestsV1.getCompanyTypes();
    dispatch({ type: ACTION_GET_USER_COMPANY_TYPE, payload: companyTypes });
  } finally {
    dispatch({ type: ACTION_GET_USER_COMPANY_TYPE_IS_ON_REQUEST, payload: false });
  }
};

export const getUserSupportStatus = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_STATUS_IS_ON_REQUEST, payload: true });
    const supportTypes = await UserRequestsV1.getSupportStatus();
    dispatch({ type: ACTION_GET_USER_STATUS, payload: supportTypes });
  } finally {
    dispatch({ type: ACTION_GET_USER_STATUS_IS_ON_REQUEST, payload: false });
  }
};

export const getUsersSupport = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST, payload: true });
    const { rows } = await UserPanelRequestsV1.getAdministrators();
    dispatch({ type: ACTION_GET_USERS_SUPPORT, payload: rows });
  } finally {
    dispatch({ type: ACTION_GET_USERS_SUPPORT_IS_ON_REQUEST, payload: false });
  }
};

export const getUserPayments = (userId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.getUserPayments(userId);
  } finally {
    dispatch({ type: ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST, payload: false });
  }
};

export const createUserPayments = (userId, payment) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST, payload: true });
    return await UserPanelRequestsV1.createUserPayment(userId, payment);
  } finally {
    dispatch({ type: ACTION_GET_USER_PAYMENTS_IS_ON_REQUEST, payload: false });
  }
};

export const setSignupUser = (key, value) => (dispatch, getState) => {
  dispatch({
    type: ACTION_SET_SIGNUP_DATA,
    payload: { ...getState().user.signupUserData, [key]: value },
  });
};

export const clearSignupUser = () => (dispatch) => {
  dispatch({
    type: ACTION_SET_SIGNUP_DATA,
    payload: { documentType: DocumentTypes.CNPJ, type: UserTypes.USER_LEGAL },
  });
};

export const passwordRecovery = (email) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST, payload: true });
    return await UserRequestsV1.passwordRecovery({ email });
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST, payload: false });
  }
};

export const passwordChange = (data, isRecovery) => async (dispatch) => {
  dispatch({ type: ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST, payload: true });
  try {
    if (data) {
      let success;

      if (isRecovery) {
        success = await UserRequestsV1.passwordRecoveryChange(data);
      } else {
        success = await UserRequestsV1.passwordChange(data);
      }

      if (success) {
        await dispatch(AuthActions.getUserDetails());

        notification.success({
          message: 'alteração de senha',
          description: 'senha alterada com sucesso, vocẽ deve utilizá-la para acessar a plataforma a partir de agora.',
        });
      }
    }
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_POST_USER_RECOVER_PASSWORD_IS_ON_REQUEST, payload: false });
  }
};
