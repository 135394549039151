import React from 'react';
import { Translate } from 'react-redux-i18n';

export const SignupSteps = {
  TypeUserStep: 'typeUserStep',
  AccessDataStep: 'accessDataStep',
  QuestionsStep: 'questionsStep',
  PersonalDataStep: 'personalDataStep',
  ConfirmationCodeStep: 'confirmationCodeStep',
};

export const GetSignupStepsArray = [
  { step: SignupSteps.TypeUserStep, title: <Translate value="routes.signup.content.steps.step1" />, key: 0 },
  { step: SignupSteps.AccessDataStep, title: <Translate value="routes.signup.content.steps.step2" />, key: 1 },
  { step: SignupSteps.QuestionsStep, title: <Translate value="routes.signup.content.steps.step3" />, key: 2 },
  { step: SignupSteps.PersonalDataStep, title: <Translate value="routes.signup.content.steps.step4" />, key: 3 },
  { step: SignupSteps.ConfirmationCodeStep, title: <Translate value="routes.signup.content.steps.step5" />, key: 4 },
];

export const GetRightCurrentStep = (currentStep, theresQuestions) => {
  const currentStepKey = GetSignupStepsArray.find((o) => o.step === currentStep)?.key;
  if (!theresQuestions && currentStepKey > 1) {
    return currentStepKey - 1;
  }
  return currentStepKey;
};

export const GetNextStep = (currentStep, increment) => {
  const nextStepKey = GetSignupStepsArray.find((o) => o.step === currentStep).key + increment;
  const found = GetSignupStepsArray.find((o) => o.key === nextStepKey).step;
  if (found) {
    return found;
  }
  return currentStep;
};

export const GetPrevStep = (currentStep, decrement) => {
  const prevStepKey = GetSignupStepsArray.find((o) => o.step === currentStep).key - decrement;
  const found = GetSignupStepsArray.find((o) => o.key === prevStepKey)?.step;

  if (found) {
    return found;
  }

  return currentStep;
};
