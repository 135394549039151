import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'antd';

// eslint-disable-next-line react/display-name
const PaginatedDatatable = forwardRef(
  (
    {
      dataSource,
      onChange: onChangeExternal,
      getMethod,
      pageSize = 10,
      autoInitalize = true,
      columns,
      loading,
      selectedIds,
      onChangeSelection,
      scroll,
      onSelectAll,
      disabled,
    },
    ref,
  ) => {
    const [currentPage, setCurrentPage] = useState(0);

    useImperativeHandle(ref, () => ({
      reset() {
        setCurrentPage(0);
        getMethod(0);
      },
    }));

    const onChange = (page) => {
      setCurrentPage(page);
      getMethod(page);

      if (onChangeExternal) {
        onChangeExternal(page);
      }
    };

    useEffect(() => {
      if (autoInitalize) {
        getMethod(0);
      }
    }, []);

    useEffect(() => {
      if (dataSource?.count || dataSource?.count === 0) {
        const maximumPage = Math.floor(dataSource.count / pageSize);
        if (currentPage > maximumPage) {
          onChange(maximumPage);
        }
      }
    }, [dataSource?.count]);

    const onChangeSelectionPrivate = (item, isNotSelected) => {
      onChangeSelection(item, isNotSelected, currentPage);
    };

    return (
      <Table
        dataSource={dataSource?.rows || []}
        scroll={scroll || { x: 900, y: 'max-content' }}
        ellipsis={false}
        bordered
        size="small"
        pagination={{
          showTotal: (totalRows, range) => `${range[0]}-${range[1]} de ${totalRows}`,
          onChange,
          pageSize,
          total: dataSource?.count || 0,
          showSizeChanger: false,
          showQuickJumper: false,
          current: currentPage || 1,
        }}
        columns={columns}
        loading={loading}
        rowKey={(record) => record?.id}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onSelect: onChangeSelectionPrivate,
          hideSelectAll: disabled,
          onSelectAll,
          getCheckboxProps: (record) => ({
            disabled,
            key: record.key,
          }),
        }}
      />
    );
  },
);

export default PaginatedDatatable;
