/* eslint-disable max-len */
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useReduxState } from '../../../../../app/hooks/useReduxState';

import OrderProductsPriceFooter from './OrderProductsPriceFooter';
import ProductsAmountSelectionList from './ProductsAmountSelectionList';

function UpdateProductsStockForm({
  updateProductsQuantity,
  order,
  orderOriginal,
  onChangeOrderItemQuantity,
  onUpdateOrderValues,
  onRemoveOrderItems,
  getCorrectItemStock,
}) {
  const { product: productSelector } = useReduxState();

  function adjustOrderItemsToStockQuantity() {
    const productsWithStockAdjusted = order.items?.map((item) => {
      const originalItem = orderOriginal?.items?.find((o) => o.productId === Number(item?.productId));

      const itemStock = productSelector?.productsStock?.find(
        (product) => Number(product.id) === Number(item?.productId),
      )?.stock;

      if (originalItem) {
        return {
          ...item,
          quantity: itemStock + Number(originalItem?.quantity),
        };
      }
      return {
        ...item,
        quantity: Number(itemStock),
      };
    });

    return onUpdateOrderValues([{ key: 'items', value: productsWithStockAdjusted }]);
  }

  return (
    <div className="order-form__products__modal-body">
      <div className="order-form__products__modal-body__content">
        <div className="order-form__products__modal-body__content__description">
          <div className="order-form__products__modal-body__content__description__container">
            <p>{I18n.t('forms.order.product.advancedModal.stockUpdate.description')}</p>

            <button
              type="button"
              className="order-form__products__modal-body__content__description__container__adjust-button"
              onClick={adjustOrderItemsToStockQuantity}
            >
              <span>{I18n.t('forms.order.product.advancedModal.stockUpdate.adjustStockButton')}</span>
            </button>
          </div>
        </div>
      </div>
      <ProductsAmountSelectionList
        order={order}
        updateProductsQuantity={updateProductsQuantity}
        onChange={onChangeOrderItemQuantity}
        onRemoveOrderItems={onRemoveOrderItems}
        getCorrectItemStock={getCorrectItemStock}
      />

      <OrderProductsPriceFooter order={order} />
    </div>
  );
}

export default UpdateProductsStockForm;
