import validator from 'validator';
import { read as xlslRead, utils as xlsxUtils, write as xlsxWrite, writeFile as xlsxWriteFile } from 'xlsx';
import { getProductProviderTranslatedProp } from '../enum/product_provider_props';
import { getProductProviderCorrectValues } from '../enum/product_provider_values';
import moment from 'moment-timezone';

function csvToJson(csv, separator = ';') {
  const lines = csv.split('\n');
  const result = [];
  let headers = lines[0].split(separator);

  headers = headers.map((o) => o.trim());

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = lines[i].split(separator);
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j].replace('\r', '');
    }
    result.push(obj);
  }

  return result;
}

async function filesToUploadPayloadAsync(fileList) {
  const filesToUpload = [];
  await Promise.all(
    fileList.map(async (file) => {
      const jsonResult = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (event) => resolve(csvToJson(event.target.result));
        reader.onerror = (event) => reject(event);
      });

      filesToUpload.push({
        file,
        jsonResult,
      });
    }),
  );

  return filesToUpload;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const payloadCsvEmails = (file) => {
  return {
    emails: file[0].jsonResult.map((item) => item.emails.toLowerCase()).filter((item) => validator.isEmail(item)),
  };
};

const xlsxToJson = (fileList) =>
  new Promise((resolve) => {
    if (fileList) {
      // eslint-disable-next-line no-promise-executor-return
      return fileList.map((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlslRead(data, { cellText: true, cellDates: false });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsxUtils.sheet_to_json(worksheet, {
            raw: false,
            dateNF: 'dd/mm/yyyy hh:mm:ss',
          });
          resolve(json);
        };
        reader.readAsArrayBuffer(file);
      });
    }
  });

const jsonToXlsx = (products, provider) => {
  const workSheet = xlsxUtils.json_to_sheet(products);
  const workBook = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(workBook, workSheet, 'products');
  xlsxWrite(workBook, { bookType: 'xlsx', type: 'binary' });
  xlsxWriteFile(workBook, `${provider}-${moment().format('DD-MM-YYYY_HH-mm')}-products-export.xlsx`);
  return workBook;
};

const createProductsParse = (products) => {
  if (products) {
    const productsArray = products.map((o, index) => {
      const productWithRowNum = { ...o, rowNum: index + 2 };
      const props = Object.keys(productWithRowNum).map((s) => getProductProviderTranslatedProp(s));
      const values = Object.values(productWithRowNum).map((s) => getProductProviderCorrectValues(s));
      const product = [props, values]
        .reduce((a, b) => a.map((v, i) => ({ [v]: b[i] })))
        .reduce((a, b) => ({ ...a, ...b }));
      return product;
    });
    return productsArray;
  }
};

const createDownloadFile = (content) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default {
  getBase64,
  filesToUploadPayloadAsync,
  payloadCsvEmails,
  xlsxToJson,
  createProductsParse,
  jsonToXlsx,
  createDownloadFile,
};
