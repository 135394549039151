import NotificationPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/notification/panel/notificationPanelRequestsV1';
import { UserTypes } from '../../enum/user_types';

export const ACTION_SEND_PUSH_IS_ON_REQUEST = 'ACTION_GET_CATEGORIES_IS_ON_REQUEST:push';

export const sendPush = (data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_SEND_PUSH_IS_ON_REQUEST, payload: true });

    const payload = {};
    const { title, body, type, users } = data;
    if (title) payload.title = title;
    if (body) payload.body = body;
    if (type && type !== UserTypes.ANYUSER) payload.type = type;
    if (users && users.length && type === UserTypes.ANYUSER) payload.users = users.map((o) => Number(o));

    const success = await NotificationPanelRequestsV1.create(payload);
    if (success) return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_SEND_PUSH_IS_ON_REQUEST, payload: false });
  }
};
