import styled from 'styled-components';
import { FaWindowClose } from 'react-icons/fa';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  height: 100%;
  min-height: 24px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);

  :active {
    border-color: rgba(0, 0, 0, 0.2);
    outline: 0;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
  }

  :focus {
    border-color: rgba(0, 0, 0, 0.2);
    outline: 0;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
  }

  :focus-visible {
    border: 1px solid #d9d9d9;
  }
`;

export const Line = styled.span`
  padding: 5px;
  align-items: center;
  display: flex;
  background-color: ${({ disabled }) => (disabled ? darken(0.05, '#fff') : '#fff')};
  :hover {
    background-color: ${({ disabled }) => (!disabled ? darken(0.05, '#fff') : 'none')};
  }
`;

export const CustomFaWindowClose = styled(FaWindowClose).attrs({
  size: 16,
})`
  margin: 5px;
  min-width: 16px;
  min-height: 16px;

  :hover {
    color: ${({ disabled }) => (!disabled ? 'red' : 'none')};
  }
`;

export const CustomSpanInside = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
`;
