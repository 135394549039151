import { Router, navigate } from '@reach/router';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';
import { initializeInstances } from 'tiffin-foods-api-client/lib/cjs/modules/instance/instance';
import { AxiosInstanceTypeEnumerator } from 'tiffin-foods-api-client/lib/cjs/modules/instance/instanceModule';
import { isLogged } from 'tiffin-foods-api-client/lib/cjs/utils/auth';
import { accessActionTypes } from '../enum/access_action_types';
import { accessTypes } from '../enum/access_types';
import AccessFeaturesUsers from '../../containers/panel/administration/accessFeaturesUsers/AccessFeaturesUsers';
import AccessGroups from '../../containers/panel/administration/accessGroups/AccessGroups';
import AccessFeatures from '../../containers/panel/administration/accessFeatures/AccessFeatures';
import AccessGroupsFeatures from '../../containers/panel/administration/accessGroupsFeatures/AccessGroupsFeatures';
import AccessGroupsUsers from '../../containers/panel/administration/accessGroupsUsers/AccessGroupsUsers';
// eslint-disable-next-line import/extensions
import PackageJson from '../../../package.json';

import {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_CHAT_URL,
  REACT_APP_API_FILE_URL,
  REACT_APP_DEFAULT_BASIC_TOKEN,
} from '../config/env.config';

import Panel from './Panel';
import Public from './Public';

import { AuthActions, SettingActions, AccessActions, ProductActions } from '../redux/actions';

import Download from '../../containers/download';
import NotFound from '../../containers/notfound';
import Forbidden from '../../containers/forbidden';

import Login from '../../containers/login';
import Signup from '../../containers/signup';
import RegisterPassword from '../../containers/registerPassword/RegisterPassword';
import ForgetPassword from '../../containers/forgetPassword/ForgetPassword';

import Chat from '../../containers/panel/chat';
import Users from '../../containers/panel/users';
import Orders from '../../containers/panel/orders';
import Products from '../../containers/panel/products';
import Customers from '../../containers/panel/customers';
import Providers from '../../containers/panel/providers';
import Dashboard from '../../containers/panel/dashboard';
import Notifications from '../../containers/panel/marketing/notifications';
import ProviderBilling from '../../containers/panel/providerBilling/ProviderBilling';
import ProviderRatings from '../../containers/panel/providerRatings/ProviderRatings';
import Tags from '../../containers/panel/administration/tags/Tags';
import Highlights from '../../containers/panel/administration/highlights/Highlights';
import HighlightProducts from '../../containers/panel/administration/highlightProducts/HighlightProducts';
import { useReduxState } from '../hooks/useReduxState';
import { UserStatus } from '../enum/user_status';
import AdvancedModal from '../../components/shared/AdvancedModal/AdvancedModal';
import UserPasswordModal from '../../components/shared/UserPasswordModal/UserPasswordModal';
import Collections from '../../containers/panel/administration/collections/Collections';

function Content() {
  const dispatch = useDispatch();
  const { auth } = useReduxState();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    console.log(`tiffins admin version: ${PackageJson.version}`);

    const initializeStuff = async () => {
      await initializeInstances({
        settings: [
          {
            apiBaseUrl: REACT_APP_API_BASE_URL,
            basicToken: REACT_APP_DEFAULT_BASIC_TOKEN,
            type: AxiosInstanceTypeEnumerator.backend,
            stringifyAllRequestsPayload: true,
          },
          {
            apiBaseUrl: REACT_APP_API_CHAT_URL,
            basicToken: REACT_APP_DEFAULT_BASIC_TOKEN,
            type: AxiosInstanceTypeEnumerator.chat,
          },
          {
            apiBaseUrl: REACT_APP_API_FILE_URL,
            basicToken: REACT_APP_DEFAULT_BASIC_TOKEN,
            type: AxiosInstanceTypeEnumerator.file,
          },
        ],
        stringifyAllRequestsPayload: true,
        storage: localStorage,
        callback: async () => {
          setInitialized(true);
          await dispatch(SettingActions.getSettings());

          if (isLogged()) {
            await dispatch(AuthActions.getUserDetails());
            await dispatch(AccessActions.getUserAccess());
          }
        },
        onErrorCallback: (error) => {
          if (error?.details) {
            dispatch(ProductActions.setUploadProductsErrors(error));
          }
          notification.error({
            message: I18n.t('routes.panel.dashboard.errors.message'),
            description: error.message,
          });
        },
        expiredRefreshTokenCallback: () => {
          dispatch(AuthActions.logout());
          navigate(I18n.t('routes.login.url'));
        },
      });
    };
    initializeStuff();
  }, []);

  return initialized ? (
    <>
      <AdvancedModal
        closable={false}
        visible={auth?.user?.status === UserStatus.RESET_PASSWORD}
        bodyStyle={{ padding: 0 }}
      >
        <UserPasswordModal />
      </AdvancedModal>
      <Router>
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.signup.url')}
          container={<Signup />}
          title={`${I18n.t('routes.signup.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forbidden.url')}
          container={<Forbidden />}
          title={`${I18n.t('routes.forbidden.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.downloadScreen.url')}
          container={<Download />}
          title={`${I18n.t('routes.downloadScreen.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={`${I18n.t('routes.signup.url')}/codevalidation`}
          container={<Signup codeValidation />}
          title={`${I18n.t('routes.forbidden.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.registerPassword.url')}
          container={<RegisterPassword />}
          title={`${I18n.t('routes.registerPassword.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forgetPassword.url')}
          container={<ForgetPassword />}
          title={`${I18n.t('routes.forgetPassword.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.users.url')}
          container={<Users />}
          title={`${I18n.t('routes.panel.users.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.USERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.orders.url')}
          container={<Orders />}
          title={`${I18n.t('routes.panel.orders.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ORDERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.products.url')}
          container={<Products />}
          title={`${I18n.t('routes.panel.products.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.PRODUCTS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.providers.url')}
          container={<Providers />}
          title={`${I18n.t('routes.panel.providers.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.PROVIDERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.chats.url')}
          container={<Chat />}
          disableChatContainer
          title={`${I18n.t('routes.panel.chats.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.CHAT]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.providerBilling.url')}
          container={<ProviderBilling />}
          disableChatContainer
          title={`${I18n.t('routes.panel.providerBilling.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.PROVIDER_BILLING]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.providerRatings.url')}
          container={<ProviderRatings />}
          title={`${I18n.t('routes.panel.providerRatings.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.PROVIDER_RATINGS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.marketing.notifications.url')}
          container={<Notifications />}
          title={`${I18n.t('routes.panel.marketing.notifications.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.PUSH_NOTIFICATION]}
          routeAccessFeatures={[accessActionTypes.CREATE]}
        />
        <Panel
          path={I18n.t('routes.panel.customers.url')}
          container={<Customers />}
          title={`${I18n.t('routes.panel.customers.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.CUSTOMERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.administration.tags.url')}
          container={<Tags />}
          title={`${I18n.t('routes.panel.administration.tags.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_TAGS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.administration.accessGroups.url')}
          container={<AccessGroups />}
          title={`${I18n.t('routes.panel.administration.accessGroups.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_ACCESS_GROUPS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.administration.accessFeature.url')}
          container={<AccessFeatures />}
          title={`${I18n.t('routes.panel.administration.accessFeature.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_ACCESS_FEATURES]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.accessGroupsUsers.url')}/:accessGroupId`}
          container={<AccessGroupsUsers />}
          title={`${I18n.t('routes.panel.administration.accessGroupsUsers.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_ACCESS_GROUPS_USERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.accessFeaturesUsers.url')}/:accessFeatureId`}
          container={<AccessFeaturesUsers />}
          title={`${I18n.t('routes.panel.administration.accessFeaturesUsers.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
          routeAccess={[accessTypes.ADMIN_ACCESS_FEATURES_USERS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.accessGroupsFeatures.url')}/:accessGroupId`}
          container={<AccessGroupsFeatures />}
          title={`${I18n.t('routes.panel.administration.accessGroupsFeatures.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
          routeAccess={[accessTypes.ADMIN_ACCESS_GROUPS_FEATURES]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.administration.highlights.url')}
          container={<Highlights />}
          title={`${I18n.t('routes.panel.administration.highlights.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_HIGHLIGHTS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.highlightProducts.url')}/:highlightId`}
          container={<HighlightProducts />}
          title={`${I18n.t('routes.panel.administration.highlightProducts.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_HIGHLIGHTS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={`${I18n.t('routes.panel.dashboards.url')}/:accessFeatureId`}
          container={<Dashboard />}
          title={`${I18n.t('routes.panel.dashboards.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.DASHBOARD]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />
        <Panel
          path={I18n.t('routes.panel.administration.collections.url')}
          container={<Collections />}
          title={`${I18n.t('routes.panel.administration.collections.pageTitle')} - ${I18n.t('application.name')}`}
          routeAccess={[accessTypes.ADMIN_COLLECTIONS]}
          routeAccessFeatures={[accessActionTypes.READ]}
        />

        <Public default container={<NotFound />} title={`${I18n.t('application.name')}`} />
      </Router>
    </>
  ) : (
    <div />
  );
}

export default Content;
