import React from 'react';
import { Layout, Row, Card } from 'antd';
import { I18n } from 'react-redux-i18n';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { Content } = Layout;
    return (
      <Content className="downloadScreen__container">
        <Card className="downloadScreen__container__card">
          <Row className="downloadScree__container__card__content" align="middle" justify="center">
            <div className="downloadScreen__container__card__content__text">
              <img
                alt="AppStore"
                className="downloadScreen__container__card__content__logo__imgLogo"
                src="/assets/img/img_logo.png"
              />
              <h1>{I18n.t('routes.downloadScreen.title')}</h1>

              <h2>{I18n.t('routes.downloadScreen.description')}</h2>
            </div>
            <div className="downloadScreen__container__card__content__logo">
              <a
                href="https://apps.apple.com/br/app/tiffin/id1491663992"
                className="downloadScreen__container__card__content__logo__container"
              >
                <img
                  alt="AppStore"
                  className="downloadScreen__container__card__content__logo__img"
                  src="/assets/img/img_appStore.png"
                />
              </a>
              <a
                className="downloadScreen__container__card__content__logo__container"
                href="https://play.google.com/store/apps/details?id=br.com.tiffinfoods&hl=pt"
              >
                <img
                  alt="GooglePlay"
                  className="downloadScreen__container__card__content__logo__img"
                  src="/assets/img/img_googlePlay.png"
                />
              </a>
            </div>
          </Row>
        </Card>
      </Content>
    );
  }
}

export default Download;
