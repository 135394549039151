import AccessRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/access/accessRequestsV1';

export const ACTION_GET_USER_ACCESS_IS_ON_REQUEST = 'ACTION_GET_USER_ACCESS_IS_ON_REQUEST:access';
export const ACTION_GET_USER_ACCESS = 'ACTION_GET_USER_ACCESS:access';
export const ACTION_GET_USER_ACCESS_OBTAINED = 'ACTION_GET_USER_ACCESS_OBTAINED:access';

export const getUserAccess = (callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_USER_ACCESS_OBTAINED, payload: false });
    dispatch({ type: ACTION_GET_USER_ACCESS_IS_ON_REQUEST, payload: true });
    const response = await AccessRequestsV1.getUserAccessFeatures();
    dispatch({ type: ACTION_GET_USER_ACCESS, payload: response });
    dispatch({ type: ACTION_GET_USER_ACCESS_OBTAINED, payload: true });
    if (callback && typeof callback === 'function') {
      callback();
    }
  } finally {
    dispatch({ type: ACTION_GET_USER_ACCESS_IS_ON_REQUEST, payload: false });
  }
};
