import HighlightPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/highlight/panel/highlightPanelRequestsV1';

export const ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST:highlight';
export const ACTION_GET_HIGHLIGHT_IS_ON_REQUEST = 'ACTION_GET_HIGHLIGHT_IS_ON_REQUEST:highlight';
export const ACTION_GET_HIGHLIGHTS_PAGINATED_DATA = 'ACTION_GET_HIGHLIGHTS_PAGINATED_DATA:highlight';
export const ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST = 'ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST:highlight';
export const ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST = 'ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST:highlight';
export const ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST = 'ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST:highlight';
export const ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST = 'ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST:highlight';
export const ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST = 'ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST:highlight';

export const getHighlightsPaginated = (params) => async (dispatch) => {
  try {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: Number(params?.page) - 1 || 0,
    };
    dispatch({ type: ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST, payload: true });
    const highlights = await HighlightPanelRequestsV1.getAll(params);
    const { count, rows } = highlights;
    dispatch({ type: ACTION_GET_HIGHLIGHTS_PAGINATED_DATA, payload: { count, rows } });
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_HIGHLIGHTS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const getHighlightById = (highlightId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_HIGHLIGHT_IS_ON_REQUEST, payload: true });
    if (highlightId) {
      return await HighlightPanelRequestsV1.getById(highlightId);
    }
  } finally {
    dispatch({ type: ACTION_GET_HIGHLIGHT_IS_ON_REQUEST, payload: false });
  }
};

export const createHighlights = (highlightsData, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST, payload: true });
    await HighlightPanelRequestsV1.create(highlightsData);

    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_CREATE_HIGHLIGHTS_IS_ON_REQUEST, payload: false });
  }
};

export const associateHighlightProducts = (highlightId, productIds, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST, payload: true });
    await HighlightPanelRequestsV1.associateHighlightProducts(highlightId, productIds);

    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_ASSOCIATE_PRODUCTS_IS_ON_REQUEST, payload: false });
  }
};

export const disassociateHighlightProducts = (highlightId, productsId, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST, payload: true });
    await HighlightPanelRequestsV1.disassociateHighlightProducts(highlightId, productsId);

    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_DISASSOCIATE_PRODUCTS_IS_ON_REQUEST, payload: false });
  }
};

export const updateHighlight = (highlightId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST, payload: true });
    if (highlightId) {
      return await HighlightPanelRequestsV1.update(highlightId, data);
    }
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_HIGHLIGHT_IS_ON_REQUEST, payload: false });
  }
};

export const deleteHighlightById = (highlightId, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST, payload: true });
    await HighlightPanelRequestsV1.remove(highlightId);

    if (callback && typeof callback === 'function') {
      callback();
    }
    return true;
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_DELETE_HIGHLIGHT_BY_ID_IS_ON_REQUEST, payload: false });
  }
};
