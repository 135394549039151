import AccessFeaturePanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/accessFeature/panel/accessFeaturePanelRequestsV1';

export const ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST:accessFeature';
export const ACTION_GET_ACCESS_FEATURES_PAGINATED_DATA = 'ACTION_GET_ACCESS_FEATURES_PAGINATED_DATA:accessFeature';
export const ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST = 'ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST:accessFeature';
export const ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST =
  'ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST:accessFeature';
export const ACTION_UPDATE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST =
  'ACTION_UPDATE_ACCESS_FEATURES_BY_ID_IS_ON_REQUEST:accessFeature';
export const ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST =
  'ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST:accessFeature';
export const ACTION_GET_ACCESS_FEATURE_BY_ID = 'ACTION_GET_ACCESS_FEATURE_BY_ID:accessFeature';

export function getAccessFeaturesPaginated(params) {
  return async (dispatch) => {
    params = {
      ...params,
      search: params?.search || '',
      limit: params?.limit || 10,
      page: params?.page ? params.page - 1 : 0,
    };
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: true });
      const accessFeatures = await AccessFeaturePanelRequestsV1.getAll(params);
      const { count, rows } = accessFeatures;
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_PAGINATED_DATA, payload: { count, rows } });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURES_PAGINATED_IS_ON_REQUEST, payload: false });
    }
  };
}

export function createAccessFeature(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST, payload: true });
      const success = await AccessFeaturePanelRequestsV1.create(payload);
      if (success) {
        return true;
      }
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_CREATE_ACCESS_FEATURE_IS_ON_REQUEST, payload: false });
    }
  };
}

export function deleteAccessFeatureById(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: true });
      await AccessFeaturePanelRequestsV1.remove(id);
      if (callback) {
        callback();
      }
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_DELETE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}

export function getAccessFeatureById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: true });
      const payload = await AccessFeaturePanelRequestsV1.getById(id);
      dispatch({ type: ACTION_GET_ACCESS_FEATURE_BY_ID, payload });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}

export function updateAccessFeatureById(id, payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_UPDATE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: true });
      await AccessFeaturePanelRequestsV1.update(id, payload);
      return true;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_UPDATE_ACCESS_FEATURE_BY_ID_IS_ON_REQUEST, payload: false });
    }
  };
}
