import React from 'react';
import { Input } from 'antd';
import { I18n } from 'react-redux-i18n';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

function AdvancedInput({
  label,
  placeholder,
  onChange,
  type,
  value,
  disabled,
  isPassword,
  style,
  required,
  className,
  maxLength,
  rules = () => false,
  onPressEnter,
}) {
  return (
    <div className="advanced-input" style={style}>
      <label className="advanced-input__label">
        {label && (
          <span className="advanced-input__label__inner">
            {label} {required && <strong>*</strong>}
          </span>
        )}
        {isPassword ? (
          <Input.Password
            size="middle"
            placeholder={placeholder || I18n.t('shared.typeSomething')}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onPressEnter={onPressEnter || undefined}
          />
        ) : (
          <Input
            className={className}
            size="middle"
            placeholder={placeholder || I18n.t('shared.typeSomething')}
            onChange={(ev) => onChange(ev.currentTarget.value)}
            type={type || 'text'}
            value={value}
            disabled={disabled || false}
            maxLength={maxLength}
            onPressEnter={onPressEnter || undefined}
          />
        )}
        {rules(value) && <span className="advanced-input__label__error">{rules(value)}</span>}
      </label>
    </div>
  );
}

export default AdvancedInput;
