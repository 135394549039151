import React, { useState } from 'react';

import classnames from 'classnames';

import { Card, Typography, Popover } from 'antd';
import { MdInfo } from 'react-icons/md';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  ChatMessageTypeEnumerator,
  ChatReferenceTypeEnumerator,
} from 'tiffin-foods-chat-component/lib/cjs/enumerators';
import { ChatActions } from 'tiffin-foods-chat-component/lib/cjs/actions';
import PromotionType from '../../../../../app/enum/promotion_type';
import AdvancedButton from '../../../../shared/AdvancedButton';

import ChatReferenceTypes from '../../../../../app/enum/chat_reference_type';
import { UserTypes } from '../../../../../app/enum/user_types';

import { AuthSelectors, OrderSelectors, ProductSelectors, SettingSelectors } from '../../../../../app/redux/reducers';

import * as PriceUtils from '../../../../../app/utils/prices';
import { hasAccess } from '../../../../../app/services/access';
import { accessTypes } from '../../../../../app/enum/access_types';
import { accessActionTypes } from '../../../../../app/enum/access_action_types';

function OrderFormFooter({
  getDefaultCoinValue,
  getUserType,
  isCreateOrderOnRequest,
  isUpdateOrderOnRequest,
  isGetProductsStockOnRequest,
  order,
  onCloseForm,
  onSubmit,
  getUserId,
  disabled,
  isLoading,
}) {
  const [popoverFreightVisible, setPopoverFreightVisible] = useState(false);
  const [popoverTaxVisible, setPopoverTaxVisible] = useState(false);
  const [popoverCouponVisible, setPopoverCouponVisible] = useState(false);

  const { Text, Title } = Typography;

  const popoverFreightContent = () => {
    return (
      <div>
        Estão faltando os valores de frete, não deixe de
        <br />
        inserir os valores para dar andamento ao pedido.
      </div>
    );
  };

  const popoverTaxContent = () => {
    return (
      <div>
        Estão faltando os valores de impostos, não deixe de
        <br />
        inserir os valores para dar andamento ao pedido.
      </div>
    );
  };

  const popoverCouponContent = (coupon) => {
    return (
      <div>
        O cupom usado foi: <b>{coupon.couponCode}</b>
        <br />
        Desconto de{' '}
        <b>
          {coupon.type === PromotionType.PERCENTAGE ? `${coupon.value}% OFF` : `${PriceUtils.getPrice(coupon.value)}`}
        </b>
      </div>
    );
  };

  const onOpenUserChat = async () => {
    const references = [{ referenceType: ChatReferenceTypes.USER, referenceId: order?.userId }];
    const isSupport = [UserTypes.SUPPORT, UserTypes.ADMIN].includes(getUserType);

    if (isSupport) {
      references.push({
        referenceId: order?.provider?.id,
        referenceType: ChatReferenceTypes.PROVIDER,
      });
    }

    const payload = {
      type: ChatMessageTypeEnumerator.window,
      windowTitle: 'nova mensagem',
      createdById: isSupport ? getUserId : order?.provider?.id,
      createdByType: isSupport ? ChatReferenceTypeEnumerator.support : ChatReferenceTypeEnumerator.provider,
      references,
    };

    await ChatActions.createEmptyChat(payload);
  };

  return (
    <div className="order-form__footer">
      <Card className="order-form__footer__card">
        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.value.label')}:</Text>
          <Text>{PriceUtils.getPrice(order?.value)}</Text>
        </div>

        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.discount.label')}:</Text>
          <Text>{`- ${PriceUtils.getPrice(order?.discountItems)}`}</Text>
        </div>

        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.discountExtra.label')}:</Text>
          <Text>{`- ${PriceUtils.getPrice(order?.discountExtra)}`}</Text>
        </div>

        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.discountCoupon.label')}:</Text>
          <span className="order-form__footer__card__row__waiting-cell">
            <Text>{`- ${PriceUtils.getPrice(order?.discountCoupon)}`}</Text>
            {order?.coupon && (
              <>
                <MdInfo
                  onMouseEnter={() => setPopoverCouponVisible(true)}
                  color="black"
                  size={16}
                  className="order-form__footer__card__row__waiting-cell__info-icon"
                />
                <Popover
                  placement="topLeft"
                  title={`Detalhes do cupom ID: ${order?.coupon.id}`}
                  content={popoverCouponContent(order?.coupon)}
                  trigger="hover"
                  visible={popoverCouponVisible}
                  onVisibleChange={() => setPopoverCouponVisible(false)}
                />
              </>
            )}
          </span>
        </div>

        <div className="order-form__footer__card__row">
          <Text
            className={classnames({
              'order-form__footer__card__row__text': true,
              'order-form__footer__card__row__text--alert': order && order?.freight === null,
            })}
          >
            {I18n.t('forms.order.orderValue.freight.label')}:
          </Text>
          <span className="order-form__footer__card__row__waiting-cell">
            <Text
              className={classnames({
                'order-form__footer__card__row__text': true,
                'order-form__footer__card__row__text--alert': order && order?.freight === null,
              })}
            >
              {order?.freight !== null ? PriceUtils.getPrice(order?.freight) : 'Aguardando'}
            </Text>
            {order?.freight === null && (
              <>
                <MdInfo
                  onMouseEnter={() => setPopoverFreightVisible(true)}
                  color="red"
                  size={16}
                  className="order-form__footer__card__row__waiting-cell__info-icon"
                />
                <Popover
                  placement="topLeft"
                  title="Aguardando valor de frete"
                  content={popoverFreightContent}
                  trigger="hover"
                  visible={popoverFreightVisible}
                  onVisibleChange={() => setPopoverFreightVisible(false)}
                />
              </>
            )}
          </span>
        </div>

        <div className="order-form__footer__card__row">
          <Text
            className={classnames({
              'order-form__footer__card__row__text': true,
              'order-form__footer__card__row__text--alert': order && order?.tax === null,
            })}
          >
            {I18n.t('forms.order.orderValue.tax.label')}:
          </Text>
          <span className="order-form__footer__card__row__waiting-cell">
            <Text
              className={classnames({
                'order-form__footer__card__row__text': true,
                'order-form__footer__card__row__text--alert': order && order?.tax === null,
              })}
            >{`${order?.tax !== null ? PriceUtils.getPrice(order?.tax) : 'Aguardando'}`}</Text>
            {order?.tax === null && (
              <>
                <MdInfo
                  onMouseEnter={() => setPopoverTaxVisible(true)}
                  color="red"
                  size={16}
                  className="order-form__footer__card__row__waiting-cell__info-icon"
                />
                <Popover
                  placement="topLeft"
                  title="Aguardando valor de impostos"
                  content={popoverTaxContent}
                  trigger="hover"
                  visible={popoverTaxVisible}
                  onVisibleChange={() => setPopoverTaxVisible(false)}
                />
              </>
            )}
          </span>
        </div>

        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.fees.label')}:</Text>
          <Text>{`${PriceUtils.getPrice(
            PriceUtils.getFees({
              value: PriceUtils.getOrderTotalValueWithoutFees({
                ...order,
                coinValue: getDefaultCoinValue,
              }),
              fees: order?.fees,
            }),
          )}`}</Text>
        </div>

        <div className="order-form__footer__card__row">
          <Text>{I18n.t('forms.order.orderValue.coin.label')}:</Text>
          <Text>{`- ${PriceUtils.getPrice(
            PriceUtils.getCoinsDiscount({ wallet: order?.wallet, coinValue: getDefaultCoinValue }),
          )} (${Number(order?.wallet?.walletOut) || 0})`}</Text>
        </div>

        <div className="order-form__footer__card__row">
          <Title style={{ margin: 0, padding: 0 }} level={4}>
            {I18n.t('forms.order.orderValue.total.label')}:
          </Title>
          <Title style={{ margin: 0, padding: 0 }} level={4}>{`${PriceUtils.getPrice(
            PriceUtils.getOrderTotalValue({ ...order, coinValue: getDefaultCoinValue }),
          )}`}</Title>
        </div>
      </Card>

      <div className="order-form__footer__buttons">
        {hasAccess(
          [accessTypes.CHAT],
          [accessActionTypes.READ, accessActionTypes.CREATE, accessActionTypes.UPDATE, accessActionTypes.REMOVE],
        ) ? (
          <AdvancedButton
            style={{ alignSelf: 'flex-start' }}
            text={I18n.t('forms.order.orderValue.chat.label')}
            onClick={onOpenUserChat}
            loading={isLoading}
          />
        ) : (
          <div />
        )}
        <div>
          <AdvancedButton type="link" text={I18n.t('forms.cancelButtonText')} onClick={onCloseForm} />
          {!disabled && (
            <AdvancedButton
              loading={isCreateOrderOnRequest || isUpdateOrderOnRequest || isGetProductsStockOnRequest}
              text={I18n.t('forms.submitButtonText')}
              onClick={onSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    getDefaultCoinValue: SettingSelectors.getDefaultCoinValue(state),
    getUserType: AuthSelectors.getUserType(state),
    getUserId: AuthSelectors.getUserId(state),
    isCreateOrderOnRequest: OrderSelectors.isCreateOrderOnRequest(state),
    isUpdateOrderOnRequest: OrderSelectors.isUpdateOrderOnRequest(state),
    isGetProductsStockOnRequest: ProductSelectors.isGetProductsStockOnRequest(state),
  };
}

export default connect(mapStateToProps, null)(OrderFormFooter);
