import ProductPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/product/panel/productPanelRequestsV1';
import ProductRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/product/productRequestsV1';
import ProviderPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/provider/panel/providerPanelRequestsV1';
import ErrorUtils from '../../utils/errors';

import FileUtils from '../../utils/file';

export const ACTION_GET_PRODUCTS_PAGINATED = 'ACTION_GET_PRODUCTS_PAGINATED:product';
export const ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST = 'ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST:product';

export const ACTION_GET_PRODUCT_IS_ON_REQUEST = 'ACTION_GET_PRODUCT_IS_ON_REQUEST:product';
export const ACTION_CREATE_PRODUCT_IS_ON_REQUEST = 'ACTION_CREATE_PRODUCT_IS_ON_REQUEST:product';
export const ACTION_UPDATE_PRODUCT_IS_ON_REQUEST = 'ACTION_UPDATE_PRODUCT_IS_ON_REQUEST:product';
export const ACTION_DELETE_PRODUCT_IS_ON_REQUEST = 'ACTION_DELETE_PRODUCT_IS_ON_REQUEST:product';
export const ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST = 'ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST:product';
export const ACTION_GET_PRODUCTS_UNITS_IS_ON_REQUEST = 'ACTION_GET_PRODUCTS_UNITS_ON_REQUEST:product';
export const ACTION_GET_PRODUCTS_UNITS = 'ACTION_GET_PRODUCTS_UNITS:product';
export const ACTION_GET_PRODUCT_TO_SAMPLE_IS_ON_REQUEST = 'ACTION_GET_PRODUCTS_TO_SAMPLE_IS_ON_REQUEST:product';
export const ACTION_GET_PRODUCT_TO_SAMPLE_DATA = 'ACTION_GET_PRODUCTS_TO_SAMPLE_DATA:product';
export const ACTION_UPLOAD_PRODUCTS_ERRORS = 'ACTION_UPLOAD_PRODUCTS_ERRORS:product';
export const ACTION_GET_PRODUCTS_BY_HIGHLIGHT = 'ACTION_GET_PRODUCTS_BY_HIGHLIGHT:product';
export const ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST = 'ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST:product';
export const ACTION_GET_PRODUCTS_STOCK_DATA = 'ACTION_GET_PRODUCTS_STOCK_DATA:product';
export const ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST = 'ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST:product';

export const getProductsPaginated = (parameters) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST, payload: true });

    parameters = {
      ...parameters,
      limit: parameters?.limit || 10,
      page: parameters?.page ? parameters.page - 1 : 0,
    };

    const products = await ProductPanelRequestsV1.getAll(parameters);

    dispatch({
      type: ACTION_GET_PRODUCTS_PAGINATED,
      payload: products,
    });
  } finally {
    dispatch({ type: ACTION_GET_PRODUCTS_PAGINATED_IS_ON_REQUEST, payload: false });
  }
};

export const getProduct = (providerId, productId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCT_IS_ON_REQUEST, payload: true });
    if (providerId && productId) {
      return await ProviderPanelRequestsV1.getProviderProductById(
        providerId,
        productId
      );
    }
  } finally {
    dispatch({ type: ACTION_GET_PRODUCT_IS_ON_REQUEST, payload: false });
  }
};

export const deleteProduct = (providerId, productId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_DELETE_PRODUCT_IS_ON_REQUEST, payload: true });
    // v1 panel provider
    await ProviderPanelRequestsV1.deleteProviderProduct(providerId, productId);
    return true;
  } finally {
    dispatch({ type: ACTION_DELETE_PRODUCT_IS_ON_REQUEST, payload: false });
  }
};

export const updateProduct = (providerId, productId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_PRODUCT_IS_ON_REQUEST, payload: true });
    // v1 panel provider
    await ProviderPanelRequestsV1.updateProviderProduct(providerId, productId, data);
    return true;
  } finally {
    dispatch({ type: ACTION_UPDATE_PRODUCT_IS_ON_REQUEST, payload: false });
  }
};

export const createProduct = (providerId, data) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_CREATE_PRODUCT_IS_ON_REQUEST, payload: true });
    // v1 panel provider
    await ProviderPanelRequestsV1.createProviderProduct(providerId, data);
    return true;
  } finally {
    dispatch({ type: ACTION_CREATE_PRODUCT_IS_ON_REQUEST, payload: false });
  }
};

export const uploadProducts = (providerId, fileList) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST, payload: true });

    const productsRaw = await FileUtils.xlsxToJson(fileList);
    const productsParsed = FileUtils.createProductsParse(productsRaw);
    const productsFieldsToArray = productsParsed.map((o) => {
      return {
        ...o,
        categories: o.categories ? o.categories.split(';') : undefined,
      };
    });
    const success = await ProviderPanelRequestsV1.panelUploadProviderProduct(providerId, productsFieldsToArray);
    if (success) {
      return true;
    }
  } catch (err) {
    // empty
  } finally {
    dispatch({ type: ACTION_UPLOAD_PRODUCTS_IS_ON_REQUEST, payload: false });
  }
};

export const setUploadProductsErrors = (error) => async (dispatch) => {
  let description = null;
  if (error) {
    description = ErrorUtils.formatDetailsError(error);
  }
  dispatch({ type: ACTION_UPLOAD_PRODUCTS_ERRORS, payload: description });
};

export const getProductsUnits = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCTS_UNITS_IS_ON_REQUEST, payload: true });
    // v1
    const productsUnits = await ProductRequestsV1.getProductUnits();
    dispatch({ type: ACTION_GET_PRODUCTS_UNITS, payload: productsUnits });
  } finally {
    dispatch({ type: ACTION_GET_PRODUCTS_UNITS_IS_ON_REQUEST, payload: false });
  }
};

export const getProductToSample = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCT_TO_SAMPLE_IS_ON_REQUEST, payload: true });
    // v1 panel
    const randomProduct = await ProductPanelRequestsV1.getRandomProduct();
    dispatch({ type: ACTION_GET_PRODUCT_TO_SAMPLE_DATA, payload: randomProduct });
  } finally {
    dispatch({ type: ACTION_GET_PRODUCT_TO_SAMPLE_IS_ON_REQUEST, payload: false });
  }
};

export const getProductsByHighlight = (parameters) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST, payload: true });

    parameters = {
      ...parameters,
      limit: parameters?.limit || 10,
      page: parameters?.page ? parameters.page - 1 : 0,
    };

    const products = await ProductPanelRequestsV1.getAll(parameters);
    dispatch({
      type: ACTION_GET_PRODUCTS_BY_HIGHLIGHT,
      payload: products,
    });
  } finally {
    dispatch({ type: ACTION_GET_PRODUCTS_BY_HIGHLIGHT_IS_ON_REQUEST, payload: false });
  }
};

export const getOrderProductsStock = (ids) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST, payload: true });
    const response = await ProductRequestsV1.getOrderProductsStock(ids);
    dispatch({ type: ACTION_GET_PRODUCTS_STOCK_DATA, payload: response });
    return response;
  } finally {
    dispatch({ type: ACTION_GET_PRODUCTS_STOCK_IS_ON_REQUEST, payload: false });
  }
};
