import React from 'react';
import { Switch } from 'antd';

function AdvancedSwitch({ label, onChange, value, disabled, required, style }) {
  return (
    <div className="advanced-switch" style={style}>
      <label className="advanced-switch__label">
        <span className="advanced-switch__label__inner">
          {label} {required && <strong>*</strong>}
        </span>
        <Switch onChange={(ev) => onChange(ev)} checked={value} disabled={disabled || false} />
      </label>
    </div>
  );
}

export default AdvancedSwitch;
