import React, { useState, useEffect } from 'react';
import { Modal, Avatar, notification, Popconfirm } from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedButton from '../../../shared/AdvancedButton';
import { OrderActions } from '../../../../app/redux/actions';
import { OrderSelectors } from '../../../../app/redux/reducers';
import * as DateUtils from '../../../../app/utils/date';
import RatingStars from '../../../shared/RatingStars/RatingStars';
import { getInitials } from '../../../../app/utils/string';
import AdvancedTextArea from '../../../shared/AdvancedTextArea/AdvancedTextArea';
import useForm from '../../../../app/hooks/useForm';

function ProviderRatingDetailsModal({
  visible,
  data,
  onOk,
  onCancel,
  onUpdateOrderEvaluation,
  isUpdateOrderEvaluationIsOnRequest,
}) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const getInitialState = () => ({
    reply: null,
  });

  const form = useForm(getInitialState());

  useEffect(() => {
    form.handleInputChange('reply', data?.reply);
  }, [visible]);

  const onHandleVisibleChange = () => {
    setPopoverVisible(!popoverVisible);
  };

  const onCancelModal = async () => {
    await setPopoverVisible(false);
    form.handleInputChange('reply', null);
    onCancel();
  };

  const onOkModal = async () => {
    await setPopoverVisible(false);
    form.handleInputChange('reply', null);
    onOk();
  };

  const onClickSendButton = async () => {
    if (form.values?.reply) {
      const success = await onUpdateOrderEvaluation(data.orderId, { reply: form.values?.reply });
      if (success) {
        notification.success({
          message: I18n.t('routes.panel.providerRatings.notifications.success.message'),
          description: I18n.t('routes.panel.providerRatings.notifications.success.description'),
        });
      }
      onOkModal();
      return;
    }
    notification.error({
      message: I18n.t('routes.panel.providerRatings.notifications.errors.noReply.message'),
      description: I18n.t('routes.panel.providerRatings.notifications.errors.noReply.description'),
    });
  };

  const disableReply = !!(data?.repliedAt || isUpdateOrderEvaluationIsOnRequest);
  return (
    <Modal
      onCancel={onCancelModal}
      footer={false}
      title={I18n.t('routes.panel.providerRatings.titles.modalTitle', { orderId: data?.orderId })}
      visible={visible}
      width={700}
      className="provider-rating-details-modal"
    >
      <div className="provider-rating-details-modal__container">
        <div className="provider-rating-details-modal__container__body-infos">
          <div className="provider-rating-details-modal__container__body-infos__header">
            <div className="provider-rating-details-modal__container__body-infos__header__user">
              <div className="provider-rating-details-modal__container__avatar-column__avatar">
                {data && (
                  <Avatar
                    className="provider-rating-details-modal__container__avatar-column__avatar__img"
                    size={70}
                    src={data?.photoUrl ? data.photoUrl : null}
                  >
                    {data?.photoUrl ? null : getInitials(data.userName)}
                  </Avatar>
                )}
              </div>
              <div className="provider-rating-details-modal__container__body-infos__header__user__infos">
                <div className="provider-rating-details-modal__container__body-infos__header__user__infos__name">
                  {data?.userName}
                </div>
                <div className="provider-rating-details-modal__container__body-infos__header__user__infos__date">
                  {DateUtils.humanizeDateTime(data?.createdAt, 'DD/MM/YYYY HH:mm')}
                </div>
                <div className="provider-rating-details-modal__container__body-infos__header__user__infos__rating">
                  <RatingStars rating={data?.rating} visible={visible} />
                </div>
              </div>
            </div>
          </div>
          <div className="provider-rating-details-modal__container__body-infos__body">
            <div className="provider-rating-details-modal__container__body-infos__body__evaluation">
              <div className="provider-rating-details-modal__container__body-infos__body__evaluation__title">
                {I18n.t('routes.panel.providerRatings.titles.evaluationTitle')}
              </div>
              <div className="provider-rating-details-modal__container__body-infos__body__evaluation__evaluation-container">
                {data?.comment}
              </div>
            </div>
            <div className="provider-rating-details-modal__container__body-infos__body__reply">
              <div className="provider-rating-details-modal__container__body-infos__body__reply__title">
                {I18n.t('routes.panel.providerRatings.titles.yourReply')}
              </div>
              <div className="provider-rating-details-modal__container__body-infos__body__reply__reply-box">
                <AdvancedTextArea
                  className="provider-rating-details-modal__container__body-infos__body__reply__reply-box__textarea"
                  rows={5}
                  placeholder={I18n.t('routes.panel.providerRatings.textArea.placeholder')}
                  maxLength={255}
                  disabled={disableReply}
                  value={form.values?.reply}
                  onChange={(val) => form.handleInputChange('reply', val)}
                />
              </div>
            </div>
          </div>
          <div className="provider-rating-details-modal__container__body-infos__footer">
            <Popconfirm
              className="provider-rating-details-modal__container__body-infos__footer__popover"
              placement="topLeft"
              title={
                <>
                  <strong>{I18n.t('routes.panel.providerRatings.content.warning')}</strong>{' '}
                  {I18n.t('routes.panel.providerRatings.content.topText')}
                  <br />
                  {I18n.t('routes.panel.providerRatings.content.bottomText')}
                  <br />
                </>
              }
              onConfirm={() => onClickSendButton()}
              okText={I18n.t('routes.panel.providerRatings.popover.buttons.confirmButton')}
              cancelText={I18n.t('routes.panel.providerRatings.popover.buttons.cancelButton')}
            >
              <AdvancedButton
                type="primary"
                text={I18n.t('routes.panel.providerRatings.popover.buttons.sendButton')}
                onClick={() => setPopoverVisible(true)}
                disabled={disableReply}
                loading={isUpdateOrderEvaluationIsOnRequest}
                strongText
              />
            </Popconfirm>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isUpdateOrderEvaluationIsOnRequest: OrderSelectors.isUpdateOrderEvaluationIsOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateOrderEvaluation: (orderId, payload) => dispatch(OrderActions.updateOrderEvaluation(orderId, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderRatingDetailsModal);
