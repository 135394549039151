import React from 'react';
import { Input } from 'antd';

import './AdvancedTextArea.less';
import { I18n } from 'react-redux-i18n';

function AdvancedTextArea({
  label,
  placeholder,
  onChange,
  showCount,
  maxLength,
  autoSize,
  value,
  disabled,
  style,
  textAreaStyle,
  required,
  rows,
}) {
  return (
    <div className="advanced-text-area" style={style}>
      <label className="advanced-text-area__label">
        <span className="advanced-text-area__label__inner">
          {label} {required && <strong>*</strong>}
        </span>
        <Input.TextArea
          style={textAreaStyle}
          size="middle"
          placeholder={placeholder || I18n.t('shared.typeSomething')}
          onChange={(ev) => onChange(ev.currentTarget.value)}
          value={value}
          disabled={disabled || false}
          showCount={showCount}
          maxLength={maxLength}
          autoSize={autoSize}
          rows={rows || 3}
        />
      </label>
      {maxLength && maxLength > 0 && (
        <div className="advanced-text-area__info">
          <span className="advanced-text-area__info__current">{value?.length || '0'}</span>
          <span className="advanced-text-area__info__of">{I18n.t('shared.of')}</span>
          <span className="advanced-text-area__info__max">{maxLength}</span>
        </div>
      )}
    </div>
  );
}

export default AdvancedTextArea;
