import React, { useState, useEffect } from 'react';
import { Tag, Row } from 'antd';
import { I18n } from 'react-redux-i18n';

function AdvancedPreviewTag({ colorProp, textColorProp, setTextTag, item }) {
  const defaultValues = {
    text: I18n.t('shared.advancedPreviewTag.defaultValues.text'),
    color: 'FFFFFF',
    textColor: '000000',
  };

  const [text, setText] = useState('');

  const [imageUrl, setImageUrl] = useState('');
  const [color, setColor] = useState(defaultValues.color);
  const [textColor, setTextColor] = useState(defaultValues.textColor);

  useEffect(() => {
    setText('');
    setTextColor(defaultValues.textColor);
    setColor(defaultValues.color);
  }, [item]);

  useEffect(() => {
    setColor(colorProp);
  }, [colorProp]);

  useEffect(() => {
    setTextColor(textColorProp);
  }, [textColorProp]);

  useEffect(() => {
    if (item?.images && Array.isArray(item?.images)) {
      setImageUrl(item?.images[0]?.imageUrl);
    }
  }, [item?.images]);

  const handleOnChangeTagtext = (value) => {
    if (value.length <= 24) {
      setTextTag(value);
      setText(value);
    }
  };

  return (
    <div className="Advanced-preview-tag__container">
      <Row>
        <div
          className="Advanced-preview-tag__container__picture"
          style={{
            backgroundImage: `url(${imageUrl || '/assets/img/img_tag_background.png'})`,
          }}
        />
        <div className="Advanced-preview-tag__container__infos">
          <div className="Advanced-preview-tag__container__infos__top">
            <div className="Advanced-preview-tag__container__infos__top__title">{item?.name || 'tiffin'}</div>
            <div className="Advanced-preview-tag__container__infos__top__description">
              {item?.description || I18n.t('shared.advancedPreviewTag.itemDescription')}
            </div>
          </div>
          <div className="Advanced-preview-tag__container__infos__middle">
            <Tag
              className="Advanced-preview-tag__container__tag"
              style={{
                color: !textColor.includes('#') ? `#${textColor}` : textColor,
                backgroundColor: !color.includes('#') ? `#${color}` : color,
                width: text?.length ? `${(text?.length || 0) * 9}px` : '50px',
              }}
            >
              <input
                autoComplete="off"
                type="text"
                id="tagLabel"
                onChange={({ target }) => handleOnChangeTagtext(target.value)}
                className="Advanced-preview-tag__container__text"
                placeholder={defaultValues.text}
                value={text}
                style={{
                  width: text?.length ? `${(text?.length || 0) * 9}px` : '50px',
                  backgroundColor: !color.includes('#') ? `#${color}` : color,
                }}
              />
            </Tag>
          </div>
          <div className="Advanced-preview-tag__container__infos__bottom">
            <div className="Advanced-preview-tag__container__infos__bottom__min-order">Pedido minimo</div>
            <div className="Advanced-preview-tag__container__infos__bottom__price">{`R$ ${
              item?.provider?.minValue || '0,00'
            }`}</div>
          </div>
        </div>
      </Row>
    </div>
  );
}

AdvancedPreviewTag.displayName = 'AdvancedPreviewTag';

export default AdvancedPreviewTag;
