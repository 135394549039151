import React, { useCallback, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { notification } from 'antd';
import { connect } from 'react-redux';

import MaskedInput from 'antd-mask-input';
import AdvancedInput from '../../../shared/AdvancedInput';
import AdvancedButton from '../../../shared/AdvancedButton/AdvancedButton';

import { UserActions } from '../../../../app/redux/actions';
import { UserSelectors } from '../../../../app/redux/reducers';
import { ContainerFooterButton } from './styles';

function ChangeDataStep({
  user,
  setUser,
  stepCurrent,
  setStepCurrent,
  setChangeData,
  onUpdateSignUpUser,
  isCreateUserAndCodeValidation,
  isUpdateUserAndCodeValidation,
}) {
  const [userInside, setUserInside] = useState(user);
  const { phone, email } = userInside;

  const handleNextButton = useCallback(() => {
    if (!email) {
      notification.error({
        message: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.message'),
        description: I18n.t('routes.signup.content.dataAccessContent.notifications.noEmail.description'),
      });
      return;
    }

    if (!phone || phone.match(/\d+/g)?.join('').length < 10) {
      notification.error({
        message: I18n.t('routes.signup.content.dataAccessContent.notifications.noPhone.message'),
        description: I18n.t('routes.signup.content.dataAccessContent.notifications.noPhone.description'),
      });
      return;
    }

    setUser(userInside);
    onUpdateSignUpUser(userInside);
    setChangeData(false);
    setStepCurrent(stepCurrent);
  }, [email, onUpdateSignUpUser, setChangeData, setStepCurrent, setUser, stepCurrent, userInside]);

  return (
    <>
      <div className="signup__container__register__container__form__headerDescription">
        {I18n.t('signup.changeDataStep.headerDescription')}
      </div>

      <AdvancedInput
        placeholder={I18n.t('routes.signup.content.emailPlaceholder')}
        type="email"
        onChange={(value) => setUserInside({ ...userInside, email: value })}
        value={email}
        name="email"
      />
      <MaskedInput
        defaultValue={phone}
        mask="(00) 00000-0000"
        label={I18n.t('routes.signup.content.personalDataContent.content.phone.label')}
        placeholder={I18n.t('routes.signup.content.personalDataContent.content.phone.placeholder')}
        value={phone}
        onChange={({ target }) => setUserInside({ ...userInside, phone: target.value })}
      />
      <ContainerFooterButton>
        <AdvancedButton
          text={I18n.t('routes.signup.content.nextButton')}
          loading={isCreateUserAndCodeValidation || isUpdateUserAndCodeValidation}
          onClick={handleNextButton}
        />
      </ContainerFooterButton>
    </>
  );
}

const mapStateToProps = (state) => ({
  isCreateUserAndCodeValidation: UserSelectors.isCreateUserAndCodeIsOnRequest(state),
  isUpdateUserAndCodeValidation: UserSelectors.isUpdateUserAndCodeIsOnRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateSignUpUser: (user) => dispatch(UserActions.updateUserAndCodeValidation(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDataStep);
