import Immutable from 'seamless-immutable';
import {
  ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST,
  ACTION_GET_ACCESS_GROUPS_PAGINATED_DATA,
  ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST,
  ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST,
  ACTION_UPDATE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST,
  ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST,
  ACTION_GET_ACCESS_GROUP_BY_ID,
} from '../actions/accessGroup';

const initialState = Immutable({
  accessGroupsPaginated: null,
  getAccessGroupsPaginatedIsOnRequest: false,
  accessGroupById: null,
  accessGroupByIdIsOnRequest: false,
  createAccessGroupIsOnRequest: false,
  deleteAccessGroupIsOnRequest: false,
  updateAccessGroupIsOnRequest: false,
});

export default function accessGroup(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_ACCESS_GROUPS_PAGINATED_DATA:
      return state.merge({
        accessGroupsPaginated: action.payload,
      });
    case ACTION_GET_ACCESS_GROUP_BY_ID:
      return state.merge({
        accessGroupById: action.payload,
      });
    case ACTION_GET_ACCESS_GROUPS_PAGINATED_IS_ON_REQUEST:
      return state.merge({
        getAccessGroupsPaginatedIsOnRequest: action.payload,
      });
    case ACTION_CREATE_ACCESS_GROUP_IS_ON_REQUEST:
      return state.merge({
        createAccessGroupIsOnRequest: action.payload,
      });
    case ACTION_GET_ACCESS_GROUP_BY_ID_IS_ON_REQUEST:
      return state.merge({
        accessGroupByIdIsOnRequest: action.payload,
      });
    case ACTION_DELETE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST:
      return state.merge({
        deleteAccessGroupIsOnRequest: action.payload,
      });
    case ACTION_UPDATE_ACCESS_GROUP_BY_ID_IS_ON_REQUEST:
      return state.merge({
        updateAccessGroupIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}
