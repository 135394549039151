import Immutable from 'seamless-immutable';
import { ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST, ACTION_GET_DISTRIBUTORS } from '../actions/distributor';

const initialState = Immutable({
  distributors: null,
  distributorsIsOnRequest: false,
});

export default function distributor(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_DISTRIBUTORS:
      return state.merge({
        distributors: action.payload,
      });
    case ACTION_GET_DISTRIBUTORS_IS_ON_REQUEST:
      return state.merge({
        distributorsIsOnRequest: action.payload,
      });
    default:
      return state;
  }
}

export function getDistributors(state) {
  return state.distributor.distributors;
}

export function getDistributorsIsOnRequest(state) {
  return state.distributor.distributorsIsOnRequest;
}
