import React from 'react';
import { I18n } from 'react-redux-i18n';
import DetailInfoErrors from '../enum/details_info_errors';
import { ProductProviderPropsTranslated } from '../enum/product_provider_props';

const getInfoTranslate = (info) => {
  switch (info) {
    case DetailInfoErrors.DELETED:
      return I18n.t('application.errors.onErrorCallback.formatDetailsError.deleted');
    case DetailInfoErrors.WRONG:
      return I18n.t('application.errors.onErrorCallback.formatDetailsError.wrong');
    case DetailInfoErrors.NOT_FOUND:
      return I18n.t('application.errors.onErrorCallback.formatDetailsError.notFound');
    case DetailInfoErrors.INVALID_DATE:
      return I18n.t('application.errors.onErrorCallback.formatDetailsError.invalidDate');
    default:
  }
};

const formatDetailsError = ({ code, details }) => {
  if (code === 2700 && details) {
    const errorsJson = JSON.parse(details);
    errorsJson.sort((a, b) => a.rowNum - b.rowNum);
    const errorsParsed = [];
    errorsJson.map((o) => {
      const { column, rowNum, info } = o;
      errorsParsed.push(
        <>
          <span>
            Linha <strong style={{ color: 'red' }}>{rowNum}</strong>,{' '}
            <strong style={{ color: 'red' }}>{ProductProviderPropsTranslated[column]}</strong> está com valor{' '}
            <strong style={{ color: 'red' }}>{getInfoTranslate(info)}</strong>.
          </span>
          <br />
        </>,
      );
    });
    return errorsParsed;
  }
};

export default { formatDetailsError };
