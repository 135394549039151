export const ACTION_SHOW_MESSAGE = 'ACTION_SHOW_MESSAGE:message';

export function showMessage(title, message, isError) {
  return (dispatch) => {
    dispatch({
      type: ACTION_SHOW_MESSAGE,
      payload: { title: null, message: null },
    });
    dispatch({
      type: ACTION_SHOW_MESSAGE,
      payload: { title, message, isError },
    });
  };
}

export function showPushMessage(data) {
  return (dispatch) => {
    dispatch(showMessage(data.title, data.body, false));
  };
}

export function handlePushNotification() {
  return () => {};
}
