import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Collapse, Row } from 'antd';
import { I18n } from 'react-redux-i18n';
import { ProviderActions } from '../../../../app/redux/actions';
import useForm from '../../../../app/hooks/useForm';
import AdvancedCheckbox from '../../../shared/AdvancedCheckbox';
import AdvancedInput from '../../../shared/AdvancedInput';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';
import Loading from '../../../shared/Loading';
import { useReduxState } from '../../../../app/hooks/useReduxState';

const { Panel } = Collapse;

function InnerProviderUnitsProductForm({ product, updateProductData, disabled }) {
  const dispatch = useDispatch();
  const { provider } = useReduxState();

  const getInitialState = () => ({
    units: [],
  });

  const form = useForm(getInitialState());

  useEffect(() => {
    const getUnits = async () => {
      const units = await dispatch(ProviderActions.getProviderUnits(product?.provider?.id));
      let payload = units;

      if (product?.id) {
        payload = units.map((o) => {
          const find = product.units.find((unit) => unit.id === o.id);
          if (find) {
            return { ...o, stock: find.stock, checked: true };
          }

          return o;
        });
      }

      form.handleInputChange('units', payload);
    };

    if (product?.provider?.id) {
      getUnits();
    } else {
      form.handleInputChange('units', []);
    }
  }, [dispatch, product?.provider?.id]);

  const onChangeUnitData = (index, name, value) => {
    const unitsCopy = [...form.values.units];

    if (name === 'checked') {
      unitsCopy[index].checked = !unitsCopy[index].checked;
    } else {
      unitsCopy[index][name] = value;
    }

    form.handleInputChange('units', unitsCopy);
    product.units = unitsCopy;
    updateProductData(product);
  };

  return (
    <div>
      {provider.isGetProviderUnitsOnRequest && (
        <div className="product-form__inner__loading">
          <Loading />
        </div>
      )}

      {!provider.isGetProviderUnitsOnRequest && (
        <Collapse
          activeKey={form.values.units?.filter((o) => o.checked).map((unit, unitIndex) => unitIndex.toString())}
        >
          {form.values.units?.map((unit, unitIndex) => (
            <Panel
              key={unitIndex.toString()}
              header={unit.name}
              collapsible={!unit.checked ? 'disabled' : 'header'}
              extra={
                <AdvancedCheckbox
                  label="Gerenciar"
                  value={unit.checked}
                  onChange={() => onChangeUnitData(unitIndex, 'checked')}
                  noPadding
                  disabled={disabled}
                />
              }
            >
              {unit.checked && (
                <div>
                  <Row>
                    <Col span={24}>
                      <AdvancedInput
                        label={I18n.t('forms.product.items.providerUnits.items.name.label')}
                        value={unit.name}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <AdvancedInputNumber
                        label={I18n.t('forms.product.items.providerUnits.items.stock.label')}
                        value={unit.stock}
                        onChange={(value) => onChangeUnitData(unitIndex, 'stock', value)}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
}

export default InnerProviderUnitsProductForm;
