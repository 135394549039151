import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from 'react-redux-i18n';
import { useReduxState } from '../../../../app/hooks/useReduxState';

import { GetShelfLifeTimeTypesArray } from '../../../../app/enum/shelf_life_time_types';

import AdvancedSelect from '../../../shared/AdvancedSelect';
import AdvancedInputNumber from '../../../shared/AdvancedInputNumber';

function InnerSizesProductForm({ product, updateProductData, disabled }) {
  const { product: productSelector } = useReduxState();

  const handleInputChange = (name, value) => {
    const productCopy = { ...product };
    productCopy[name] = value;
    updateProductData(productCopy);
  };

  return (
    <div>
      <Row>
        <Col>
          <p>{I18n.t('forms.product.items.sizes.description')}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.minimum.label')}
            onChange={(val) => handleInputChange('minimum', val)}
            value={product.minimum}
            min={1}
            required
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.multiplier.label')}
            onChange={(val) => handleInputChange('multiplier', val)}
            value={product.multiplier}
            min={1}
            required
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSelect
            label={I18n.t('forms.product.items.unitId.label')}
            onChange={(val) => handleInputChange('unitId', val)}
            value={product.unitId}
            options={productSelector?.productsUnits}
            required
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.weight.label')}
            onChange={(val) => handleInputChange('weight', val)}
            value={product.weight}
            step={0.001}
            min={0.001}
            required
            precision={3}
            disabled={disabled}
            placeholder={I18n.t('forms.product.items.weight.placeholder')}
            isFormatted
            isParsered
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.width.label')}
            onChange={(val) => handleInputChange('width', val)}
            value={product.width}
            step={0.1}
            min={0.1}
            required
            precision={1}
            disabled={disabled}
            placeholder={I18n.t('forms.product.items.width.placeholder')}
            isFormatted
            isParsered
          />
        </Col>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.height.label')}
            onChange={(val) => handleInputChange('height', val)}
            value={product.height}
            step={0.1}
            min={0.1}
            required
            precision={1}
            disabled={disabled}
            placeholder={I18n.t('forms.product.items.height.placeholder')}
            isFormatted
            isParsered
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.length.label')}
            onChange={(val) => handleInputChange('length', val)}
            value={product.length}
            step={0.1}
            min={0.1}
            required
            precision={1}
            disabled={disabled}
            placeholder={I18n.t('forms.product.items.length.placeholder')}
            isFormatted
            isParsered
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedSelect
            label={I18n.t('forms.product.items.shelfLifeType.label')}
            onChange={(val) => handleInputChange('shelfLifeType', val)}
            options={GetShelfLifeTimeTypesArray}
            value={product.shelfLifeType}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AdvancedInputNumber
            label={I18n.t('forms.product.items.shelfLifeTime.label')}
            onChange={(val) => handleInputChange('shelfLifeTime', val)}
            value={product.shelfLifeTime}
            min={0}
            disabled={disabled}
          />
        </Col>
      </Row>
    </div>
  );
}

export default InnerSizesProductForm;
