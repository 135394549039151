import FreightRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/freight/freightRequestsV1';

export const ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST =
  'ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST:shipment';
export const ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST = 'ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST:shipment';
export const ACTION_GET_ORDER_SHIPMENT_LABEL_DATA = 'ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST:shipment';

export const updateOrderShipmentStatus = (id, type) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST, payload: true });

    return await FreightRequestsV1.updateOrderShipmentStatus(id, type);
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_UPDATE_ORDER_SHIPMENT_STATUS_IS_ON_REQUEST, payload: false });
  }
};

export function getOrderShipmentLabel(id, orderVolumeNumber) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST, payload: true });
      const payload = await FreightRequestsV1.getOrderShipmentLabel(id, orderVolumeNumber);

      dispatch({ type: ACTION_GET_ORDER_SHIPMENT_LABEL_DATA, payload });

      if (payload) return payload;
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_ORDER_SHIPMENT_LABEL_IS_ON_REQUEST, payload: false });
    }
  };
}
