import { useEffect, useState } from 'react';

const useForm = (defaultForm, onChange) => {
  const [values, setForm] = useState(defaultForm);

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(values);
    }
  }, [values]);

  const handleInputChange = (name, value) => {
    setForm((formInputs) => ({ ...formInputs, [name]: value }));
  };

  return {
    handleInputChange,
    values,
    setForm,
  };
};

export default useForm;
