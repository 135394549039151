import React, { useEffect, useRef, useState } from 'react';
import { FullSizeChat } from 'tiffin-foods-chat-component/lib/cjs/components';

import * as S from './styles';

function Chat() {
  const ref = useRef();
  const [height, setHeight] = useState(ref.current?.clientHeight);

  useEffect(() => {
    setHeight(ref.current?.clientHeight);
  }, [ref.current]);

  return (
    <S.Container ref={ref}>
      <FullSizeChat chatWindowHeight={`${window.innerWidth <= 1100 ? window.innerHeight : height}px`} />
    </S.Container>
  );
}

export default Chat;
