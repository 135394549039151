import PaymentRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/payment/paymentRequestsV1';
import PaymentPanelRequestsV1 from 'tiffin-foods-api-client/lib/cjs/modules/payment/panel/paymentPanelRequestsV1';

export const ACTION_GET_PAYMENTS_IS_ON_REQUEST = 'ACTION_GET_PAYMENTS_IS_ON_REQUEST:payment';
export const ACTION_GET_PAYMENTS_DATA = 'ACTION_GET_PAYMENTS_DATA:payment';

export const ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST = 'ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST:payment';
export const ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST =
  'ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST:payment';

export const ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST = ' ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST:payment';
export const ACTION_GET_PAYMENT_STATUS = ' ACTION_GET_PAYMENT_STATUS:payment';

export function getPayments() {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTION_GET_PAYMENTS_IS_ON_REQUEST, payload: true });
      const payments = await PaymentRequestsV1.getAvailableMethods();
      dispatch({ type: ACTION_GET_PAYMENTS_DATA, payload: payments });
    } catch (err) {
      /* empty */
    } finally {
      dispatch({ type: ACTION_GET_PAYMENTS_IS_ON_REQUEST, payload: false });
    }
  };
}

export const getPaymentsBanks = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST, payload: true });
    return await PaymentRequestsV1.getPaymentBanks();
  } finally {
    dispatch({ type: ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST, payload: false });
  }
};

export const getPaymentsAccountTypes = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST, payload: true });
    return await PaymentRequestsV1.getPaymentAccountTypes();
  } finally {
    dispatch({ type: ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST, payload: false });
  }
};

export const getPaymentStatus = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST, payload: true });
    const paymentStatus = await PaymentPanelRequestsV1.panelGetPaymentStatus();
    dispatch({ type: ACTION_GET_PAYMENT_STATUS, payload: paymentStatus });
  } catch (err) {
    /* empty */
  } finally {
    dispatch({ type: ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST, payload: false });
  }
};
