import Immutable from 'seamless-immutable';
import {
  ACTION_GET_PAYMENTS_DATA,
  ACTION_GET_PAYMENTS_IS_ON_REQUEST,
  ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST,
  ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST,
  ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST,
  ACTION_GET_PAYMENT_STATUS,
} from '../actions/payment';

const initialState = Immutable({
  payments: [],
  isGetPaymentsOnRequest: false,
  isGetPaymentsBanksOnRequest: false,
  isGetPaymentsAccountTypesOnRequest: false,
  paymentStatus: [],
  isGetPaymentStatusOnRequest: false,
});

export default function payment(state = initialState, action) {
  switch (action.type) {
    case ACTION_GET_PAYMENTS_DATA:
      return state.merge({
        payments: action.payload,
      });
    case ACTION_GET_PAYMENTS_IS_ON_REQUEST:
      return state.merge({
        isGetPaymentsOnRequest: action.payload,
      });
    case ACTION_GET_PAYMENTS_BANKS_IS_ON_REQUEST:
      return state.merge({
        isGetPaymentsBanksOnRequest: action.payload,
      });
    case ACTION_GET_PAYMENTS_ACCOUNT_TYPES_IS_ON_REQUEST:
      return state.merge({
        isGetPaymentsAccountTypesOnRequest: action.payload,
      });
    case ACTION_GET_PAYMENT_STATUS_IS_ON_REQUEST:
      return state.merge({
        isGetPaymentStatusOnRequest: action.payload,
      });
    case ACTION_GET_PAYMENT_STATUS:
      return state.merge({
        paymentStatus: action.payload,
      });
    default:
      return state;
  }
}

export function getPayments(state) {
  return state.payment.payments.filter((item) => !item.deletedAt);
}

export function getPaymentName(state, type) {
  const found = state.payment.payments.find((item) => item.type === type);
  if (found) return `${found.typeName}${found.optionName ? ` ${found.optionName}` : ''}`;
  return 'N/A';
}

export function isGetPaymentsOnRequest(state) {
  return state.payment.isGetPaymentsOnRequest;
}

export function isGetPaymentsBanksOnRequest(state) {
  return state.payment.isGetPaymentsBanksOnRequest;
}

export function isGetPaymentsAccountTypesOnRequest(state) {
  return state.payment.isGetPaymentsAccountTypesOnRequest;
}

export function isGetPaymentStatusOnRequest(state) {
  return state.payment.isGetPaymentStatusOnRequest;
}

export function getPaymentStatus(state) {
  const paymentsStatusFiltered = state.payment.paymentStatus?.map(({ id, type, name }) => {
    id = type;
    return { id, name };
  });
  return paymentsStatusFiltered;
}
